import Vue from "vue";
import { MutationTree } from "vuex";
import { PageState } from "@/store/currentPage/types";

export const mutations: MutationTree<PageState> = {
  set(state, { pageKey, componentKey, value }) {
    if (componentKey) {
      Vue.set(state.currentPage[pageKey], componentKey, value);
    } else {
      Vue.set(state.currentPage, pageKey, value);
    }
  },
};
