import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/organSpecificDetails/getters';
import { actions } from '@/store/organSpecificDetails/actions';
import { OrganDetails } from '@/store/organSpecificDetails/types';
import { mutations } from '@/store/organSpecificDetails/mutations';

const state: OrganDetails = {};

const namespaced = true;

export const organSpecificDetails: Module<OrganDetails, RootState> = {
  namespaced,
  getters,
  actions,
  mutations,
  state,
};