import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/waitlist/actions';
import { getters } from '@/store/waitlist/getters';
import { mutations } from '@/store/waitlist/mutations';
import { WaitlistState } from '@/store/waitlist/types';

const state: WaitlistState = {
  waitlistQueryResult: null,
};

const namespaced = true;

export const waitlist: Module<WaitlistState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state
};
