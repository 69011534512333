











































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { State, Getter } from 'vuex-class';
import { TableConfig } from '@/types';
import { VueGoodTable } from 'vue-good-table';
import { LabHLAAntibody } from '@/store/labs/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import HlaAntibodyModal from '@/components/hla/HlaAntibodyModal.vue';

export interface HlaAntibodiesFilterRow {
  _id?: { $oid: string };
  sampleCode: string;
  sampleDrawDate: string;
  laboratory: string;
  cpra1: string;
  cpra2: string;
  combinedCpra: string;
  selected_unacceptable: string[];
  selected_indeterminate: string[];
  selected_possible_allele_specific: string[];
}

@Component({
  components: {
    VueGoodTable,
    HlaAntibodyModal,
  }
})
export default class HlaAntibodyFilterTable extends mixins(DateUtilsMixin) {
  @Prop({ default: null }) tableConfig!: TableConfig|null;
  @Prop({ required: true }) id!: string;
  @Prop({ default: null }) testingMethod!: number|null;
  @Prop({ default: () => { return []; } }) selectedAntibodies!: string[];

  // HLA Antibody Labs filtered by testing method and selected antibodies
  get filteredRows(): HlaAntibodiesFilterRow[] {
    const rows = (this.tableConfig?.data || []);
    // Filter source HLA Antibodies Tests by testing method
    const method = this.testingMethod;
    const filteredByMethod = method ? rows.filter((lab: LabHLAAntibody) => {
      return lab.hla_class1_testing_method_code == method || lab.hla_class2_testing_method_code == method;
    }) : rows;
    // Build row data by identifying matching antibodies based on category
    const filteredByAntibody = filteredByMethod.map((matchingLab: LabHLAAntibody) => {
      if (matchingLab.antibodies) {
        // Concatenate antibodies by category
        const class1 = matchingLab.antibodies.class1 || {};
        const class2 = matchingLab.antibodies.class2 || {};
        const class1_unacceptable = (class1.antibodies?.unacceptable_allele_group || []).concat(class1.antibodies?.unacceptable_allele_specific || []).concat(class1.antibodies?.unacceptable_alpha_beta || []);
        const class1_indeterminate = (class1.antibodies?.indeterminate_allele_group || []).concat(class1.antibodies?.indeterminate_allele_specific || []).concat(class1.antibodies?.indeterminate_alpha_beta || []);
        const class2_unacceptable = (class2.antibodies?.unacceptable_allele_group || []).concat(class2.antibodies?.unacceptable_allele_specific || []).concat(class2.antibodies?.unacceptable_alpha_beta || []);
        const class2_indeterminate = (class2.antibodies?.indeterminate_allele_group || []).concat(class2.antibodies?.indeterminate_allele_specific || []).concat(class2.antibodies?.indeterminate_alpha_beta || []);
        // Possible Allele Specific (low-res legacy data only found in historical records)
        const class1_possible_allele_specific = class1.antibodies?.possible_allele_specific || [];
        const class2_possible_allele_specific = class2.antibodies?.possible_allele_specific || [];
        // Incorporate epitope antibodies
        const epitopes = matchingLab.antibodies.class1.epitopes || {};
        const epitopes_unacceptable = epitopes.unacceptable || [];
        const epitopes_indeterminate = epitopes.indeterminate || [];
        const unacceptable = class1_unacceptable.concat(class2_unacceptable).concat(epitopes_unacceptable);
        const indeterminate = class1_indeterminate.concat(class2_indeterminate).concat(epitopes_indeterminate);
        const possibleAlleleSpecific = class1_possible_allele_specific.concat(class2_possible_allele_specific);
        // Filter selected antibodies by category
        const unacceptableSelected = (this.selectedAntibodies || []).filter((antibody: string) => {
          return unacceptable.includes(antibody);
        });
        const indeterminateSelected = (this.selectedAntibodies || []).filter((antibody: string) => {
          return indeterminate.includes(antibody);
        });
        const possibleAlleleSpecificSelected = (this.selectedAntibodies || []).filter((antibody: string) => {
          return possibleAlleleSpecific.includes(antibody);
        });
        // Highlight the row if any antibodies match the filters
        const highlight = (unacceptableSelected.length + indeterminateSelected.length + possibleAlleleSpecificSelected.length) > 0;
        // Return matching HLA Antibodies Test with selected antibodies injected by category
        return {
          _id: matchingLab._id,
          sampleCode: matchingLab.test_code || '-',
          sampleDrawDate: this.parseDisplayDateUi(matchingLab.sample_date) || '-',
          laboratory: matchingLab.lab_code || '-',
          cpra1: matchingLab.cpra_class1 == null ? '-' : matchingLab.cpra_class1.toString(),
          cpra2: matchingLab.cpra_class2 == null ? '-' : matchingLab.cpra_class2.toString(),
          combinedCpra: matchingLab.cpra == null ? '-' : matchingLab.cpra.toString(),
          selected_unacceptable: unacceptableSelected,
          selected_indeterminate: indeterminateSelected,
          selected_possible_allele_specific: possibleAlleleSpecificSelected,
          highlight,
        };
      } else {
        return {
          _id: matchingLab._id,
          sampleCode: matchingLab.test_code || '-',
          sampleDrawDate: this.parseDisplayDateUi(matchingLab.sample_date) || '-',
          laboratory: matchingLab.lab_code || '-',
          cpra1: matchingLab.cpra_class1 == null ? '-' : matchingLab.cpra_class1.toString(),
          cpra2: matchingLab.cpra_class2 == null ? '-' : matchingLab.cpra_class2.toString(),
          combinedCpra: matchingLab.cpra == null ? '-' : matchingLab.cpra.toString(),
          selected_unacceptable: [],
          selected_indeterminate: [],
          selected_possible_allele_specific: [],
        };
      }
    });
    return filteredByAntibody;
  }

  // Open the HLA Antibody Modal
  private onRowClick(row: any): void {
    const selectedHlaAntibodyLabId = row?._id?.$oid;
    const modal = this.$refs.vxmAntibodyModal as HlaAntibodyModal;
    modal.initialize(selectedHlaAntibodyLabId);
  }

  // Row style class for Filter Table highlighting
  private rowStyleClass(row: any): string {
    if (row.highlight) {
      return 'set-link filterRow filterRowMatch';
    } else {
      return 'set-link filterRow';
    }
  }
}
