import i18n from '@/i18n';
import { GetterTree } from 'vuex';
import { format, parse, isValid } from 'date-fns';
import { RootState, ObjectId } from '@/store/types';
import { UtilitiesState } from '@/store/utilities/types';
import { TagObject, Format } from '@/store/utilities/types';
import { isMasked, idComparator } from '@/utils';

function removeMarkup(str: string): string {
  return str.replace(/<\/?[^>]+(>|$)/g, "");
}

/**
* Convert datetime with correct timezone offset
*
* @param datetime string containing a datetime
* @returns {string}
*/
function correctDateByTimeZone(datetime?: string) {
  if (!datetime) {
    return undefined;
  }
  const dt = new Date(datetime);
  let dtDateOnly:any;

  if ( datetime.includes("T00:00:00.000+00:00") || datetime.includes("T00:00:00.000Z")) {
    dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
  } else {
    dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() / 60 * 1000);
  }
  return dtDateOnly;
}

export const getters: GetterTree<UtilitiesState, RootState> = {
   translateError(state, getters) {
     /**
      * Get error message for UI components, deferring to i18n if needed
      *
      * @param error array of error values
      *
      * @returns {string} error message
      */
    return (error: any): string => {
      if (!error) return '';
      const errorMessage = typeof error == 'object' ? error[0] : error;
      // if error '.validation.message.not_blank
      if (errorMessage.startsWith('validation.') || errorMessage.startsWith('Request failed with status code')) {
        const key = errorMessage; // set key to validation.message.not_blank
        // if we have text to replace?
        if (key.indexOf(':') > -1) {
          const array = (key.split(':')[1]).split(','); // build an array
          const filteredKey = key.split(':')[0]; // get the i18n message text
          let message = i18n.tc(filteredKey);
          message = message.replaceAll('__first__', array[0]); // replace __first__ with what we have for array[0]
          message = message.replaceAll('__second__', array[1]); // replace __second__ with what we have for array[1]
          message = message.replaceAll('__all__', array.join(', ')); // replace __all__ with what we have for entire array
          return message; // return array
        } else {
          return i18n.tc(key); // otherwise return message from i18n list
        }
      } else {
        // otherwise return message as normal
        return removeMarkup(errorMessage);
      }
    };
  },
  /**
   * Calculate total number of days between two Date objects
   *
   * @param openingDate date to start counting
   * @param closingDate date to stop counting
   *
   * @returns {number} number of days
   */
  daysBetweenDates(state, getters, rootState, rootGetters) {
    return (openingDate: Date, closingDate: Date): number => {
      // Calculate the time duration between the events
      const duration = closingDate.getTime() - openingDate.getTime();
      // Convert the duration to a number of days
      const days = duration / (1000 * 60 * 60 * 24);
      // Round down if the result is positive, up if the result is negative
      return days > 0 ? Math.floor(days) : Math.ceil(days);
    };
  },
  /**
   * Sort a collection based on the specified field name
   *
   * @param collection array of objects
   * @param fieldName field name to sort with
   *
   * @returns {number} number of days
   */
  sortByDate(state, getters, rootState, rootGetters) {
    return (collection: any[], fieldName: string): any[] => {
      const sorted = collection.sort((a: any, b: any): number => {
        // Generate date objects for each date, assuming today's date if the parameter is missing
        const aDate = a[fieldName] ? new Date(a[fieldName]) : new Date();
        const bDate = b[fieldName] ? new Date(b[fieldName]) : new Date();
        return bDate.getTime() - aDate.getTime();
      });
      return sorted;
    };
  },
  /**
   * Return a string of organs the recipient is listed for.  In the case
   * of a single organ we provide only organCode.  If a cluster then clusterOrganCode
   * will be provided and should be used to determine which organs are in the cluster.
   *
   * @param organCode organ code for the recipient
   * @param clusterOrganCode cluster organ code for the recipient
   *
   * @returns {string} organs the recipient is listed for
   */
  clusterOrganCodeDisplayValue(state, getters, rootState, rootGetters) {
    return (organCode: number|null, clusterOrganCode?: string|null): string => {
      if (!organCode && !clusterOrganCode) return '';
      // Use the organName lookup getter
      const organName = rootGetters['lookups/organName'];
      // If we only have an organCode then return the organ name
      if (organCode != null && !clusterOrganCode) {
        return organName(organCode);
      }
      const result: string[] = [];
      // If we have a clusterOrganCode and it's a string
      if (clusterOrganCode && typeof clusterOrganCode === 'string') {
        // Cluster organ codes are separated with a '/'
        const clusterOrganCodes = clusterOrganCode.split('/');
        clusterOrganCodes.forEach((organCode: string) => {
          // If the organCode has a value
          if (organName(organCode)) {
            result.push(organName(organCode));
          }
        });
      }
      // Join all organs with a / separator
      return result.join(' / ');
    };
  },

  getDefaultPaginationOptions(state) {
    return {
      enabled: true,
      perPage: 25,
      mode: 'records',
      perPageDropdown: [10, 25, 100],
      dropdownAllowAll: true,
      nextLabel: 'Older',
      prevLabel: 'Newer',
      rowsPerPageLabel: 'Results per page',
    };
  },

  // Check for timezone
  hasTimeZone(state): (datetime: string) => boolean {
    return (datetime: string): boolean => {
      if (!datetime) return false;
      if (datetime.indexOf("+") > 0) return true;
      if (datetime.indexOf("Z") > 0) return true;
      return false;
    };
  },

  // Convert API date/time into format useable in extract methods for UI date input
  parseDateUiFromDateTime(state, getters): (datetime?: string|undefined|null) => string|undefined {
    return(datetime?: string|undefined|null): string|undefined => {
      if (!datetime) { return undefined; } // if no value, return undefined
      if (isMasked(datetime)) { return datetime; } // if masked return masked value to trigger masked layout in date-input

      const dtDateOnly = correctDateByTimeZone(datetime);
      return format(dtDateOnly, Format.DATE_FORMAT);
    };
  },

  // Convert API date into format useable in extract methods for UI date input
  parseDateUi(state, getters): (datetime?: string|undefined|null) => string|undefined {
    return(datetime?: string|undefined|null): string|undefined => {
      if (!datetime) { return undefined; } // if no value, return undefined
      if (isMasked(datetime)) { return datetime; } // if masked return masked value to trigger masked layout in date-input

      // NOTE: since DBv13.1.0 we can use date-only format
      const parsed = parse(datetime, Format.DATE_FORMAT, new Date());
      if (!isValid(parsed)) return undefined;

      return format(parsed, Format.DATE_FORMAT);
    };
  },

  // Convert API date into readable date string for plain-text display in UI template
  parseDisplayDateUi(state, getters): (datetime?: string|undefined|null) => string|undefined {
    return(datetime?: string|undefined|null): string|undefined => {
      if (!datetime || isMasked(datetime)) {
        return undefined;
      }

      // NOTE: since DBv13.1.0 we can use date-only format
      const parsed = parse(datetime, Format.DATE_FORMAT, new Date());
      if (!isValid(parsed)) return undefined;

      return format(parsed, Format.DISPLAY_DATE);
    };
  },

  // Convert API date into readable date string for plain-text display in UI template
  parseDisplayDateUiFromDateTime(state, getters): (datetime?: string|undefined|null) => string|undefined {
    return(datetime?: string|undefined|null): string|undefined => {
      if (!datetime || isMasked(datetime)) {
        return undefined;
      }

      const dtDateOnly = correctDateByTimeZone(datetime);
      return format(dtDateOnly, Format.DISPLAY_DATE);
    };
  },

  // Convert potential datetime string into just the readable date/time or undefined extract methods for ui
  // For display only
  // Note:
  // GMT will be +00:00
  // ETC will be -05:00
  parseDisplayDateTimeUi(state, getters): (datetime?: string|undefined|null) => string|undefined {
    return(datetime?: string|undefined|null): string|undefined => {
      if (!datetime || isMasked(datetime)) {
        return undefined;
      }
      // Generate date object and format output as date string
      const dateObject = new Date(datetime);
      return format(dateObject, Format.DISPLAY_DATE_TIME);
    };
  },

  // Convert API datetime string into just the readable date/time or undefined extract methods for ui
  parseDisplayDateTimeUiFromDateTime(state, getters): (datetime?: string|undefined|null) => string|undefined {
    return(datetime?: string|undefined|null): string|undefined => {
      if (!datetime || isMasked(datetime)) {
        return undefined;
      }
      // Generate date object and format output as date string
      const dateObject = new Date(datetime);
      return format(dateObject, Format.DISPLAY_DATE_TIME);
    };
  },

  // Convert potential datetime string into just the readable time portion or undefined extract methods for ui
  parseTimeUiFromDateTime(state, getters): (datetime?: string|undefined|null) => string|undefined {
    return(datetime?: string|undefined|null): string|undefined => {
      if (!datetime) { return undefined; } // if no value, return undefined
      if (isMasked(datetime)) { return datetime; } // if masked return masked value to trigger masked layout in date-input

      // Generate date object and format output as time string
      const dateObject = new Date(datetime);
      return format(dateObject, Format.TIME_FORMAT);
    };
  },

  // Convert potential datetime string into just the readable time portion or undefined extract methods for ui
  parseTimeUi(state, getters): (datetime?: string|undefined|null) => string|undefined {
    return(datetime?: string|undefined|null): string|undefined => {
      if (!datetime) { return undefined; } // if no value, return undefined
      if (isMasked(datetime)) { return datetime; } // if masked return masked value to trigger masked layout in date-input

      // Generate date object and format output as time string
      const dateObject = new Date(datetime);
      return format(dateObject, Format.TIME_FORMAT);
    };
  },

  // Convert potential datetime string into just the readable date portion or undefined extract methods for ui
  parseDateTimeUi(state, getters): (datetime?: string) => string|undefined {
    return(datetime?: string): string|undefined => {
      if (!datetime) {
        return undefined;
      }
      // Generate date object and format output as date string
      const dateObject = new Date(datetime);
      return format(dateObject, Format.DATE_TIME_FORMAT);
    };
  },

  // Convert potential datetime string into just the readable date portion or undefined extract methods for ui
  parseFormattedDateUi(state, getters): (datetime?: string) => string|undefined {
    return(datetime?: string): string|undefined => {
      if (!datetime) {
        return undefined;
      }
      const dtDateOnly = correctDateByTimeZone(datetime);
      return format(dtDateOnly, Format.FORMATTED_DATE);
    };
  },

  // Convert potential datetime string into just the readable date portion or undefined extract methods for ui
  parseFormattedDateTimeUi(state, getters): (datetime?: string) => string|undefined {
    return(datetime?: string): string|undefined => {
      if (!datetime) {
        return undefined;
      }
      const dtDateOnly = correctDateByTimeZone(datetime);
      return format(dtDateOnly, Format.DISPLAY_DATE_TIME);
    };
  },

  // Convert API datetime string into just the readable date portion or undefined extract methods for ui
  parseFormattedDateTimeUiFromDateTime(state, getters): (datetime?: string) => string|undefined {
    return(datetime?: string): string|undefined => {
      if (!datetime) {
        return undefined;
      }
      const dtDateOnly = correctDateByTimeZone(datetime);
      return format(dtDateOnly, Format.DISPLAY_DATE_TIME);
    };
  },

  // Correct timezone offset for date
  correctTimeOffset(state, getters): (date: string|undefined|null) => string|undefined|null {
    return(date: string|undefined|null): string|undefined|null => {
      if (!date) return date;
      const dt = new Date(date);
      const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
      return dtDateOnly.toISOString();
    };
  },

  /**
   * Convert date and time into a valid ISO 8601 datetime for API
   *
   * Will return 'Invalid' string if combined datetime can't be parsed so API can generate an error
   *
   * @param date string containing a date
   * @param time string containing a time
   * @returns {string|null} ISO 8601 datetime if valid date/time, 'Invalid' if invalid date/time, null if both date/time missing
   */
  sanitizeDateTimeApi(state, getters): (date?: string|null, time?: string|null) => string|null {
    return(date?: string|null, time?: string|null): string|null => {
      // If both values are blank, then return null.
      // API will permit a null date only if it is an optional property
      if (!date && !time) return null;

      // If only date or only time are provided, then return invalid 'Invalid' string to generate API error
      // API must reject this value, because a date/time field is valid only if both date and time are provided.
      if (!date || !time) return 'Invalid';

      // browser should add offset
      const dt = new Date(`${date}T${time}`);

      return format(dt, Format.DATE_TIME_ISO);
    };
  },
  // /**
  //  * Convert date and time into a valid ISO 8601 datetime for API
  //  *
  //  * API can't validate date and time separate so this a temporary solution.
  //  * Will return '-' if combined datetime can't be parsed so API can generate an error
  //  *
  //  * @param date string containing a date
  //  * @param time string containing a time
  //  * @returns {string} valid ISO 8601 datetime string or '-' if invalid
  //  */
  sanitizeDateWithRequiredTimeApi(state, getters, rootState, rootGetters): (date: string, time?: string) => string|undefined {
    return(date: string, time?: string): string|undefined => {
      if (!time) {
        return undefined;
      }
      // Combine date and time
      const datetimeString = `${date}T${time}`;
      // Sanitize date and time
      const timestamp = Date.parse(datetimeString);
      if (isNaN(timestamp)) {
        // Date failed so return a non-blank string so API can generate an error
        // An empty string does not validate on API so we need to send something
        return undefined;
      } else {
        const dateObject = new Date(timestamp);
        return dateObject.toISOString();
      }
    };
  },
  /**
   * Convert date into a valid date-only string for API
   *
   * Will return null if date can't be parsed so API can generate an error
   *
   * @param date string containing a date
   * @returns {string|null} date-only string, null if date is missing
   */
  sanitizeDateApi(state, getters, rootState, rootGetters): (date?: string|null) => string|null {
    return(date?: string|null): string|null => {
      // API will permit a null date only if it is an optional property
      if (!date) return null;
      if (isNaN(Date.parse(date))) return 'Invalid';

      // NOTE: since DBv13.1.0 we can use date-only format
      const parsed = parse(date, Format.DATE_FORMAT, new Date());

      return format(parsed, Format.DATE_FORMAT);
    };
  },
  sanitizeDateWithOptionalTimeApi(state, getters, rootState, rootGetters): (date?: string, time?: string) => any {
    return(date?: string, time?: string): any => {
      if (date && time) {
        return getters.sanitizeDateWithRequiredTimeApi(date, time);
      } else if (date && !time) {
        return getters.sanitizeDateApi(date);
      } else {
        return undefined;
      }
    };
  },
  stripHTML() {
    return(value = ''): string => {
      return value.replace(/<[^>]*>?/gm, '');
    };
  },
  /**
   * Convert time in seconds to hours/minute format
   *
   *
   * @param value string containing a time in seconds
   * @returns {string} HH::mm
   */
  convertTimeInSeconds(): (value: number)=> string|null {
    return (value: number): string|null => {
      // If value is masked, then user does not have permission to see time field
      if(isMasked(value)) return null;

      const hours = Math.floor(value / 3600);
      const minutes = Math.floor(value % 3600 / 60);

      return  hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
    };
  },

  /**
   * Convert lookup to tag values
   *
   *
   * @param lookup lookup data object
   * @returns TagObject[] vue-tags-input tags
   */
   getTagsFromLookup() {
    return (lookup: any[]): TagObject[] => {
      if (!lookup) return [];
      const newTags: any[] = [];
      lookup.map((item: any) => {
        // rather than use the built-in command we add our own
        // to keep our code value and distinguish 'other' from 'other'
        newTags.push({
          text: item.value,
          code: item.code,
          expired_date: item.expired_date,
          disabled: item.disabled,
          tiClasses: ['ti-valid']
        });
      });
      return newTags;
    };
  },

  /**
   * Return boolean to represent if the id supplied is the most recent entry in the collection
   * in vue-x state, corresponding to specified module name and collection key
   *
   * Note: assumes that API has sorted the collection, and UI has not changed its order
   *
   * @param id objectId to compare
   * @param entries array of entries to check against
   * @returns {boolean} true if this is the latest entry
   */
   isLastEntry(state, getters, rootState, rootGetters): (id: ObjectId, entries: any[]) => boolean {
    return(id: ObjectId, entries: any[]): boolean => {
      // If no entries to check, then no this is not the last entry
      if (!entries || entries.length === 0) return false;

      // If we have entries, fetch the most recent
      const lastEntryIndex = 0; // Api has already sorted the collection, latest one will be first in the array
      const lastEntry = entries[lastEntryIndex];
      if (!lastEntry) return false;

      // Return true only if the specified ID matches the last array item's ID
      const result = idComparator({ _id: id }, lastEntry);
      return result;
    };
  },

  /*
  * Check if date is expired
  * Mainly used to check if a lookup entry is expired by checking their expiry_date field
  *
  * returns false if there is no expiry_date or the expiry_date hasn't occured yet
  * returns true if there is an expiry_date and the date is in the past
  *
  * @param expired_date date to check
  * @returns {boolean} vue-tags-input tags
  */
 isExpired(): (expired_date: string|undefined) => boolean {
   return (expired_date: string|undefined) => {
     if (expired_date === undefined) return false; // No expired date? keep it

     const expiryTimestamp = Date.parse(expired_date);
     const cutOffExpiry = new Date().getTime(); // Default cutoff date is today

     if (isNaN(expiryTimestamp)) return false; // Invalid expired date? keep it

     return expiryTimestamp < cutOffExpiry; // Valid expired date? keep only if expiry is after cutoff
   };
 },

 getTelephoneMask(): string {
   return Format.TELEPHONE_MASK;
 },

  // Convert potential datetime string into just the readable date portion or undefined extract methods for ui
  anotherFormatDate(state, getters): (datetime?: string|undefined|null) => string|undefined {
    return(datetime?: string|undefined|null): string|undefined => {
      if (!datetime) { return undefined; } // if no value, return undefined
      if (isMasked(datetime)) { return datetime; } // if masked return masked value to trigger masked layout in date-input
      // Generate date object and format output as date string
      const dateObject = new Date(datetime);

      return format(dateObject, Format.DATE_FORMAT);
    };
  },
};
