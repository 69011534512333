import axios from 'axios';
import { ActionTree, Dispatch, Commit } from 'vuex';
import { SystemOrganTypes, RootState, GenericCodeValue } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { LookupsState, Country, Organ, OrganDiseaseCode, OrganSpecification, HlaDictionaryEntry, OrganCauseOfFailure } from '@/store/lookups/types';

const lookupSubTableHandler: { [key: string]: any } = {
  country: (countries: Country[], commit: Commit) => {
    // loop over each country to retrieve sub_table data then set it
    countries.forEach(country => {
      switch(country.code) {
        case "CAN": {
          // retrieve provinces from sub_tables
          const provinces = country.sub_tables.province;
          commit('loadSubTables', {
            id: 'province',
            value: provinces
          });
          break;
        }
        case "USA": {
          // retrieve USA states from sub_tables
          const states = country.sub_tables.province;
          commit('loadSubTables', {
            id: 'us_state',
            value: states
          });
          break;
        }
      }
    });
  },

  organ: (organs: Organ[], commit: Commit) => {
    commit('setSystemOrganTypes', organs, { root: true });
  }
};

export const actions: ActionTree<LookupsState, RootState> = {
  load({ commit, dispatch }, lookupId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const _lookupId = lookupId.toString().toLowerCase();
      const url = APIRoute(EP.lookups.show, [[':id', _lookupId]]);
      axios.get(url).then((response: any) => {
        // no longer filter out expired lookups on load, do that separately
        const responseData: any[] = response.data;
        commit('set', { id: _lookupId, value: responseData });
        // if there are any subtables, load them here
        if(lookupId.match(/(\bcountry\b)|(\borgan\b)/)){
          const structuredLookup = lookupSubTableHandler[lookupId](responseData, commit);
        }
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  loadHlaDictionary({ commit }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.lookups.hla_dictionary);
      axios.get(url).then((response: any) => {
        const hlaDictionary: HlaDictionaryEntry[] = response.data;
        commit('set', { id: 'hla_dictionary', value: hlaDictionary });
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  },
  loadRecipientJourneyStates({ commit }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.lookups.recipient_journey_states);
      axios.get(url).then((response: any) => {
        const recipientJourneyStates: GenericCodeValue[] = response.data.recipient_journey_states;
        commit('set', { id: 'recipient_journey_states', value: recipientJourneyStates });
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  },
  // TODO: Add the remaining sub_tables
  // cause_of_failures, waitlist_medical_statuses, offer_decline_reasons
  getDiseaseCodes({ state }, organCode: number): Promise<OrganDiseaseCode[]> {
    return new Promise<OrganDiseaseCode[]>((resolve, reject) => {
      const organ = (state.organ as Organ[]);
      const code = organ.find(e => e.code === organCode );
      const diseaseCode = code ? code.sub_tables.disease_code : [];
      resolve(diseaseCode);
    });
  },
  getRecipientOrganSpecifications({ state }, organCode: number): Promise<OrganSpecification[]> {
    return new Promise<OrganSpecification[]>((resolve, reject) => {
      const organ = (state.organ as Organ[]);
      const code = organ.find(e => e.code === organCode );
      const organSpecifications: OrganSpecification[] = code?.sub_tables?.organ_specifications || [];
      const recipientOrganSpec: OrganSpecification[] = organSpecifications.filter((organSpec: OrganSpecification) => {
        return !!organSpec.recipient;
      });
      resolve(recipientOrganSpec);
    });
  },
  getOfferOrganSpecifications({ state }, organCode: number): Promise<OrganSpecification[]> {
    return new Promise<OrganSpecification[]>((resolve, reject) => {
      const organ = (state.organ as Organ[]);
      const code = organ.find(e => e.code === organCode );
      const organSpecifications: OrganSpecification[] = code?.sub_tables?.organ_specifications || [];
      const offerOrganSpec: OrganSpecification[] = organSpecifications.filter((organSpec: OrganSpecification) => {
        return !!organSpec.offer;
      });
      resolve(offerOrganSpec);
    });
  },
  getTransplantOrganSpecifications({ state }, organCode: number): Promise<OrganSpecification[]> {
    return new Promise<OrganSpecification[]>((resolve, reject) => {
      const organ = (state.organ as Organ[]);
      const code = organ.find(e => e.code === organCode );
      const organSpecifications: OrganSpecification[] = code?.sub_tables?.organ_specifications || [];
      const transplantOrganSpec: OrganSpecification[] = organSpecifications.filter((organSpec: OrganSpecification) => {
        return !!organSpec.transplant;
      });
      resolve(transplantOrganSpec);
    });
  },
  loadNotificationChannels({ commit }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.lookups.notification_channels);
      axios.get(url).then((response: any) => {
        const notificationChannels: any[] = response.data;
        commit('set', { id: 'notification_channels', value: notificationChannels });
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  },
  loadNotifiableEventChannels({ commit }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.lookups.notifiable_event_channels);
      axios.get(url).then((response: any) => {
        const notificationChannels: any[] = response.data;
        commit('set', { id: 'notifiable_event_channels', value: notificationChannels });
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  },

};
