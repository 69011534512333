import { Vue } from 'vue-property-decorator';

export interface TableConfig {
  data: any[];
  columns: any[];
  empty?: string;
  createButton?: boolean;
  createText?: string;
  groupOptions?: any;
  pagination?: boolean;
  paginationOptions?: any;
  sortOptions?: any;
  searchOptions?: any;
}

export interface SaveableSection {
  savePatch: (duplicate?: boolean) => void;
  extractPatch: () => any;
  resetSaveToolbar: () => void;
}

export interface SaveProvider {
  performSave: (duplicate?: boolean) => void;
  registerSaveResult: (result: SaveResult) => void;
  resetSaveToolbar: () => void;
  parseErrors?: (errors: SaveResult) => void;
}

export enum SaveState {
  Idle,
  Saving,
  Success,
  Error
}

export interface SaveResult {
  success: boolean;
  errorMessages?: string[];
  validationErrors?: any;
  responseData?: any;
  data?: any;
  warning?: boolean;
  warningMessage?: string;
  warningExceptions?: any;
}

export interface DismissableNotification {
  text: string;
  dismissed: boolean;
}

export interface ClassObject {
  [key: string]: boolean;
}

export interface CtrErrorContext {
  ctr_error_id: string;
  ctr_error_message: string;
  parsedValue?: string;
}

export const UNKNOWN = 'unknown';

// Define regular expressions used to extract key details from CTR error message
// NOTE: affects Allocation 'Special Considerations' and 'CTR error workflow' offer outcome popup
export const CTR_ERROR_MESSAGE_PARSERS: { ctrMethod: string; ctrErrorId: string; ctrErrorMessageParser: RegExp; }[] = [
  {
    ctrMethod: 'runHSPMatchOnDonor',
    ctrErrorId: 'hsp.missingTest',
    ctrErrorMessageParser: /^Donor (.*)$/,
  },
  {
    ctrMethod: 'runHSPMatchOnDonor',
    ctrErrorId: 'hsp.invalidSerologyResult',
    ctrErrorMessageParser: /^(.*) has no value of Positive, Negative or Indeterminate$/,
  },
  {
    ctrMethod: 'runHSPMatchOnDonor',
    ctrErrorId: 'hsp.pendingFound',
    ctrErrorMessageParser: /(.*) cannot be "Pending"$/,
  },
  {
    ctrMethod: 'runHSPMatchOnDonor',
    ctrErrorId: "data.error.invalidNationalID",
    ctrErrorMessageParser: /^National patient ID (.*) is not valid$/,
  },
  {
    ctrMethod: 'getHSPMatchforDonor',
    ctrErrorId: "data.error.invalidNationalID",
    ctrErrorMessageParser: /^National patient ID (.*) is not valid$/,
  },
  {
    ctrMethod: 'runHSPMatchOnDonor',
    ctrErrorId: "data.error.patientnotexist",
    ctrErrorMessageParser: /^Patient with national ID (.*) does not exist in the registry$/,
  },
  {
    ctrMethod: 'getHSPMatchforDonor',
    ctrErrorId: "patient.error.patientType",
    ctrErrorMessageParser: /^Patient with ID \[(.*)\] is not a DeceasedDonor.$/,
  },
  {
    ctrMethod: 'getHSPMatchforDonor',
    ctrErrorId: "data.error.patientnotexist",
    ctrErrorMessageParser: /^Patient with national ID (.*) does not exist in the registry$/,
  },
];

export const organCodeLookup: {[key: string]: string} = {
  '1'  : 'liver',
  '2'  : 'heart',
  '3'  : 'kidney',
  '4'  : 'lung',
  '6'  : 'pancreas-whole',
  '6.5': 'pancreas-islets',
  '7'  : 'small-bowel',
  '7.5': 'vca'
};

export const organTransplantDetails: {[key: string]: string} = {
  '1': 'liver-transplant-details',
  '2': 'heart-transplant-details',
  '3': 'kidney-transplant-details',
  '4': 'lung-transplant-details',
  '6': 'pancreas-whole-transplant-details',
  '6.5': 'pancreas-islets-transplant-details',
  '7': 'small-bowel-transplant-details',
  '7.5': 'vca-transplant-details',
};

export interface ModalContent {
  style?: string;
  body?: string;
}

export interface ApplicationLoadingTracker {
  [key: string]: {
    currentState: 'loading' | 'loaded',
    details?: any;
  }
}

export interface TranslationContext {
  key: string;
  values?: {
    [key: string]: string;
  };
}
