











































































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { ObjectId, NumericCodeValue } from '@/store/types';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { LabCrossmatch } from '@/store/labs/types';
import { Laboratory } from '@/store/laboratories/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { SerumCrossmatchPageState } from '@/components/deceasedDonors/_SerumCrossmatchSection.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import TimeInput from '@/components/shared/TimeInput.vue';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';

export interface CrossmatchInfoPageState {
  donorId?: number;
  testingLab?: string|null;
  testDate?: string;
  testTime?: string;
  donorSampleCode?: string;
  donorSampleDate?: string|null;
  sampleType?: string;
  methodTechnique?: number;
  result?: boolean|null;
  resultReason?: string;
}

interface CrossmatchInfoRow {
  _id?: ObjectId;
  testDate?: string;
  methodTechnique?: number;
  result?: string;
  resultReason?: string;
}

@Component({
  components: {
    TextInput,
    DateInput,
    TimeInput,
    SubSection,
    SelectInput,
    NumberInput,
    BooleanRadioInput,
  }
})
export default class CrossmatchInfoSection extends mixins(DateUtilsMixin) implements SaveableSection {
  @State(state => state.deceasedDonors.selected) private deceasedDonor!: DeceasedDonor;
  @State(state => state.labs.crossmatchLabs) private crossmatchLabs!: LabCrossmatch[];
  @State(state => state.labs.selectedCrossmatch) private selectedCrossmatch!: LabCrossmatch;
  @State(state => state.laboratories.hla) private hlaLaboratoryLookup!: Laboratory[];
  @State(state => state.pageState.currentPage.serumCrossmatch) editState!: SerumCrossmatchPageState;
  @State(state => state.lookups.crossmatch_method_techniques) private techniqueLookup!: NumericCodeValue[];
  @State(state => state.lookups.crossmatch_sample_type) private sampleLookup!: NumericCodeValue[];

  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: number;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;

  // Permissions
  get canSave(): boolean {
    return this.checkAllowed('/donors/:donor_id/crossmatch_labs', 'POST');
  }

  /**
   * Gets table data for donor crossmatch labs
   *
   * @returns {CrossmatchInfoRow[]} crossmatch labs table data
   */
  get crossmatchRows(): CrossmatchInfoRow[] {
    if (!this.crossmatchLabs) {
      return [];
    }
    const result = this.crossmatchLabs.map((crossmatch: LabCrossmatch) => {
      return {
        _id: crossmatch._id,
        testDate: this.parseDisplayDateUiFromDateTime(crossmatch.crossmatch_date || '') || '-',
        methodTechnique: this.lookupValue(crossmatch.method_technique?.toString(), 'crossmatch_method_techniques') || '-',
      };
    });
    // Return results, already sorted by api
    return result;
  }

  /**
   * Gets table configuration for crossmatch labs
   *
   * @return {TableConfig} crossmatch labs table configuration
   */
  get crossmatchTableConfig(): TableConfig {
    return {
      data: this.crossmatchRows,
      columns: [
        { label: this.$t('test_date').toString(),        field: 'testDate',        width: '25%' },
        { label: this.$t('method_technique').toString(), field: 'methodTechnique', width: '25%' },
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: this.canSave,
      createText: this.$t('create_crossmatch_info').toString(),
      pagination: true
    };
  }

  /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.saveCrossmatchInfo as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }
  
  // 
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveCrossmatchInfo as unknown as SaveProvider;
    // Clear errors before saving
    this.$emit('clear');
    // Generate payload based on current edit state
    const crossmatchInfoPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      donorId: this.clientId,
      crossmatchLab: crossmatchInfoPatch,
      id: this.selectedCrossmatch ? this.selectedCrossmatch?._id?.$oid : undefined,
    };
    this.$store.dispatch('labs/saveCrossmatchInfo', payload).then((success: SaveResult) => {
      // Reload crossmatch labs
      this.$store.dispatch('labs/loadCrossmatchLabs', this.clientId);
      // set the selected Crossmatch Lab to the successfully saved Crossmatch Lab
      this.$store.commit('labs/selectCrossmatchLab', success.responseData.lab_crossmatch);
      this.initializeCrossmatchInfoForm();
      // Show success notification
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Gets a patch object representing form edit state changes for this form
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {LabCrossmatch} patch object containing field changes
   */
  public extractPatch(): LabCrossmatch {
    if (!this.editState || !this.editState.crossmatchInfo) {
      return {};
    }
    
    const crossmatchInfo = this.editState.crossmatchInfo;
    const crossmatchDateTime = this.sanitizeDateTimeApi(crossmatchInfo.testDate,crossmatchInfo.testTime);
    
    let result = {
      lab_code: crossmatchInfo.testingLab || null,
      crossmatch_date: crossmatchDateTime,
      test_code: crossmatchInfo.donorSampleCode,
      test_date: crossmatchInfo.donorSampleDate,
      sample_type: crossmatchInfo.sampleType,
      method_technique: crossmatchInfo.methodTechnique,
    };

    return result;
  }

  /**
   * Builds a crossmatchInfoPageState (optional crossmatch)
   *
   * @param crossmatch LabCrossmatch record
   * @returns {CrossmatchInfoPageState} pageState for this component
   */ 
  public buildCrossmatchInfoPageState(crossmatch?: LabCrossmatch): CrossmatchInfoPageState {
    const defaultState: CrossmatchInfoPageState  = {
      donorId: this.deceasedDonor.deceased_donor_id,
      testDate: this.currentDateUi(),
    };

    if (!!crossmatch) {
      let result: CrossmatchInfoPageState = {
        donorId: this.deceasedDonor.deceased_donor_id,
        testingLab: crossmatch.lab_code,
        testDate: this.parseDateUiFromDateTime(crossmatch.crossmatch_date || ''),
        testTime: this.parseTimeUiFromDateTime(crossmatch.crossmatch_date || ''),
        donorSampleCode: crossmatch.test_code,
        donorSampleDate: crossmatch.test_date,
        sampleType: crossmatch.sample_type,
        methodTechnique: crossmatch.method_technique,
      };

      return result;
    }
    return defaultState;
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: {[key: string]: string} = {
    'lab_crossmatch.crossmatch_date'  : 'crossmatch-info-test_date',
    'lab_crossmatch.crossmatch_time'  : 'crossmatch-info-test_time',
    'lab_crossmatch.lab_code'         : 'crossmatch-info-testing_lab',
    'lab_crossmatch.test_code'        : 'crossmatch-info-donor_sample_code',
    'lab_crossmatch.test_date'        : 'crossmatch-info-donor_sample_date',
    'lab_crossmatch.sample_type'      : 'crossmatch-info-sample_type',
    'lab_crossmatch.method_technique' : 'crossmatch-info-crossmatch_technique',
  };
  
  // Initialize component with or without a record
  public initializeCrossmatchInfoForm(crossmatch?: LabCrossmatch): void {
    // TODO: Add a clear error method here
    
    // Commit the selected lab if we have one or clear anything selected
    if (crossmatch) {
      this.$store.commit('labs/selectCrossmatchLab', crossmatch);
    } else {
      this.$store.commit('labs/clearCrossmatchLab');
      this.$store.commit('labs/clearCrossmatchSample');
    }
    // Initialize subsection component pageState
    this.$store.commit('pageState/set', {
      pageKey: 'serumCrossmatch',
      componentKey: 'crossmatchInfo',
      value: this.buildCrossmatchInfoPageState(crossmatch),
    });
  }
  
  // PRIVATE

  /**
   * Builds form edit state based on selected document
   *
   * @param event select event
   */
  private selectCrossmatchLab(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (!selectedId || !this.crossmatchLabs) {
      return;
    }
    // Find the selected source document
    const found = this.crossmatchLabs.find((each: LabCrossmatch) => {
      return each._id && each._id.$oid === selectedId;
    });
    if (!found) {
      return;
    }
    // Build form state based on selected document
    this.initializeCrossmatchInfoForm(found);
  }

  /**
   * Resets edit state to prepare for entering a new document
   */
  private createCrossmatchLab(): void {
    this.initializeCrossmatchInfoForm();
    this.$emit('initializeSection');
    this.resetSaveToolbar();
  }
}
