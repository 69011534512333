















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PageTop extends Vue {
  /**
   * Get a string representing the current Node environment e.g. production, development
   *
   * @returns {string} current Node environment
   */
  get nodeEnvironment(): string {
    return process.env.VUE_APP_DEPLOY_VERSION || process.env.NODE_ENV;
  }
}
