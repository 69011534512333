import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { LivingDonorsState } from '@/store/livingDonors/types';
import { actions } from '@/store/livingDonors/actions';
import { mutations } from '@/store/livingDonors/mutations';
import { getters } from '@/store/livingDonors/getters';

const state: LivingDonorsState = {
  selectedLivingDonor: undefined,
  livingDonorsList: undefined,
  selectedIntendedRecipient: null,
};
  
const namespaced = true;
  
export const livingDonors: Module<LivingDonorsState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state
};