























































































































































































































































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { uniqueElements } from '@/utils';
import { Getter, State } from "vuex-class";
import { TagObject } from '@/store/utilities/types';
import { Recipient } from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import { VueTagsInput, createTag, createTags } from '@johmun/vue-tags-input';
import { GenericCodeValue, NumericCodeValue, ObjectId } from '@/store/types';
import { RecipientJourney, JourneyStage } from '@/store/recipientJourney/types';
import { TableConfig, SaveableSection, SaveProvider, SaveResult } from '@/types';
import { LivingDonor, LivingDonorJourney, LivingDonorIntendedRecipient } from "@/store/livingDonors/types";

interface LinkToRecipientHistoryRow {
  _id?: ObjectId;
  linkDate: string;
  removedDate: string;
  recipientId: string;
  recipientFirstName: string;
  recipientLastName: string;
  donorRelationship: string;
}

interface LinkToRecipientForm {
  recipientSearch: RecipientSearchForm;
  recipientDetails: RecipientDetailsForm;
  unlinkRecipient: UnlinkRecipientForm;
}

interface RecipientSearchForm {
  searchTerm: string;
  tags: string[];
}

interface RecipientDetailsForm {
  linkDate: string|null;
  recipientId: string|null;
  recipientJourneyId: string|null;
  firstName: string|null;
  lastName: string|null;
  sex: string|null;
  dateOfBirth: string|null;
  insuranceNumber: string|null;
  referringOrganizationName: string|null;
  donorRelationshipToRecipient: string|null;
}

interface UnlinkRecipientForm {
  unlinkDonorAndRecipient: boolean;
  removeDate: string|null;
  reasonForUnlinking: number|null;
  reasonIfOther: string|null;
}

// Constants related to recipient search index query
const INDEX_SEARCH_FIELDS = [
  'client_id',
  'first_name',
  'last_name',
];
const INDEX_SEARCH_STRATEGY = 'or';
const INDEX_PAGE_SIZE = 25;
const INDEX_PAGE = 1;

const RECIPIENT_JOURNEY_STAGES_ELIGIBLE_FOR_LINK_TO_LIVING_DONOR = [
  JourneyStage.Referral,
  JourneyStage.Assessment,
  JourneyStage.Waitlist,
];

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    SelectInput,
    CardSection,
    VueTagsInput,
    CheckboxInput,
    SelectOtherInput,
  }
})
export default class LinkToRecipient extends mixins(DateUtilsMixin) implements SaveableSection {
  // Component properties
  @Prop({ default: false }) canSave!: boolean;
  @Prop({ default: false }) newLivingDonor!: boolean;

  // Vue-x store state
  @State(state => state.recipients.selectedRecipient) selectedRecipient!: Recipient;
  @State(state => state.lookups.relationship) relationshipLookup!: NumericCodeValue[];
  @State(state => state.pageState.currentPage.linkToRecipient) editState!: LinkToRecipientForm;
  @State(state => state.lookups.unlink_reason) livingDonorUnlinkReasonsLookup!: NumericCodeValue[];
  @State(state => state.livingDonors.selectedIntendedRecipient) selectedIntendedRecipient!: LivingDonorIntendedRecipient|null;

  // Vue-x store getters
  @Getter('clientId', { namespace: 'livingDonors' }) livingDonorId!: string;
  @Getter('showList', { namespace: 'recipients' }) recipientList!: { entries: any[] };
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any) => string;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('selectedLivingDonorJourney', { namespace: 'livingDonors' }) selectedLivingDonorJourney!: LivingDonorJourney|null;
  @Getter('getHospitalAbbreviation', { namespace: 'hospitals' }) getHospitalAbbreviation!: (hospitalCode?: string|null) => string|null;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = [
    'relationship',
    'unlink_reason',
  ];

  // Local component state
  private isLoadingRecipientList = false;
  private isLoadingSelectedRecipient = false;
  private selectedHistoryRow: LinkToRecipientHistoryRow|null = null;

  // Configure table for the sub-section component
  get linkToRecipientTableConfig(): TableConfig {
    return {
      data: this.linkToRecipientHistoryRows,
      columns: [
        { label: this.$t('link_date_column').toString(), field: 'linkDate' },
        { label: this.$t('removed_date_column').toString(), field: 'removedDate' },
        { label: this.$t('recipient_id_column').toString(), field: 'recipientId' },
        { label: this.$t('recipient_first_name_column').toString(), field: 'recipientFirstName' },
        { label: this.$t('recipient_last_name_column').toString(), field: 'recipientLastName' },
        { label: this.$t('donor_relationship_column').toString(), field: 'donorRelationship' },
      ],
      empty: this.$t('link_history_empty').toString(),
      createButton: this.canSave,
      createText: this.$t('create_new_recipient_link_button').toString(),
    };
  }

  // Rows to show in sub-section table
  get linkToRecipientHistoryRows(): LinkToRecipientHistoryRow[] {
    if (!this.selectedLivingDonorJourney) return [];

    const intendedRecipients: LivingDonorIntendedRecipient[] = this.selectedLivingDonorJourney.intended_recipients || [];
    const rows: LinkToRecipientHistoryRow[] = intendedRecipients.map((intendedRecipient: LivingDonorIntendedRecipient): LinkToRecipientHistoryRow => {
      const recipientId = intendedRecipient.recipient?.client_id;
      const donorRelationshipCode = intendedRecipient.donor_relationship_code;
      const donorRelationship = donorRelationshipCode ? this.lookupValue(donorRelationshipCode.toString(), 'relationship') : null;
      return {
        _id: intendedRecipient._id,
        linkDate: this.parseDisplayDateUi(intendedRecipient.link_date) || '-',
        removedDate: this.parseDisplayDateUi(intendedRecipient.unlink_date) || '-',
        donorRelationship: donorRelationship || '-',
        recipientId:  recipientId ? recipientId.toString() : '-',
        recipientFirstName: intendedRecipient.recipient?.patient_profile?.first_name || '-',
        recipientLastName: intendedRecipient.recipient?.patient_profile?.last_name || '-',
      };
    }).reverse();
    return rows;
  }

  // Disable the card-section, sub-section, buttons, and fields?
  get isDisabled(): boolean {
    // Note: assumes we do not save Link To Recipient from New Living Donor page
    return !this.canSave || !this.editState || this.newLivingDonor;
  }

  // Disable the Unlink Recipient form area?
  get isUnlinkAreaDisabled(): boolean {
    return !this.selectedIntendedRecipient;
  }

  // Disable the Unlink Checkbox field?
  get isUnlinkFieldDisabled(): boolean {
    if (!this.selectedIntendedRecipient) return false;

    return !!this.selectedIntendedRecipient.unlink_date;
  }

  // Disable the Reason for Unlinking field?
  get isReasonForUnlinkingDisabled(): boolean {
    if (!this.editState || !this.editState.unlinkRecipient) return true;

    return !this.editState.unlinkRecipient.unlinkDonorAndRecipient;
  }

  // Was Other selected for Reason for Unlinking?
  get isReasonForUnlinkingOther(): boolean {
    if (!this.editState || !this.editState.unlinkRecipient) return false;

    const reasonForUnlinking = this.editState.unlinkRecipient.reasonForUnlinking;
    if (!reasonForUnlinking) return false;

    const entries: NumericCodeValue[] = this.livingDonorUnlinkReasonsLookup || [];
    const selected = entries.find((entry: NumericCodeValue) => {
      return entry.code == reasonForUnlinking;
    });
    if (!selected) return false;

    return !!selected.other_selected;
  }

  // List of recipients matching search criteria with applicable journey
  get recipientSearchOptions(): TagObject[] {
    if (!this.recipientList) return [];

    const entries = this.recipientList?.entries || [];
    // TODO: filter for recipients with at least one active journey for same organ that is not completed
    const options: TagObject[] = entries.map((recipient: any): TagObject => {
      // Text is based on the three searchable fields: TGLN ID, First Name, and Last Name
      const text = [recipient.client_id, recipient.first_name, recipient.last_name].join(' ');

      // Hints are based on the other 'Search Results' fields: DoB, insurance number, and transplant program(s)
      const hints: string[] = [];
      const dateOfBirth = this.parseDisplayDateUi(recipient.dob);
      if (dateOfBirth) hints.push(dateOfBirth);
      const insuranceNumber = recipient.insurance_number;
      if (insuranceNumber) hints.push(insuranceNumber);
      // TODO: journey-level transplant program(s)

      return {
        text,
        code: recipient.client_id,
        hint: hints.join(' '),
      };
    });

    return options;
  }

  /**
   * Placeholder text for Recipient Search tag entry
   *
   * @returns {string} placeholder text if empty, or empty string when a tag has been entered
   */
  get recipientSearchPlaceholder(): string {
    if ((this.editState?.recipientSearch?.tags || []).length > 0) return '';

    return this.$t('recipient_search_note').toString();
  }

  // Initialize the form before the page mounts
  private mounted(): void {
    // Clear previously loaded data
    this.clearRecipientList();
    this.clearSelectedRecipient();

    // Initialize the form edit state
    this.initializeForm();
  }

  // Handle changes to the search term tags
  private onTagsChanged(tags: TagObject[]): void {
    // Set tag in search area
    const newTags = createTags(tags);
    Vue.set(this.editState.recipientSearch, 'tags', newTags);

    // Update results area
    if (newTags.length > 0) {
      // Set Link Date to current date and use tag code as recipient TGLN ID
      const linkDate = this.currentDateUi();
      const firstTag = newTags[0];
      const recipientId = firstTag.code;
      this.updateRecipientDetails(linkDate, recipientId);
    } else {
      // Clear any previously loaded recipient info
      this.updateRecipientDetails(null, null);
    }
  }

  // Update Recipient Details form area based on recipient's TGLN ID
  private updateRecipientDetails(linkDate: string|null, recipientClientId: number|null): void {
    // Set Link Date and Recipient TGLN ID
    Vue.set(this.editState.recipientDetails, 'linkDate', linkDate);
    Vue.set(this.editState.recipientDetails, 'recipientId', recipientClientId);

    // Reload recipient info based on Recipient TGLN ID
    this.reloadSelectedRecipient(linkDate, recipientClientId);
  }

  // Get the Recipient Details to show
  private reloadSelectedRecipient(linkDate: string|null, recipientClientId?: number|null): void {
    // Clear any previously loaded recipient info
    this.clearSelectedRecipient();
    if (!recipientClientId) {
      this.syncRecipientDetailsArea(null);
      return;
    }

    // Load recipient info based on Recipient TGLN ID
    this.isLoadingSelectedRecipient = true;
    this.$store.dispatch('recipients/get', recipientClientId).then(() => {
      // Loaded successfully
      this.syncRecipientDetailsArea(linkDate);
      this.isLoadingSelectedRecipient = false;
    }).catch((error: any) => {
      // Could not load due to unexpected error
      console.warn(error);
      this.isLoadingSelectedRecipient = false;
    });
  }

  // Clear any previously loaded recipient info
  private clearSelectedRecipient(): void {
    this.$store.commit('recipients/clearRecipient');
  }

  // Sanitize entry of a search term tag
  private onBeforeAddingTag(event: { tag: TagObject, addTag: () => void; }): void {
    event.addTag();
  }

  // Row clicked
  private onTableRowClick(event: any): void {
    // Find underlying sub-document related to clicked row
    const selectedId = event?.row?._id?.$oid;
    const subdocuments = this.selectedLivingDonorJourney?.intended_recipients || [];
    const selected = subdocuments.find((intendedRecipient: LivingDonorIntendedRecipient) => {
      return intendedRecipient?._id?.$oid === selectedId;
    });

    // Commit the selection to vue-x store
    this.$store.commit('livingDonors/selectIntendedRecipient', selected || null);

    // Clear validation errors
    this.$emit('clear');

    // Initialize form state
    this.initializeForm();
  }

  // Create button clicked
  private onTableCreateRow(): void {
    // Clear vue-selection
    this.$store.commit('livingDonors/selectIntendedRecipient', null);

    // Clear validation errors
    this.$emit('clear');

    // Initialize form state
    this.initializeForm();
  }

  // Handle changes to Unlink checkbox
  private onUnlinkChanged(newUnlinkValue: boolean): void {
    if (!this.editState) return;

    // Start defining new form state for Unlink Recipient area
    const newFormState = Object.assign({}, this.editState.unlinkRecipient);

    if (newUnlinkValue) {
      // Auto-populate Remove Date with current date when Unlink is checked
      const removeDate = this.currentDateUi();
      Object.assign(newFormState, {
        removeDate,
      });
    } else {
      // Clear Unlink Recipient form area if unchecked
      Object.assign(newFormState, {
        removeDate: null,
        reasonForUnlinking: null,
        reasonIfOther: null,
      });
    }

    // Sync changes to editable form state
    this.$store.commit('pageState/set', {
      pageKey: 'linkToRecipient',
      componentKey: 'unlinkRecipient',
      value: newFormState,
    });
  }

  // Watch for changes to recipient search term
  @Watch('editState.recipientSearch.searchTerm')
  private onSearchTermChanged(): void {
    this.reloadRecipientList();
  }

  // Watch for changes to Living Donor state in vue-x store from elsewhere
  @Watch('livingDonor', { immediate: true, deep: true })
  private onLivingDonorChanged(): void {
    this.initializeForm();
  }

  // Clears out the recipient list
  private clearRecipientList(): void {
    this.$store.commit('recipients/setList', undefined);
  }

  /**
   * Load recipients based on search term, using the 'OR' search strategy to Search the same search term duplicated in all three searched fields
   *
   * Note: clear out the list and resolves immediately if there is no search term
   *
   * @returns {Promise<void>} promise that resolves after recipients are loaded, or rejects if an error is caught
   */
  private reloadRecipientList(): Promise<void> {
    const searchTerm = this.editState?.recipientSearch?.searchTerm;
    if (!searchTerm) return new Promise<void>((resolve) => {
      this.clearRecipientList();
      resolve();
    });

    // Configure search to query the search term in every search field simultaneously
    const searchCriteria: { [key: string]: string } = {
      search_strategy: INDEX_SEARCH_STRATEGY
    };
    INDEX_SEARCH_FIELDS.forEach((key: string) => {
      searchCriteria[key] = searchTerm;
    });

    // Define recipients index action options based on search configuration
    const mappedParams: string[] = [];
    Object.keys(searchCriteria).forEach((key: any) => {
      mappedParams.push(`${key}=${searchCriteria[key]}`);
    });
    const opts = {
      pageNumber: INDEX_PAGE,
      pageSize: INDEX_PAGE_SIZE,
      search_params: `&${mappedParams.join('&')}`,
      oopResults: false
    };

    // Start loading and dispatch the recipients index action
    this.isLoadingRecipientList = true;
    return new Promise<void>((resolve, reject) => {
      this.$store.dispatch('recipients/getList', opts).then(() => {
        // Loaded successfully
        this.isLoadingRecipientList = false;
        resolve();
      }).catch((error: any) => {
        // Could not load due to unexpected error
        console.warn(error);
        this.isLoadingRecipientList = false;
        reject();
      });
    });
  }

  // Build the page component's editable form state 
  private initializeForm(): void {
    const intendedRecipient = this.selectedIntendedRecipient || null;

    // Generate form state based on selected Intended Recipient subdocument, if any
    this.$store.commit('pageState/set', {
      pageKey: 'linkToRecipient',
      value: this.buildFormState(intendedRecipient),
    });

    // Refresh Recipient Details based on Recipient Client ID populated by API in Intended Recipient subdocument
    if (intendedRecipient) {
      const linkDate = this.parseDateUi(intendedRecipient.link_date) || null;
      const recipientClientId = intendedRecipient?.recipient?.client_id || null;
      this.reloadSelectedRecipient(linkDate, recipientClientId);
    }
  }

  // Reset just the recipient details portion of the form state
  private syncRecipientDetailsArea(linkDate: string|null): void {
    const recipient: Recipient|null = this.selectedRecipient || null;
    const intendedRecipient: LivingDonorIntendedRecipient|null = this.selectedIntendedRecipient || null;

    this.$store.commit('pageState/set', {
      pageKey: 'linkToRecipient',
      componentKey: 'recipientDetails',
      value: this.buildRecipientDetailsFormState(linkDate, recipient, intendedRecipient)
    });
  }

  // Define form state entire form
  public buildFormState(intendedRecipient: LivingDonorIntendedRecipient|null): LinkToRecipientForm {
    const removeDate = intendedRecipient?.unlink_date || null;
    const result = {
      recipientSearch: {
        searchTerm: '',
        tags: [],
      },
      recipientDetails: this.buildRecipientDetailsFormState(null, null, null),
      unlinkRecipient: {
        unlinkDonorAndRecipient: !!removeDate,
        removeDate: this.parseDateUi(removeDate) || null,
        reasonForUnlinking: intendedRecipient?.unlink_reason_code || null,
        reasonIfOther: intendedRecipient?.unlink_reason_other || null,
      },
    };
    return result;
  }

  // Define form state for just the Recipient Details form
  private buildRecipientDetailsFormState(linkDate: string|null, recipient: Recipient|null, intendedRecipient: LivingDonorIntendedRecipient|null): RecipientDetailsForm {
    // Link date
    const result: RecipientDetailsForm = {
      linkDate,
      recipientId: null,
      recipientJourneyId: null,
      firstName: null,
      lastName: null,
      sex: null,
      dateOfBirth: null,
      insuranceNumber: null,
      referringOrganizationName: null,
      donorRelationshipToRecipient: null,
    };

    // Populate form state from intended recipient subdocument if specified
    if (intendedRecipient) {
      Object.assign(result, {
        linkDate: this.parseDateUi(intendedRecipient.link_date),
        donorRelationshipToRecipient: intendedRecipient.donor_relationship_code,
      });
    }
    if (!recipient) return result;

    const linkedRecipientJourneyId = intendedRecipient?.recipient_journey_id;
    const recipientJourneyId = linkedRecipientJourneyId || this.matchRecipientJourney(recipient);
    const recipientJourney = recipient.journeys?.find((journey: RecipientJourney) => {
      return journey._id?.$oid == recipientJourneyId?.$oid;
    });

    let referringOrganizationName = '';
    if (recipientJourney && recipientJourney.stage_attributes && recipientJourney.stage_attributes.referral && recipientJourney.stage_attributes.referral.referrer && recipientJourney.stage_attributes.referral.referrer.organization && recipientJourney.stage_attributes.referral.referrer.organization.name) {
      referringOrganizationName = recipientJourney.stage_attributes.referral.referrer.organization.name;
    }

    const insurance = recipient?.patient_profile?.insurance || {};
    const sex = recipient?.patient_profile?.sex;

    Object.assign(result, {
      recipientId: recipient?.client_id,
      firstName: recipient?.patient_profile?.first_name,
      lastName: recipient?.patient_profile?.last_name,
      sex: sex ? this.lookupValue(sex, 'gender') : null,
      dateOfBirth: this.parseDisplayDateUi(recipient?.patient_profile?.birth?.date),
      insuranceNumber: insurance.other_plan || insurance.number,
      referringOrganizationName: referringOrganizationName
    });
    return result;
  }

  private matchRecipientJourney(recipient: Recipient): ObjectId|null {
    if (!recipient.journeys || recipient.journeys!.length == 0) return null;

    // Match journey based on Stage, Completed, and Organ Code
    const journeys: RecipientJourney[] = recipient.journeys || [];
    const filtered: RecipientJourney[] = journeys.filter((journey: RecipientJourney) => {
      return journey.stage && RECIPIENT_JOURNEY_STAGES_ELIGIBLE_FOR_LINK_TO_LIVING_DONOR.includes(journey.stage as JourneyStage) && !journey.completed && journey.organ_code == this.selectedLivingDonorJourney?.organ_code;
    });
    if (filtered.length === 0) return null;

    // Note: here we assume the first such applicable journey is sufficient
    return filtered[0]?._id || null;
  }

  // Request payload containing changes to Living Donor Intended Recipient subdocument
  public extractPatch(): LivingDonorIntendedRecipient {
    const recipientDetails = this.editState?.recipientDetails || {};
    const unlinkRecipient = this.editState?.unlinkRecipient || {};

    const recipient = this.selectedRecipient || null;
    const intentedRecipientJourneyId = this.selectedIntendedRecipient?.recipient_journey_id;
    const recipientJourneyId =  intentedRecipientJourneyId || this.matchRecipientJourney(recipient);    
    const donorRelationshipCode = parseInt(`${recipientDetails.donorRelationshipToRecipient}`);
    
    return {
      recipient_id: recipient?._id || null,
      recipient_journey_id: recipientJourneyId || null,
      link_date: this.sanitizeDateApi(recipientDetails.linkDate),
      donor_relationship_code: donorRelationshipCode || null,
      unlink_date: unlinkRecipient.unlinkDonorAndRecipient ? unlinkRecipient.removeDate : null,
      unlink_reason_code: unlinkRecipient.reasonForUnlinking || null,
      unlink_reason_other: unlinkRecipient.reasonIfOther || null,
    };
  }

  // Local save button
  public savePatch(): void {
    // Clear validation errors
    this.$emit('clear');
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveLinkToRecipient as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'linkToRecipient');
    // Generate payload based on current edit state
    const id = this.selectedIntendedRecipient?._id?.$oid || null;
    const livingDonorId = this.livingDonorId;
    const livingDonorJourneyId = this.selectedLivingDonorJourney?._id?.$oid || null;
    const intendedRecipientPatch = this.extractPatch();
    // Dispatch save action and register the response
    this.$store.dispatch('livingDonors/saveIntendedRecipient', {
      id,
      livingDonorId,
      livingDonorJourneyId,
      intendedRecipientPatch,
    }).then((success: SaveResult) => {
      // If successful, reload living donor and show success notification
      this.$emit('reload');
      saveProvider.registerSaveResult(success);
      // Clear form
      this.onTableCreateRow();
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = this.$refs.saveLinkToRecipient as unknown as SaveProvider;

    // Reset the save provider's save toolbar
    if (saveProvider) saveProvider.resetSaveToolbar();
  }

  // Mapping from API field-level validation error keys to UI template input field IDs
  public idLookup(): {[key: string]: string} {
    return {
      'intendedRecipient.recipient_id'            : 'link-to-recipient-search',
      'intendedRecipient.donor_relationship_code' : 'link-to-recipient-donor-relationship',
      'intendedRecipient.link_date'               : 'link-to-recipient-date',
      'intendedRecipient.unlink_date'             : 'link-to-recipient-remove-date',
      'intendedRecipient.unlink_reason_code'      : 'link-to-recipient-reason-for-unlinking',
      'intendedRecipient.unlink_reason_other'     : 'link-to-recipient-reason-if-other',
    };
  }
}
