








































































































































































































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { GenericCodeValue } from '@/store/types';
import { LabHLAAntibody } from '@/store/labs/types';
import { Recipient } from '@/store/recipients/types';
import { YesNoNoSelection } from '@/store/lookups/types';
import HlaInput from '@/components/shared/HlaInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { LungDetails } from '@/store/organSpecificDetails/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';

interface LungSpecificForm {
  comments?: string;
  generalInfo: {
    secondarySpecification?: string;
    transplantType?: string;
    perfusionRight?: number;
    perfusionLeft?: number;
    predictedTotalLungCapacity?: number;
    actualTotalLungCapacity?: number;
    estimatedRightVentricularSystolicPressure?: number;
    pulmonaryArterySystolicPressure?: number;
    pulmonaryArteryDiastolicPressure?: number;
    meanPulmonaryAterialPressure?: number;
    lungAllocationScore?: number;
    echocardiogramDate?: string|null;
    echocardiogramComment?: string;
    chestCtScanDate?: string|null;
    chestCtScanResult?: string;
    predictedForcedVitalCapacity?: number;
    actualForcedExpiratoryVolume?: number;
    hlaAntibodiesDate?: string;
    unacceptableDsa?: string;
    extraCorporealLifeSupport?: boolean; // boolean as 'Y' and 'N' while 'U' is nil
    extraCorporealLifeSupportComment?: string;
    ventilator?: boolean;  // boolean as 'Y' and 'N' while 'U' is nil
    ventilatorComment?: string;
  };
}

@Component({
  components: {
    DateInput,
    TextInput,
    HlaInput,
    SubSection,
    CardSection,
    SelectInput,
    NumberInput,
    TextAreaInput,
    CheckboxInput,
    BooleanRadioInput
  }
})
export default class LungSpecificDetails extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.labs.hlaAntibodies) hlaAntibodies!: LabHLAAntibody[];
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) selectedRecipient!: Recipient;
  @State(state => state.pageState.currentPage.lungDetails) editState!: LungSpecificForm;
  @State(state => state.lookups.yes_no_noselection) private yesNoNoSelectionLookup!: YesNoNoSelection[];

  // Getters
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('lungSecondarySpecOptions', { namespace: 'lookups' }) private lungSecondarySpecOptions!: GenericCodeValue[];

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['yes_no_noselection'];

  // Properties
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  /**
   * Return true if lung specific details section can be edited
   * 
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }
  
  get secondarySpecificationOptions(): GenericCodeValue[] {
    if (!this.lungSecondarySpecOptions || this.lungSecondarySpecOptions.length === 0) return [];

    const translated = this.lungSecondarySpecOptions.map((option: GenericCodeValue): GenericCodeValue => {
      return {
        code: option.code,
        value: this.$t(option.value).toString(),
      };
    });
    return translated;
  }

  // Comes from card-section after all the lookups are loaded
  public loaded(): void {
    this.$emit('loaded', 'organSpecificDetailsSection');
    this.initializeForm();
  }

  // Copy relevant data from journey into editState or initialize as blank
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'lungDetails',
      value: this.extractLungSpecificDetailsForm(this.journey)
    });
    this.loadHlaAntibodies();
  }

  // Update HLA Antibodies Date field with most recent sample_date available
  public loadHlaAntibodies(): void {
    const recipientId = this.selectedRecipient.client_id;
    this.$store.dispatch('labs/loadHlaAntibodies', recipientId).then(() => {
      let hlaAntibodiesDate: string|undefined;
      let unacceptableDsa: string[]|undefined;
      if (this.hlaAntibodies && this.hlaAntibodies.length > 0) {
        // API already sorted values based on most recent date, first one in the array will be most recent value
        hlaAntibodiesDate = this.parseDateUi(this.hlaAntibodies[0].sample_date);
        const hlaUnacceptable = this.hlaAntibodies.filter((item: LabHLAAntibody) => {
          return this.parseDateUi(item.sample_date) == hlaAntibodiesDate;
        });
        const class1 = hlaUnacceptable[0].antibodies!.class1 || {};
        const class2 = hlaUnacceptable[0].antibodies!.class2 || {};
        const class1_unacceptable = (class1.antibodies.unacceptable_allele_group || []).concat(class1.antibodies.unacceptable_allele_specific || []).concat(class1.antibodies.unacceptable_alpha_beta || []);
        const class2_unacceptable = (class2.antibodies.unacceptable_allele_group || []).concat(class2.antibodies.unacceptable_allele_specific || []).concat(class2.antibodies.unacceptable_alpha_beta || []);
        unacceptableDsa = class1_unacceptable!.concat(class2_unacceptable);
      } else {
        hlaAntibodiesDate = 'N/A';
      }
      // Must use Vue.set or the UI will not be updated
      Vue.set(this.$store.state.pageState.currentPage.lungDetails.generalInfo, 'hlaAntibodiesDate', hlaAntibodiesDate);
      Vue.set(this.$store.state.pageState.currentPage.lungDetails.generalInfo, 'unacceptableDsa', unacceptableDsa);
    }).catch(() => {
      console.warn('Could not load recipient HLA Antibody labs');
    });
  }

  // Extract relevant parts from Recipient to match the form layout
  public extractLungSpecificDetailsForm(journey?: RecipientJourney): LungSpecificForm {
    if (!journey) {
      const result: LungSpecificForm = { generalInfo: {} };
      return result;
    }
    const organ_specific_details = journey.organ_specific_details as LungDetails;
    const ecls = organ_specific_details.extra_corporeal_life_support;
    const tlc = organ_specific_details.actual_total_lung_capacity ? parseFloat(organ_specific_details.actual_total_lung_capacity.toFixed(2)) : undefined;
    const vent = organ_specific_details.ventilator;
    const result: LungSpecificForm = {
      comments: journey.comments,
      generalInfo: {
        secondarySpecification: organ_specific_details.organ_specification_preference_code?.toString(),
        transplantType: organ_specific_details.transplant_type,
        perfusionRight: organ_specific_details.perfusion_right,
        perfusionLeft: organ_specific_details.perfusion_left,
        predictedTotalLungCapacity: organ_specific_details.predicted_total_lung_capacity,
        actualTotalLungCapacity: tlc,
        estimatedRightVentricularSystolicPressure: organ_specific_details.estimated_right_ventricular_systolic_pressure,
        pulmonaryArterySystolicPressure: organ_specific_details.pulmonary_artery_systolic_pressure,
        pulmonaryArteryDiastolicPressure: organ_specific_details.pulmonary_artery_diastolic_pressure,
        meanPulmonaryAterialPressure: organ_specific_details.pulmonary_mean_arterial_pressure,
        lungAllocationScore: organ_specific_details.lung_allocation_score,
        echocardiogramDate: this.parseDateUi(organ_specific_details.echocardiogram_date),
        echocardiogramComment: organ_specific_details.echocardiogram_comment,
        chestCtScanDate: this.parseDateUi(organ_specific_details.chest_ct_scan_date),
        chestCtScanResult: organ_specific_details.chest_ct_scan_result,
        extraCorporealLifeSupport: (ecls === 'U' ? undefined : ecls === 'Y' ? true : false),
        extraCorporealLifeSupportComment: organ_specific_details.extra_corporeal_life_support_comment,
        ventilator: (vent === 'U' ? undefined : vent === 'Y' ? true : false),
        ventilatorComment: organ_specific_details.ventilator_comment,
        predictedForcedVitalCapacity: organ_specific_details.predicted_forced_vital_capacity,
        actualForcedExpiratoryVolume: organ_specific_details.actual_forced_expiratory_volume,
      }
    };
    return result;
  }

  // Translate the form structure into the Recipient data structure
  public extractPatch(): RecipientJourney {
    if (this.editState) {
      const lungDetails = this.editState.generalInfo;
      return {
        comments: this.editState.comments,
        organ_specific_details: {
          organ_specification_preference_code: lungDetails.secondarySpecification ? Number(lungDetails.secondarySpecification) : null,
          transplant_type: lungDetails.transplantType,
          perfusion_right: lungDetails.perfusionRight,
          perfusion_left: lungDetails.perfusionLeft,
          predicted_total_lung_capacity: lungDetails.predictedTotalLungCapacity,
          actual_total_lung_capacity: lungDetails.actualTotalLungCapacity,
          estimated_right_ventricular_systolic_pressure: lungDetails.estimatedRightVentricularSystolicPressure,
          pulmonary_artery_systolic_pressure: lungDetails.pulmonaryArterySystolicPressure,
          pulmonary_artery_diastolic_pressure: lungDetails.pulmonaryArteryDiastolicPressure,
          pulmonary_mean_arterial_pressure: lungDetails.meanPulmonaryAterialPressure,
          lung_allocation_score: lungDetails.lungAllocationScore,
          echocardiogram_date: this.sanitizeDateApi(lungDetails.echocardiogramDate),
          echocardiogram_comment: lungDetails.echocardiogramComment,
          chest_ct_scan_date: this.sanitizeDateApi(lungDetails.chestCtScanDate),
          chest_ct_scan_result: lungDetails.chestCtScanResult,
          extra_corporeal_life_support: lungDetails.extraCorporealLifeSupport == null ? 'U' : lungDetails.extraCorporealLifeSupport ? 'Y' : 'N',
          extra_corporeal_life_support_comment: lungDetails.extraCorporealLifeSupportComment,
          ventilator: lungDetails.ventilator == null ? 'U' : lungDetails.ventilator ? 'Y' : 'N',
          ventilator_comment: lungDetails.ventilatorComment,
          predicted_forced_vital_capacity: lungDetails.predictedForcedVitalCapacity,
          actual_forced_expiratory_volume: lungDetails.actualForcedExpiratoryVolume,
        }
      };
    } else {
      return {};
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = this.$refs.saveLungSpecificDetails as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }

  // Handle saving triggered by local save button
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveLungSpecificDetails as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'saveLungSpecificDetails');
    // Generate payload based on current edit state
    const organDetailsPatch = {
      recipientId: this.selectedRecipient.client_id,
      journeyId: this.journey ? this.journey._id!.$oid : null,
      journey: this.extractPatch()
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveJourney', organDetailsPatch).then((success: SaveResult) => {
      // If successful, reload the current recipient
      this.$store.dispatch('recipients/get', this.selectedRecipient.client_id);
      // If successful, update the current recipient and show success notification
      this.$store.commit('journeyState/setJourney', success.responseData.journey);
      this.initializeForm();
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): {[key: string]: string} {
    const result = {
      'comments'                                                             : 'lungsd-comments',
      'organ_specific_details.organ_specification_preference_code'           : 'lungsd-secondary-specification',
      'organ_specific_details.perfusion_right'                               : 'lungsd-transplant-perfusion-right',
      'organ_specific_details.perfusion_left'                                : 'lungsd-transplant-perfusion-left',
      'organ_specific_details.predicted_total_lung_capacity'                 : 'lungsd-transplant-predicted-tlc',
      'organ_specific_details.actual_total_lung_capacity'                    : 'lungsd-transplant-actual-tlc',
      'organ_specific_details.estimated_right_ventricular_systolic_pressure' : 'lungsd-transplant-rv',
      'organ_specific_details.pulmonary_artery_systolic_pressure'            : 'lungsd-transplant-systolic-pa',
      'organ_specific_details.pulmonary_artery_diastolic_pressure'           : 'lungsd-transplant-diastolic-pa',
      'organ_specific_details.pulmonary_mean_arterial_pressure'              : 'lungsd-transplant-mean-pa',
      'organ_specific_details.lung_allocation_score'                         : 'lungsd-transplant-las',
      'organ_specific_details.echocardiogram_date'                           : 'lungsd-transplant-echo-date',
      'organ_specific_details.echocardiogram_comment'                        : 'lungsd-transplant-echo-comment',
      'organ_specific_details.chest_ct_scan_date'                            : 'lungsd-transplant-ct-date',
      'organ_specific_details.chest_ct_scan_result'                          : 'lungsd-transplant-ct-results',
      'organ_specific_details.extra_corporeal_life_support'                  : 'lungsd-transplant-ecls',
      'organ_specific_details.extra_corporeal_life_support_comment'          : 'lungsd-transplant-ecls-comment',
      'organ_specific_details.ventilator'                                    : 'lungsd-transplant-vent',
      'organ_specific_details.ventilator_comment'                            : 'lungsd-transplant-vent-comment',
      'organ_specific_details.predicted_forced_vital_capacity'               : 'lungsd-transplant-fvc',
      'organ_specific_details.actual_forced_expiratory_volume'               : 'lungsd-transplant-fev1',
    };

    return result;
  }
}
