import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/dashboard/actions';
import { getters } from '@/store/dashboard/getters';
import { DashboardState } from '@/store/dashboard/types';
import { mutations } from '@/store/dashboard/mutations';

// Initialize empty lookups state (all lookups are initially undefined)
const state: DashboardState = {
  notifications: null,
  allocations: null,
  primaryOffers: null,
  bookmarks: null,
  backupOffers: null,
  activeDonors: null,
  labResults: null,
  recentRecipients: null,
  recentLivingDonors: null,
  urgentListings: null,
  exdDonors: null
};

const namespaced = true;

export const dashboard: Module<DashboardState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state
};
