


























































































































import { Getter, State } from "vuex-class";
import HlaTyping from '@/components/hla/HlaTyping.vue';
import { Component, Vue } from 'vue-property-decorator';
import { VirologyType } from '@/store/labs/types';
import { SaveProvider, SaveResult } from '@/types';
import { LivingDonor } from '@/store/livingDonors/types';
import PageTop from '@/components/shared/PageTop.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import Demographics from '@/components/livingDonors/Demographics.vue';
import VirologyResults from '@/components/virology/VirologyResults.vue';
import LinkToRecipient from '@/components/livingDonors/LinkToRecipient.vue';
import ContactInformation from '@/components/livingDonors/ContactInformation.vue';
import LivingDonorSummary from '@/components/livingDonors/LivingDonorSummary.vue';
import DonationInformation from '@/components/livingDonors/DonationInformation.vue';
import SideNavLivingDonor from '@/components/livingDonors/side-nav/SideNavLivingDonor.vue';
import LivingDonorStickySummary from '@/components/livingDonors/LivingDonorStickySummary.vue';
import GeneralClinicalInformation from '@/components/livingDonors/GeneralClinicalInformation.vue';
import RecoveryInformation from '@/components/livingDonors/RecoveryInformation.vue';
import PotentialDuplicateModal from "@/components/livingDonors/PotentialDuplicateModal.vue";
import { EP } from '@/api-endpoints';
import { ACTIVE_ONTARIO_TRANSPLANT_PROGRAM } from '@/store/hospitals/types';

@Component({
  components: {
    PageTop,
    HlaTyping,
    CardSection,
    SaveToolbar,
    Demographics,
    VirologyResults,
    LinkToRecipient,
    ContactInformation,
    SideNavLivingDonor,
    LivingDonorSummary,
    DonationInformation,
    LivingDonorStickySummary,
    GeneralClinicalInformation,
    RecoveryInformation,
    PotentialDuplicateModal
  }
})
export default class EditLivingDonor extends Vue implements SaveProvider{
  // State
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;

  // Getters
  @Getter('livingDonorDisplayName', { namespace: 'livingDonors' } ) private livingDonorDisplayName!: string;
  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string|undefined;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('isLivingDonorRecoveryComplete', { namespace: 'livingDonors' }) private isLivingDonorRecoveryComplete!: boolean;
  @Getter('showRecoveryInformation', { namespace: 'livingDonors' }) private showRecoveryInformation!: boolean;

  private dispatchEventsComplete = false;
  private sectionsLoaded = new Set();
  private allSectionsLoaded = false;

  get canSaveLivingDonor(): boolean {
    return this.checkAllowed(EP.living_donors.create, 'POST');
  }

  /**
   * Returns enum value VirologyType.LivingDonor
   *
   * @returns {number} enum value for Living Donor
   */
  get virologyType(): number {
    return VirologyType.LivingDonor;
  }

  // Vue lifecycle hooks
  public mounted(): void {
    Promise.all([
      this.$store.commit('setPageTitle', `LivingDonors / ${this.livingDonorDisplayName}`),
      this.$store.dispatch('hospitals/load', ACTIVE_ONTARIO_TRANSPLANT_PROGRAM),
      this.$store.dispatch('livingAllocations/getAllAllocations', { clientId: this.clientId, clearSelectedAllocation: true }),
      this.$store.dispatch('validations/loadEdit', { view: 'living_donors', action: 'edit_validations', clientId: this.clientId })
    ]).finally(() => {
      this.dispatchEventsComplete = true;
    });
  }

  get isLoaded(): boolean {
    return this.allSectionsLoaded;
  }

  public loaded(ref: string): void {
    if (!ref) return;
    // Create a set of all the sections to load filtering out sections we don't care about
    const sectionsToLoad = new Set(Object.keys(this.$refs).filter((ref: string) => !ref.match(/validations|saveLivingDonor|potentialDuplicateModal/)));
    // Add the ref we just loaded
    this.sectionsLoaded.add(ref);
    if (this.sectionsLoaded.size === sectionsToLoad.size) {
      this.$store.dispatch('utilities/scrollBehavior');
      this.allSectionsLoaded = true;
    }
  }

  public handleContinueDemographics(): void {
    (this.$refs.demographics as Demographics).savePatch(true);
  }
  public handleContinueContactInfo(): void {
    (this.$refs.contactInfo as ContactInformation).savePatch(true);
  }
  public handleContinueGeneralClinicalInformation(): void {
    (this.$refs.generalClinical as GeneralClinicalInformation).savePatch(true);
  }

  public handleWarnings(errors: SaveResult, target: string): void {
    (this.$refs.potentialDuplicateModal as PotentialDuplicateModal).checkingDuplicateWarning(errors, target);
  }

  // Handle saving triggered by local save button
  public performSave(potential_duplicate_profile_confirmed?: boolean): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveLivingDonor as SaveToolbar;
    // Show appropriate notification
    saveToolbar.startSaving();
    // Ref for each component required in the patch
    const demographics = this.$refs.demographics as Demographics;
    const contactInfo = this.$refs.contactInfo as ContactInformation;
    const donationInformation = this.$refs.donationInformation as DonationInformation;
    const generalClinical = this.$refs.generalClinical as GeneralClinicalInformation;
    const recoveryInformation = this.$refs.recoveryInformation as RecoveryInformation;
    const virology = this.$refs.virology as VirologyResults;

    // Extract demographics and general clinical
    let livingDonorPatch: LivingDonor = {
      ...donationInformation.extractPatch(),
      ...generalClinical.extractPatch(),
      ...donationInformation.extractPatch(),
      ...demographics.extractPatch(potential_duplicate_profile_confirmed)
    };

    // recovery information may or may not be there
    if (recoveryInformation) {
      livingDonorPatch = {...livingDonorPatch, ...recoveryInformation.extractPatch()};
    }

    // Extract contact info and merge with patient profile
    livingDonorPatch.patient_profile = {
      ...livingDonorPatch.patient_profile,
      ...contactInfo.extractPatch().patient_profile,
    };
    // Extract and add virology if exists
    if (!virology.isEmpty) {
      const virologyPatch = virology.extractPatch();
      // Separately extract the virology comments
      if (virologyPatch.virology_comments && livingDonorPatch) {
        Object.assign(livingDonorPatch.living_donor_info, { 
          virology_comments: virologyPatch.virology_comments 
        });
        // Then delete the key so we don't include them in the virology patch
        delete virologyPatch.virology_comments;
      }
      livingDonorPatch.living_donor_info?.virology_comments;
      if (virologyPatch.lab_id !== null) {
        livingDonorPatch.virology_labs = [virologyPatch];
      }
    }

    // Extract and add measurement if exists
    const measurement = generalClinical.extractMeasurementPatch();
    if (measurement !== null) {
      livingDonorPatch.measurements = [measurement];
    }

    // Clear any save toolbar messages
    demographics.resetSaveToolbar();
    contactInfo.resetSaveToolbar();
    generalClinical.resetSaveToolbar();
    
    if (recoveryInformation) {
      recoveryInformation.resetSaveToolbar();
    }
  
    // Clear previous errors
    (this.$refs.validations as any).reset();

    // Attempt to save the livingDonor
    this.$store.dispatch('livingDonors/saveLivingDonorsPatch', livingDonorPatch).then((success: SaveResult) => {
      this.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      this.registerSaveResult(error);
    });
  }

  // Handle result of save
  public registerSaveResult(result: SaveResult): void {
    /**
     * If successful, update the root record(s). Depending on what the page is intended to save, this could be one
     * record (e.g. EditLivingDonor updates the currently selected livingDonor), zero records, or multiple records.
     */
    if (result.success) {
      this.$store.commit('livingDonors/set', result.responseData.living_donor);
    } else {
      // Handle errors
      this.handleErrors(result, true);
    }
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveLivingDonor as SaveToolbar;
    // Show appropriate saving notification
    saveToolbar.stopSaving(result);
  }

  // Handle save events generated by descendent components
  private handleSectionSave(sectionSaved: string): void {
    (this.$refs.validations as any).reset();
    this.resetSaveToolbar();
  }

  private resetValidationErrors(): void {
    (this.$refs.validations as any).reset();
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult[]|SaveResult, mainSave?: boolean): void {    
    mainSave = mainSave ? true : false;

    // Check validation keys for card sections that are always displayed
    const idLookup: {[key: string]: string} = {
      ...(this.$refs.demographics as Demographics).idLookup,
      ...(this.$refs.contactInfo as ContactInformation).idLookup,
      ...(this.$refs.donationInformation as DonationInformation).idLookup(),
      ...(this.$refs.generalClinical as GeneralClinicalInformation).idLookup,
      ...(this.$refs.virology as VirologyResults).idLookup(),
      ...(this.$refs.linkToRecipient as LinkToRecipient).idLookup(),
      ...(this.$refs.hlaTypingLivingDonor as HlaTyping).idLookup(),
    };

    // Check validation keys for card sections that may or may not be shown
    const recoveryInformation = this.$refs.recoveryInformation as RecoveryInformation;
    if (recoveryInformation) {
      Object.assign(idLookup, {
        ...recoveryInformation.idLookup,
      });
    }

    let aggregateErrors: {[key: string]: []} = {};
    errors = Array.isArray(errors) ? errors : [errors];
    errors.forEach((item) => {
      aggregateErrors = { ...item.validationErrors };
    });
    const formErrors: {[key: string]: []} = {};
    for (const key of Object.keys(aggregateErrors)) {
      formErrors[idLookup[key]] = aggregateErrors[key];
    }
    (this.$refs.validations as any).setErrors(formErrors);

    if(mainSave) {
      errors.forEach((err: SaveResult) => {
        console.log(err);
        (this.$refs.potentialDuplicateModal as PotentialDuplicateModal).checkingDuplicateWarning(err, 'savePatch');
      });
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveLivingDonor as SaveToolbar;
    if (saveToolbar) saveToolbar.reset();
  }

  // Reload living donor after successful subsection save
  private reload(): void {
    this.$store.dispatch('livingDonors/get', this.clientId).then(() => {
      this.reinitialize();
    });
  }

  // Re-initialize card sections that rely on Living Donor document
  public reinitialize(): void {
    const donationInformation = this.$refs.donationInformation as DonationInformation;
    if (donationInformation) donationInformation.reinitialize();
    const recoveryInformation = this.$refs.recoveryInformation as DonationInformation;
    if (recoveryInformation) recoveryInformation.reinitialize();
  }
}
