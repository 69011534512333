import axios from 'axios';
import { ActionTree } from 'vuex';
import { SaveResult } from '@/types';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { prefixErrors, buildErrorResult } from '@/utils';
import { LabsState, LabVirology, LabHLAAntibody, HlaAntibodyTestingMethod, LabHLATypingRecipient, LabHlaTypingDonor, VirologyType } from '@/store/labs/types';

export const actions: ActionTree<LabsState, RootState> = {
  loadVirologies({ commit }, { virologyType, clientId }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let view = '';
      if (virologyType === VirologyType.DeceasedDonor) view = 'donors';
      if (virologyType === VirologyType.LivingDonor) view = 'living_donors';
      if (virologyType === VirologyType.Recipient) view = 'recipients';
      const url = APIRoute(EP.virologies.index, [[':view', view], [':clientId', clientId]]);
      axios.get(url).then((response: any) => {
        if (virologyType === VirologyType.DeceasedDonor) {
          const rawVirologies: any[] = response.data.lab_virology_donors;
          const sanitizedVirologies = rawVirologies.map((rawVirology: LabVirology) => {
            return {
              _id: rawVirology._id,
              donor_id: rawVirology.donor_id,
              lab_code: rawVirology.lab_code,
              lab_other: rawVirology.lab_other,
              test_date: rawVirology.test_date,
              test_code: rawVirology.test_code,
              sample_date: rawVirology.sample_date,
              sample_type: rawVirology.sample_type,
              from_mother: rawVirology.from_mother,
              comments: rawVirology.comments,
              results: rawVirology.results
            };
          });
          commit('setVirologies', sanitizedVirologies);
          resolve();
        }
        if (virologyType === VirologyType.LivingDonor) {
          const rawVirologies: any[] = response.data.lab_virology_living_donors;
          const sanitizedVirologies = rawVirologies.map((rawVirology: LabVirology) => {
            return {
              _id: rawVirology._id,
              donor_id: rawVirology.donor_id,
              lab_id: rawVirology.lab_id,
              lab_code: rawVirology.lab_code,
              lab_other: rawVirology.lab_other,
              test_date: rawVirology.test_date,
              sample_number: rawVirology.sample_number,
              sample_date: rawVirology.sample_date,
              results: rawVirology.results
            };
          });
          commit('setVirologies', sanitizedVirologies);
          resolve();
        }
        if (virologyType === VirologyType.Recipient) {
          const rawVirologies: any[] = response.data.lab_virology_recipients;
          const sanitizedVirologies = rawVirologies.map((rawVirology: LabVirology) => {
            return {
              _id: rawVirology._id,
              recipient_id: rawVirology.recipient_id,
              lab_code: rawVirology.lab_code,
              lab_other: rawVirology.lab_other,
              test_date: rawVirology.test_date,
              sample_number: rawVirology.sample_number,
              sample_date: rawVirology.sample_date,
              comments: rawVirology.comments,
              results: rawVirology.results
            };
          });
          commit('setVirologies', sanitizedVirologies);
          resolve();
        }
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  saveVirology({ getters }, { virologyType, id, clientId, virology }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup request method, endpoint, view and payload
      let method: any, ep: string, payload: any;
      let view = '';
      switch(virologyType) {
        case VirologyType.DeceasedDonor:
          view = 'donors';
          payload = { lab_virology_donor: virology };
          break;
        case VirologyType.LivingDonor:
          view = 'living_donors';
          payload = { lab_virology_living_donor: virology };
          break;
        case VirologyType.Recipient:
          view = 'recipients';
          payload = { lab_virology_recipient: virology };
          break;
      }
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.virologies.update, [[':view', view], [':id', id], [':clientId', clientId]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.virologies.create, [[':view', view], [':clientId', clientId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Generate error for Virology time field if date failed
          if (response.data.errors.test_date) {
            response.data.errors.test_time = response.data.errors.test_date;
          }
          if (response.data.errors.sample_date) {
            response.data.errors.sample_time = response.data.errors.sample_date;
          }

          // Check virology markers if any have been defined
          const virology_markers = virology?.results || [];
          virology_markers.map((item: any)=>{
            const error_key = `results[${item.code}].positive`;
            if(error_key in response.data.errors && response.data.errors[error_key].includes("validation.messages.not_blank"))
            {
              response.data.errors[error_key].splice(0,1,'validation.messages.custom_virology_results_validation');
            }
          });
          // Handle field-level validations as well as top-level error strings
          const result = buildErrorResult(response.data.errors, 'virology_labs');
          reject(result);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  loadHlaTyping({ commit }, recipientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.hlaTyping.show, [[':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        const sanitizedHlaTyping: LabHLATypingRecipient = response.data.lab_hla_typing_recipient || {};
        commit('setHlaTyping', sanitizedHlaTyping);
        resolve();
      }).catch((error: any) => {
        // 404 error expected if patient does not yet have an HLA Typing lab result
        commit('setHlaTyping', {});
        reject();
      });
    });
  },
  loadHlaAntibodies({ commit }, recipientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.hlaAntibodies.index, [[':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        const hlaAntibodies: LabHLAAntibody[] = response.data.lab_hla_antibodies;
        commit('setHlaAntibodies', hlaAntibodies);
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  },
  saveHlaAntibodiesTest({ getters }, { id, recipientId, hlaAntibodiesTest }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup request method, endpoint, and payload
      let method: any;
      let ep: string;
      const payload = { lab_hla_antibody: hlaAntibodiesTest };
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.hlaAntibodies.update, [[':id', id], [':recipientId', recipientId]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.hlaAntibodies.create, [[':recipientId', recipientId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          if (typeof response.data.errors === 'string') {
            reject({ success: false , errorMessages: [response.data.errors] });
          } else {
            // Generate error for 'default' Testing Method if both class-specific Testing Methods failed
            // This makes sure errors appear for the virtual field, even when the class-specific methods are hidden
            if (response.data.errors.hla_class1_testing_method_code && response.data.errors.hla_class2_testing_method_code) {
              response.data.errors.default_testing_method = response.data.errors.hla_class1_testing_method_code;
            }
            // Handle server-side validation errors
            const validationErrors = prefixErrors(response.data.errors, 'lab_hla_antibody');
            reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors });
          }
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveHlaTyping({ getters }, { id, recipientId, hlaTyping }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup request method, endpoint, and payload
      let method: any;
      let ep: string;
      const payload = { lab_hla_typing_recipient: hlaTyping };
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.hlaTyping.update, [[':id', id], [':recipientId', recipientId]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.hlaTyping.create, [[':recipientId', recipientId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const validationErrors = prefixErrors(response.data.errors, 'lab_hla_typing_recipient');
          const result: SaveResult = { success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors };
          // Check if there are any exceptions in the validation errors object
          const exceptions: { rule: string, incidents: { molecular_locus: string, sequence_number: number }[] }[] = [];
          exceptions.push(...(validationErrors['lab_hla_typing_recipient.exceptions'] || []));
          // Only show exception 'warning' if there are 'exceptions' and no other validation errors
          const numValiationErrors = Object.keys(validationErrors).length;
          if (exceptions.length > 0 && numValiationErrors === 1) {
            result.warning = true;
            result.warningExceptions = exceptions;
          }
          reject(result);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  loadHlaTypingDonor({ commit }, donorId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.deceasedDonors.hlaTyping.show, [[':donorId', donorId]]);
      axios.get(url).then((response: any) => {
        const sanitizedHlaTyping: LabHlaTypingDonor = response.data.lab_hla_typing_donor || {};
        commit('setHlaTyping', sanitizedHlaTyping);
        resolve();
      }).catch((error: any) => {
        // 404 error expected if patient does not yet have an HLA Typing lab result
        commit('setHlaTyping', {});
        reject();
      });
    });
  },
  loadHlaTypingLivingDonor({ commit }, livingDonorId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.living_donors.hlaTyping.show, [[':living_donor_id', livingDonorId]]);
      axios.get(url).then((response: any) => {
        const sanitizedHlaTyping: LabHlaTypingDonor = response.data.lab_hla_typing_living_donor || {};
        commit('setHlaTyping', sanitizedHlaTyping);
        resolve();
      }).catch((error: any) => {
        // 404 error expected if living donor does not yet have an HLA Typing lab result
        commit('setHlaTyping', {});
        reject();
      });
    });
  },
  saveHlaTypingDonor({ getters }, { id, donorId, hlaTyping }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup request method, endpoint, and payload
      let method: any;
      let ep: string;
      const payload = { lab_hla_typing_donor: hlaTyping };
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.deceasedDonors.hlaTyping.update, [[':id', id], [':donorId', donorId]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.deceasedDonors.hlaTyping.create, [[':donorId', donorId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const validationErrors = prefixErrors(response.data.errors, 'lab_hla_typing_donor');
          const result: SaveResult = { success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors };
          // Check if there are any exceptions in the validation errors object
          const exceptions: { rule: string, incidents: { molecular_locus: string, sequence_number: number }[] }[] = [];
          exceptions.push(...(validationErrors['lab_hla_typing_donor.exceptions'] || []));
          // Only show exception 'warning' if there are 'exceptions' and no other validation errors
          const numValiationErrors = Object.keys(validationErrors).length;
          if (exceptions.length > 0 && numValiationErrors === 1) {
            result.warning = true;
            result.warningExceptions = exceptions;
          }
          reject(result);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveHlaTypingLivingDonor({ getters }, { id, livingDonorId, hlaTyping }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Setup request method, endpoint, and payload
      let method: any;
      let ep: string;
      const payload = { lab_hla_typing_living_donor: hlaTyping };
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.living_donors.hlaTyping.update, [[':id', id], [':living_donor_id', livingDonorId]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.living_donors.hlaTyping.create, [[':living_donor_id', livingDonorId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const validationErrors = prefixErrors(response.data.errors, 'lab_hla_typing_donor');
          const result: SaveResult = { success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors };
          // Check if there are any exceptions in the validation errors object
          const exceptions: { rule: string, incidents: { molecular_locus: string, sequence_number: number }[] }[] = [];
          exceptions.push(...(validationErrors['lab_hla_typing_donor.exceptions'] || []));
          // Only show exception 'warning' if there are 'exceptions' and no other validation errors
          const numValiationErrors = Object.keys(validationErrors).length;
          if (exceptions.length > 0 && numValiationErrors === 1) {
            result.warning = true;
            result.warningExceptions = exceptions;
          }
          reject(result);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveCrossmatchInfo({ getters }, { donorId, crossmatchLab, id }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;
      const payload = { lab_crossmatch: crossmatchLab };
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.deceasedDonors.crossmatchLabs.update, [[':donorId', donorId], [':id', id]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.deceasedDonors.crossmatchLabs.create, [[':donorId', donorId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Generate error for Crossmatch time field if date failed
          if (response.data.errors.crossmatch_date) {
            response.data.errors.crossmatch_time = response.data.errors.crossmatch_date;
          }
          // Handle server-side validation errors
          const validationErrors = prefixErrors(response.data.errors, 'lab_crossmatch');
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  loadCrossmatchLabs({ commit }, donorId): Promise<void> {
    return new Promise<void>((resolve) => {
      const url = APIRoute(EP.deceasedDonors.crossmatchLabs.index, [[':donorId', donorId]]);
      axios.get(url).then((response: any) => {
        commit('setCrossmatchLabs', response.data.lab_crossmatches);
        resolve(response);
      });
    });
  },
  saveCrossmatchRecipientSample({ getters }, { donorId, crossmatchLabId, id, crossmatchRecipientSampleLab }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;
      const payload = { crossmatch_recipient_sample: crossmatchRecipientSampleLab };
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.deceasedDonors.crossmatchLabs.recipientSamples.update, [[':donorId', donorId], [':crossmatchLabId', crossmatchLabId], [':id', id]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.deceasedDonors.crossmatchLabs.recipientSamples.create, [[':donorId', donorId], [':crossmatchLabId', crossmatchLabId],]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const validationErrors = response.data.errors;
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors });
        }
      }).catch((error: any) => {
        // DIAG: 404 error on this request means the client_id wasn't found
        // to highlight UI we add a custom error here
        const errorStatus = error.response.status;
        if (errorStatus === 404) {
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: { client_id: ['Invalid Recipient ID'] } });
        } else {
          // Handle generic errors
          reject({ success: false, errorMessages: [error.message] });
        }
      });
    });
  },
};
