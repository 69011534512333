import { render, staticRenderFns } from "./OfferIcon.vue?vue&type=template&id=6324c8f8&"
import script from "./OfferIcon.vue?vue&type=script&lang=ts&"
export * from "./OfferIcon.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/_locales/common.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingAllocations%2Foffers%2FOfferIcon.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/components/allocations/_locales/common.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingAllocations%2Foffers%2FOfferIcon.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports