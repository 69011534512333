

























import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeceasedDonor } from '@/store/deceasedDonors/types';

@Component
export default class SelectedDeceasedDonorHlaLinks extends Vue {
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;
  @Getter('clientId', { namespace: 'deceasedDonors' }) clientId!: string|undefined;

  @Prop({default: true}) open!: boolean;
  @Prop({default: false}) active!: boolean;

  public show = true;

  public toggleNav() {
    this.show = !this.show;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.active) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'edit-deceased-donor', params: { id: (this.clientId || '') }, hash: hash });
      return location.href;
    }
  }
}
