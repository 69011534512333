




















import { Getter, State } from 'vuex-class';
import { Organ } from '@/store/lookups/types';
import { Recipient } from '@/store/recipients/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RecipientJourney } from '@/store/recipientJourney/types';

@Component
export default class NewOrganLink extends Vue {
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;

  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('organOptions', { namespace: 'lookups' }) organOptions!: (type?: string) => { code: number; value: string }[];

  @Prop({ default: false }) button!: boolean;
  @Prop({ default: false }) navlink!: boolean;

  get showAddOrgan(): boolean {
    return this.checkAllowed('/recipients/:recipient_id/journeys', 'POST');
  }

  /**
   * Gets a string representation of the Recipient Client ID to use for routing
   * 
   * @returns {string}
   */
  get recipientId(): string {
    if (!this.recipient || !this.recipient.client_id) {
      return '';
    }
    return this.recipient.client_id.toString();
  }

  /**
   * Returns an array of options for Add new required organ
   * 
   * @returns {Organ[]} organ options
   */
  get addOrganOptions(): { code: number; value: string }[] {
     // Build options list for organ
    return this.organOptions('single');
  }
}
