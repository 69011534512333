



















































import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { SaveResult } from '@/types';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { TransplantInProgressReportState } from '@/store/reports/types';

@Component({
  components: {
    SaveToolbar,
    SelectInput,
    SubSection
  }
})

export default class TransplantInProgressReport extends Vue {
  // State
  @State(state => state.reports.TransplantInProgressReportState) editState!: TransplantInProgressReportState;
  
  // sort options
  public sortOptions = [
    {code:"1", value:"Donor ID"},
    {code:"2", value:"Transplant Program"}
  ];

  public mounted(): void {
    this.$store.dispatch('reports/newTransplantInProgressReport');
  }

  public generateReport(): void {
    const saveToolbar = this.$refs.generateReport as SaveToolbar;
    saveToolbar.startSaving();

    // Submit query
    this.$store.dispatch('reports/printTransplantInProgressReport', { sort: this.editState.sort }).then((result: SaveResult) => {

      // Get the filename
      const fileName = result.responseData.report; 

      // Is there actually a filename
      if (!!fileName) {
        // Create a link
        const link = document.createElement('a');
        link.href = fileName;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        // Then click it forcing a save/open dialogue
        link.click();
        // Show success notification
      }
      saveToolbar.stopSaving(result);
      
    }).catch((result: SaveResult) => {
      saveToolbar.stopSaving(result);
    });
  }
}
