














































import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { APIRoute, EP } from '@/api-endpoints';

@Component
export default class MainMenu extends Vue {
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;
  @Getter('canMakeReportRequests', { namespace: 'users' }) private canMakeReportRequests!: boolean;

  public mainMenuOpen = false;

  // Check to see if we're on a page for out-of-province recipients 
  isOopPage(): boolean {
    const name = this.$route.name || null;
    return name == 'edit-recipient-oop' || name == 'edit-organ-oop';
  }

  // get url for recipients link
  get getRecipientsLinkRoute(): string {
    return this.isOopPage() ? 'list-recipients-oop' : 'list-recipients';
  }

  // force active if inside oop page
  get getRecipientsLinkClass(): string {
    return this.isOopPage() ? 'router-link-exact-active active' : '';
  }

  // Lifecycle events
  private mounted(): void {
    this.mainMenuOpen = window.innerWidth >= 992;
    window.addEventListener('resize', this.checkWindowWidth);
  }

  // Event listeners
  private checkWindowWidth(): void {
    // set to true if inner width greater than 992
    this.mainMenuOpen = window.innerWidth >= 992;
  }

  get showListDeceasedDonors(): boolean {
    return this.checkAllowed(EP.deceasedDonors.create, 'GET');
  }

  get showListRecipients(): boolean {
    return this.checkAllowed(EP.recipients.create, 'GET');
  }

  get showListLivingDonors(): boolean {
    return this.checkAllowed(EP.living_donors.all, 'GET');
  }
  
  get showListWaitlist(): boolean {
    return this.checkAllowed('/waitlist/:id/grid', 'POST');
  }  

  get showListReports(): boolean {
    return this.canMakeReportRequests;
  }

  public toggleMenu(): void {
    if (window.innerWidth < 992) { 
      this.$emit('toggle');
      this.mainMenuOpen = !this.mainMenuOpen;
    }
  } 
}
