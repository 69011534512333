







import { State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { RecipientJourney } from '@/store/recipientJourney/types';

@Component
export default class BaseOrgan extends Vue {
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
}
