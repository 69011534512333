import Vue from 'vue';
import { MutationTree } from 'vuex';
import { idComparator } from '@/utils';
import { DeceasedDonorsState, DonorSignificantEvent } from '@/store/deceasedDonors/types';

export const mutations: MutationTree<DeceasedDonorsState> = {
  set(state, deceasedDonor) {
    Vue.set(state, 'selected', deceasedDonor);
  },
  clearDeceasedDonor(state) {
    Vue.set(state, 'selected', undefined);
  },
  setList(state, deceasedDonors) {
    Vue.set(state, 'donorsList', deceasedDonors);
  },
  setMeasurements(state, measurements) {
    Vue.set(state.selected!, 'measurements', measurements);
  },
  setDonorAttchments(state, attachments) {
    Vue.set(state, 'attachments', attachments);
  },
  clearExdConfirmations(state) {
    Vue.set(state, 'exdConfirmations', null);
  },
  setExdConfirmations(state, exdConfirmations) {
    Vue.set(state, 'exdConfirmations', exdConfirmations);
  },
  clearOrganDonations(state) {
    Vue.set(state, 'organDonations', null);
  },
  setOrganDonations(state, organDonations) {
    Vue.set(state, 'organDonations', organDonations);
  },
  clearSignificantEvents(state: DeceasedDonorsState) {
    Vue.set(state, 'significantEvents', null);
  },
  setSignificantEvents(state: DeceasedDonorsState, significantEvents: DonorSignificantEvent[]) {
    Vue.set(state, 'significantEvents', significantEvents);
  },
  setCtrLogs(state, ctrLogs) {
    Vue.set(state, 'ctrLogs', ctrLogs);
  },
  clearCtrLogs(state) {
    Vue.set(state, 'ctrLogs', []);
  },
  setNowLists(state, nowLists) {
    Vue.set(state, 'nowLists', nowLists);
  },
  clearNowLists(state) {
    Vue.set(state, 'nowLists', []);
  },
};
