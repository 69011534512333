




























































import { Getter } from "vuex-class";
import { Component, Vue, Watch } from "vue-property-decorator";
import UserMenu from "@/components/shared/site-header/UserMenu.vue";
import MainMenu from "@/components/shared/site-header/MainMenu.vue";
import SearchBar from "@/components/search/SearchBar.vue";

@Component({
  components: {
    UserMenu,
    SearchBar,
    MainMenu,
  },
})
export default class SiteHeader extends Vue {
  @Getter('applicationNamePrefix', { namespace: 'administration' }) private applicationNamePrefix!: string;

  @Getter('getUserName', { namespace: 'users' }) private getUserName!: string;

  public isActive = false;
  public show = false;

  private mounted(): void {
    window.addEventListener('resize', this.checkWindowWidth);
    if (window.innerWidth > 765) {
      this.show = true;
    }
  }

  private toggleSearch(): void {
    this.show = !this.show;
  }

  private checkWindowWidth(): void {
    if (window.innerWidth < 991) {
      this.isActive = false;
    }
    if (window.innerWidth > 765) {
      this.show = true;
    } else {
      this.show = false;
    }
  }


  get isAfflo(): boolean {
    return process.env.VUE_APP_LOGO === "AFFLO";
  }

  public mainMenuClicked(): void {
    this.isActive = !this.isActive;
  }
}


