import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/livingAllocations/actions';
import { getters } from '@/store/livingAllocations/getters';
import { mutations } from '@/store/livingAllocations/mutations';
import { LivingAllocationState } from '@/store/livingAllocations/types';

const state: LivingAllocationState = {
  selected: undefined,
  activeAllocations: undefined,
  inactiveAllocations: undefined,
  exclusionRules: undefined,
  donorDetails: undefined,
  recipientDetails: undefined,
  checklist: undefined,
  checklistDetails: undefined,
  checklistHistory: undefined,
  // loading state flags used across components
  isCreatingAllocation: false,
  isLoadingAllocation: false,
  isLoadingAllocations: false,
  isGeneratingAllocationReport: false,
  isAddingRecipient: false,
  isDiscontinuingOneAllocation: false,
  isDiscontinuingAllAllocations: false,
  isMakingOffer: false,
  isDiscontinuingOneOffer: false,
  isRespondingOffer: false,
  isDecliningMultiple: false,
};

const namespaced = true;

export const livingAllocations: Module<LivingAllocationState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state
};
