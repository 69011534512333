import axios from 'axios';
import { ActionTree } from 'vuex';
import { SaveResult } from '@/types';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { prefixErrors, buildErrorResult } from '@/utils';
import { LivingDonor, LivingDonorIntendedRecipient, LivingDonorsState, LivingDonorRecoveryInfo, LivingDonorTransplantCompleteInfo, LivingDonorJourney } from '@/store/livingDonors/types';

export const actions: ActionTree<LivingDonorsState, RootState> = {
  new({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('set', getters.new);
      resolve();
    });
  },
  get({ commit, getters }, clientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.living_donors.show, [[':id', clientId]]);
      axios.get(url).then((response: any) => {
        const sanitizedLivingDonor: LivingDonor = response.data.living_donor;
        commit('set', sanitizedLivingDonor);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  getList({ commit, getters }, { pageNumber, pageSize, search_params }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.living_donors.index, [
        [':page_number', pageNumber],
        [':page_size', pageSize],
        [':search_params', search_params]
      ]);
      axios.get(url).then((response: any) => {
        const sanitizedLivingDonors: LivingDonor[] = response.data.living_donors;
        commit('setList', sanitizedLivingDonors);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  saveLivingDonor({ commit, getters }, { livingDonorId, livingDonor }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      if (livingDonor) {
        let method: any;
        let ep: string;
        const payload = { living_donor: livingDonor };
        if (livingDonorId) {
          method = axios.patch;
          ep = APIRoute(EP.living_donors.update, [[':id', livingDonorId]]);
        } else {
          method = axios.post;
          ep = APIRoute(EP.living_donors.create);
        }
        method(ep, payload).then((response: any) => {
          const isSuccessful = response.data && !response.data.errors;
          if (isSuccessful) {
            // Handle successful response
            resolve({ success: true, responseData: response.data });
          } else if (response.data && response.data.errors) {
            // Handle server-side validation errors
            // is string if Warning kind of Potential duplicate profile
            if(typeof response.data.errors === 'string' && response.data.errors.includes("living_donor_duplicate_profile") ){
              reject({
                success: false,
                errorMessages: ['validation.messages.duplicate_profile_error'],
                warningMessage: response.data.errors,
                warning: true
              });
            } else {
              reject({ success: false, errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
            }
          }
        }).catch((error: any) => {
          // Handle generic errors
          reject({ success: false, errorMessages: [error.message] });
        });
      } else {
        reject();
      }
    });
  },
  saveLivingDonorsPatch({ commit, getters }, livingDonorPatch): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      if (livingDonorPatch) {
        // Prepare information to send
        const ep = APIRoute(EP.living_donors.update, [[':id', getters.show.client_id]]);
        const payload = { living_donor: livingDonorPatch };
        // Send asynchronously
        axios.patch(ep, payload).then((response: any) => {
          // Check if the update was successful
          const isSuccessful = response.data && !response.data.errors;
          if (isSuccessful) {
            // Handle successful response
            resolve({ success: true, responseData: response.data });
          } else if (response.data && response.data.errors) {
            // is string if Warning kind of Potential duplicate profile
            if(typeof response.data.errors === 'string' && response.data.errors.includes("living_donor_duplicate_profile")){
              reject({
                success: false,
                errorMessages: ['validation.messages.duplicate_profile_error'],
                warningMessage: response.data.errors,
                warning: true
              });
            } else {
              // Handle server-side validation errors
              reject({ success: false, errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
            }
          }
        }).catch((error: any) => {
          // Handle generic errors
          reject({ success: false, errorMessages: [error.message] });
        });
      } else {
        reject();
      }
    });
  },
  loadMeasurements({ commit, getters }, clientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.living_donors.measurements.index, [[':living_donor_id', clientId]]);
      axios.get(url).then((response: any) => {
        commit('setMeasurements', response.data.measurements);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  saveMeasurement({ commit, getters }, { id, livingDonorId, measurement }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;
      const payload = { measurement: measurement } ;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.living_donors.measurements.update, [[':living_donor_id', livingDonorId], [':id', id.$oid]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.living_donors.measurements.create, [[':living_donor_id', livingDonorId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Prefix errors with measurements
          const validationErrors = prefixErrors(response.data.errors, 'measurements');
          // Handle server-side validation errors
          reject({ success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors: validationErrors });
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },

  // Dispatch patch request for a Living Donor's Living Donor Journey embedded sub-document
  saveLivingDonorJourney({ commit, getters }, params: { livingDonorId: string; livingDonorJourneyId: string; livingDonorJourneyPatch: LivingDonorJourney }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const ep = APIRoute(EP.living_donors.journeys.update, [
        [':living_donor_id', params.livingDonorId],
        [':id', params.livingDonorJourneyId],
      ]);
      const payload = {
        journey: params.livingDonorJourneyPatch,
      };
      axios.patch(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Successful save
          resolve({ success: true, responseData: response.data });
        } else {
          // Activity errors e.g. field-level validations or top-level error strings
          reject(buildErrorResult(response.data.errors));
        }
      }).catch((error: any) => {
        // HTTP errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },

  saveIntendedRecipient({ commit, getters }, { id, livingDonorId, livingDonorJourneyId, intendedRecipientPatch }: { id: string|null; livingDonorId: string, livingDonorJourneyId: string; intendedRecipientPatch: LivingDonorIntendedRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      let method: any;
      let ep: string;
      const payload = { intended_recipient: intendedRecipientPatch } ;
      if (id) {
        method = axios.patch;
        ep = APIRoute(EP.living_donors.journeys.intendedRecipients.update, [[':living_donor_id', livingDonorId], [':living_donor_journey_id', livingDonorJourneyId], [':intended_recipient_id', id]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.living_donors.journeys.intendedRecipients.create, [[':living_donor_id', livingDonorId], [':living_donor_journey_id', livingDonorJourneyId]]);
      }
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors, 'intendedRecipient');
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveRecoveryInfoTransplantComplete({ commit, getters }, { id, livingDonorId, livingDonorJourneyId, transplantCompletePatch }: { id: string|null; livingDonorId: string, livingDonorJourneyId: string; transplantCompletePatch: LivingDonorTransplantCompleteInfo }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      const payload = transplantCompletePatch;
      const method = axios.put;
      const ep = APIRoute(EP.living_donors.recoveryInfo.transplant_complete, [[':living_donor_id', livingDonorId], [':living_donor_journey_id', livingDonorJourneyId]]);
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  validateRecoveryInfo({ commit, getters }, { id, livingDonorId, livingDonorJourneyId, recoveryInfoPatch }: { id: string|null; livingDonorId: string, livingDonorJourneyId: string; recoveryInfoPatch: LivingDonorRecoveryInfo }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      const payload = { 
        living_donor_id: livingDonorId,
        living_donor_journey_id: livingDonorJourneyId,
        recovery_info: recoveryInfoPatch };
      const method = axios.put;
      const ep = APIRoute(EP.living_donors.recoveryInfo.validate_save, [[':living_donor_id', livingDonorId], [':living_donor_journey_id', livingDonorJourneyId]]);
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  saveRecoveryInfo({ commit, getters }, { id, livingDonorId, livingDonorJourneyId, recoveryInfoPatch }: { id: string|null; livingDonorId: string, livingDonorJourneyId: string; recoveryInfoPatch: LivingDonorRecoveryInfo }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Prepare information to send
      const payload = { 
        living_donor_id: livingDonorId,
        living_donor_journey_id: livingDonorJourneyId,
        recovery_info: recoveryInfoPatch };
      const method = axios.put;
      const ep = APIRoute(EP.living_donors.recoveryInfo.update, [[':living_donor_id', livingDonorId], [':living_donor_journey_id', livingDonorJourneyId]]);
      // Send asynchronously
      method(ep, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        // Check if the update was successful
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const saveResult = buildErrorResult(response.data.errors);
          reject(saveResult);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
};
