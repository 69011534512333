






































































































































































































import { EP } from '@/api-endpoints';
import { Getter, State } from 'vuex-class';
import { SaveResult, SaveProvider } from '@/types';
import { OrganSpecification, OrganCodeValue } from '@/store/lookups/types';
import { prefixErrors, buildErrorResult } from '@/utils';
import SubSection from '@/components/shared/SubSection.vue';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import CardSection from '@/components/shared/CardSection.vue';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

interface PackagingFormsPageState {
  organDonationId: string;
  organCode: number;
  organName: string;
  organSpecificationCode: number;
  osdif: boolean;
  coolerSheet: boolean;
  interiorLabel: boolean;
  exteriorLabel: boolean;
  all: boolean;
  forResearch: boolean;
  allocationClientId: number;
  recipientClientId: number;
  organLabel: string;
}

@Component({
  components: {
    SubSection,
    CardSection,
  }
})
export default class OrganPackagingForms extends Vue {
  // State
  @State(state => state.deceasedDonors.selected) private deceasedDonor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.packagingForms) private editState!: any;

  // Getters
  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: string|undefined;
  @Getter('donorOrganPackagingForms', { namespace: 'deceasedDonors'}) private donorOrganPackagingForms!: any[];
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('getOrganSpecificationName', { namespace: 'lookups' }) getOrganSpecificationName!: (organCode?: number|null, organSpecificationCode?: number|null) => string;
  @Getter('groupExists', { namespace: 'validations' }) private groupExists!: (groupName: string) => boolean;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;

  @Prop({ default: false }) canSave!: boolean;


  get isOneSelected(): boolean {
    if(this.editState && this.editState.length > 0) {
      return true;
    }

    return this.editState.map((item: any) =>{
      return Object.keys(item).map((value) => item[value])
                              .filter((value) => typeof value === 'boolean')
                              .reduce((t,i) => t || i) === true;
    }).reduce((t: boolean, i: boolean) => t || i);
  }

  // TODO: Tech debt - update the input fields with checkbox-input

  /**
   * Gets the value for consented_for_research
   *
   * @returns {boolean} true if this donor consented_for_research
   */
  get isConsentedForResearch(): boolean {
    return !!this.deceasedDonor.consented_for_research;
  }

  /**
   * Check if current organ donation row is a vessels for banking organ
   *
   * @returns {boolean} true if this organ donation is a vessels for banking
   */
  public isVesselsForBanking(row: PackagingFormsPageState): boolean {
    return row.organCode === OrganCodeValue.VesselsForBanking;
  }

  // Event handlers
  public mounted(): void {
    this.initializeForm();
  }

  // Loaded
  public loaded(): void {
    this.$emit('loaded', 'organPackagingForms');
  }

  /**
   * Gets changes from the edit state as a patch for the packaging form request
   *
   * @returns {any} object containing field changes
   */
  public extractPatch(): any {
    if (this.editState) {
      return this.extractPackagingFormPatch(this.editState);
    } else {
      return {};
    }
  }

  // PRIVATE

  // Initialize card and set pageState
  @Watch('deceasedDonor', { immediate: true, deep: true })
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'packagingForms',
      value: this.buildPackagingFormState(this.donorOrganPackagingForms),
    });
  }

  // Update rows on click
  private checkRow(event: any, row: PackagingFormsPageState): void {
    const rowId: string = event.target.id;
    if (/osdif/.test(rowId)) {
      Vue.set(row, 'osdif', !row.osdif);
    } else if (/cooler/.test(rowId)) {
      Vue.set(row, 'coolerSheet', !row.coolerSheet);
    } else if (/interior/.test(rowId)) {
      Vue.set(row, 'interiorLabel', !row.interiorLabel);
    } else if (/exterior/.test(rowId)) {
      Vue.set(row, 'exteriorLabel', !row.exteriorLabel);
    } else if (/all/.test(rowId)) {
      const keys: string[] = ['osdif', 'coolerSheet', 'interiorLabel', 'exteriorLabel'];
      if (this.isConsentedForResearch) keys.push('forResearch');
      keys.forEach((item) => {
        if(!this.isVesselsForBanking(row) || item !== 'coolerSheet') {
          Vue.set(row, item, !row.all);
        }
      });
      Vue.set(row, 'all', !row.all);
    } else if (/research/.test(rowId)) {
      Vue.set(row, 'forResearch', !row.forResearch);
    }
  }

  // build pageState from getter
  private buildPackagingFormState(packagingForms: any): PackagingFormsPageState[] {
    let result: PackagingFormsPageState[] = [];
    packagingForms.forEach((item: PackagingFormsPageState) => {
      // TODO: Is organ spec still needed here, only Lung has this value now
      result.push({
        organDonationId: item.organDonationId,
        organCode: item.organCode,
        organName: `${this.$t(item.organName)}`,
        organSpecificationCode: item.organSpecificationCode,
        osdif: false,
        coolerSheet: false,
        interiorLabel: false,
        exteriorLabel: false,
        all: false,
        forResearch: false,
        allocationClientId: item.allocationClientId,
        recipientClientId: item.recipientClientId,
        organLabel : item.organSpecificationCode ? `${this.$t(this.getOrganSpecificationName(item.organCode, item.organSpecificationCode))}` : `${this.$t(item.organName)}`,
      });
    });
    return result;
  }

  /**
   * Returns a journey patch object containing changes from a Referral Details form
   *
   * @returns {any}
   */
  private extractPackagingFormPatch(packagingForms: PackagingFormsPageState[]): any {
    let result: any[] = [];
    packagingForms.forEach((item: PackagingFormsPageState) => {
      result.push({
        organ_donation_id: item.organDonationId,
        organ_code: item.organCode,
        organ_specification_code: item.organSpecificationCode,
        osdif: item.osdif,
        cooler_sheet: item.coolerSheet,
        interior_label: item.interiorLabel,
        exterior_label: item.exteriorLabel,
        all: item.all,
        for_research: item.forResearch,
      });
    });
    return result;
  }

  /**
   * Saves the form edit state.
   *
   * Prepares a payload for the Organ Packaging Forms request, dispatches the save action and register the save result.
   *
   * @emits save
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.packagingForms as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'packagingForms');
    // Generate payloads based on current edit state
    const payload = {
      donorId: this.clientId,
      payload: this.extractPatch(),
    };
    // Dispatch save actions in parallel and register the responses
    this.$store.dispatch('deceasedDonors/createPackagingForms', payload).then((response: SaveResult) => {
      // Get the filename
      const fileName = response.data.download_url;
      // Is there actually a filename
      if (!!fileName) {
        // Create a link
        const link = document.createElement('a');
        link.href = fileName;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        // Then click it forcing a save/open dialogue
        link.click();
        // Show success notification
        saveProvider.registerSaveResult({success: true});
      } else {
        // There was no filename
        const saveResult = buildErrorResult(response.data.errors);
        saveProvider.registerSaveResult(saveResult);
      }
    }).catch((error: SaveResult) => {
      // this.$emit('handleErrors', error);
      const saveResult = buildErrorResult(error);
      saveProvider.registerSaveResult(saveResult);
    });
  }
}
