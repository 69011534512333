import Vue from 'vue';
import { MutationTree } from 'vuex';
import { ObjectId } from '@/store/types';
import { LivingAllocationState, LivingAllocations, LivingAllocation, LivingExclusionRules, 
  LivingAllocationChecklistResponse, LivingAllocationChecklistForm, LivingAllocationChecklist, LivingRecipientDetails } from '@/store/livingAllocations/types';

export const mutations: MutationTree<LivingAllocationState> = {
  setExclusionRules(state: LivingAllocationState, exclusionRules: LivingExclusionRules) {
    Vue.set(state, 'exclusionRules', exclusionRules);
  },
  setAllocation(state: LivingAllocationState, allocation: LivingAllocation) {
    Vue.set(state, 'selected', allocation);
  },
  setConfirmedChecklistStatus(state: LivingAllocationState, checklist: LivingAllocationChecklist): void {
    Vue.set(state, 'checklist_status', checklist);
  },
  /**
   * Mutation to clear out a selected allocation so that the we can reset it.
   * @param state
   */
  clearAllocation(state: LivingAllocationState) {
    [
      'selected',
      'exclusionRules',
      'checklist',
      'lastLoadedAllocationDonorId'
    ].forEach((key: string) => Vue.set(state, key, undefined));
  },
  setAllocations(state: LivingAllocationState, allocations: LivingAllocations) {
    Vue.set(state, 'allAllocations', allocations);
  },
  setActiveAllocations(state: LivingAllocationState, allocations: LivingAllocations) {
    Vue.set(state, 'activeAllocations', allocations);
  },
  setInactiveAllocations(state: LivingAllocationState, allocations: LivingAllocations) {
    Vue.set(state, 'inactiveAllocations', allocations);
  },
  startCreatingAllocation(state: LivingAllocationState) {
    Vue.set(state, 'isCreatingAllocation', true);
  },
  stopCreatingAllocation(state: LivingAllocationState) {
    Vue.set(state, 'isCreatingAllocation', false);
  },
  setLastLoadedAllocationDonorId(state: LivingAllocationState, livingDonorId: ObjectId) {
    Vue.set(state, 'lastLoadedAllocationDonorId', livingDonorId);
  },
  startLoading(state: LivingAllocationState) {
    Vue.set(state, 'isLoadingAllocation', true);
  },
  startLoadingAllocations(state: LivingAllocationState) {
    Vue.set(state, 'isLoadingAllocations', true);
  },
  stopLoading(state: LivingAllocationState) {
    Vue.set(state, 'isLoadingAllocation', false);
  },
  stopLoadingAllocations(state: LivingAllocationState) {
    Vue.set(state, 'isLoadingAllocations', false);
  },
  startGeneratingReport(state: LivingAllocationState) {
    Vue.set(state, 'isGeneratingAllocationReport', true);
  },
  stopGeneratingReport(state: LivingAllocationState) {
    Vue.set(state, 'isGeneratingAllocationReport', false);
  },
  startAddingRecipient(state: LivingAllocationState) {
    Vue.set(state, 'isAddingRecipient', true);
  },
  stopAddingRecipient(state: LivingAllocationState) {
    Vue.set(state, 'isAddingRecipient', false);
  },
  startDiscontinuingOneAllocation(state: LivingAllocationState) {
    Vue.set(state, 'isDiscontinuingOneAllocation', true);
  },
  stopDiscontinuingOneAllocation(state: LivingAllocationState) {
    Vue.set(state, 'isDiscontinuingOneAllocation', false);
  },
  startDiscontinuingAllAllocations(state: LivingAllocationState) {
    Vue.set(state, 'isDiscontinuingAllAllocations', true);
  },
  stopDiscontinuingAllAllocations(state: LivingAllocationState) {
    Vue.set(state, 'isDiscontinuingAllAllocations', false);
  },
  startDiscontinuingDonor(state: LivingAllocationState) {
    Vue.set(state, 'discontinuingDonor', false);
  },
  startDiscontinuingOneOffer(state: LivingAllocationState) {
    Vue.set(state, 'isDiscontinuingOneOffer', true);
  },
  stopDiscontinuingOneOffer(state: LivingAllocationState) {
    Vue.set(state, 'isDiscontinuingOneOffer', false);
  },
  startMakingOffer(state: LivingAllocationState) {
    Vue.set(state, 'isMakingOffer', true);
  },
  stopMakingOffer(state: LivingAllocationState) {
    Vue.set(state, 'isMakingOffer', false);
  },
  startRespondingOffer(state: LivingAllocationState) {
    Vue.set(state, 'isRespondingOffer', true);
  },
  stopRespondingOffer(state: LivingAllocationState) {
    Vue.set(state, 'isRespondingOffer', false);
  },
  startDecliningMultiple(state: LivingAllocationState) {
    Vue.set(state, 'isDecliningMultiple', true);
  },
  stopDecliningMultiple(state: LivingAllocationState) {
    Vue.set(state, 'isDecliningMultiple', false);
  },
  setDonorDetails(state: LivingAllocationState, allocation: LivingAllocation) {
    Vue.set(state, 'donorDetails', allocation);
  },
  clearDonorDetails(state: LivingAllocationState) {
    Vue.set(state, 'donorDetails', undefined);
  },
  setRecipientDetails(state: LivingAllocationState, recipientDetails: LivingRecipientDetails) {
    Vue.set(state, 'recipientDetails', recipientDetails);
  },
  clearRecipientDetails(state: LivingAllocationState) {
    Vue.set(state, 'recipientDetails', undefined);
  },
  setChecklist(state: LivingAllocationState, checklist: LivingAllocationChecklistForm) {
    Vue.set(state, 'checklist', checklist);
  },
  setChecklistDetails(state: LivingAllocationState, checklist: LivingAllocationChecklistResponse) {
    Vue.set(state, 'checklistDetails', checklist);
  },
  setChecklistHistory(state: LivingAllocationState, checklist: LivingAllocationChecklistResponse) {
    Vue.set(state, 'checklistHistory', checklist);
  }
};
