export interface UtilitiesState {
}

export interface TagObject {
  text: string;
  code?: number|string;
  expired_date?: string;
  disabled?: boolean;
  tiClasses?: string[];
  hint?: string;
}

// Constants for handling date and time formats for input and output
export enum Format {
  DATE_FORMAT = 'yyyy-MM-dd', // used for parsing
  TIME_FORMAT = 'HH:mm', // used for parsing
  TIME_MASK = '##:##', // v-mask
  TIME_DISPLAY = '-- : --', // visible input placeholder
  TIME_ARIA_DISPLAY = 'Entry pattern is twenty-four hour time with two digits for the hour and two digits for the day',
  DATE_TIME_ISO = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx',
  DATE_TIME_FORMAT = 'dd-MM-yyyy HH:mm', // used for parsing on offer history
  FORMATTED_DATE = 'd MMM, yyyy', // formatted date
  FORMATTED_DATE_TIME = 'd MMM, yyyy; HH:mm', // formatted datetime
  DISPLAY_DATE = 'dd-MM-yyyy', // used for displaying date
  DISPLAY_DATE_TIME = 'dd-MM-yyyy HH:mm', // used for displaying date & time
  TELEPHONE_MASK = '(XXX) XXX-XXXX' // used to mask phone number inputs
}
