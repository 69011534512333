import axios from 'axios';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { handleAllocationErrors, buildErrorResult } from '@/utils';
import { TaskState, TaskType } from '@/store/tasks/types';

export const actions: ActionTree<TaskState, RootState> = {  
  // poll task number for allocation, until returns complete, once complete populate allocation state as normal with response
  loadTask({ commit, state, dispatch, getters }, taskId): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.tasks, [[':taskId', taskId]]);
      axios.get(url).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          const task: TaskType = response.data.task;
          commit('setCurrentTask', task as TaskType);
          const responseObject = task && task.response ? JSON.parse(task.response) : null;
          
          if (responseObject?.success == false) {
            commit('setErrorState', responseObject?.errors?.errors?.allocation_service);
          } else {
            const percentage = getters.getPercentageFromTask;
            commit('setPercentage', percentage);
            commit('setStatus', task.status);
          }
          resolve();
        } else {
          // Build error result
          const errorResult = handleAllocationErrors(response.data.errors);
          // Reject with errors
          reject(errorResult);
        }
      }).catch((error: any) => {
        reject(error);
      });
    });
  }
};
