import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { HistoryState, HistoryVxm } from '@/store/history/types';

export const getters: GetterTree<HistoryState, RootState> = {
  // Which entry in VXM history was generated most recently
  latestHistoryVxm(state): HistoryVxm|null {
    const collection = state.vxm || [];
    if (collection.length === 0) return null;

    const latest: HistoryVxm = collection.reduce((prev: HistoryVxm, curr: HistoryVxm) => {
      if(prev && curr) {
        const prevDate = prev.date;
        const currDate = curr.date;
        return prevDate < currDate ? curr : prev;
      }
      return curr;
    });
    return latest || null;
  },
};
