




















































































































































































































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { VueGoodTable } from 'vue-good-table';
import HlaInput from '@/components/shared/HlaInput.vue';
import { Laboratory } from '@/store/laboratories/types';
import TextInput from '@/components/shared/TextInput.vue';
import { HlaAntibodyTestKit } from '@/store/lookups/types';
import { RootState, GenericCodeValue, NumericCodeValue } from '@/store/types';
import { idComparator } from '@/utils';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import HlaInputGroup from '@/components/shared/HlaInputGroup.vue';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import HlaAntibodyFilterTable from '@/components/hla/HlaAntibodyFilterTable.vue';
import { Recipient, RecipientProfileDetails, RecipientDiagnosticsHla, AntibodiesCumulative } from '@/store/recipients/types';
import { LabHLAAntibody, HlaAntibodyData, HlaAntibodyTestingMethod, HLAAntibodiesForm, HlaAntibodyDataForm } from '@/store/labs/types';

// Form schema
interface HlaSerumSummaryReviewForm {
  cumulativeAntibodies?: HlaAntibodyDataForm;
  selectedAntibodies?: string[];
  testingMethod?: number|null;
  hlaAntibodiesTestOpenInModal?: HLAAntibodiesForm;
}

interface HlaAntibodiesFilterRow {
  _id?: { $oid: string };
  sampleCode: string;
  sampleDrawDate: string;
  laboratory: string;
  cpra1: string;
  cpra2: string;
  combinedCpra: string;
  selected_unacceptable: string[];
  selected_indeterminate: string[];
  selected_possible_allele_specific: string[];
}

interface ViewCumulativeAntibodyRow {
  reactivity: string;
  class1: string;
  class2: string;
}

// Types of modal switching
enum ModalSwitch {
  Previous,
  Next
}

@Component({
  components: {
    CardSection,
    SubSection,
    SelectInput,
    TextInput,
    TextAreaInput,
    NumberInput,
    HlaInput,
    VueGoodTable,
    ModalSection,
    HlaInputGroup,
    HlaAntibodyFilterTable,
  }
})
export default class HlaSerumSummaryReview extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.labs.hlaAntibodies) private hlaAntibodies!: LabHLAAntibody[];
  @State(state => state.labs.selectedHlaSerumSummaryReview) private selectedHlaSerumSummaryReview!: LabHLAAntibody;
  @State(state => state.pageState.currentPage.hlaSerumSummaryReview) editState!: HlaSerumSummaryReviewForm;
  @State(state => state.recipients.selectedRecipient) private selectedRecipient!: Recipient;
  @State(state => state.lookups.laboratory_hla_antibody_test_kits) private hlaAntibodyTestKitLookup!: HlaAntibodyTestKit[];
  @State(state => state.recipients.selectedRecipient.diagnostics.hla) private recipientDiagnosticsHla!: RecipientDiagnosticsHla;
  @State(state => state.laboratories.hla) private hlaLaboratoryLookup!: Laboratory[];

  // Lookup tables to be loaded by the CardSection component
  private lookupsToLoad = [
    'hla_testing_method',
    'hla_dictionary_epitopes',
    'laboratory_hla_antibody_test_kits',
    'hla_antibody_allele_group_overrides',
  ];

  // Laboratory lookups to be loaded by the CardSection component
  private laboratoriesToLoad = ['hla'];

  // Getters
  @Getter('hla_testing_method', { namespace: 'lookups' }) private hlaTestingMethodLookup: any;
  @Getter('currentHlaAntibodyTest', { namespace: 'labs' }) private currentHlaAntibodyTest!: LabHLAAntibody|null;
  @Getter('buildHLAAntibodiesForm', { namespace: 'labs' }) buildHLAAntibodiesForm!: (hlaAntibodiesData: LabHLAAntibody) => HLAAntibodiesForm;
  @Getter('lookupHlaAntibodyTestingKit', { namespace: 'lookups' }) lookupHlaAntibodyTestingKit!: (code: string|null) => HlaAntibodyTestKit|null;

  // Initialize form when card section has loaded
  private loaded(): void {
    // Load the labs after card section has finished loading relevant lookups
    const recipientId = this.selectedRecipient.client_id;
    this.$store.dispatch('labs/loadHlaAntibodies', recipientId).then(() => {
      this.initializeForm();
      this.$emit('loaded', 'hlaSerumSummaryReview');
    }).catch(() => {
      console.warn('Could not load recipient HLA Antibody labs');
    });
  }

  // Watchers
  @Watch('hlaAntibodies')
  private onHlaAntibodiesChanged(): void {
    this.initializeForm();
  }
  @Watch('selectedRecipient')
  private onSelectedRecipientChanged(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    // Deselect any HLA Antibodies Test if one is selected
    this.$store.commit('labs/selectHlaSerumSummaryReview', undefined);
    // Populate editable form state with values extracted from selected recipient
    this.$store.commit('pageState/set', {
      pageKey: 'hlaSerumSummaryReview',
      value: this.extractHlaSerumSummaryReviewForm(this.selectedRecipient, this.hlaAntibodies)
    });
  }

  // Translate just the HLA Cumulative Antibody data based on the form layout
  private buildHlaCumulativeAntibodiesDataForm(recipientDiagnosticsHla: RecipientDiagnosticsHla): HlaAntibodyDataForm {
    const cumulative: AntibodiesCumulative = recipientDiagnosticsHla.cumulative_antibodies || {};
    const class1 = cumulative.class1 || { antibodies: {}, epitopes: {} };
    const class2 = cumulative.class2 || { antibodies: {}, epitopes: {} };
    const epitopes = class1.epitopes || {};
    return {
      class1_unacceptable: class1.antibodies.unacceptable_allele_group,
      class1_unacceptable_allele_specific: class1.antibodies.unacceptable_allele_specific,
      class1_unacceptable_alpha_beta: class1.antibodies.unacceptable_alpha_beta,
      class1_indeterminate: class1.antibodies.indeterminate_allele_group,
      class1_indeterminate_allele_specific: class1.antibodies.indeterminate_allele_specific,
      class1_indeterminate_alpha_beta: class1.antibodies.indeterminate_alpha_beta,
      class1_possible_allele_specific: class1.antibodies.possible_allele_specific,
      class2_unacceptable: class2.antibodies.unacceptable_allele_group,
      class2_unacceptable_allele_specific: class2.antibodies.unacceptable_allele_specific,
      class2_unacceptable_alpha_beta: class2.antibodies.unacceptable_alpha_beta,
      class2_indeterminate: class2.antibodies.indeterminate_allele_group,
      class2_indeterminate_allele_specific: class2.antibodies.indeterminate_allele_specific,
      class2_indeterminate_alpha_beta: class2.antibodies.indeterminate_alpha_beta,
      class2_possible_allele_specific: class2.antibodies.possible_allele_specific,
      epitopes_unacceptable: epitopes.unacceptable,
      epitopes_indeterminate: epitopes.indeterminate,
    };
  }

  // Translate from relevant parts of the HLA Antibody Lab data structure to the form layout
  private extractHlaSerumSummaryReviewForm(recipient: Recipient, hlaAntibodies: LabHLAAntibody[]): HlaSerumSummaryReviewForm {
    // Retrieve and transform all values needed for the form
    const result: HlaSerumSummaryReviewForm = {
      cumulativeAntibodies: (!recipient.diagnostics!.hla ? {} : this.buildHlaCumulativeAntibodiesDataForm(recipient.diagnostics!.hla)),
      selectedAntibodies: [],
      testingMethod: HlaAntibodyTestingMethod.Sab
    };
    return result;
  }

  // Event handlers
  private removeAllSelectedAntibodies(): void {
    this.editState.selectedAntibodies = [];
  }
  private toggleSelectedAntibody(selectedAntibody: any): void {
    if (this.editState && this.editState.selectedAntibodies) {
      // Check whether or not the antibody has already been selected
      if (!this.editState.selectedAntibodies.includes(selectedAntibody)) {
        // Add antibody to selection
        this.editState.selectedAntibodies.push(selectedAntibody);
      } else {
        // Remove antibody from selection
        const indexOfGene = this.editState.selectedAntibodies.indexOf(selectedAntibody);
        this.editState.selectedAntibodies.splice(indexOfGene, 1);
      }
    }
  }
  private removeSelectedAntibody(selectedAntibody: any): void {
    if (this.editState && this.editState.selectedAntibodies) {
      // Remove antibody from selection
      const indexOfGene = this.editState.selectedAntibodies.indexOf(selectedAntibody);
      this.editState.selectedAntibodies.splice(indexOfGene, 1);
    }
  }
  private openHlaAntibodiesTestModalFromTable(selectedRow: any): void {
    // Get HLA Antibodies Test ID from the table row referenced in the select event
    const selectedHlaAntibodiesId = selectedRow._id && selectedRow._id.$oid ? selectedRow._id!.$oid : undefined;
    // Retrieve the HLA Antibodies Test object from the Vue-X Store
    const foundHlaAntibodies: LabHLAAntibody|undefined = this.hlaAntibodies.find((each: LabHLAAntibody) => {
      return each._id && each._id.$oid === selectedHlaAntibodiesId;
    });
    // Check if we successfully found the HLA Antibodies Test object
    if (foundHlaAntibodies) {
      this.openHlaAntibodiesTestModal(foundHlaAntibodies);
    }
  }
  private openHlaAntibodiesTestModal(hlaAntibodiesTest: LabHLAAntibody): void {
    // Store the selected HLA Antibodies Test object
    this.$store.commit('labs/selectHlaSerumSummaryReview', hlaAntibodiesTest);
    // Extract form state from the selected object
    Vue.set(this.editState, 'hlaAntibodiesTestOpenInModal', this.buildHLAAntibodiesForm(this.selectedHlaSerumSummaryReview));
    // Begin showing the modal
    const hlaAntibodiesModal = this.$refs.hlaAntibodiesModal as ModalSection;
    hlaAntibodiesModal.showModal();
  }
  private previousHlaAntibodiesTestModal(): void {
    // Begin switching the modal
    const hlaAntibodiesModal = this.$refs.hlaAntibodiesModal as ModalSection;
    hlaAntibodiesModal.switchModal(ModalSwitch.Previous);
  }
  private nextHlaAntibodiesTestModal(): void {
    // Begin switching the modal
    const hlaAntibodiesModal = this.$refs.hlaAntibodiesModal as ModalSection;
    hlaAntibodiesModal.switchModal(ModalSwitch.Next);
  }
  private onHlaAntibodiesTestModalSwitched(options?: any): void {
    if (this.selectedHlaSerumSummaryReview) {
      // Use filtered rows from table component so that modal switch order logically corresponds to row order
      const table = this.$refs.serumSummaryEntriesTable as unknown as { filteredRows: any[] };
      const filterRowsFromTable: HlaAntibodiesFilterRow[] = table.filteredRows[0].children;
      // Determine index of selected test with respect to the filtered rows
      const indexOfCurrentTest = filterRowsFromTable.findIndex((test: any) => {
        return idComparator(test, this.selectedHlaSerumSummaryReview);
      });
      if (indexOfCurrentTest > -1) {
        if (options == ModalSwitch.Previous) {
          // Identify previous row with respect to filtering, sorting, etc.
          let previousFilterRow: HlaAntibodiesFilterRow;
          if (indexOfCurrentTest < 1) {
            previousFilterRow = filterRowsFromTable[filterRowsFromTable.length - 1];
          } else {
            previousFilterRow = filterRowsFromTable[indexOfCurrentTest - 1];
          }
          if (previousFilterRow) {
            // Fetch direct reference to source API document regardless of filtering
            const previousTest = this.hlaAntibodies.find((test: any) => {
              return idComparator(test, previousFilterRow);
            });
            if (previousTest) {
              // Select previous test
              this.openHlaAntibodiesTestModal(previousTest);
            }
          }
        } else if (options == ModalSwitch.Next) {
          // Identify next row with respect to filtering, sorting, etc.
          let nextFilterRow: HlaAntibodiesFilterRow;
          if (indexOfCurrentTest > filterRowsFromTable.length - 2) {
            nextFilterRow = filterRowsFromTable[0];
          } else {
            nextFilterRow = filterRowsFromTable[indexOfCurrentTest + 1];
          }
          if (nextFilterRow) {
            // Fetch direct reference to source API document regardless of filtering
            const nextTest = this.hlaAntibodies.find((test: any) => {
              return idComparator(test, nextFilterRow);
            });
            if (nextTest) {
              // Select next test
              this.openHlaAntibodiesTestModal(nextTest);
            }
          }
        }
      }
    }
  }

  // Open export data modal for copying plaintext antibody data
  private openExportDataModal(): void {
    const exportDataModel = this.$refs.exportDataModel as ModalSection;
    if (!exportDataModel) return;

    exportDataModel.showModal();
  }

  // Get method dropdown options as string-based values so it is visible in the disabled select input
  get hlaTestingMethodOptions(): GenericCodeValue[] {
    const methods = this.hlaTestingMethodLookup || [];
    const sanitizedMethods = methods.map((method: NumericCodeValue): GenericCodeValue => {
      return { code: method.code.toString(), value: method.value };
    });
    return sanitizedMethods;
  }

  // Conditions for toggling form areas
  get zeroAntibodiesSelected(): boolean {
    return !this.editState.selectedAntibodies || this.editState.selectedAntibodies.length === 0;
  }

  // Display Class 1 legacy data?
  get showClass1PossibleAlleleSpecific(): boolean {
    if (!this.editState || !this.editState.cumulativeAntibodies) return false;

    const values = this.editState.cumulativeAntibodies.class1_possible_allele_specific || [];
    return values.length > 0;
  }

  // Display Class 2 legacy data?
  get showClass2PossibleAlleleSpecific(): boolean {
    if (!this.editState || !this.editState.cumulativeAntibodies) return false;

    const values = this.editState.cumulativeAntibodies.class2_possible_allele_specific || [];
    return values.length > 0;
  }

  // Display legacy data column in filter table?
  get showPossibleAlleleSpecificColumn(): boolean {
    return this.showClass1PossibleAlleleSpecific || this.showClass2PossibleAlleleSpecific;
  }

  // Display legacy data rows in 'view' popup?
  get showPossibleAlleleSpecificRows(): boolean {
    return this.showClass1PossibleAlleleSpecific || this.showClass2PossibleAlleleSpecific;
  }

  get tableColumns(): { label: string; field: string; width?: string }[] {
    // Columns that are always present
    const result = [
      { label: 'Sample Code', field: 'sampleCode' },
      { label: 'Sample Draw Date', field: 'sampleDrawDate' },
      { label: 'Laboratory', field: 'laboratory' },
      { label: 'cPRA / PRA Class I', field: 'cpra1' },
      { label: 'cPRA / PRA Class II', field: 'cpra2' },
      { label: 'Combined cPRA', field: 'combinedCpra' },
      { label: 'Unacceptable', field: 'selected_unacceptable', width: '10%' },
      { label: 'Indeterminate', field: 'selected_indeterminate', width: '10%' },
    ];

    // Possible Allele Specific column only shown for low-res legacy data found in historical records
    if (this.showPossibleAlleleSpecificColumn) {
      result.push(
        { label: this.$t('possible_allele_specific_column').toString(), field: 'selected_possible_allele_specific', width: '10%' },
      );
    }
    return result;
  }

  // Filter table configuration
  get hlaSerumSummaryReviewTableConfig(): TableConfig {
    return {
      data: this.hlaAntibodies,
      columns: this.tableColumns,
      // Disable unused sorting feature, because Vue Good Table has sorting enabled by default
      sortOptions: {
        enabled: false,
      },
    };
  }

  // Current Antibodies Class I column heading
  get exportCurrentClass1Column(): string {
    if (!this.currentHlaAntibodyTest) return 'Class I';

    const sampleDate = this.currentHlaAntibodyTest?.sample_date;
    const parsed = this.parseDisplayDateUi(sampleDate) || '-';
    return `Class I, Sample Date: ${parsed}`;
  }

  // Current Antibodies Class II column heading
  get exportCurrentClass2Column(): string {
    if (!this.currentHlaAntibodyTest) return 'Class II';

    const sampleDate = this.currentHlaAntibodyTest?.sample_date;
    const parsed = this.parseDisplayDateUi(sampleDate) || '-';
    return `Class II, Sample Date: ${parsed}`;
  }

  // Cumulative Antibodies Class I column heading
  get exportCumulativeClass1Column(): string {
    return 'Class I';
  }

  // Cumulative Antibodies Class II column heading
  get exportCumulativeClass2Column(): string {
    return 'Class II';
  }

  // Generate plain text representation of a single string array
  private plainTextArray(arr?: string[]): string {
    if (!arr || arr.length === 0) return '';

    // NOTE: Tp-13895 We need to use Cw rather than C when displaying the copy popup
    const mapped = arr.map((raw: string): string => {
      return raw.replace(/C/, "Cw");
    });

    return mapped.join(' ').trim();
  }

  // Generate rows of plain text data based on recipient's most recent HLA Antibody Test
  get currentAntibodyRows(): ViewCumulativeAntibodyRow[] {
    if (!this.currentHlaAntibodyTest || !this.currentHlaAntibodyTest.antibodies) return [];

    // Generate multiple entries in row array, based on a single HLA antibody test
    const rows: ViewCumulativeAntibodyRow[] = [];
    const antibodies: HlaAntibodyData = this.currentHlaAntibodyTest.antibodies;

    rows.push({
        reactivity: "Unacceptable allele group",
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.unacceptable_allele_group),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.unacceptable_allele_group),
    });
    rows.push({
        reactivity: "Unacceptable allele-specific",
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.unacceptable_allele_specific),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.unacceptable_allele_specific),
    });
    rows.push({
        reactivity: "Unacceptable epitope",
        class1: this.plainTextArray(antibodies?.class1?.epitopes?.unacceptable),
        class2: this.plainTextArray(antibodies?.class2?.epitopes?.unacceptable),
    });
    rows.push({
        reactivity: "Unacceptable alpha-beta",
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.unacceptable_alpha_beta),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.unacceptable_alpha_beta),
    });
    rows.push({
        reactivity: "Indeterminate allele group",
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.indeterminate_allele_group),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.indeterminate_allele_group),
    });
    rows.push({
        reactivity: "Indeterminate allele-specific",
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.indeterminate_allele_specific),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.indeterminate_allele_specific),
    });
    rows.push({
        reactivity: "Indeterminate epitope",
        class1: this.plainTextArray(antibodies?.class1?.epitopes?.indeterminate),
        class2: this.plainTextArray(antibodies?.class2?.epitopes?.indeterminate),
    });
    rows.push({
        reactivity: "Indeterminate alpha-beta",
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.indeterminate_alpha_beta),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.indeterminate_alpha_beta),
    });
    if (this.showPossibleAlleleSpecificRows) {
      rows.push({
        reactivity: this.$t('possible_allele_specific_row').toString(),
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.possible_allele_specific),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.possible_allele_specific),
      });
    }
    return rows;
  }

  // Generate rows of plain text data based on recipient's cumulative HLA diagnostics
  get cumulativeAntibodyRows(): ViewCumulativeAntibodyRow[] {
    if (!this.currentHlaAntibodyTest) return [];

    // Generate multiple entries in row array, based on a recipient-level HLA diganostics
    const rows: ViewCumulativeAntibodyRow[] = [];
    const antibodies: AntibodiesCumulative= this.recipientDiagnosticsHla.cumulative_antibodies || {};

    rows.push({
        reactivity: "Unacceptable allele group",
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.unacceptable_allele_group),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.unacceptable_allele_group),
    });
    rows.push({
        reactivity: "Unacceptable allele-specific",
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.unacceptable_allele_specific),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.unacceptable_allele_specific),
    });
    rows.push({
        reactivity: "Unacceptable epitope",
        class1: this.plainTextArray(antibodies?.class1?.epitopes?.unacceptable),
        class2: this.plainTextArray(antibodies?.class2?.epitopes?.unacceptable),
    });
    rows.push({
        reactivity: "Unacceptable alpha-beta",
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.unacceptable_alpha_beta),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.unacceptable_alpha_beta),
    });
    rows.push({
        reactivity: "Indeterminate allele group",
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.indeterminate_allele_group),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.indeterminate_allele_group),
    });
    rows.push({
        reactivity: "Indeterminate allele-specific",
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.indeterminate_allele_specific),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.indeterminate_allele_specific),
    });
    rows.push({
        reactivity: "Indeterminate epitope",
        class1: this.plainTextArray(antibodies?.class1?.epitopes?.indeterminate),
        class2: this.plainTextArray(antibodies?.class2?.epitopes?.indeterminate),
    });
    rows.push({
        reactivity: "Indeterminate alpha-beta",
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.indeterminate_alpha_beta),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.indeterminate_alpha_beta),
    });
    if (this.showPossibleAlleleSpecificRows) {
      rows.push({
        reactivity: this.$t('possible_allele_specific_row').toString(),
        class1: this.plainTextArray(antibodies?.class1?.antibodies?.possible_allele_specific),
        class2: this.plainTextArray(antibodies?.class2?.antibodies?.possible_allele_specific),
      });
    }
    return rows;
  }

  // Current Antibodies plain text table configuration
  get exportDataCurrentTableConfig(): TableConfig {
    return {
      data: this.currentAntibodyRows,
      columns: [
        {
          label: this.$t('serum_summary_export_data_reactivity_column'),
          field: 'reactivity',
          width: '24%',
        },
        {
          label: this.exportCurrentClass1Column,
          field: 'class1',
          width: '38%',
        },
        {
          label: this.exportCurrentClass2Column,
          field: 'class2',
          width: '38%',
        },
      ],
      empty: this.$t('serum_summary_export_data_empty').toString(),
      sortOptions: {
        enabled: false,
      },
    };
  }

  // Cumulative Antibodies plain text table configuration
  get exportDataCumulativeTableConfig(): TableConfig {
    return {
      data: this.cumulativeAntibodyRows,
      columns: [
        {
          label: this.$t('serum_summary_export_data_reactivity_column'),
          field: 'reactivity',
          width: '24%',
        },
        {
          label: this.exportCumulativeClass1Column,
          field: 'class1',
          width: '38%',
        },
        {
          label: this.exportCumulativeClass2Column,
          field: 'class2',
          width: '38%',
        },
      ],
      empty: this.$t('serum_summary_export_data_empty').toString(),
      sortOptions: {
        enabled: false,
      },
    };
  }

  // Row style class for Filter Table highlighting
  private hlaSerumSummaryReviewRowStyleClass(row: any): string {
    if (row.highlight) {
      return 'set-link filterRow filterRowMatch';
    } else {
      return 'set-link filterRow';
    }
  }

  private includesAntibody(sourceAntibodies: string[], targetAntibody: string): boolean {
    // Check for target antibody in source antibodies
    return sourceAntibodies.includes(targetAntibody);
  }

  private saving(event: any) {
    this.$emit('saving', event);
  }

  private handleErrors(event: any) {
    this.$emit('handleErrors', event);
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): {[key: string]: string} {
    const result = {};
    return result;
  }


}
