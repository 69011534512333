






























import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import CardSection from '@/components/shared/CardSection.vue';
import CrossmatchInfoSection, { CrossmatchInfoPageState } from '@/components/deceasedDonors/CrossmatchInfoSection.vue';
import CrossmatchSampleSection, { CrossmatchSamplePageStage } from '@/components/deceasedDonors/CrossmatchSampleSection.vue';

export interface SerumCrossmatchPageState {
  crossmatchInfo?: CrossmatchInfoPageState;
  crossmatchSample?: CrossmatchSamplePageStage;
}

@Component({
  components: {
    CardSection,
    CrossmatchInfoSection,
    CrossmatchSampleSection,
  }
})
export default class SerumCrossmatchSection extends Vue {
  @State(state => state.deceasedDonors.selected) private deceasedDonor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.serumCrossmatch) editState!: SerumCrossmatchPageState;

  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: number;

  @Prop({ default: false }) newJourney!: boolean;

  private isFinishedLoadingSubsections = new Set();
  private totalSubSections = 2; // How many sub sections are we mounting

  private lookupsToLoad = [
    't_b_cell',
    'crossmatch_dsa_results',
    'crossmatch_treatment_types',
    'crossmatch_method_techniques',
    'crossmatch_sample_type',
  ];
  
  // Laboratory lookups to be loaded by the CardSection component
  private laboratoriesToLoad = ['hla'];

  /**
   * Populates form state with default values for the Consultation Section
   */
  public initializeForm(): void {
    // Clear validation errors
    this.clear();
    // Initialize empty page state
    let crossmatchInfoPageState: CrossmatchInfoPageState = {};
    let crossmatchSamplePageState: CrossmatchSamplePageStage = {};
    // Sub section components
    const crossmatchInfoForm = this.$refs.crossmatchInfo as CrossmatchInfoSection;
    const crossmatchSampleForm = this.$refs.crossmatchSample as CrossmatchSampleSection;
    // Build pageState for Crossmatch Info section
    if (!!crossmatchInfoForm) {
      crossmatchInfoPageState = crossmatchInfoForm.buildCrossmatchInfoPageState();
    }
    // Build pageState for Crossmatch Sample section
    if (!!crossmatchSampleForm) {
      crossmatchSamplePageState = crossmatchSampleForm.buildCrossmatchSamplePageState();
    }

    // Commit our state
    this.$store.commit('pageState/set', {
      pageKey: 'serumCrossmatch',
      value: { 
        crossmatchInfo: crossmatchInfoPageState,
        crossmatchSample: crossmatchSamplePageState,
       },
    });
  }
  
  // API response keys on the left, id for our UI on the right
  public idLookup(): {[key: string]: string} {
    const result = {};

    // Crossmatch Info Section
    const crossmatchInfoSection = this.$refs.crossmatchInfo as CrossmatchInfoSection;
    if (crossmatchInfoSection) {
      Object.assign(result, { ...crossmatchInfoSection.idLookup });
    }
    
    // Crossmatch Sample Section
    const crossmatchSampleSection = this.$refs.crossmatchSample as CrossmatchSampleSection;
    if (crossmatchSampleSection) {
      Object.assign(result, { ...crossmatchSampleSection.idLookup });
    }
    
    return result;
  }

  // PRIVATE

  // loaded
  private loaded(): void {
    // Load xm labs
    this.$store.dispatch('labs/loadCrossmatchLabs', this.clientId);
    this.checkIfLoadingComplete();
    this.$emit('loaded', 'serumCrossmatch');
  }

  // After sub section mounts add the ref to isFinishedLoadingSubsections
  private loadedSubsection(sectionName: string): void {
    this.isFinishedLoadingSubsections.add(sectionName);
    this.checkIfLoadingComplete();
  }

  // Check if all sub sections have been mounted
  private checkIfLoadingComplete(): void {
    if (this.isFinishedLoadingSubsections.size === this.totalSubSections) {
      this.initializeForm();
    }
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }
  
  // Emit event to parent so it can clear validations
  private clear() {
    this.$emit('clear');
  }
}
