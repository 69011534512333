









































































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { Recipient } from '@/store/recipients/types';
import TextInput from "@/components/shared/TextInput.vue";
import SubSection from '@/components/shared/SubSection.vue';
import SelectInput from "@/components/shared/SelectInput.vue";
import { Component, Vue, Watch } from 'vue-property-decorator';
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";
import { SaveResult, SaveableSection, SaveProvider, ModalContent } from '@/types';
import WaitlistHistoryTable from '@/components/organs/shared/WaitlistHistoryTable.vue';
import { WaitlistSectionPageState } from '@/components/organs/shared/_WaitlistSection.vue';
import { JourneyStage, RecipientJourney, RecipientWaitlistAttributes, RecipientWaitlistFactors, WaitlistDecision, WaitlistFactorEvent, WaitlistStatusValue } from '@/store/recipientJourney/types';
import { Organ, OrganWaitlistMedicalStatus, OrganWaitlistMedicalStatusValue, HeartMedicalStatusValue, ReasonForMedicalHold, HEART_STATUSES_EXCLUDED_FROM_SECONDARY, WaitlistFactorCodeValue } from '@/store/lookups/types';
import { EP } from "@/api-endpoints";

export interface WaitlistStatusUpdateState {
  medicalStatus?: string;
  secondaryMedicalStatus?: string;
  extendHold?: boolean;
  removeHold?: boolean;
  reasonForHold?: number;
  ifOtherReason?: string;
  holdComments?: string;
}

@Component({
  components: {
    SubSection,
    SelectInput,
    TextInput,
    TextAreaInput,
    CheckboxInput,
    SelectOtherInput,
    WaitlistHistoryTable,
  },
})
export default class WaitlistStatus extends mixins(DateUtilsMixin) {
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.waitlistSection) editState!: WaitlistSectionPageState;
  @State(state => state.lookups.medical_hold_reasons) reasonForMedicalHoldOptions!: ReasonForMedicalHold[];
  @State(state => state.journeyState.waitlistDecisions) waitlistDecisions!: WaitlistDecision[];

  @Getter('clientId', { namespace: 'recipients' }) private recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('wasRemovedFromWaitlist', { namespace: 'journeyState' }) wasRemovedFromWaitlist!: boolean;
  @Getter('isHeartJourneyOrHeartCluster', { namespace: 'journeyState' }) isHeartJourneyOrHeartCluster!: boolean
  @Getter('medicalStatusLookup', { namespace: 'lookups' }) medicalStatusLookup!: (excludeHold: boolean, organLookup?: Organ[], organCode?: number) => any;
  @Getter('secondaryMedicalStatusLookup', { namespace: 'lookups' }) secondaryMedicalStatusLookup!: (organLookup?: Organ[], organCode?: number) => any;
  @Getter('describeMedicalStatus', { namespace: 'lookups' }) describeMedicalStatus!: (organLookup?: Organ[], organCode?: number, medicalStatusCode?: string|null, secondaryMedicalStatusCode?: string|null) => string|undefined;
  @Getter('isOnMedicalHold', { namespace: 'journeyState' }) isOnMedicalHold!: boolean;
  @Getter('isClustered', { namespace: 'journeyState' }) isClustered!: boolean;
  @Getter('clusteredJourneys', { namespace: 'journeyState' }) clusteredJourneys!: RecipientJourney[];
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;

  // Whether or not user can save Waitlist Status is based on the waitlist attributes update endpoint
  get canSave(): boolean {
    if(this.journey.stage == JourneyStage.PostTransplant) return false;
    return !this.journey.completed && this.checkAllowed('/recipients/:recipient_id/journeys/:journey_id/waitlist_attributes', 'PATCH');
  }
  /**
   * Emits a loaded event when the component has finished mounting
   * 
   * @emits loaded
   */
  private mounted(): void {
    this.initializeForm();
    this.$emit('loaded', 'waitlistStatus');
  }

  /**
   * Populates form state with default values for the Waitlist Status Update sub-section
   */
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'waitlistSection',
      componentKey: 'statusUpdate',
      value: this.buildWaitlistStatusUpdateState(),
    });
  }

  /**
   * Get a numeric representation of which Organ is associated with the selected Journey
   * 
   * @returns {number} code that can be used when fetching entries from the Organ lookup table
   */
  get organCode(): number {
    if (!this.journey || !this.journey.organ_code) {
      return 0;
    }
    return this.journey.organ_code;
  }

  /**
   * Build state for the Waitlist Status History form area
   * 
   * @returns {WaitlistHistoryState} form state for Status History
   */
  public buildWaitlistStatusUpdateState(): WaitlistStatusUpdateState {
    const result: WaitlistStatusUpdateState = {};
    const waitlistAttributes = this.journey?.stage_attributes?.waitlist || {};
    if (!waitlistAttributes) {
      return result;
    }
    // Build waitlist update directly from current waitlist attributes
    const factors = waitlistAttributes.factors || {};
    const reasonForHold = factors.on_hold_medical_reason_code || undefined;
    // Fetch entry in the lookup table describing the Medical Hold reason
    const reasonOptions = this.reasonForMedicalHoldOptions || [];
    const reasonDocument = reasonOptions.find((reason: ReasonForMedicalHold) => {
      return reason.code == reasonForHold;
    });
    // Check if the entry is 'Other'
    const isOther = reasonDocument === undefined ? false : reasonDocument.other_selected || false;
    Object.assign(result, {
      medicalStatus: factors.medical_status_code || undefined,
      secondaryMedicalStatus: factors.medical_status_secondary_code || undefined,
      reasonForHold,
      ifOtherReason: isOther ? factors.on_hold_medical_other_reason || undefined : undefined,
      holdComments: factors.on_hold_medical_reason_comment || undefined,
    });
    return result;
  }

  /**
   * Saves the form edit state.
   * 
   * Prepares an update payload for Waitlist Atributes, dispatches a save action, and registers the save result.
   * @emits saving saving has begun
   * @emits saved saving has completed successful
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveWaitlistStatus as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('saving', 'waitlistStatus');
    // Generate payload based on current edit state
    const waitlistAttributesPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      journeyId: this.journeyId,
      recipientId: this.recipientId,
      waitlistAttributes: waitlistAttributesPatch,
      prefix: 'waitlistStatus',
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveWaitlist', payload).then((success: SaveResult) => {
      // Reload recipient and journey for latest Waitlist Factors
      this.$store.dispatch('recipients/get', this.recipientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
          // Re-initialize Waitlist Status Update form state
          this.initializeForm();
          saveProvider.registerSaveResult(success);
          // Are we removing a medical hold? Are we clustered?
          if (this.isRemovingHold && this.isClustered) {
            // Display outcome notification
            this.displayOutcomeNotification();            
          }
          // Report to parent that saving has completed so it can clear validations and reload waitlist decisions
          this.$emit('saved', 'waitlistStatus');
        });
      });
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }
  
  // Define what is needed for the outcome modal and emit an event
  private displayOutcomeNotification(): void {
    let clusteredOrganDetails: { id: string, name: string }[] = [];
    // Add any organs on medical hold
    this.clusteredJourneys.forEach((journey: RecipientJourney) => {
      const waitlistFactors = journey.stage_attributes?.waitlist?.factors || {};
      // If the other organ is not on medical hold skip it
      if (waitlistFactors.on_hold_medical) {
        clusteredOrganDetails.push({
          id: `${journey._id?.$oid}`,
          name: this.organNameLookup(journey.organ_code),
        });
      }
    });
    // If there are no organDetails don't show the popup
    if (clusteredOrganDetails.length <= 0) return;
    // Combine all organ names 
    const clusteredOrganNames = clusteredOrganDetails.map((item: { id: string, name: string }) => item.name).join(', ');
    let body = `This organ is part of a cluster.  This action removed the current Medical Hold but a Cluster Hold will still remain.  To return this patient to waiting status you must also remove the Medical Hold from the clustered organ(s): ${clusteredOrganNames}. <br /><br />`;
    // Add links to the other organs
    clusteredOrganDetails.forEach((item: { id: string, name: string }) => {
      const organLink = this.$router.resolve({ name: 'edit-organ', params: { organ_id: item.id } });
      body += `Please <a href="${organLink.href}">click here</a> to proceed to the relevant ${item.name} page<br />`;
    });
    // Emit event for top-level waitlist section to show outcome
    const modalContent: ModalContent = {
      style: 'modal-warning',
      body,
    };
    this.$emit('display-outcome-notification', modalContent);
  }

  /**
   * Gets a patch object representing form edit state changes for this form
   * 
   * Delegates the logic of building the patch to a local private method
   * 
   * @returns {any} patch object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.statusUpdate) {
      return {};
    }
    // Fetch data from the Add to Waitlist form
    const form = this.editState?.statusUpdate || {};
    const isOnMedicalHold = this.isOnMedicalHold || false;
    const hasSelectedMedicalHold = this.hasSelectedMedicalHold || false;
    const isRemovingHold = this.isRemovingHold || false;
    const isExtendingHold = this.isExtendingHold || false;
    // Determine which type of patch to send
    if (!isOnMedicalHold && !hasSelectedMedicalHold) {
      return this.extractUpdateMedicalStatusPatch(form);
    } else if (!isOnMedicalHold && hasSelectedMedicalHold) {
      return this.extractPlaceNewMedicalHoldPatch(form);
    } else if (isRemovingHold) {
      return this.extractRemoveMedicalHoldPatch(form);
    } else if (isExtendingHold) {
      return this.extractExtendMedicalHoldPatch(form);
    } else {
      return this.extractEditMedicalHoldPatch(form);
    }
  }

  /**
   * Returns a Waitlist Attributes patch for updating a journey's medical status
   * 
   * @param form state of the Medical Status and Medical Hold update form
   * @returns {RecipientWaitlistAttributes} patch object containing field changes
   */
  private extractUpdateMedicalStatusPatch(form: WaitlistStatusUpdateState): RecipientWaitlistAttributes {
    // Waitlist Factors for Medical Status
    let factors: RecipientWaitlistFactors = {
      medical_status_code: form.medicalStatus !== undefined ? form.medicalStatus : null,
      medical_status_secondary_code: this.showSecondaryMedicalStatus ? form.secondaryMedicalStatus || null : null,
    };
    // Waitlist attributes with all factor changes included
    const result: RecipientWaitlistAttributes = {
      factors,
    };
    return result;
  }

  /**
   * Returns a Waitlist Attributes patch for placing a new medical hold
   * 
   * @param form state of the Medical Status and Medical Hold update form
   * @returns {RecipientWaitlistAttributes} patch object containing field changes
   */
  private extractPlaceNewMedicalHoldPatch(form: WaitlistStatusUpdateState): RecipientWaitlistAttributes {
    // Fetch form data
    const reasonCode = form.reasonForHold;
    const reasonDocument = this.reasonForMedicalHold(reasonCode);
    const isOther = reasonDocument === undefined ? false : reasonDocument.other_selected || false;
    // Waitlist factors for Medical Hold
    const holdFactors: RecipientWaitlistFactors = {
      on_hold_medical: true,
      on_hold_medical_reason_code: reasonCode !== undefined ? reasonCode : null,
      on_hold_medical_other_reason: isOther ? form.ifOtherReason : null,
      on_hold_medical_reason_comment: form.holdComments,
      on_hold_medical_extension_date: null,
    };
    // Waitlist factors for Medical Status
    const statusFactors: RecipientWaitlistFactors = {
      medical_status_code: form.medicalStatus !== undefined ? form.medicalStatus : null,
      medical_status_secondary_code: this.showSecondaryMedicalStatus ? form.secondaryMedicalStatus || null : null,
    };
    // Waitlist attributes with all factor changes included
    const result: RecipientWaitlistAttributes = {
      factors: {
        ...holdFactors,
        ...statusFactors,
      }
    };
    return result;
  }

  /**
   * Returns a Waitlist Attributes patch for removing an existing medical hold
   * 
   * @param form state of the Medical Status and Medical Hold update form
   * @returns {RecipientWaitlistAttributes} patch object containing field changes
   */
  private extractRemoveMedicalHoldPatch(form: WaitlistStatusUpdateState): RecipientWaitlistAttributes {
    // Fetch form data
    const reasonCode = form.reasonForHold;
    const reasonDocument = this.reasonForMedicalHold(reasonCode);
    const isOther = reasonDocument === undefined ? false : reasonDocument.other_selected || false;
    // Waitlist factors for Medical Hold
    const holdFactors: RecipientWaitlistFactors = {
      on_hold_medical: false,
      on_hold_medical_reason_code: null,
      on_hold_medical_other_reason: null,
      on_hold_medical_reason_comment: form.holdComments,
      on_hold_medical_extension_date: null,
      on_hold_medical_expiry_date: null,
      on_hold_initial_waitlisted: false,
    };
    // Waitlist factors for Medical Status
    const statusFactors: RecipientWaitlistFactors = {
      medical_status_code: form.medicalStatus !== undefined ? form.medicalStatus : null,
      medical_status_secondary_code: this.showSecondaryMedicalStatus ? form.secondaryMedicalStatus || null : null,
    };
    // Waitlist attributes with all factor changes included
    const result: RecipientWaitlistAttributes = {
      factors: {
        ...holdFactors,
        ...statusFactors,
      }
    };
    return result;
  }

  /**
   * Returns a Waitlist Attributes patch for extending an existing medical hold
   * 
   * @param form state of the Medical Status and Medical Hold update form
   * @returns {RecipientWaitlistAttributes} patch object containing field changes
   */
  private extractExtendMedicalHoldPatch(form: WaitlistStatusUpdateState): RecipientWaitlistAttributes {
    // Fetch form data
    const reasonCode = form.reasonForHold;
    const reasonDocument = this.reasonForMedicalHold(reasonCode);
    const isOther = reasonDocument === undefined ? false : reasonDocument.other_selected || false;
    /**
     * TODO: TECH_DEBT: currently the UI is sending on_hold_medical_extension_date, but ideally
     * this should also be automatically assigned by the API. This can be reworked once both UI
     * and API are using 'significant_event_factor_hold_medical_details_change_types' lookup
     *
     * For more information, see ticket #10496
     */
    // Waitlist factors for Medical Hold
    const factors: RecipientWaitlistFactors = {
      on_hold_medical_extension_date: this.currentDateTimeApi(),
      on_hold_medical_reason_comment: form.holdComments,
      on_hold_medical_change_type: 'extended',
    };
    // Waitlist attributes with all factor changes included
    const result: RecipientWaitlistAttributes = {
      factors,
    };
    return result;
  }

  /**
   * Returns a Waitlist Attributes patch for updating an existing medical hold
   * 
   * @param form state of the Medical Status and Medical Hold update form
   * @returns {RecipientWaitlistAttributes} patch object containing field changes
   */
  private extractEditMedicalHoldPatch(form: WaitlistStatusUpdateState): RecipientWaitlistAttributes {
    // Fetch form data
    const reasonCode = form.reasonForHold;
    const reasonDocument = this.reasonForMedicalHold(reasonCode);
    const isOther = reasonDocument === undefined ? false : reasonDocument.other_selected || false;
    // Waitlist factors for Medical Hold
    const factors: RecipientWaitlistFactors = {
      on_hold_medical_reason_code: reasonCode !== undefined ? reasonCode : null ,
      on_hold_medical_other_reason: isOther ? form.ifOtherReason : null,
      on_hold_medical_reason_comment: form.holdComments,
      on_hold_medical_change_type: 'updated',
    };
    // Waitlist attributes with all factor changes included
    const result: RecipientWaitlistAttributes = {
      factors,
    };
    return result;
  }

  /**
   * Clears all save notifications shown by the form.
   * 
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.saveWaitlistStatus as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }

  /**
   * Returns full lookup document for a Reason for Medical Hold
   * 
   * @param reasonCode code number
   * @returns {ReasonForMedicalHold|undefined} lookup document if it exists, undefined otherwise
   */
  private reasonForMedicalHold(reasonCode?: number): ReasonForMedicalHold|undefined {
    if (!this.reasonForMedicalHoldOptions || !reasonCode) {
      return undefined;
    }
    return this.reasonForMedicalHoldOptions.find((reason: ReasonForMedicalHold) => {
      return reason.code == reasonCode;
    });
  }

  // Get whether or not a medical hold has been entered into the waitlist status update form
  get hasSelectedMedicalHold(): boolean {
    if (!this.editState || !this.editState.statusUpdate) {
      return false;
    }
    const form: WaitlistStatusUpdateState = this.editState?.statusUpdate || {};
    return form.medicalStatus == OrganWaitlistMedicalStatusValue.OnHold;
  }

  // Get translations key for heading based on whether or not journey currently has medical hold
  get medicalFormHeading(): string {
    const isMedicalHoldForm = this.isOnMedicalHold;
    const formHeading = isMedicalHoldForm ? 'waitlist_medical_hold_heading' : 'waitlist_medical_status_heading';
    return this.$t(formHeading).toString();
  }

  // Get translations key for save button based whether or not a medical hold status was selected
  get saveButtonText(): string {
    const isSavingMedicalHold = this.hasSelectedMedicalHold;
    const saveButton = isSavingMedicalHold ? 'waitlist_save_medical_hold' : 'waitlist_save_medical_status';
    return this.$t(saveButton).toString();
  }

  /**
   * Gets a boolean value representing whether or not to show Secondary Medical Status in the Medical Status and Medical Hold form
   * 
   * @returns {boolean} true if the form area should be shown, false otherwise
   */
  get showSecondaryMedicalStatus(): boolean {
    if (!this.editState || !this.editState.statusUpdate) {
      return false;
    }
    const form: WaitlistStatusUpdateState = this.editState?.statusUpdate || {};
    return form.medicalStatus == HeartMedicalStatusValue.Sensitized;
  }

  /**
   * Changing a medical status should clear the secondary medical status selection
   */
  private clearSecondaryMedicalStatus(): void {
    if (!this.editState || !this.editState.statusUpdate) {
      return;
    }
    Vue.set(this.editState.statusUpdate, 'secondaryMedicalStatus', undefined);
  }

  /**
   * Gets an array of options for Medical Status
   * 
   * @returns {OrganWaitlistMedicalStatus[]} organ-specific options for medical status
   */
  get medicalStatusOptions(): OrganWaitlistMedicalStatus[] {
    // Disable 'hold' option if removing medical hold
    const excludeHold = this.isRemovingHold || false;
    // Delegate common medical status handling to shared utils file
    const options = this.medicalStatusLookup(excludeHold, this.organLookup, this.organCode);
    const journeyStage = this.journey?.stage;

    // For Urgent Listing filter to only include urgent listing options
    const isUrgent = this.journey?.urgent || false;
    // system should allow user to change medical status to any non-urgent selection after listing 
    if (!isUrgent || journeyStage === JourneyStage.Waitlist) return options;

    const filtered = options.filter((lookup: OrganWaitlistMedicalStatus) => {
      return lookup.urgent_listing_option;
    });
    return filtered;
  }

  /**
   * Gets an array of options for Secondary Medical Status
   * 
   * @returns {OrganWaitlistMedicalStatus[]} organ-specific options for secondary medical status
   */
  get secondaryMedicalStatusOptions(): OrganWaitlistMedicalStatus[] {
    return this.secondaryMedicalStatusLookup(this.organLookup, this.organCode);
  }


  get isMedicalStatusDisabled(): boolean {
    return this.isOnMedicalHold && !this.isRemovingHold;
  }

  // True if a Medical Hold has been selected and the Extend option has been checked
  get isExtendingHold(): boolean {
    if (!this.editState || !this.editState.statusUpdate) {
      return false;
    }
    const form: WaitlistStatusUpdateState = this.editState?.statusUpdate || {};
    return form.extendHold || false;
  }

  private onExtendHold(value?: boolean|null): void {
    // Check if user ticked extend hold
    const isChecked = value || false;
    // Stop if field was unticked
    if (!isChecked) {
      return;
    }
    // Reset related options
    if (this.editState && this.editState.statusUpdate) {
      Vue.set(this.editState.statusUpdate, 'removeHold', null);
    }
  }

  private onRemoveHold(value?: boolean|null): void {
    // Check if user ticked remove hold, so we can highlight / focus on medical status field
    const isChecked = value || false;
    let medicalStatus = null;
    if (isChecked) {
      // Find medical status field by id instead of ref, because it is is nested within the component
      const medicalStatusField = document.getElementById('waitlist-status');
        // highlight medical status field
      if (medicalStatusField) {
        medicalStatusField.focus();
      }
    } else {
      // If unticking Remove Hold, then reset Medical Status to known '0' value
      medicalStatus = OrganWaitlistMedicalStatusValue.OnHold;
    }
    // Reset medical status values and related options
    if (this.editState && this.editState.statusUpdate) {
      const currentState: WaitlistStatusUpdateState = this.editState?.statusUpdate || {};
      const newState = Object.assign(currentState, {
        medicalStatus,
        secondaryMedicalStatus: null,
        extendHold: null,
      });
      this.$store.commit('pageState/set', {
        pageKey: 'waitlistSection',
        componentKey: 'statusUpdate',
        value: newState,
      });
    }
  }

  // True if a Medical Hold has been selected and the Remove option has been checked
  get isRemovingHold(): boolean {
    if (!this.editState || !this.editState.statusUpdate) {
      return false;
    }
    const form: WaitlistStatusUpdateState = this.editState?.statusUpdate || {};
    return form.removeHold || false;
  }

  // True if a Medical Hold has been selected and either of the Extend or Remove options have been checked
  get isRemovingOrExtendingHold(): boolean {
    return this.isExtendingHold || this.isRemovingHold;
  }

  // Determine when Extend Hold is disabled
  get canExtendHold(): boolean {
    return !this.isRemovingHold && !this.isUpdatingReason;
  }

  // Determine when Remove Hold is disabled
  get canRemoveHold(): boolean {
    return !this.isExtendingHold && !this.isUpdatingReason;
  }

  // Check if user has changed Reason for Hold or If Other Reason
  // All values are cast to strings for the purposes of comparison
  get isUpdatingReason(): boolean {
    // Get values already saved on the journey
    const factors: RecipientWaitlistFactors = this.journey.stage_attributes?.waitlist?.factors || {};
    const savedReasonForHold = factors.on_hold_medical_reason_code?.toString();
    const savedIfOtherReason = factors.on_hold_medical_other_reason?.toString();
    // Get current values from form edit state
    const form: WaitlistStatusUpdateState = this.editState?.statusUpdate || {};
    const formReasonForHold = form.reasonForHold?.toString();
    const formIfOtherReason = form.ifOtherReason?.toString();
    // Check if relevant values differ
    return savedReasonForHold !== formReasonForHold || savedIfOtherReason !== formIfOtherReason;
  }

  // Returns a string for confirmation alert dialog if needed, otherwise returns undefined to prevent alert
  get confirmationText(): string|undefined {
    // Fetch form state
    const form: WaitlistStatusUpdateState = this.editState?.statusUpdate || {};
    // Check if managing medical hold
    const isPlacingHold = this.hasSelectedMedicalHold && !this.isOnMedicalHold;
    const isRemovingHold = this.isRemovingHold || false;
    let result: string|undefined = undefined;
    if (isRemovingHold) {
      // Describe medical status and secondary medical status using a combination of codes and values
      const medicalStatus = this.describeMedicalStatus(this.organLookup, this.organCode, form.medicalStatus) || 'Unknown';
      const secondaryMedicalStatus = this.describeMedicalStatus(this.organLookup, this.organCode, form.secondaryMedicalStatus);
      // Build Remove Medical Hold confirmation with specific indentation and line breaks
      result = `This action will remove the current Medical Hold and update the patient’s medical status to:

Medical Status: ${medicalStatus}${secondaryMedicalStatus !== undefined ? `

Secondary Medical Status: ${secondaryMedicalStatus}` : ''}

Do you wish to proceed?`;
    } else if (isPlacingHold) {
      // Build Place Medical Hold confirmation with specific indentation and line breaks
      result = `This action will place this patient on Medical Hold and change their medical status to:

0 - Temporarily on Hold.

${this.isHeartJourneyOrHeartCluster ? `Because this patient is waitlisted for a heart they will automatically be placed on suspension and not be eligible for allocation.

` : ``}Do you wish to proceed?`;
    }
    return result;
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): {[key: string]: string} {
    return {
      'waitlistStatus.factors.medical_status_code'            : 'waitlist-status',
      'waitlistStatus.factors.medical_status_secondary_code'  : 'waitlist-secondary-status',
      'waitlistStatus.factors.on_hold_medical_reason_code'    : 'waitlist-hold-reason',
      'waitlistStatus.factors.on_hold_medical_other_reason'   : 'waitlist-other-reason-other',
      'waitlistStatus.factors.on_hold_medical_reason_comment' : 'waitlist-comments',
    };
  }
}
