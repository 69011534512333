import { render, staticRenderFns } from "./OfferHistoryModal.vue?vue&type=template&id=5e90f966&"
import script from "./OfferHistoryModal.vue?vue&type=script&lang=ts&"
export * from "./OfferHistoryModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/_locales/common.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingAllocations%2Foffers%2FOfferHistoryModal.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/components/allocations/_locales/common.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingAllocations%2Foffers%2FOfferHistoryModal.vue"
if (typeof block1 === 'function') block1(component)
import block2 from "@/components/allocations/offers/_locales/common.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingAllocations%2Foffers%2FOfferHistoryModal.vue"
if (typeof block2 === 'function') block2(component)
import block3 from "@/components/allocations/offers/_locales/OfferHistoryModal.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingAllocations%2Foffers%2FOfferHistoryModal.vue"
if (typeof block3 === 'function') block3(component)

export default component.exports