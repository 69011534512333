



































































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from "vuex-class";
import { Component, Prop, Vue } from "vue-property-decorator";
import SubSection from "@/components/shared/SubSection.vue";
import { Recipient } from "@/store/recipients/types";
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import WarningBanner from "@/components/shared/WarningBanner.vue";
import TextInput from "@/components/shared/TextInput.vue";
import DateInput from "@/components/shared/DateInput.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import VcaTransplantDetails, { VcaTransplantDetailsPageState } from '@/components/organs/vca/VcaTransplantDetails.vue';
import HeartTransplantDetails, { HeartTransplantDetailsPageState } from "@/components/organs/heart/HeartTransplantDetails.vue";
import LungTransplantDetails, { LungTransplantDetailsPageState } from '@/components/organs/lung/LungTransplantDetails.vue';
import LiverTransplantDetails, { LiverTransplantDetailsPageState } from '@/components/organs/liver/LiverTransplantDetails.vue';
import { RecipientJourney, RecipientTransplantAttributes, TransplantFactors, JourneyStage } from "@/store/recipientJourney/types";
import KidneyTransplantDetails, { KidneyTransplantDetailsPageState } from '@/components/organs/kidney/KidneyTransplantDetails.vue';
import PancreasWholeTransplantDetails, { PancreasTransplantDetailsPageState } from '@/components/organs/pancreas/PancreasWholeTransplantDetails.vue';
import PancreasIsletsTransplantDetails, { PancreasIsletsTransplantDetailsPageState } from '@/components/organs/pancreas/PancreasIsletsTransplantDetails.vue';
import SmallBowelTransplantDetails, { SmallBowelTransplantDetailsPageState } from '@/components/organs/bowel/SmallBowelTransplantDetails.vue';
import { organTransplantDetails } from '@/types';
import { ObjectId } from "@/store/types";
import { TransplantDetailsPageState, TransplantSectionPageState } from '@/mixins/transplant-mixin';

@Component({
  components: {
    SubSection,
    TextInput,
    DateInput,
    SelectInput,
    CheckboxInput,
    WarningBanner,
    VcaTransplantDetails,
    HeartTransplantDetails,
    LungTransplantDetails,
    LiverTransplantDetails,
    KidneyTransplantDetails,
    PancreasWholeTransplantDetails,
    SmallBowelTransplantDetails,
    PancreasIsletsTransplantDetails,
  }
})
export default class TransplantDetails extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.transplantSection) editState!: TransplantSectionPageState;

  // Getter
  @Getter('isClustered', { namespace: 'journeyState', }) isClustered!: boolean;
  @Getter('wasTransplanted', { namespace: 'journeyState' }) wasTransplanted!: boolean;
  @Getter("organName", { namespace: "lookups" }) organName!: (organCode?: number) => string;
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('clusteredJourneys', { namespace: 'journeyState' }) clusteredJourneys!: RecipientJourney[];
  @Getter("allocatedDonorDetails", { namespace: "journeyState" }) allocatedDonor!: { code: string; value: string }[];
  @Getter("noAllocatedDonorIdOptionsText", { namespace: "journeyState" }) noDonorIdOptionsText!: string;
  @Getter("isTransplantDetailsApplicable", { namespace: "journeyState" }) isTransplantDetailsApplicable!: boolean;
  @Getter("isOopRecipient", { namespace: 'recipients' }) isOopRecipient!: boolean;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

   /**
   * Gets the current journey as lower case
   *
   * Using the organ code, return the component
   *
   * @returns {String} organ as lower case
   */
  get transplantComponent(): string {
    const result = this.organCode === null ? '' : organTransplantDetails[String(this.organCode)];
    return result;
  }

  /**
   * Get organ code from the params
   *
   * @returns {number} organ_code
   */
  get organCode(): number {
    if (!this.newJourney) {
      return Number(this.journey.organ_code);
    }
    return Number(this.$route.params.organ_code) != NaN ? Number(this.$route.params.organ_code) : 0;
  }

  get organ(): string {
    return this.organName(this.organCode).replace(/\(|\)/g,'').replace(/\s/g,'').toLowerCase();
  }

  get isTransplanted(): boolean {
    if(this.journey && this.journey.stage_attributes && this.journey.stage_attributes.waitlist && this.journey.stage_attributes.waitlist.factors) {
      return this.journey.stage === JourneyStage.PostTransplant && !this.journey.stage_attributes.waitlist.factors.transplant_in_progress;
    }
    return false;
  }

  get showCopyToClusterCheckbox(): boolean {
    return this.isClustered || false;
  }

  // Check if any clustered journeys have partial cluster transplant hold
  get partialClusterTransplantHoldJourneys(): RecipientJourney[] {
    const partialClusterTransplantHoldJourneys = this.clusteredJourneys.filter((journey: RecipientJourney) => {
      return journey.stage === JourneyStage.Waitlist && journey?.stage_attributes?.waitlist?.factors?.on_hold_partial_cluster_transplant;
    });
    return partialClusterTransplantHoldJourneys;
  }

  // Map from journey to router link information
  get partialTransplantLinks(): { route: any, text: string }[] {
    const links = this.partialClusterTransplantHoldJourneys.map((journey: RecipientJourney) => {
      return {
        route: {
          name: 'edit-organ',
          hash: '#transplant-details-section',
          params: {
            organ_id: `${journey._id?.$oid}`,
          },
        },
        text: this.organNameLookup(journey.organ_code),
      };
    });
    return links;
  }

  /**
   * Whether or not we need to show the persistent warning banner for partially transplanted clsuters
   *
   * for details see https://shore.tpondemand.com/entity/7541-722-v42-transplant-details
   *
   * @returns {boolean} true only if the warning banner should be shown
   */
  get showPartialClusterTransplantWarningBanner(): boolean {
    if (!this.isClustered) return false;

    if (!this.wasTransplanted) return false;

    return this.partialClusterTransplantHoldJourneys.length > 0;
  }

  // Vue lifecycle hooks
  public mounted(): void {
    // There are no validations on a new journey
    const transplantDispatchMethod = this.isOopRecipient ? 'journeyState/getTransplantOop' : 'journeyState/getTransplant';
    if (!this.newJourney) {
      this.$store.dispatch(transplantDispatchMethod, { journeyId: this.journeyId, recipientId: this.recipientId });
      const recipients_route = this.isOopRecipient ? 'oop_recipients' : 'recipients';
      this.$store.dispatch('validations/loadEdit', { view: `${recipients_route}/${this.recipientId}/journeys`, action: 'transplant/edit_validations', clientId: this.journeyId });
    }
  }
  
  /**
   * Generates Transplant Details form state based on the selected journey
   *
   * @param transplantDetails RecipientTransplantAttributes
   * @returns {TransplantDetailsForm} Transplant Details form state
   */
  public buildTransplantDetailsForm(transplantDetails?: RecipientTransplantAttributes, recipientStatus?: string): TransplantDetailsPageState {
    const transplantDate = transplantDetails?.factors?.transplant_date;
    const result: TransplantDetailsPageState = {
      donorId: transplantDetails?.donor_id?.$oid,
      donorClientId: transplantDetails?.donor_client_id,
      transplantDate: transplantDate ? this.parseDateUi(transplantDate) : undefined,
      recipientStatus:  recipientStatus,
      organTransplantDetails: {}
    };
    const organName = this.organName(this.organCode).replace(/\(|\)/g,'').replace(/\s/g,'').toLowerCase();
    let transplantDetailsForm = this.getOrganRef(organName);
    
    if (!!transplantDetailsForm) {
      result.organTransplantDetails = transplantDetailsForm.buildTransplantDetailsState(transplantDetails);
    }
    // Return form state
    return result;
  }

  public getOrganRef(organName: string): any {
    let transplantDetailsForm;
    switch(organName) {
      case "heart":
        transplantDetailsForm = this.$refs.heartTransplantDetails as HeartTransplantDetails;     
        break;
      case "lung":
        transplantDetailsForm = this.$refs.lungTransplantDetails as LungTransplantDetails;
        break;
      case "liver":
        transplantDetailsForm = this.$refs.liverTransplantDetails as LiverTransplantDetails;
        break;
      case "kidney":
        transplantDetailsForm = this.$refs.kidneyTransplantDetails as KidneyTransplantDetails;
        break;
      case "pancreaswhole":
        transplantDetailsForm = this.$refs.pancreaswholeTransplantDetails as PancreasWholeTransplantDetails;
        break;
      case "pancreasforislets":
        transplantDetailsForm = this.$refs.pancreasforisletsTransplantDetails as PancreasIsletsTransplantDetails;
        break;
      case "smallbowel":
        transplantDetailsForm = this.$refs.smallbowelTransplantDetails as SmallBowelTransplantDetails;
        break;
      case "vca":
        transplantDetailsForm = this.$refs.vcaTransplantDetails as VcaTransplantDetails;
        break;
      default:
        break;
    }
    return transplantDetailsForm;
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): {[key: string]: string} {
    // Local mapping for inputs defined in this component
    const result = {
      'factors.transplant_date' : 'transplantDate',
      'donor_id'                :  'donor-id',
      'copy_to_cluster'         :  'typing-entries-typing_incomplete'
    };
    const organName = this.organName(this.organCode).replace(/\(|\)/g,'').replace(/\s/g,'').toLowerCase();

    // Nested mapping for inputs defined in sub-section children components
    const transplantDetails = this.getOrganRef(organName);
    if (transplantDetails) {
      Object.assign(result, { ...transplantDetails.idLookup() });
    }
    
    return result;
  }

  // Tell the top-level form validation observer to reset all errors
  private clear() {
    this.$emit('clear');
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit("handleErrors", errors);
  }

  // Reload recipient and journey data after saving
  private handleSaved(event: any) {
    this.$emit('saved', 'transplantDetails');
  }

  private loaded(event: any): void {
    this.$emit('loaded', event);
  }
}
