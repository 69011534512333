import axios from 'axios';
import { ActionTree } from "vuex";
import { SaveResult } from '@/types';
import { RootState } from "@/store/types";
import { APIRoute, EP } from '@/api-endpoints';
import { handleAllocationErrors } from '@/utils';
import { HistoryState, HistoryVxm, LIVING_DONOR_KEY, DECEASED_DONOR_KEY } from "@/store/history/types";

export const actions: ActionTree<HistoryState, RootState> = {
  loadRecipientVxm({ commit }, recipientId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('clearSelectedVxm');
      commit('clearVxm');
      const url = APIRoute(EP.recipients.history_vxm.index, [[':recipient_id', recipientId]]);
      axios.get(url).then((response: any) => {
        const historyVxm: HistoryVxm[] = response.data.history_vxm;
        commit('setVxm', historyVxm);
        resolve();
      }).catch((error: any) => {
        console.warn(error);
        reject();
      });
    });
  },
  createRecipientVxm({ commit }, { recipientId, donorId, donorType}): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const url = APIRoute(EP.recipients.history_vxm.create, [[':recipient_id', recipientId]]);
      let donorPayload = {};

      if(donorType === DECEASED_DONOR_KEY) {
        donorPayload = { donor_deceased_donor_id: donorId };
      }
      else if (donorType === LIVING_DONOR_KEY ){
        donorPayload = { donor_living_donor_id: donorId };
      }

      const payload = {
        history_vxm: donorPayload
      };

      axios.post(url, payload).then((response: any) => {
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          resolve({ success: true, responseData: response.data });
        } else {
          const errorResult = handleAllocationErrors(response.data.errors);
          reject(errorResult);
        }
      }).catch((error: any) => {
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
  selectLatestRecipientVxm({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve) => {
      const target = getters.latestHistoryVxm;
      // Select the target event
      commit('selectVxm', target);
      // Resolve promise successfully
      resolve();
    });
  },
};
