import { render, staticRenderFns } from "./SearchBar.vue?vue&type=template&id=d69f9556&"
import script from "./SearchBar.vue?vue&type=script&lang=ts&"
export * from "./SearchBar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./_locales/SearchBar.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fsearch%2FSearchBar.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports