































































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { DashboardSettingState, Lab, LabResult, WidgetSettings } from '@/store/dashboard/types';
import { TableConfig } from '@/types';
import SubSection from '@/components/shared/SubSection.vue';
import { urlParams } from "@/utils";
import TextInput from '@/components/shared/TextInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';

import { SaveResult } from '@/types';
import { Format } from "@/store/utilities/types";

@Component({
  components: {
    BaseWidget,
    SubSection,
    TextInput,
    CheckboxInput,
    SelectInput
  }
})
export default class WidgetLabResults extends mixins(DateUtilsMixin) {
  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashLabResults) private editState!: WidgetSettings;

  @Getter('getLabResults', { namespace: 'dashboard' }) getLabResults!: any;
  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('getPanelStyles', { namespace: 'dashboard' }) getPanelStyles!: any[];
  @Getter('getDefaultPaginationOptions', { namespace: 'utilities' }) getDefaultPaginationOptions!: any;

  public currentPage = 1;
  public perPage = 5;
  public errorMessage = null;

  mounted(): void {
    Promise.all([
      this.loadData()
    ]).finally(() => {
      this.initializeWidget();
    });
  }

  // Setup temporary edit state for unsaved widget settings form fields
  initializeWidget(): void {
    this.errorMessage = null; // clear error message
    const preferences = this.getPreferences;

    this.$store.commit('pageState/set', {
      pageKey: 'dashLabResults',
      value: this.buildLabResultsEditState(preferences),
    });
  }

  public selectDonor(event: any) {
    if (event.row.donor_client_id) {
      const clientId: string = event.row.donor_client_id.toString();
      this.$router.push({
        name: 'edit-deceased-donor',
        params: {
          id: clientId,
        },
        hash: event.row.route_hash
      });
    } else {
      console.warn('Missing donor clientId');
      this.$router.push({name: 'list-deceased-donors'});
    }
  }

  public loadData(search='', sort='') {
    const search_params = [search, sort].filter((p) => { return p && p.length >= 0; });

    this.$store.dispatch(
      'dashboard/loadLabResults', {
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search_params: `${search_params.length > 0 ? '&' : ''}${search_params.join('&')}`
    });
  }

  // Commit edit state field model values to vue-x store
  saveSettings(): void {
    const newState = this.extractDashboardSettings(this.preferences, this.editState);
    this.$store.dispatch('users/savePreferences', { preferences: newState }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });
  }

  public dismiss(): void {
    $("#id-lab-results").collapse('toggle');
  }

  // Build edit state based on overall dashboard settings
  buildLabResultsEditState(preferences: DashboardSettingState): WidgetSettings {
    return Object.assign({}, preferences.labResults);
  }

  // Build new overall dashboard settings with new settings for this specific widget
  extractDashboardSettings(currentSettings: DashboardSettingState, editState: WidgetSettings): DashboardSettingState {
    const newSettings = Object.assign({ labResults: {} }, currentSettings);
    newSettings.labResults = {
      style: editState.style,
      visible: editState.visible
    };
    return newSettings;
  }

  // Reinitialize if overall dashboard settings change
  @Watch('preferences')
  onDashboardEditStateChanged(): void {
    this.initializeWidget();
  }

  get labResultsData(): LabResult[] {
    if(!this.getLabResults) {
      return [];
    }

    return this.getLabResults.map((lab: Lab) => {
      return {
        deceased_donor_id: lab.deceased_donor_id || '',
        donor_client_id: lab.donor_client_id || '',
        recipient_id: lab.recipient?.client_id || '',
        result_type: lab._type ? this.$t(lab._type) : '',
        route_hash: this.routeHash(lab?._type),
        date_time_received: this.parseDisplayDateTimeUiFromDateTime(lab.updated_at || '')
      };
    });
  }

  private routeHash(result_type: string|undefined): string {
    if(result_type === 'virology_donor') {
      return '#virology';
    }

    else if(result_type === 'hla_typing_donor'){
      return '#hla-typing';
    }

    return '';
  }

  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: this.labResultsData,
      columns: [
        { label: this.$t('donor_id'), field: 'deceased_donor_id', sortable: true },
        { label: this.$t('result_type'), field: 'result_type', sortable: true },
        { label: this.$t('date_time_received'), field: 'date_time_received', sortable: true, type: 'date', dateInputFormat: Format.DATE_TIME_FORMAT, dateOutputFormat: Format.DATE_TIME_FORMAT}
      ],
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'date_time_received', type: 'asc'}
      },
      empty: this.$t('empty_widget_lab_results').toString(),
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: 5,
        mode: 'records',
        perPageDropdown: [5, 10, 25, 100],
        dropdownAllowAll: false,
        nextLabel: this.$t('older').toString(),
        prevLabel: this.$t('newer').toString(),
        rowsPerPageLabel: this.$t('results_per_page').toString(),
      }
    };
  }
}
