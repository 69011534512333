import axios from 'axios';
import Vue from 'vue';
import { ActionTree } from 'vuex';
import { SaveResult } from '@/types';
import { APIRoute, APIBaseRoute, EP } from '@/api-endpoints';
import { RootState } from '@/store/types';
import { User, UsersState } from '@/store/users/types';

export const actions: ActionTree<UsersState, RootState> = {
  // load user info
  loadUser({ commit }): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      // Get validations based on view and action
      const url = APIRoute(EP.user.show);
      axios.get(url).then((response: any) => {
        const responseData: any = response.data;
        // Pass value to mutation to place in store
        const user = responseData && responseData.user ? responseData.user : null;
        commit('setUser', user);
        resolve();
      }).catch((error: any) => {
        // check for response error code
        const response = error && error.response ? error.response : null;
        if (!response) reject(error);
        if (response.status == 401) {
          localStorage.removeItem('access_token');
          window.location.href = EP.user.login;
          reject(error);
        } else if (response.status == 403) {
          reject(error);
        } else {
          reject(error);
        }
      });
    });
  },
  savePreferences({ commit, getters }, { preferences }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      if (preferences) {
        const payload = { data: JSON.stringify(preferences) };
        const method: any = axios.put;
        const ep: string = APIRoute(EP.user.preferences);
        method(ep, payload).then((response: any) => {
          const responseData: any = response.data;
          // Pass preferences to mutation
          const newPreferences = responseData && responseData.preferences ? responseData.preferences : undefined;
          commit('setPreferences', newPreferences);
          resolve({ success: true, responseData: response.data });
        }).catch((error: any) => {
          // Handle generic errors
          reject({ success: false, message: error });
        });
      } else {
        reject();
      }
    });
  },
  // load all possible user roles
  loadRoles({ commit, getters, rootState, rootGetters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // If tglnAdmin
      if (rootGetters["users/isTglnAdmin"]) {
        // Get validations based on view and action
        const url = APIRoute(EP.users.roles);
        axios.get(url).then((response: any) => {
          const responseData: any = response.data;
          // Pass value to mutation to place in store
          const roles = responseData && responseData.roles ? responseData.roles : null;
          commit('setRoles', roles);
          resolve();
        }).catch((error: any) => {
          console.log(error);
          reject(error);
        });
      } else {
        commit('setRoles', []);
        resolve();
      }
    });
  },
  // token methods
  getToken({ commit }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const token = localStorage.getItem('access_token') || null;
      commit('setToken', token);
      resolve();
    });
  },
  setToken({ commit }, { token }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // TODO: clean this up as it's both in the action and mutation
      localStorage.setItem('access_token', token);
      commit('setToken', token);
      resolve();
    });
  },
  removeToken(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      localStorage.removeItem('access_token');
      resolve();
    });
  },

  // store route for permissions
  setRoute({ commit }, route): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('setRoute', route);
      resolve();
    });
  },

  // store redirect url
  setAfterLoginUrl({ commit }, { url }): Promise<void> {
    return new Promise<void>((resolve) => {
      localStorage.setItem('after_login_url', url);
      resolve();
    });
  },

  redirectAfterLogin({ commit }): Promise<void> {
    return new Promise<void>((resolve) => {
      let returnLocation = '';
      const afterLoginUrl = localStorage.getItem('after_login_url') || '';
      if (afterLoginUrl) {
        // redirect to original page on login
        returnLocation = afterLoginUrl;
        localStorage.removeItem('after_login_url');
      } else {
        // redirect with page refresh to force api calls to reload
        returnLocation = "/";
      }
      // do redirect
      window.location.href = returnLocation;
      resolve();
    });
  }
};
