import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { APIRoute, EP } from "@/api-endpoints";
import { SurgicalPersonsState, SurgicalPerson } from "@/store/surgicalPersons/types";

export const actions: ActionTree<SurgicalPersonsState, RootState> = {
  loadAllSurgicalPeople({ commit }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.surgicalPeople.index);
      axios.get(url).then((response: any) => {
        const surgicalPeople: SurgicalPerson[] = response.data.surgical_people;
        commit('setSurgicalPeople', surgicalPeople);
        resolve();
      }).catch((error: any) => {
        reject();
      });
    });
  }
};
