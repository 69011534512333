import axios from "axios";
import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { APIRoute, EP } from "@/api-endpoints";
import { ResponsiblePhysician, ResponsiblePhysiciansState } from '@/store/responsiblePhysicians/types';

export const actions: ActionTree<ResponsiblePhysiciansState, RootState> = {
  loadAllResponsiblePhysicians({ commit }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.responsiblePhysicians.index);
      axios.get(url).then((response: any) => {
        const responsiblePhysicians: ResponsiblePhysician[] = response.data.responsible_physicians;
        commit('setResponsiblePhysicians', responsiblePhysicians);
        resolve();
      }).catch((error: any) => {
        reject();
      });
    });
  },
  loadFilteredResponsiblePhysicians({ commit }, { hospitalId, organCode} ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.responsiblePhysicians.filtered_index, [[':hospital', hospitalId], [':organ', organCode]]);
      axios.get(url).then((response: any) => {
        const filteredResponsiblePhysicians: ResponsiblePhysician[] = response.data.responsible_physicians;
        commit('setFilteredResponsiblePhysicians', filteredResponsiblePhysicians);
        resolve();
      }).catch((error: any) => {
        reject();
      });
    });
  },
};
