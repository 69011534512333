import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/hospitals/actions';
import { mutations } from '@/store/hospitals/mutations';
import { HospitalsState } from '@/store/hospitals/types';
import { getters } from '@/store/hospitals/getters';

const state: HospitalsState = {
  transplantProgram: [],
  oop_transplant: [],
  ontario_transplant: [],
  loadingTracker: {}
};

const namespaced = true;

export const hospitals: Module<HospitalsState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state
};
