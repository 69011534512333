























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SideNavToolbar extends Vue {
  @Prop() returnLink!: string;

  /**
   * Emits an event when the eye is clicked
   *
   * Emits a toggle-sidebar event to the parent component and changes the toggleEye boolean
   *
   * @emits toggle
   */
  public toggleSideNav(): void {
    this.$emit('toggle');
  }
}
