





















import { Getter } from "vuex-class";
import { LivingDonor } from "@/store/livingDonors/types";
import { Component, Vue } from "vue-property-decorator";
import PageTop from '@/components/shared/PageTop.vue';
import LoadingSideNav from '@/components/shared/side-nav/LoadingSideNav.vue';
import LoadingDonorPage from '@/components/shared/LoadingDonorPage.vue';

@Component({
  components: {
    PageTop,
    LoadingSideNav, 
    LoadingDonorPage
  },
})
export default class BaseLivingDonor extends Vue {
  @Getter("show", { namespace: "livingDonors" }) private livingDonor!: LivingDonor;
}
