






















































import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Recipient } from '@/store/recipients/types';
import { organCodeLookup } from '@/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SelectedRecipientHlaLinks from '@/components/recipients/side-nav/SelectedRecipientHlaLinks.vue';
import SelectedRecipientJourneyLinksOop from '@/components/recipientsOop/side-nav/SelectedRecipientJourneyLinksOop.vue';
import SelectedRecipientProfileLinksOop from '@/components/recipientsOop/side-nav/SelectedRecipientProfileLinksOop.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedRecipientJourneyLinksOop,
    SelectedRecipientProfileLinksOop,
  }
})
export default class SideNavJourneyOop extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) private journey!: RecipientJourney;
  
  @Getter('selectedRecipientJourneysList', { namespace: 'recipients' }) private selectedRecipientJourneysList!: { id: string; name: string, organCode: number }[];

  @Prop({ default: false }) active!: boolean;
  @Prop({ default: null }) newOrgan!: string|null;
  @Prop({ default: null }) organCode!: string;
  @Prop({ default: false }) newRecipient!: boolean;

  public show = true;
  public sideNavClosed = false;

  public toggleNav() {
    this.show = !this.show;
  }

  // TODO: Remove when we have Small Bowel specific details section
  public ORGAN_CODES_TO_EXCLUDE = ['7'];

  /**
   * Return if we should show the organ specfic details link on the side nav
   * 
   * @returns {boolean} true if we should show the nav item
   */
  get showOrganSpecificDetails(): boolean {
    // Check if the journey organ code should be excluded
    const isExcluded = this.ORGAN_CODES_TO_EXCLUDE.includes(this.organCode.toString());
    // Hide field if organ is excluded
    return !isExcluded;
  }

  /**
   * Return organ name from the organ code using organCodeLookup
   * 
   * @returns {string} organ name 
   */
  get organName(): string {
    return this.organCode ? organCodeLookup[this.organCode] : '';
  }

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }

  
}
