
















































































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State }  from 'vuex-class';
import { RootState, ObjectId } from '@/store/types';
import { Recipient } from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import CardSection from '@/components/shared/CardSection.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import { LiverMeldLab } from '@/store/organSpecificDetails/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { LiverDetails } from '@/store/organSpecificDetails/types';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import DiseasesSection from '@/components/organs/liver/DiseasesSection.vue';
import TherapiesSection from '@/components/organs/liver/TherapiesSection.vue';
import { LiverSpecificForm } from '@/components/organs/liver/LiverSpecificDetails.vue';

export interface SodiumMeldForm {
  dateOfNaMeldData?: string;
  naMeldExpiryDate?: string;
  totalBilirubin?: number;
  inr?: number;
  serumSodium?: number;
  serumCreatinine?: number;
  onDialysis?: boolean;
  naMeldScore?: number;
}

interface LiverMeldRow {
  _id?: ObjectId;
  dateOfNaMeldData?: string;
  naMeldExpiryDate?: string;
  totalBilirubin?: string;
  inr?: string;
  serumSodium?: string;
  serumCreatinine?: string;
  onDialysis?: string;
  naMeldScore?: string;
}

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    NumberInput,
    TextAreaInput,
    CheckboxInput,
  }
})

export default class MeldSection extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.pageState.currentPage.liverDetails) editState!: LiverSpecificForm;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.organSpecificDetails.liverMelds) private liverMelds!: LiverMeldLab[];
  @State(state => state.organSpecificDetails.selectedLiverMeld) private selectedMeld!: LiverMeldLab;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  /**
   * Return true if we can edit the selected measurement
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }

    if (!!this.selectedMeld && !!this.liverMelds) {
      if (this.selectedMeld._id) {
        return this.isLastEntry(this.selectedMeld._id, this.liverMelds);
      }
    }
    return true;
  }

  /**
   * Emits a loaded event when the component has finished mounting
   *
   * @emits loaded
   */
  private mounted(): void {
    this.initializeSodiumMeldForm();
    this.$emit('loaded', 'meldSection');
  }

  /**
   * Gets table data for recipient liver NaMELD labs
   *
   * @returns {LiverMeldRow[]} NaMELD table data
   */
  get meldRows(): LiverMeldRow[] {
    if (!this.liverMelds) {
      return [];
    }  
    return this.liverMelds.map((lab: LiverMeldLab) => {
      return {
        _id: lab._id,
        dateOfNaMeldData: this.parseDisplayDateUi(lab.test_date) || '-',
        naMeldExpiryDate: this.parseDisplayDateUi(lab.expiry_date) || '-',
        totalBilirubin: lab.serum_bilirubin == undefined ? '-' : lab.serum_bilirubin.toFixed(1).toString(),
        inr: lab.inr == undefined ? '-' : lab.inr.toFixed(1).toString(),
        serumSodium: lab.serum_sodium == undefined ? '-' : lab.serum_sodium.toFixed(1).toString(),
        serumCreatinine: lab.serum_creatinine == undefined ? '-' : lab.serum_creatinine.toFixed(1).toString(),
        onDialysis:  lab.on_dialysis ? 'yes' : 'no',
        naMeldScore: lab.na_meld == undefined ? '-' : lab.na_meld.toString(),
      };
    });
  }

  /**
   * Loads a form edit state based on the lab, or a new state if there is none
   *
   * @param lab Liver MELD lab fetched from API, or undefined
   */
  public initializeSodiumMeldForm(lab?: LiverMeldLab): void {

    // Clear table selections from subsection component
    this.$store.commit('organSpecificDetails/selectLiverMeld', lab);
    // Initialize subsection component form edit states
    this.$store.commit('pageState/set', {
      pageKey: 'liverDetails',
      componentKey: 'sodiumMeld',
      value: this.buildSodiumMeldForm(lab),
    });

  }

  /**
   * Returns Sodium Meld form edit state based on recipient Sodium Meld lab document
   *
   * @param lab NaMELD lab document fetched from API
   * @returns {SodiumMeldForm} editable NaMELD form state
   */
  public buildSodiumMeldForm(lab?: LiverMeldLab): SodiumMeldForm {
    if (!lab) {
      return {
        dateOfNaMeldData: this.currentDateUi(),
        onDialysis: false,
      };
    }

    return {
      dateOfNaMeldData: this.parseDateUi(lab.test_date),
      naMeldExpiryDate: this.parseDateUi(lab.expiry_date),
      totalBilirubin: lab.serum_bilirubin === undefined ? undefined : Number( lab.serum_bilirubin.toFixed(1) ),
      inr: lab.inr === undefined ? undefined : Number( lab.inr.toFixed(1) ),
      serumSodium: lab.serum_sodium === undefined ? undefined : Number( lab.serum_sodium.toFixed(1) ),
      serumCreatinine: lab.serum_creatinine === undefined ? undefined : Number( lab.serum_creatinine.toFixed(1) ),
      onDialysis: lab.on_dialysis,
      naMeldScore: lab.na_meld === undefined ? undefined :  Number( lab.na_meld.toFixed(1) ),
    };
  }

  /**
   * Gets table configuration for the Sodium MELD subsection table
   *
   * @returns {TableConfig} table configuration
   */
  get meldTableConfig(): TableConfig {
    return {
      data: this.meldRows,
      columns: [
        { label: 'Date of NaMELD Data', field: 'dateOfNaMeldData', width: '20%' },
        { label: 'NaMELD Expiry Date', field: 'naMeldExpiryDate', width: '20%' },
        { label: 'Total Bilirubin', field: 'totalBilirubin', width: '10%' },
        { label: 'INR', field: 'inr', width: '10%' },
        { label: 'Serum Sodium', field: 'serumSodium', width: '10%' },
        { label: 'Serum Creatinine', field: 'serumCreatinine', width: '10%' },
        { label: 'On Dialysis', field: 'onDialysis', width: '10%' },
        { label: 'Calculated NaMELD Score', field: 'naMeldScore', width: '10%' },
      ],
      empty: 'Use the form below to add a new NaMELD',
      createButton: this.canEdit,
      createText: 'Create NaMELD',
      pagination: true
    };
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: {[key: string]: string} = {
    'liver_meld_lab.test_date'        : 'lsd-meld-date',
    'liver_meld_lab.expiry_date'      : 'lsd-meld-expiry-date',
    'liver_meld_lab.serum_bilirubin'  : 'lsd-meld-bilirubin',
    'liver_meld_lab.inr'              : 'lsd-meld-inr',
    'liver_meld_lab.serum_sodium'     : 'lsd-meld-sodium',
    'liver_meld_lab.serum_creatinine' : 'lsd-meld-creatine',
    'liver_meld_lab.on_dialysis'      : 'lsd-meld-dialysis',
  };

  // PRIVATE

  /**
   * Builds form edit state based on selected document
   *
   * @param event select event
   */
  private selectMeld(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (!selectedId || !this.liverMelds) {
      return;
    }
    // Find the selected source document
    const found = this.liverMelds.find((each: LiverMeldLab) => {
      return each._id && each._id.$oid === selectedId;
    });
    if (!found) {
      return;
    }
    // Build form state based on selected document
    this.initializeSodiumMeldForm(found);
  }

  /**
   * Build an empty new form edit state
   */
  private createMeld(): void {
    // Build form state
    this.initializeSodiumMeldForm();
  }

  /**
   * Saves current form state for Sodium MELD
   */
  private saveSodiumMeld(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveSodiumMeld as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'sodiumMeld');
    // Generate payload based on current edit state
    const sodiumMeldPayload = {
      id: !!this.selectedMeld ? this.selectedMeld._id : undefined,
      recipientId: this.recipientId,
      sodiumMeld: this.extractSodiumMeldPatch(),
    };
    // Dispatch save action and register the response
    this.$store.dispatch('organSpecificDetails/saveSodiumMeld', sodiumMeldPayload).then((success: SaveResult) => {
      // If successful reload table data, clear form, and show success notification
      this.reload();
      saveProvider.registerSaveResult(success);
      // Clear any errors
      this.$emit('clear');
      // Request card-section to reload sub-sections related to Liver Scores simultaneously
      this.$emit('reloadLiverExceptionPoints');
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Reload list of Liver MELDs, the Recipient and Liver Journey, as well as any new Waitlist Decisions.
   *
   * This is needed because saving a Liver MELD Labs can affect the Journey's Waitlist Factors.
   */
  private reload(): void {
    this.$store.dispatch('organSpecificDetails/loadLiverMelds', this.recipientId).then(() => {
      this.initializeSodiumMeldForm();
    });
  }

  /**
   * Returns a patch object containing changes for a Liver Meld Lab document
   *
   * For a document that does not yet exist, the object contains all data to be saved
   *
   * @returns {LiverMeldLab} changes to save
   */
  private extractSodiumMeldPatch(): LiverMeldLab {
    if (!this.editState || !this.editState.sodiumMeld) {
      return {};
    }
    const sodiumMeld = this.editState.sodiumMeld;
    return {
      test_date: this.sanitizeDateApi(sodiumMeld.dateOfNaMeldData),
      expiry_date: this.sanitizeDateApi(sodiumMeld.naMeldExpiryDate),
      inr: sodiumMeld.inr,
      on_dialysis: sodiumMeld.onDialysis ? true : false,
      serum_bilirubin: sodiumMeld.totalBilirubin,
      serum_creatinine: sodiumMeld.serumCreatinine,
      serum_sodium: sodiumMeld.serumSodium,
    };
  }
}
