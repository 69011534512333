import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { UserAccountsState } from '@/store/userAccounts/types';
import { actions } from '@/store/userAccounts/actions';
import { getters } from '@/store/userAccounts/getters';
import { mutations } from '@/store/userAccounts/mutations';

const state: UserAccountsState = {
  selected: undefined,
  userList: undefined
};

const namespaced = true;

export const userAccounts: Module<UserAccountsState, RootState> = {
  namespaced,
  actions,
  getters,
  mutations,
  state
};
