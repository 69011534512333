
















































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { State, Getter } from 'vuex-class';
import { TableConfig } from '@/types';
import { SaveProvider, SaveResult } from '@/types';
import { Recipient } from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { HeartMeasurement } from '@/store/organSpecificDetails/types';
import { HeartSpecificPageState } from '@/components/organs/heart/HeartSpecificDetails.vue';
import { ObjectId } from '@/store/types';

export interface HeartMeasurementForm {
  _id?: { $oid: string };
  date?: string|null;
  rightCfa?: number;
  rightCfv?: number;
  leftCfa?: number;
  leftCfv?: number;
  comments?: string;
}

interface HeartMeasurementRow {
  _id?: { $oid: string };
  date?: string;
  rightCfaText?: string;
  rightCfvText?: string;
  leftCfaText?: string;
  leftCfvText?: string;
  comments?: string;
}

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    NumberInput,
    TextAreaInput,
  }
})
export default class HeartMeasurements extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.heartDetails) editState!: HeartSpecificPageState;
  @State(state => state.organSpecificDetails.heartMeasurements) heartMeasurements!: HeartMeasurement[];

  // Getters
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  /**
   * Return true if we can edit the selected measurement
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    
    const selectedId = this.editState?.heartMeasurement?._id;
    if (!!selectedId && !!this.heartMeasurements) {
      return this.isLastEntry(selectedId, this.heartMeasurements);
    }
    return true;
  }


  // Begin fetching the recipient's Heart Measurements
  public loadHeartMeasurments(): void {
    const recipientId = this.recipient.client_id;
    this.$store.dispatch('organSpecificDetails/loadHeartMeasurements', recipientId);
  }

  /**
   * Gets configuration for the Heart Measurements table
   *
   * @returns {TableConfig} Heart Measurement Table configuration
   */
  public heartMeasurementsTableConfig(): TableConfig {
    return {
      data: this.heartMeasurementRows || [],
      columns: [
        { label: this.$t('date_of_measurement').toString(), field: 'date' },
        { label: this.$t('right_cfa').toString(), field: 'rightCfaText' },
        { label: this.$t('right_cfv').toString(), field: 'rightCfvText' },
        { label: this.$t('left_cfa').toString(), field: 'leftCfaText' },
        { label: this.$t('left_cfv').toString(), field: 'leftCfvText' },
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: this.canEdit,
      createText: this.$t('create_heart_measurement').toString()
    };
  }

  /**
   * Gets table row data for the Heart Measurement table
   *
   * @returns {HeartMeasurementRow[]} Heart Measurement table rows
   */
  get heartMeasurementRows(): HeartMeasurementRow[] {
    if (!this.heartMeasurements || this.heartMeasurements.length < 0) {
      return [];
    }
    const result: HeartMeasurementRow[] = [];
    this.heartMeasurements.forEach((heartMeasurement: HeartMeasurement) => {
      const row: HeartMeasurementRow = {
        _id: heartMeasurement._id,
        date: heartMeasurement.date ? this.parseDisplayDateUi(heartMeasurement.date) : undefined,
        rightCfaText: heartMeasurement.common_femoral_artery_right == null ? '-' : heartMeasurement.common_femoral_artery_right.toString(),
        rightCfvText: heartMeasurement.common_femoral_vein_right  == null ? '-' : heartMeasurement.common_femoral_vein_right.toString(),
        leftCfaText: heartMeasurement.common_femoral_artery_left == null ? '-' : heartMeasurement.common_femoral_artery_left.toString(),
        leftCfvText: heartMeasurement.common_femoral_vein_left == null ? '-' : heartMeasurement.common_femoral_vein_left.toString(),
      };
      result.push(row);
    });
    return result;
  }

  /**
   * Loads the selected row into editState.heartMeasurement
   *
   * @listens hsd-measurements#table-row-click
   * @param event selected row from the table
   */
  public selectHeartMeasurement(event: { row: HeartMeasurement }): void {
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (this.heartMeasurements && this.heartMeasurements.length > 0) {
      const heartMeasurments = this.heartMeasurements as HeartMeasurement[];
      const foundHeartMeasurement: HeartMeasurementForm|undefined = heartMeasurments
        .find((each: HeartMeasurementForm) => {
          return each._id && each._id.$oid === selectedId;
        });
      if (foundHeartMeasurement) {
        this.$store.commit('pageState/set', {
          pageKey: 'heartDetails',
          componentKey: 'heartMeasurement',
          value: this.sanitizeHeartMeasurement(foundHeartMeasurement)
        });
      }
    }
  }

  /**
   * Converts a selected HeartMeasurement entry into HeartMeasurementForm
   *
   * @param HeartMeasurement Heart Measurement
   * @returns {HeartMeasurementForm} Heart Measurement Form entry
   */
  public sanitizeHeartMeasurement(measurement: HeartMeasurement): HeartMeasurementForm {
    return {
      _id: measurement._id,
      date: measurement.date ? this.parseDateUi(measurement.date) : undefined,
      rightCfa: measurement.common_femoral_artery_right,
      rightCfv: measurement.common_femoral_vein_right,
      leftCfa: measurement.common_femoral_artery_left,
      leftCfv: measurement.common_femoral_vein_left,
      comments: measurement.comments,
    };
  }

  /**
   * Deselects any selected Heart Measurement, and clears the entry for
   * editState.heartDetails.heartMeasurement
   *
   * @listens hsd-measurements#table-create-row
   */
  public createHeartMeasurement(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'heartDetails',
      componentKey: 'heartMeasurement',
      value: {}
    });
    this.$emit('clear');
    this.resetSaveToolbar();
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const gci = this.$refs.saveHeartMeasurement as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    gci.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: {[key: string]: string} = {
    'heart_measurement.date'                          : 'hsd-measurements-date',
    'heart_measurement.common_femoral_artery_left'    : 'hsd-measurements-cfa-left',
    'heart_measurement.common_femoral_artery_right'   : 'hsd-measurements-cfa-right',
    'heart_measurement.common_femoral_vein_left'      : 'hsd-measurements-cfv-left',
    'heart_measurement.common_femoral_vein_right'     : 'hsd-measurements-cfv-right',
  };

  /**
   * PRIVATE
   */

  /**
   * Gets the entry for editSate.heartDetails.heartMeasurement
   *
   * Converts the entry for Heart Measurement from editState
   * into a payload ready for the API.
   *
   * @returns {HeartMeasurement} object containing any field changes
   */
  private extractHeartMeasurementPatch(): HeartMeasurement {
    if (!this.editState || !this.editState.heartMeasurement) {
      return {};
    }
    const heartMeasurement = this.editState.heartMeasurement;
    return {
      date: heartMeasurement.date ? this.sanitizeDateApi(heartMeasurement.date) : null,
      common_femoral_artery_right: heartMeasurement.rightCfa,
      common_femoral_vein_right: heartMeasurement.rightCfv,
      common_femoral_artery_left: heartMeasurement.leftCfa,
      common_femoral_vein_left: heartMeasurement.leftCfv,
      comments: heartMeasurement.comments,
    };
  }

  /**
   * Attempt to save the Heart Measurement.
   *
   * Prepares an update payload for Heart Measurement,
   * dispatches a save action, and registers the save result.
   */
  private saveHeartMeasurement(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveHeartMeasurement as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('saving', 'heartMeasurement');
    // Generate payload based on current edit state
    const heartMeasurementPayload = {
      id: this.editState.heartMeasurement!._id,
      recipientId: this.recipient.client_id,
      heartMasurement: this.extractHeartMeasurementPatch()
    };
    // Dispatch save action and register the response
    this.$store.dispatch('organSpecificDetails/saveHeartMeasurement', heartMeasurementPayload).then((success: SaveResult) => {
      // If successful, reload all of the recipient's measurements and show success notification
      this.loadHeartMeasurments();
      this.createHeartMeasurement();
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }
}
