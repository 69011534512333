import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { FeatureDetails, FeaturesState, ApiFeatures } from '@/store/features/types';

export const getters: GetterTree<FeaturesState, RootState> = {
  show(state): FeatureDetails|undefined {
    return state.features || {};
  },
  iposHeart(state): boolean {
    const api = state.features && state.features.api ? state.features.api : [];
    return api.includes(ApiFeatures.ipos_hearts);
  },
  showUserSelect(state): boolean {
    const api = state.features && state.features.api ? state.features.api : [];
    return api.includes(ApiFeatures.disable_user_select) ? false : true;
  },
  oneid(state): boolean {
    const api = state.features && state.features.api ? state.features.api : [];
    return api.includes(ApiFeatures.oneid);
  },
};
