import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/coordinators/actions';
import { getters } from '@/store/coordinators/getters';
import { mutations } from '@/store/coordinators/mutations';
import { Coordinator, CoordinatorsState } from '@/store/coordinators/types';

const state: CoordinatorsState = {
  filtered: [],
  recipientCoordinator: undefined,
  followUpCoordinators: [],
};

const namespaced = true;

export const coordinators: Module<CoordinatorsState, RootState> = {
  namespaced,
  mutations,
  actions,
  state,
  getters,
};
