















































import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { SaveResult } from '@/types';
import { organCodeLookup } from '@/types';
import { Recipient } from '@/store/recipients/types';
import { RecipientJourney, RecipientReferralAttributes } from '@/store/recipientJourney/types';

import Vca from '@/components/organs/vca/Vca.vue';
import Lung from '@/components/organs/lung/Lung.vue';
import Heart from '@/components/organs/heart/Heart.vue';
import Liver from '@/components/organs/liver/Liver.vue';
import Kidney from '@/components/organs/kidney/Kidney.vue';
import SmallBowel from '@/components/organs/bowel/SmallBowel.vue';
import PancreasWhole from '@/components/organs/pancreas/PancreasWhole.vue';
import PancreasIslets from '@/components/organs/pancreas/PancreasIslets.vue';
import PageTop from '@/components/shared/PageTop.vue';
import SideNavJourney from "@/components/organs/shared/side-nav/SideNavJourney.vue";
import RecipientSummary from '@/components/recipients/RecipientSummary.vue';
import RecipientStickySummary from '@/components/recipients/RecipientStickySummary.vue';
import { OrganCodeValue } from '@/store/lookups/types';

@Component({
  components: {
    Vca,
    Lung,
    Heart,
    Liver,
    Kidney,
    PageTop,
    SmallBowel,
    PancreasWhole,
    PancreasIslets,
    SideNavJourney,
    RecipientSummary,
    RecipientStickySummary
  }
})
export default class NewOrgan extends Vue {
  // State
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('recipientDisplayName', { namespace: 'recipients' }) recipientDisplayName!: string;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter("recipientAge", { namespace: "recipients" }) recipientAge!: number;

  public mounted(): void {
    Promise.all([
      this.$store.commit('setPageTitle', `Recipients / ${this.recipientDisplayName} / ${this.organName}`),
      this.$store.dispatch('validations/loadNew', { view: `recipients/${this.recipientId}/journeys/`, action: 'new_validations' })
    ]);
  }

  /**
   * Return organ_code param from url
   * 
   * @returns {string} organ code
   */
  get organCode(): string {
    return this.$route.params.organ_code;
  }

  /**
   * Return titlized organ name from lookup value
   * 
   * @returns {string} organ name
   */
  get organName(): string {
    const organ = this.lookupValue(this.organCode, 'organ');
    return organ ? organ : '';
  }

  /**
   * Gets the current journey as lower case
   *
   * Using the organ code, return the lower case organ name
   *
   * @returns {string} organ as lower case
   */
  get organComponentName(): string {
    const name=this.organCode;
    return organCodeLookup[this.organCode];
  }


  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult|SaveResult[]): void {
    // Refer to top level organ component
    const organComponent = this.$refs.organComponent as Liver|Lung|Kidney|Heart|PancreasWhole|PancreasIslets|Vca|SmallBowel;
    // Gather validation IDs used in forms on this page
    const idLookup: {[key: string]: string} = {
      // until all organs are updated this will cause a linter error
      ...organComponent.idLookup(),
    };
    // Aggregate validation errors
    let aggregateErrors: {[key: string]: []} = {};
    errors = Array.isArray(errors) ? errors : [errors];
    errors.forEach((item) => {
      aggregateErrors = { ...item.validationErrors };
    });
    const formErrors: {[key: string]: []} = {};
    for (const key of Object.keys(aggregateErrors)) {
      formErrors[idLookup[key]] = aggregateErrors[key];
    }
    // Set all validation errors using the validation observer wrapping entire form
    (this.$refs.validations as any).setErrors(formErrors);
  }

  // Clearing validations when saving
  private saving(formReference: string) {
    this.resetValidationErrors();
  }

  // Tell the top-level form validation observer to reset all errors
  private resetValidationErrors() {
    (this.$refs.validations as any).reset();
  }
}
