import Vue from 'vue';
import axios from 'axios';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { UtilitiesState } from '@/store/utilities/types';

export const actions: ActionTree<UtilitiesState, RootState> = {
  // scroll behavior jump-to action
  scrollBehavior({ commit }): Promise<void> {
    
    return new Promise((resolve) => {
      let to = location.hash || null;
      const navigationType = window.performance ? window.performance.navigation.type : null;
      const historyLength = window.history.length || 0;
      if (to) {
        const elementTarget = document.getElementById(to.replace('#', ''));
        if (elementTarget) {
             // Determine if user has already scrolled there or past it
          if (window.scrollY > (elementTarget.offsetTop + elementTarget.offsetHeight)) {
            // if there already there prevent the scrollto
             to = " ";
          }
        }
 
        // Resolve routes with hashes by scrolling to the selected ID with offset
        setTimeout(() => {
          const element = document.getElementById((to || '').replace('#', ''));
          if (element) {
            const yoffset = window.innerWidth < 767 ? 25 : 102 ;
            const y = element.getBoundingClientRect().top + window.pageYOffset - yoffset;
            window.scrollTo({top: y, behavior: 'smooth'});
          }
        }, 150);
      } else {
        // Otherwise scroll to the top of the page
        if (window.scrollY < 200) {
          // unless user has already scrolled the page
          setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
          }, 50);
        }
   
      }
      resolve();
    });
  },
  scrollTo({}, hash): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        const element = document.getElementById(hash.replace('#', ''));
        if (element) {
          const y = element.getBoundingClientRect().top + window.pageYOffset -100;
          window.scrollTo({top: y, behavior: 'smooth'});
        }
        resolve();
      }, 1500);
    });
  },
};
