




































































import { Getter, State }  from 'vuex-class';
import { RootState, ObjectId } from '@/store/types';
import { Recipient } from '@/store/recipients/types';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import ModalSection from "@/components/shared/ModalSection.vue";
import { RecipientJourney } from '@/store/recipientJourney/types';
import { LiverDetails } from '@/store/organSpecificDetails/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import { LiverSpecificForm } from '@/components/organs/liver/LiverSpecificDetails.vue';

export interface HccCriteriaForm {
  hccOutOfCriteria?: boolean;
}

const HCC_CRITERIA_MODAL_ERRORS = [
  'validation.messages.waitlist_factors.liver_hcc_in_criteria_date.already_present',
  'validation.messages.waitlist_factors.liver_hcc_in_criteria_date.existing_hcc_labs',
];

@Component({
  components: {
    SubSection,
    ModalSection,
    BooleanRadioInput,
  }
})
export default class HccCriteria extends Vue {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.liverDetails) editState!: LiverSpecificForm;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Internal state variables that don't need to be shared across components
  private modalErrorMessages: string[] = [];

  /**
   * Return true if HCC criteria section can be edited
   * 
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

  // Initialize the form before the page mounts
  public mounted(): void {
    this.initializeHccForm();
  }

  // Loads a form edit state based on the lab, or a new state if there is none
  private initializeHccForm(): void {
    // Initialize subsection component form edit states
    this.$store.commit('pageState/set', {
      pageKey: 'liverDetails',
      componentKey: 'hccCriteria',
      value: this.buildHccCriteriaForm(this.journey),
    });
  }

  // Re-initialize the form edit state
  public reinitialize(): void {
    this.initializeHccForm();
  }

  /**
   * Returns Liver Specific Details form edit state
   *
   * Note: HCC Criteria is "Out of Criteria" if journey does NOT have the criteria date
   *
   * @param liverDetails liver specific details document fetched from API
   * @returns {HccForm} editable Hcc form state
   */
  public buildHccCriteriaForm(journey?: RecipientJourney): HccCriteriaForm {
    let out_of_criteria = undefined;
    if (journey) {
      const waitlistFactors = journey.stage_attributes?.waitlist?.factors || {};
      out_of_criteria = !waitlistFactors?.liver_hcc_in_criteria_date;
    }
    return {
      hccOutOfCriteria: out_of_criteria
    };
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: {[key: string]: string} = {
    'organ_specific_details.out_of_criteria' : 'out_of_criteria',
  };

  // PRIVATE

  // Saves current form state for HCC
  private saveHccCriteria(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveHccCriteria as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'liverSpecificDetails');
    // Generate payload based on current edit state
    const hccPayload = {
      recipientId: this.recipientId,
      journeyId: this.journeyId, 
      withinCriteria: this.extractHccWithinCriteriaFlag(),
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveHccCriteria', hccPayload).then((success: SaveResult) => {
      saveProvider.registerSaveResult(success);
      // Request card-section to reload sub-sections related to Liver Scores simultaneously
      // Note: this will cause the card-section to invoke this component's public 'reinitialize' method
      this.$emit('reloadLiverExceptionPoints');
      // Reload all waitlist decisions for the recipient's journey
      const opts = {
        journeyId: this.journeyId,
        recipientId: this.recipientId,
      };
      this.$store.dispatch('journeyState/loadWaitlistDecisions', opts);
    }).catch((error: SaveResult) => {
      // Emit event to handle field-level validation errors
      this.$emit('handleErrors', error);
      // Check if the error includes any errors to show in popup modal
      if (this.checkModalErrors(error)) {
        // Show generic error in save toolbar
        saveProvider.registerSaveResult({
          success: false,
          errorMessages: [this.$t('hcc_criteria_generic_error').toString()] 
        });
      } else {
        // Show error messages directly in the save toolbar
        saveProvider.registerSaveResult(error);
      }
    });
  }

  /**
   * Returns boolean flag to include in top-level of request payload:
   * - if true, API will set 'liver_hcc_in_criteria_date' waitlist factor to a date
   * - if false, API will set 'liver_hcc_in_criteria_date' to null
   *
   * @returns {boolean} true if liver journey is within HCC criteria
   */
  private extractHccWithinCriteriaFlag(): boolean {
    const form = this.editState?.hccCriteria || {};
    return !form?.hccOutOfCriteria;
  }

  // Check if the error result needs an error popup modal
  private checkModalErrors(error: SaveResult): boolean {
    const errorMessages: string[] = error?.errorMessages || [];
    const modalErrors = errorMessages.filter((error: string) => {
      return HCC_CRITERIA_MODAL_ERRORS.includes(error);
    });
    if (modalErrors.length > 0) {
      this.displayErrorModal(modalErrors);
      return true;
    } else {
      return false;
    }
  }

  // Display error modal for the HCC criteria section
  private displayErrorModal(modalErrorKeys: string[]): void {
    // Translate error keys to display messages
    const errorMessages: string[] = modalErrorKeys.map((errorKey: string): string => {
      return this.$t(errorKey).toString();
    });

    // Store error messages locally in this component
    Vue.set(this, 'modalErrorMessages', errorMessages);

    // Display the error modal
    const errorModal = this.$refs.hccCriteriaErrorModal as ModalSection;
    if (errorModal) errorModal.showModal();
  }
}
