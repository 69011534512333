import { GetterTree } from 'vuex';
import { GenericCodeValue, RootState } from '@/store/types';
import { OrganCodeValue } from '@/store/lookups/types';
import {DonorAcceptabilityDefaults, HospitalOption} from '@/store/hospitals/types';
import { RecipientDonorAcceptability } from '@/store/recipientJourney/types';
import { HospitalsState, Hospital, HospitalOrgansTransplanted, HOSPITAL_OUTSIDE_ONTARIO, PROVINCE_ON } from '@/store/hospitals/types';

/**
 * Filter out only Ontario hospitals
 */
function filterOntarioOnly(hospitals: Hospital[]): Hospital[] {
  return hospitals.filter((hospital: Hospital) => {
    const province_code = hospital.location ? hospital.location.province_code : "";
    return province_code === PROVINCE_ON;
  });
}

function checkExpiryDate(expiryDate: string|undefined): boolean {
  if (!expiryDate) { return true; }
  const expiryTimestamp = Date.parse(expiryDate);
  if (isNaN(expiryTimestamp)) { return false; }
  // Default cutoff date is today
  const cutOffExpiry = new Date().getTime();
  // Valid expired date? keep only if expiry is after cutoff
  return expiryTimestamp > cutOffExpiry;
}

/**
 * Filter out only vad specific hospitals
 */
function filterVadOnly(hospitals: Hospital[]): Hospital[] {
  return hospitals.filter((hospital: Hospital) => {
    const organs_transplanted = hospital.organs_transplanted ? hospital.organs_transplanted : [];
    const organ_descriptions: any = organs_transplanted.filter((organ: any) => {
      const expiryDate: string|undefined = organ.expiry_date ? organ.expiry_date : undefined;
      const organIsHeart = organ.organ_code ? organ.organ_code == OrganCodeValue.Heart: false;
      return organIsHeart && checkExpiryDate(expiryDate);
    });
    const active_ontario_transplant_program = hospital.active_ontario_transplant_program ? hospital.active_ontario_transplant_program : false;
    return active_ontario_transplant_program === true && organ_descriptions.length > 0;
  });
}

// Getters
export const getters: GetterTree<HospitalsState, RootState> = {
  /**
   * Get a select-input "friendly" list of hospitals
   *
   * Create a list of all hospitals containing the code and hospital name
   *
   * @returns {HospitalOption[]} collection of hospital options
   */
  hospitalOptions(state): HospitalOption[] {
    if (state.oop_transplant) {
      return state.oop_transplant.map((hospital: Hospital) => {
        return {
          organs_transplanted: hospital.organs_transplanted,
          code: hospital._id.$oid,
          value: hospital.hospital_name_info ? hospital.hospital_name_info.name : '-'
        };
      });
    } else if (state.ontario_transplant) {
      return state.ontario_transplant.map((hospital: Hospital) => {
        return {
          organs_transplanted: hospital.organs_transplanted,
          code: hospital._id.$oid,
          value: hospital.hospital_name_info ? hospital.hospital_name_info.name : '-'
        };
      });
    } else if (state.all) {
      return filterOntarioOnly(state.all).map((hospital: Hospital) => {
        return {
          organs_transplanted: hospital.organs_transplanted,
          code: hospital._id.$oid,
          value: hospital.hospital_name_info ? hospital.hospital_name_info.name : '-'
        };
      });
    } else {
      return [];
    }
  },

  activeOntarioTransplantOopHospitalOptions(state): HospitalOption[] {
    if (state.all) {
      const activeTransplantPrograms = state.all.filter((hospital: Hospital) => hospital.active_oop_transplant_program || hospital.active_ontario_transplant_program);

      return activeTransplantPrograms.map((hospital: Hospital) => {
        return {
          code: hospital._id.$oid,
          value: hospital.hospital_name_info ? hospital.hospital_name_info.name : '-'
        };
      });
    }
    return [];
  },

  /**
   * Getter to return only the Out of Province hospitals.
   *
   * @param state
   * @returns {HospitalOption[]} collection of hospital options
   */
  hospitalOptionsOOP(state): HospitalOption[] {
    if(!state.oop_transplant) {
      return [];
    }
    return state.oop_transplant.map((hospital: Hospital) => {
      return {
        organs_transplanted: hospital.organs_transplanted,
        code: hospital._id.$oid,
        value: hospital.hospital_name_info ? hospital.hospital_name_info.name : '-'
      };
    });
  },
  /**
   * Getter to return only the Ontario transplant programs
   * @param state
   * @returns {HospitalOption[]} collection of hospital options
   */
  hospitalOptionsOntarioTransplant(state): HospitalOption[] {
    if(!state.ontario_transplant) {
      return [];
    }
    return state.ontario_transplant.map((hospital: Hospital) => {
      return {
        organs_transplanted: hospital.organs_transplanted,
        code: hospital._id.$oid,
        value: hospital.hospital_name_info ? hospital.hospital_name_info.name : '-',
        abbr: hospital.hospital_name_info ? hospital.hospital_name_info.abbreviation : '-'
      };
    });
  },
  /**
   * Getter to return all Ontario hospital programs!
   * @param state
   * @returns {[{code: number, value: string}]} collection of hospitals
   */
  hospitalOptionsOntarioAll(state): { code: string; value: string }[] {
    if(!state.all) {
      return [];
    }
    return filterOntarioOnly(state.all).map((hospital: Hospital) => {
      return {
        code: hospital._id.$oid,
        value: hospital.hospital_name_info ? hospital.hospital_name_info.name : '-'
      };
    });
  },


  /**
   * Get a select-input "friendly" list of hospitals that transplant organs
   *
   * Create a list of all organ transplant hospitals containing the code and hospital name
   *
   * @returns {[{code: number, value: string}]} collection of hospitals
   */
  organTransplantHospitals(state): { code: string; value: string }[] {
    if (state.all) {
      return state.all.filter((hospital: Hospital) => {
        return (hospital.organs_transplanted || []).length > 0;
      }).map((hospital: Hospital) => {
        return {
          code: hospital._id.$oid,
          value: hospital.hospital_name_info ? hospital.hospital_name_info.name : '-'
        };
      });
    } else {
      return [];
    }
  },

  /**
   * Return a filtered list of Out of Province Hospitals by Organ
   *
   * Filter OOP Hospitals by organs_transplanted.organ_code
   *
   * @returns {Hospital[]} List of Hospitals
   */
  oopHospitalsByOrgan(state): (organCode: string) => Hospital[] {
    return (organCode: string): Hospital[] => {
      if (!state.oop) return [];
      return state.oop.filter((hospital: Hospital) => {
        return hospital.organs_transplanted.find((organsTransplanted: HospitalOrgansTransplanted) => {
          return `${organsTransplanted.organ_code}` === organCode;
        });
      });
    };
  },

  /**
   * Get the transplant programs
   *
   * Goes through the hospitals collection and filters out only those with Ontario transplant programs for the specified organ.
   *
   * @returns {Hospital[]|[]} collection of Hospital[] or []
   */
   getOntarioTransplantProgramsByOrgan(state): (organ_codes: number[]) => Hospital[] {
    return (organ_codes: number[]): Hospital[] => {
      if (!state.all) {
        return [];
      }
      const transplantPrograms = state.all.filter((hospital: Hospital) => {
        const activeOntarioTransplantProgram = hospital.active_ontario_transplant_program;

        let hospitalTransplantsOrgan = false;

        if (activeOntarioTransplantProgram) {
          hospital.organs_transplanted.forEach((organ: any, index: number) => {
            if (organ_codes.includes(parseFloat(organ.organ_code)) && organ.expiry_date == null) {
              hospitalTransplantsOrgan = true;
            }
          });
        }
        return activeOntarioTransplantProgram && hospitalTransplantsOrgan;
      });
      return transplantPrograms;
    };
  },

  /**
   * Filter array of HospitalOption items to only include those for specified organ
   * NOTE: organ codes in hospitals collection are strings
   *
   * @returns {(options: HospitalOption[], organCode: string) => HospitalOption[]}
   */
  filterHospitalOptionsByOrganCodes(_state): (options: HospitalOption[], organCode: string) => HospitalOption[] {
    return (options: HospitalOption[], organCode: string): HospitalOption[] => {
      if (!options || !organCode) return [];

      const filteredByOrgan = options.filter((option: HospitalOption) => {
        const hospitalOrgans = (option.organs_transplanted || []).map((organsTransplanted: HospitalOrgansTransplanted): string => { return organsTransplanted.organ_code; });
        const result = hospitalOrgans.includes(organCode);
        return result;
      });
      return filteredByOrgan;
    };
  },

  /**
   * Get the transplant programs
   *
   * Goes through the hospitals collection and filters out only transplant programs for the specified organ.
   *
   * @returns {Hospital[]|[]} collection of Hospital[] or []
   */
  getAllTransplantProgramsByOrgan(state): (organ_codes: number[]) => Hospital[] {
    return (organ_codes: number[]): Hospital[] => {
      if (!state.all) {
        return [];
      }
      const transplantPrograms = state.all.filter((hospital: Hospital) => {
        let hospitalTransplantsOrgan = false;

        if (!hospital.organs_transplanted) {
          return;
        }

        hospital.organs_transplanted.forEach((organ: any, index: number) => {
          if (organ_codes.includes(parseFloat(organ.organ_code)) && organ.expiry_date == null) {
            hospitalTransplantsOrgan = true;
          }
        });
        return hospitalTransplantsOrgan;
      });
      return transplantPrograms;
    };
  },

  /**
   * Get a select-input "friendly" list of hospitals for VAD
   *
   * Create a list of all hospitals containing the code and hospital name
   *
   * @returns {[{code: number, value: string}]} collection of hospitals
   */
  vadHospitalOptions(state): { code: string; value: string }[] {
    if (!state.all) {
      return [];
    }
    const list = filterVadOnly(state.all).map((hospital: Hospital) => {
      return {
        code: hospital._id.$oid,
        value: hospital.hospital_name_info ? hospital.hospital_name_info.name : HOSPITAL_OUTSIDE_ONTARIO
      };
    });
    list.push({code: HOSPITAL_OUTSIDE_ONTARIO, value: "Out of Province"}); // specific to VAD
    return list;
  },

  /**
   * Get a select-input "friendly" list of active ontario transplant programs
   *
   * Create a list of only active ontario transplant programs containing the code and hospital name
   *
   * @returns {[{code: number, value: string}]} collection of hospitals
   */
  ontarioTransplantOptions(state): { code: string; value: string }[] {
    if (!state.ontario_transplant) {
      return [];
    }
    return state.ontario_transplant.map((hospital: Hospital) => {
      return {
        code: hospital._id.$oid,
        value: hospital.hospital_name_info ? hospital.hospital_name_info.name : HOSPITAL_OUTSIDE_ONTARIO
      };
    });
  },

  ontarioTransplantOptionsByOrgan(state): (organ_code: number) => GenericCodeValue[] {
    return (organ_code: number): GenericCodeValue[] => {
      if (!state.ontario_transplant) {
        return [];
      }
      const transplantPrograms = state.ontario_transplant.filter((hospital: Hospital) => {

        let hospitalTransplantsOrgan = false;

          hospital.organs_transplanted.forEach((organ: HospitalOrgansTransplanted) => {
            const expiryDate: string|undefined = organ.expiry_date ? organ.expiry_date : undefined;

            if (organ.organ_code == organ_code.toString() && checkExpiryDate(expiryDate)) {
              hospitalTransplantsOrgan = true;
            }
          });
        return hospitalTransplantsOrgan;
      });
      return transplantPrograms.map((hospital: Hospital) => {
        return {
          code: hospital._id.$oid,
          value: hospital.hospital_name_info ? hospital.hospital_name_info.name : HOSPITAL_OUTSIDE_ONTARIO
        };
      });
     };
  },

  /**
   * Getter to return only the Dialysis Hospitals.
   *
   * @param state
   * @returns {HospitalOption[]} collection of hospital options
   */
 dialysisHospitals(state): HospitalOption[] {
    if(!state.all) {
      return [];
    }

    return state.all.reduce((dialysisOptions: HospitalOption[], hospital: Hospital) => {
      if(hospital.dialysis) {
        const hospitalOption = {
          organs_transplanted: hospital.organs_transplanted,
          code: hospital._id.$oid,
          value: hospital.hospital_name_info ? hospital.hospital_name_info.name : '-'
        };
        dialysisOptions.push(hospitalOption);
      }
      return dialysisOptions;
    }, []);
  },

  getHospitalByCode(state) {
    return (hospitalCode?: number|null): Hospital|null => {
      if (hospitalCode == undefined) {
        return null;
      }
      const hospitals = state.all || [];
      const result = hospitals.find((hospital: Hospital) => {
        return hospital.hospital_code == hospitalCode;
      });
      return result || null;
    };
  },
  getHospitalById(state) {
    return (hospitalId?: string|null): Hospital|null => {
      if (hospitalId == undefined) {
        return null;
      }
      const hospitals = state.all || [];
      const result = hospitals.find((hospital: Hospital) => {
        return hospital._id.$oid== hospitalId;
      });
      return result || null;
    };
  },
  getOntarioHospitalById(state) {
    return (hospitalId?: string|null): Hospital|null => {
      if (hospitalId == undefined) {
        return null;
      }
      const hospitals = state.ontario_transplant || [];
      const result = hospitals.find((hospital: Hospital) => {
        return hospital._id.$oid== hospitalId;
      });
      return result || null;
    };
  },
  getOrganCodesByHospitalId(state, getters) {
    return (hospitalId?: string|null): string[] => {
      const hospital = getters.getOntarioHospitalById(hospitalId);

      const hospitalTransplantsOrgans: any[] = [];

      if (!hospital || !hospital.organs_transplanted) return [];

      hospital.organs_transplanted.forEach((organ: HospitalOrgansTransplanted) => {
        const expiryDate: string|undefined = organ.expiry_date ? organ.expiry_date : undefined;

        if (checkExpiryDate(expiryDate)) {
          hospitalTransplantsOrgans.push(organ.organ_code);
        }
      });

      return hospitalTransplantsOrgans;
    };
  },
  getHospitalAbbreviation(state) {
    return (hospitalId?: string|null): string|null => {
      if (hospitalId == undefined) {
        return null;
      }
      const hospitals = state.all || state.oop_transplant || [];
      let returnStr = '';

      hospitals.find((hospital: Hospital) => {
        if (hospital._id.$oid == hospitalId) {
          if (hospital.hospital_name_info.abbreviation !== null) {
            returnStr = hospital.hospital_name_info.abbreviation;
          } else if (hospital.program_identifier !== null) {
            returnStr = hospital.program_identifier;
          } else {
            returnStr = "-";
          }
        }
     });
      return returnStr || null;
    };
  },

  /**
   * Get the default Donor Acceptability Criteria from a Hospital Id and Organ Code
   *
   * @returns {RecipientDonorAcceptability|undefined} Donor Acceptability defaults or undefined
   */
  getDefaultDonorCriteria(state, getters, rootState, rootGetters): (hospitalId: string|undefined, organCode: string) => RecipientDonorAcceptability|undefined {
    return (hospitalId: string|undefined, organCode: string): RecipientDonorAcceptability|undefined => {
      if (!hospitalId || !organCode) {
        return undefined;
      }

      // Get the organ name from the code and lowercase it
      const lookupValue = rootGetters['lookups/lookupValue'];
      const organValue = (lookupValue(organCode, 'organ') || '').toLowerCase();

      // Get the hospital
      const hospital = (state.all || []).find((hospital: Hospital) => {
        return hospital._id.$oid === hospitalId;
      });

      // Get the default values
      const hospitalDefaults: DonorAcceptabilityDefaults[] = hospital?.donor_acceptability_defaults || [];
      // Get the organ default values
      const organDefaults = hospitalDefaults.find((defaultValue: DonorAcceptabilityDefaults) => {
        return defaultValue.organ_code == organCode;
      });

      // If there are no defaults for this organ return
      if (!organDefaults) return undefined;

      // deceased_donor and living_donor are necessary to match the interface for RecipientDonorAcceptability
      return {
        deceased_donor: null,
        living_donor: null,
        hcv_ab_positive: organDefaults.hcv_ab_positive,
        hcv_nat_positive: organDefaults.hcv_nat_positive,
        a2_or_a2b_donor: organDefaults.a2_or_a2b_donor,
        abo_incompatible: organDefaults.abo_incompatible,
        ecd_donor: organDefaults.ecd_donor,
        hep_ab_core_positive: organDefaults.hep_ab_core_positive,
        display_in_ui: organDefaults.display_in_ui
      };
    };
  }
};
