import Vue from 'vue';
import { ObjectId } from '@/store/types';
import { CtrErrorContext } from '@/types';
import Vuex, { StoreOptions } from 'vuex';
import { OrganCodeValue } from '@/store/lookups/types';

export interface AllocationState {
  selected?: Allocation;
  allAllocations?: Allocations[];
  activeAllocations?: Allocations[];
  inactiveAllocations?: Allocations[];
  exclusionRules?: ExclusionRules[];
  donorDetails?: DonorDetails[];
  recipientDetails?: RecipientDetails;
  checklist: AllocationChecklistForm|undefined;
  checklistDetails: AllocationChecklistResponse|undefined;
  checklistHistory: AllocationChecklistDetails[]|undefined;
  // loading state flags used across components
  lastLoadedAllocationDonorId?: ObjectId;
  isCreatingAllocation: boolean;
  isLoadingAllocation: boolean;
  isLoadingAllocations: boolean;
  isGeneratingAllocationReport: boolean;
  isAddingRecipient: boolean
  isDiscontinuingOneAllocation: boolean;
  isDiscontinuingAllAllocations: boolean;
  isMakingOffer: boolean;
  isDiscontinuingOneOffer: boolean;
  isRespondingOffer: boolean;
  isDecliningMultiple: boolean;
}

export interface AllocationChecklistForm {
  _id?: { $oid: string };
  checklistType: string;
  checklistUser: string;
  allocationId?: string;
  allocationRunDate?: string;
  donorId?: number;
  consentedOrgan?: string;
  donorFirstName?: string;
  donorLastName?: string;
}

export interface AllocationChecklistData {
  confirmed: boolean;
  details: AllocationChecklistDetails;
}

export interface AllocationChecklistResponse {
  pre: AllocationChecklistData;
  post: AllocationChecklistData;
}

export interface Allocations {
  organ_code: number;
  allocations: Allocation[];
}

export interface Allocation {
  _id: string;
  client_id: string;
  state: string;
  created_at: string;
  allocation_type: string|null;
  expedited: boolean|null;
  expedited_reasons: {
    ctr_unavailable: boolean;
    ctr_unavailable_message: string;
    donor_hla_typing_missing: boolean;
    donor_virology_missing: boolean;
  };
  double_kidney: boolean;
  rerunnable: boolean;
  organ_code: number;
  organ: string;
  disabled_program_rules: string[];
  donor: AllocationDonor;
  recipients: AllocationRecipient[];
  checklists_status: AllocationChecklist;
  reallocated_to_nonintended?: number;
  supercedes_id: string|null;
}

export interface AllocationChecklistDetails {
  csc1_confirmed: boolean;
  csc1_confirmed_date: string;
  csc1_confirmed_comments: string;
  csc1_confirmed_userid?: string;
  csc2_confirmed: boolean;
  csc2_confirmed_date: string;
  csc2_confirmed_comments: string;
  csc2_confirmed_userid?: string;
}
export interface AllocationChecklist {
  [key: string]: boolean;
  post_confirmed: boolean;
  post_csc1_confirmed: boolean;
  post_csc2_confirmed: boolean;
  pre_confirmed: boolean;
  pre_csc1_confirmed: boolean;
  pre_csc2_confirmed: boolean;
}

export interface AllocationDonor {
  _id: ObjectId;
  client_id: number;
  first_name: string;
  last_name: string;
  blood_type: string;
  blood_sub_type: string;
  dob: string;
  donor_category: string;
  donor_at_threshold: boolean;
  age: number;
  sex: string;
  neurological_death: boolean;
  double_kidney: boolean;
  ecd: boolean;
  exceptional_distribution: boolean;
  hospital_id: string;
  hospital_code: number;
  province_code: string;
  region_code: string;
  liver_locality_region: string;
}

export interface AllocationRecipient {
  _id: string;
  client_id: number;
  rank: number;
  effective_rank: number;
  first_name: string;
  last_name: string;
  organ_code: number;
  cluster_organ_code?: string;
  cluster_organ_codes?: number[];
  dob: string;
  hospital_abbreviation?: string;
  age: number;
  sex: string;
  medical_status: string;
  secondary_medical_status: string;
  blood_type: string;
  height: number;
  weight: number;
  cpra: number;
  hsp: string;
  allocation_points: number|null;
  mpe_score: number|null;
  status: string;
  hospital_id: string,
  hospital_code: number;
  program: string;
  province_code: string;
  country_code: string;
  offer?: AllocationOffer;
  offer_organ_code: number;
  waitlisted_for_organ_codes: string[];
  waitlisted_for_organs: string[];
  cluster_organ: string|null;
  other_offers_pending: boolean;
  other_offers_accepted: boolean;
  out_of_province: boolean;
  added_manually: boolean;
  re_offer_scenario: boolean;
  transplant_in_progress: AllocationRecipientTransplantInProgress;
  has_2nd_ranking_entry?: boolean;
  is_2nd_ranking_entry?: boolean;
  entry_offer_actions_enabled?: boolean;
  ranking_category?: string|null;
  registration_type?: string|null;
  ctr_match_id?: string|null;
  reallocated_to_nonintended?: boolean;
}

export interface AllocationRecipientTransplantInProgress {
  donor_client_id: number;
  deceased_donor_id: number;
}

export interface AllocationOffer {
  offer_type_code: string|null;
  provisional: boolean;
  datetime_offered: string|null;
  datetime_effective: string|null;
  datetime_expires: string|null;
  offered_by: string;
  organ_specification_code: number|null;
  no_offer_reason_category: string;
  no_offer_reason_code: string;
  carried_over_from_rerun: boolean;
  comments: string;
  response_code: string|null;
  response_date: string;
  response_by: string;
  responsible_physician_id: string;
  response_reason_category_code: number|null;
  response_reason_code: number|null;
  responses: any;
}

export interface ExclusionRules {
  id: string;
  description: string;
  active: boolean;
  organ_code: number[];
  effective_date: string;
  expired_date: string;
}

export interface AllocationOfferAction {
  recipients: AllocationOfferActionRecipient[];
  type: string;
  reason_code?: number|string;
  reason_category?: number|string;
  responsible_physician_id?: string;
  organ_specification_code?: number;
  comments?: string;
  out_of_sequence_offer_reason_code?: number|null;
  out_of_sequence_offer_reason_other?: string|null;
  notification_email?: string|null;
  notification_email_other?: string|null;
  attachment_ids?: string[]|null;
  reallocated_from_intended_recipient_id?: string|null;
  send_email?: boolean;
}

export interface AllocationOfferActionRecipient {
  id: string;
  offer_organ_code: number|undefined;
  reallocated_to_nonintended?: boolean;
}

export interface AllocationResponseAction {
  recipient_id?: string;
  type?: string;
  reason_code?: number|null;
  reason_category?: number|null;
  offer_organ_code?: number;
}

export enum AllocationOfferTypeValues {
  Primary = 'P',
  Backup = 'B',
  NoOffer = 'N',
  ConvertToAcceptedPrimary = 'C',
}

export enum AllocationOfferResponseCodeValues {
  Accept = 'A',
  AcceptWithCondition = 'AC',
  Decline = 'D',
  RequestExtension = 'E',
  TimeOut = 'T',
  Withdraw = 'W',
  Cancel = 'C'
}

// NOTE: the 'Request Extension' response is still considered an open / proposed offer (see B#15470)
export const RESPONSE_CODES_CONSIDERED_TO_BE_OPEN: (string|null)[] = [
  AllocationOfferResponseCodeValues.RequestExtension.toString(),
  null,
];

export interface DonorDetails {
  _id: ObjectId;
  client_id: number;
  state: string;
  expedited: boolean;
  allocation_type: string;
  organ_code: number;
  organ: string;
  disabled_program_rules: string[];
  created_at: string;
  donor: DonorDetailsProfile;
}

export interface DonorDetailsProfile {
  _id: ObjectId;
  client_id: number;
  first_name: string;
  last_name: string;
  blood_type: string;
  blood_sub_type: string;
  blood_rh: string;
  dob: string;
  age: number;
  gender: string;
  neurological_death: boolean;
  double_kidney: boolean;
  ecd: boolean;
  exceptional_distribution: boolean;
  exd_reason_codes?: number[]|null;
  exd_reason_details_other?: string|null;
  exd_reason_details_travel?: string|null;
  exd_reason_details_transmission?: string|null;
  height: number;
  weight: number;
  bmi: number;
  hospital_id: ObjectId;
  hospital_code: number;
  region_code: string;
  ndd: boolean;
  virology: DonorDetailsVirology;
  hla_typing: DonorDetailsHlaTyping[];
}

export interface DonorDetailsVirology {
  cmv: boolean;
  ebv: boolean;
  hbv_surface_ag: boolean;
  hbv_surgace_ab: boolean;
  hcv: boolean;
  hiv_nat: boolean;
  hcv_nat: boolean;
  hbv_nat: boolean;
  hiv_1_2: boolean;
  htlv1: boolean;
  htlv2: boolean;
  syphilis: boolean;
  toxoplasma: boolean;
  wnv_pcr: boolean;
}

export interface DonorDetailsHlaTyping {
  typing_available: boolean;
  antigens: DonorDetailsHlaAntigens[];
  epitopes: string[];
}

export interface DonorDetailsHlaAntigens {
  locus: string;
  molecular: string;
  most_likely_allele: boolean;
  serologic: string[];
}


export interface RecipientDetails {
  _id: ObjectId;
  client_id: number;
  state: string;
  expedited: boolean;
  allocation_type: string;
  organ_code: number;
  organ: string;
  disabled_program_rules: string[];
  created_at: string;
  recipient: RecipientDetailsProfile;
}

export interface RecipientDetailsProfile {
  _id: ObjectId;
  client_id: number;
  national_recipient_id: string;
  mrn: string;
  rank: number;
  first_name: string;
  last_name: string;
  dob: string;
  age: number;
  gender: string;
  medical_status: string;
  secondary_medical_status: string;
  blood_type: string;
  height: number;
  weight: number;
  weight_min: number;
  weight_max: number;
  bmi: number;
  cpra: string;
  hsp: string;
  status: string;
  hospital_id: ObjectId;
  hospital_code: number;
  program: string;
  province_code: string;
  country_code: string;
  wait_days: number;
  listing_date: string;
  primary_diagnosis: string;
  secondary_diagnosis: string;
  registration_type: string;
  ranking_category: string;
  registration_status: string;
  waitlisted_for_organs: string[];
  comments: string;
  donor_acceptability: RecipientDetailsDonorAcceptability;
  total_lung_capacity: number;
  virology: RecipientDetailsVirology;
  pra: RecipientDetailsPra;
  vxm: RecipientDetailsVxm;
  hla_typing: RecipientDetailsHlaTyping;
  antibodies: RecipientDetailsHlaAntibodies;
  out_of_province?: boolean;
}

export interface RecipientDetailsDonorAcceptability {
  abo_incompatible: boolean;
  a2_or_a2b: boolean;
  ecd: boolean;
  min_weight: number;
  max_weight: number;
  comments: string;
  deceased_donor: boolean;
  living_donor: boolean;
}

export interface RecipientDetailsVirology {
  cmv: boolean;
  ebv: boolean;
  hbv_surface_ag: boolean;
  hcv: boolean;
}

export interface RecipientDetailsPra {
  class1_current_value: number;
  class1_current_months: number;
  class1_peak_value: number;
  class1_peak_months: number;
  class2_current_value: number;
  class2_current_months: number;
  class2_peak_value: number;
  class2_peak_months: number;
  total_current_value: number;
  total_current_months: number;
  total_peak_value: number;
  total_peak_months: number;
}

export interface RecipientDetailsVxm {
  result: string;
  current: RecipientDetailsVxmCurrentCumulative;
  cumulative: RecipientDetailsVxmCurrentCumulative;
  current_serum_date: string;
}

export interface RecipientDetailsVxmCurrentCumulative {
  result: string|null;
  class1?: RecipientDetailsVxmClass;
  class2?: RecipientDetailsVxmClass;
}
export interface RecipientDetailsVxmClass {
  result: string|null;
  messages: string[];
  unacceptable_allele_group: string[];
  unacceptable_allele_specific: string[];
  unacceptable_epitopes: string[];
  indeterminate_allele_group: string[];
  indeterminate_allele_specific: string[];
  indeterminate_epitopes: string[];
  possible_allele_specific: string[];
  untested: string[];
  untested_epitopes: string[];
}

export interface RecipientDetailsHlaTyping {
  antigens: RecipientDetailsHlaAntigens[];
  epitopes: string[];
}

export interface RecipientDetailsHlaAntigens {
  locus: string;
  molecular: string;
  most_likely_allele: boolean;
  serologic: string[];
}

export interface RecipientDetailsHlaAntibodies {
  cumulative: RecipientDetailsCumulativeCurrent;
  current: RecipientDetailsCumulativeCurrent;
  current_antibodies_confirmed: boolean;
  test_date: string;
}

export interface RecipientDetailsCumulativeCurrent {
  class1: RecipientDetailsHlaClass;
  class2: RecipientDetailsHlaClass;
  cpra: number;
  cpra_class1: number;
  cpra_class2: number;
}

export interface RecipientDetailsHlaClass {
  antibodies: RecipientDetailsAntibodies;
  epitopes: RecipientDetailsEpitopes;
}

export interface RecipientDetailsAntibodies {
  acceptable: string[];
  unacceptable_allele_group: string[];
  unacceptable_allele_specific: string[];
  unacceptable_alpha_beta: string[];
  indeterminate_allele_group: string[];
  indeterminate_allele_specific: string[];
  indeterminate_alpha_beta: string[];
  possible_allele_specific: string[];
  untested: string[];
}

export interface RecipientDetailsEpitopes {
  acceptable: string[];
  unacceptable: string[];
  indeterminate: string[];
  untested: string[];
}

export interface AllocationResponse {
  _id: string;
  selected?: boolean,
  offerType?: string;
  organ_spec_offered?: string|null;
  rank?: number;
  effective_rank?: number;
  tgln_id?: number;
  lastName?: string;
  transplantProgram?: string;
  hospital_abbreviation?: string;
  hospitalId?: string;
  organ?: string;
  offerOrganCode?: number;
  offerDateTime?: string;
  offeredBy?: string;
  hsp?: string;
  responseCode?: string;
  responseCategoryCode?: number|null;
  responseReasonCode?: number|null;
  responseDateTime?: string;
  responsiblePhysician?: string;
  responseBy?: string;
  recipientStatus?: string;
  waitlisted_for_organs: string[];
  waitlisted_for_organ_codes: string[];
  outOfProvince?: boolean;
  medical_status?: string;
  secondary_medical_status?: string;
  allocation_points: number|null;
  mpe_score: number|null;
  abo?: string;
  sex?: string;
  age?: number;
  height?: number;
  weight?: number;
  cpra?: number;
  disableCompareModal?: boolean;
}

export interface ChecklistUsers {
  [key: string]: ChecklistFields;
  csc1: ChecklistFields;
  csc2: ChecklistFields;
}

export interface ChecklistFields {
  [key: string]: boolean;
  donor_id: boolean;
  blood: boolean;
  donor_type: boolean;
  region: boolean;
  offering_plan: boolean;
  offering_program: boolean;
  offering_recipient: boolean;
  allocation_date: boolean;
  no_new_high_status_check: boolean;
  tips_confirmed: boolean;
  ontario_hsp_kidney: boolean;
  special_considerations: boolean;
}

export interface AllocationOfferSnapshot {
  donor_client_id?: string;
  deceased_donor_id?: string;
  client_id?: string;
  organ_code?: string;
  allocation_type?: string;
  state?: string;
  offered_recipients?: AllocationOfferedRecipient[];
}

export interface AllocationOfferedRecipient {
  client_id?: string;
  datetime_offered?: string;
  organ_code?: string;
  organ_specification_code?: string;
  datetime_expires?: string;
  offer_response_code?: string;
  program_identifier?: string;
  offer_type_code?: string;
}

export interface AllocationOfferRecipient {
  id: string;
  effective_rank: number;
  offer_organ_code: number|undefined;
  hsp: string|null;
  cluster_organ_code: string|undefined;
  cluster_organ_codes?: number[],
  re_offer_scenario: boolean;
  offer_type_code: string|null,
  response_code: string|null,
  offered_to_2nd_category?: boolean;
  out_of_province: boolean;
}


export interface OfferOutcomeContext {
  actionId: string;
  ctrErrors: CtrErrorContext[],
  warningMessages: string[],
  instructionsTemplates: string[];
  isHardStopError?: boolean;
}

export const RUN_ALLOCATION = 'run_allocation';
export const RERUN_ALLOCATION = 'rerun_allocation';
export const RUN_EXPEDITED_ALLOCATION = 'run_expedited_allocation';
export const RERUN_EXPEDITED_ALLOCATION = 'rerun_expedited_allocation';
export const MAKE_OFFER = 'make_offer';

export const RANKING_CATEGORY_HSP = 'HSP Patient Identified by CTR';

export enum AllocationStateValues {
  Offering = 'offering',
  OfferConfirmed = 'offer-confirmed',
  Concluded = 'concluded',
  Recommended = 'recommended',
}

export const DISCONTINUED = 'discontinued';
export const SUPERCEDED = 'superceded';

export const SYSTEM_ONLY_ALLOCATION_STATES = [
  'recommending', // We don't want to load allocations that are still in the recommending state
  DISCONTINUED,
  SUPERCEDED,
  'error',
];

// NOTE: assumes we are checking lower-case strings
export enum AllocationType {
  Local = 'local',
  Provincial = 'provincial',
  LivingAnonymous = 'living_anonymous'
}

export enum RegistrationType {
  Cluster = "CLUSTER"
}

export enum Transplant {
  TransplantTime = "23:59:59"
}

// Liver and Heart allocations include High-Status (HS) from CTR
export const CTR_HIGH_STATUS_ORGAN_CODES = [
  OrganCodeValue.Liver,
  OrganCodeValue.Heart
];

// Kidney (Provincial) allocations include Highly-Sensitized Patients (HSP) from CTR
export const CTR_HSP_ORGAN_CODES = [
  OrganCodeValue.Kidney
];

export const ERROR_QUERYING_CTR_OFFERS = /error_querying_ctr_offers \((.*)\): (.*)/;
