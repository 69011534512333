import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { UserDetails, UserAccountsState, UserList } from '@/store/userAccounts/types';

export const getters: GetterTree<UserAccountsState, RootState> = {
  getSelected(state: UserAccountsState, getters): UserDetails|undefined {
    return state.selected || undefined;
  },
  getUserList(state: UserAccountsState, getters): UserList|undefined {
    return state.userList;
  },
  getUserEntries(state: UserAccountsState, getters): UserDetails[]|undefined {
    return state.userList ? state.userList.entries : [];
  },
  getUserCount(state: UserAccountsState, getters): number {
    return state?.userList?.count || 0;
  },
};
