import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { TranslationContext } from '@/types';
import { GenericCodeValue } from '@/store/types';
import { errorType, TaskState, TaskType } from '@/store/tasks/types';

export const getters: GetterTree<TaskState, RootState> = {
  getCurrentTask(state, getters): TaskType|undefined {
    return state.currentTask;
  },

  getCurrentTaskId(state, getters): string|null {
    const currentTask = getters.getCurrentTask;
    return currentTask ? currentTask._id.$oid : null; 
  },

  getPercentageFromTask(state, getters): number {
    const currentTask = getters.getCurrentTask;
    // get status message, remove last word and convert to number then return
    const percentage = currentTask && currentTask.status ? parseInt(currentTask.status.split(' ').pop().replace('%', '')) : 0;
    return percentage;
  },

  getPercentage(state, getters): number {
    return state.percentage || 0;
  },

  getStatus(state, getters): string|undefined {
    return state.status;
  },

  getPercentageCss(state, getters): string {
    return `width: ${getters.getPercentage}%`;
  },

  getTaskError(state, getters): string|null {
    return state.errorState?.message || null;
  }
};
