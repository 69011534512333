


























import { Component, Vue, Prop, Model } from 'vue-property-decorator';
@Component
export default class ColumnConfigCheckboxInput extends Vue {
  // V-model
  @Model('change') value!: string;
  // Required props
  @Prop({ required: true }) inputId!: string; // HTML ID
  @Prop({ required: true }) label!: string; // Label text (what the user clicks on)
  // Initialize local copy of the value so it's available at template render
  get isChecked(): boolean {
    return !!this.value;
  }
  // Forward events to the parent component
  public inputEvents(): any {
    const _vm = this as ColumnConfigCheckboxInput;
    return Object.assign({},
      // parent listeners
      this.$listeners,
      {
        // custom listeners
        change(event: any) {
          // Emit updated value for v-model
          const newValue = !_vm.isChecked;
          _vm.$emit('change', newValue);
        }
      }
    );
  }
}
