import { MutationTree } from 'vuex';
import { SystemOrganTypes, RootState } from '@/store/types';
import { Organ } from '@/store/lookups/types';
import Vue from 'vue';

export const globalMutations: MutationTree<RootState> = {
  setPageTitle(state, pageTitle: string) {
    document.title = pageTitle;
    Vue.set(state, 'pageTitle', pageTitle);
  },
  setSystemOrganTypes(state, organs: Organ[]) {
    const acceptedOrganValues: SystemOrganTypes = {};
    // sanitize list of organs and create lookup object
    organs.forEach((item: Organ) => {
      const key: string = item.value.toLowerCase().replace(/\(|\)/g,'').replace(/\s/g,'-');
      acceptedOrganValues[key] = item;
    });

    Vue.set(state, 'systemOrganTypes', acceptedOrganValues);
  },
};
