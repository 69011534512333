import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { PageState } from '@/store/currentPage/types';
// import { actions } from '@/store/currentPage/actions';
import { getters } from '@/store/currentPage/getters';
import { mutations } from '@/store/currentPage/mutations';

const state: PageState = {
  currentPage: {},
};

const namespaced = true;

export const pageState: Module<PageState, RootState> = {
  namespaced,
  mutations,
  // actions,
  getters,
  state
};
