

















































import { State, Getter } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { organCodeLookup } from '@/types';
import { Organ } from '@/store/lookups/types';
import { Recipient } from '@/store/recipients/types';
import { aggregateSaveResults } from '@/utils';
import { SaveProvider, SaveResult, SaveableSection } from '@/types';
import { RecipientJourney, RecipientReferralAttributes } from '@/store/recipientJourney/types';

// for validation on organ type
import Vca from '@/components/organs/vca/Vca.vue';
import Lung from '@/components/organs/lung/Lung.vue';
import Heart from '@/components/organs/heart/Heart.vue';
import Liver from '@/components/organs/liver/Liver.vue';
import Kidney from '@/components/organs/kidney/Kidney.vue';
import SmallBowel from '@/components/organs/bowel/SmallBowel.vue';
import PancreasWhole from '@/components/organs/pancreas/PancreasWhole.vue';
import PancreasIslets from '@/components/organs/pancreas/PancreasIslets.vue';

// for view
import OrganOop from '@/components/recipientsOop/OrganOop.vue';

import PageTop from '@/components/shared/PageTop.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SideNavJourneyOop from '@/components/recipientsOop/side-nav/SideNavJourneyOop.vue';
import RecipientStickySummary from '@/components/recipients/RecipientStickySummary.vue';
import RecipientSummaryOop from '@/components/recipientsOop/RecipientSummaryOop.vue';
import deepmerge from 'deepmerge';

@Component({
  components: {
    Vca,
    Lung,
    Heart,
    Liver,
    Kidney,
    SmallBowel,
    PancreasWhole,
    PancreasIslets,
    OrganOop,
    PageTop,
    SaveToolbar,
    SideNavJourneyOop,
    RecipientSummaryOop,
    RecipientStickySummary,
  }
})
export default class EditOrgan extends Vue {
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('recipientDisplayName', { namespace: 'recipients' } ) private recipientDisplayName!: string;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;

  /**
   * Return organ display name
   *
   * @returns {string} organ name titlized
   */
  get organDescription(): string {
    return this.organComponent;
  }

  /**
   * Gets the current journey as lower case
   *
   * Using the organ code, return the lower case organ name
   *
   * @returns {string} organ as lower case
   */
  get organComponent(): string {
    return organCodeLookup[`${this.journey ? this.journey.organ_code : ''}`] || '';
  }

  // Save organ
  public performSave(): void {
    // Get the organ code from the route params
    const organCode = this.$route.params.organ_code;
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveOrgan as SaveToolbar;
    // Show appropriate notification
    saveToolbar.startSaving();
    // Refer to top level organ component
    const organComponent = this.$refs.organComponent as OrganOop;

    // Create payload for API
    const recipientId = this.recipientId;
    const journeyId = this.journeyId;
    const journeyPayload = { recipientId, journeyId, journey: {} };

    // Reset validation errors
    this.resetValidationErrors();

    // Dispatch save actions in parallel and register the responses
    this.$store.dispatch('journeyState/saveJourney', journeyPayload).then((success: SaveResult) => {
      this.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      this.registerSaveResult(error);
    });
  }

  // PRIVATE

  /**
   * Update journey when organ_id changes
   *
   * @listens $route.params.organ_id
   */
  @Watch('$route.params.organ_id')
  private updateJourney() {
    this.$store.dispatch('journeyState/getJourney', this.$route.params.organ_id);
  }

  /**
   * Gets a Boolean result for the selected journey.
   *
   * Converts the selected journey into a Boolean for easy reference.
   * A new journey returns true, an existing journey would be false.
   *
   * @returns {string} organ as lowercase string or empty string
   */
  private setPageTitle(): void {
    this.$store.commit('setPageTitle', `Out of Province Recipients / ${this.recipientDisplayName} / ${this.organDescription}`);
  }

  /**
   * Handle completion of each parallel save action, and complete saving when all actions have registered results
   *
   * @param result Save Result from a save action
   */
  private registerSaveResult(result: SaveResult): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveOrgan as SaveToolbar;
    // Register aggregate saving results
    saveToolbar.stopSaving(result);
    // If successful, reload the root record
    if (result.success) {
      // Clear the recipient
      const clientId = this.recipientId;
      this.$store.dispatch('recipients/getOop', clientId);
      // Navigate to Recipient Edit
      this.$router.push({name: 'edit-recipient-oop', params: { id: clientId } });
    } else {
      // Handle errors
      this.handleErrors(result);
    }
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult|SaveResult[]): void {
    // Clear previous errors
    (this.$refs.validations as any).reset();
    // Refer to top level organ component
    // const organComponent = this.$refs.organComponent as OrganOop;
    const organComponent = this.$refs.organComponent as OrganOop;
    // Gather validation IDs used in forms on this page
    const idLookup: {[key: string]: string} = {
      // until all organs are updated this will cause a linter error
      ...organComponent.idLookup(),
    };
    // Aggregate validation errors
    let aggregateErrors: {[key: string]: []} = {};
    errors = Array.isArray(errors) ? errors : [errors];
    errors.forEach((item) => {
      aggregateErrors = { ...item.validationErrors };
    });
    const formErrors: {[key: string]: []} = {};
    for (const key of Object.keys(aggregateErrors)) {
      formErrors[idLookup[key]] = aggregateErrors[key];
    }
    // Set all validation errors using the validation observer wrapping entire form
    (this.$refs.validations as any).setErrors(formErrors);
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
  private mounted(): void {
    if (!this.journey) {
      this.$store.dispatch('journeyState/getJourney', this.$route.params.organId).then(() => {
        this.setPageTitle();
        this.$store.dispatch('utilities/scrollBehavior');
      });
    } else {
      this.setPageTitle();
      this.$store.dispatch('utilities/scrollBehavior');
    }
  }

  // Clearing validations when saving
  private saving(formReference: string) {
    this.resetValidationErrors();
  }

  // Tell the top-level form validation observer to reset all errors
  private resetValidationErrors() {
    (this.$refs.validations as any).reset();
  }
}
