import Vue from 'vue';
import { MutationTree } from 'vuex';
import { idComparator } from '@/utils';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { RecipientsState, Recipient, RecipientOopTransplant, SignificantEventDecision, RecipientFinancialAttachment } from '@/store/recipients/types';

export const mutations: MutationTree<RecipientsState> = {
  set(state, recipient) {
    // Clone recipient data and sanitize the clone
    const sanitizedRecipient: Recipient = Object.assign({}, recipient);
   
    // Store sanitized clone
    Vue.set(state, 'selectedRecipient', sanitizedRecipient);
  },
  clearRecipient(state) {
    Vue.set(state, 'selectedRecipient', undefined);
  },
  setValidationRules(state, validations) {
    if (state.selectedRecipient !== undefined && state.selectedRecipient !== null) {
      state.selectedRecipient.validations = validations;
    }
  },
  setList(state, recipients) {
    Vue.set(state, 'recipientsList', recipients);
  },
  setMeasurements(state, measurements) {
    Vue.set(state.selectedRecipient!, 'measurements', measurements);
  },
  setDialysisProcedures(state, dialysisProcedures) {
    Vue.set(state, 'dialysis_procedures', dialysisProcedures);
  },
  setOutOfProvinceJourneys(state, outOfProvinceJourneys) {
    Vue.set(state, 'outOfProvinceJourneys', outOfProvinceJourneys);
  },
  clearOutOfProvinceJourneys(state) {
    Vue.set(state, 'outOfProvinceJourneys', undefined);
  },
  selectPreviousTransplant(state: RecipientsState, transplant: RecipientOopTransplant): void {
    Vue.set(state, 'selectedPreviousTransplant', transplant);
  },
  clearPreviousTransplant(state: RecipientsState): void {
    Vue.set(state, 'selectedPreviousTransplant', undefined);
  },
  clearDecisionEvents(state: RecipientsState): void {
    Vue.set(state, 'decisionEvents', undefined);
  },
  setDecisionEvents(state: RecipientsState, { recipientId, events }: { recipientId: string; events: SignificantEventDecision[] }): void {
    Vue.set(state, 'decisionEvents', events);
  },
  addUploadedFileToList(state, { originalId, attachment }) {
    const attachments = state.selectedRecipient?.attachments || [];
    attachments.push(attachment);
    if(state.selectedRecipient)
    Vue.set(state.selectedRecipient, 'attachments', attachments);
  },
  setRecipientDeath(state, death) {
    Vue.set(state, 'selectedDeath', death);
  },
  clearRecipientDeath(state) {
    Vue.set(state, 'selectedDeath', undefined);
  },
  removeUploadedFileFromList(state, { id }) {
    const index = state.selectedRecipient!.attachments!.findIndex( (item:any) => item._id.$oid === id );
    if (index > -1) {
      state.selectedRecipient!.attachments!.splice(index, 1);
    }
  },
  setCtrLogs(state, ctrLogs) {
    Vue.set(state, 'ctrLogs', ctrLogs);
  },
  clearCtrLogs(state) {
    Vue.set(state, 'ctrLogs', []);
  },
  setRecipientDiagnosticsHla(state, diagnosticsHla) {
    Vue.set(state, 'selectedDiagnosticsHla', diagnosticsHla);
  },
  clearRecipientDiagnosticsHla(state) {
    Vue.set(state, 'selectedDiagnosticsHla', undefined);
  },
};
