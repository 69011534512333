import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/responsiblePhysicians/actions';
import { getters } from '@/store/responsiblePhysicians/getters';
import { mutations } from '@/store/responsiblePhysicians/mutations';
import { ResponsiblePhysiciansState } from '@/store/responsiblePhysicians/types';

const state: ResponsiblePhysiciansState = {
  responsiblePhysicians: [],
  filteredResponsiblePhysicians: []
};

const namespaced = true;

export const responsiblePhysicians: Module<ResponsiblePhysiciansState, RootState> = {
  namespaced,
  mutations,
  actions,
  state,
  getters,
};
