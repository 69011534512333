import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/validations/actions';
import { getters } from '@/store/validations/getters';
import { ValidationsState } from '@/store/validations/types';
import { mutations } from '@/store/validations/mutations';

const state: ValidationsState = {
  ruleSet: undefined
};

const namespaced = true;

export const validations: Module<ValidationsState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state
};
