import { SaveResult } from '@/types';
import { ClassObject } from '@/types';
import { ObjectId } from '@/store/types';

export function idComparator(from: any, to: any): boolean {
  if (from._id && to._id) {
    return from._id.$oid === to._id.$oid;
  }
  return false;
}

// Convert potentially undefined string to a string or undefined
export function parseString(value?: any): string|undefined {
  if (!value) {
    return undefined;
  }
  return value.toString();
}

export function isMasked(value: number|boolean|string|undefined|null): boolean {
  if (!value) return false;
  return (`${value.toString() || ''}`).startsWith("***");
}

// Convert potential phone string into just the readable time portion or undefined extract methods for ui
export function parsePhoneUi(phone?: string|null): string|undefined {
  if (!phone || isMasked(phone)) {
    return undefined;
  }
  // Generate date object and format output as time string
  // We need to strip out any bad characters accidentally generated before
  const strippedPhone = phone.replace(/[^\d.]/g, "");
  // Restructure this as the new phone number
  return strippedPhone.replace(/(\d{3})(\d{3})(\d{4})/,"($1) $2-$3"); // (012) 345-6789
}

// Convert birthdate string into age number or undefined
export function calculateAge(birthDate?: string, deathDate?: string): number|undefined {
  if (!birthDate) {
    return undefined;
  }
  // Sanitize birth date - toLocaleDateString will remove the timezone difference which threw the calculation off my one day\
  const dateOptions: { [key: string]: string; } = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' };

  const birthDateTimestamp = Date.parse(new Date(birthDate).toLocaleDateString('en-US', dateOptions));
  if (isNaN(birthDateTimestamp)) {
    return undefined;
  }
  const dateOfBirth = new Date(birthDateTimestamp);
  let endDate: Date;
  if (!!deathDate) {
    // Sanitize death date
    const deathDateTimestamp = Date.parse(new Date(deathDate).toLocaleDateString('en-US', dateOptions));
    if (isNaN(deathDateTimestamp)) {
      return undefined;
    }
    endDate = new Date(deathDateTimestamp);
  } else {
    // Today assumed, because no death date provided
    endDate = new Date();
  }
  // calculate age
  let age: number;
  age = endDate.getFullYear() - Math.abs(dateOfBirth.getFullYear());
  // if birthday hasn't occured yet in the end date year, lower the age by 1
  if (endDate && endDate.getMonth() < dateOfBirth.getMonth() ||
    (endDate.getMonth() === dateOfBirth.getMonth() && endDate.getDate() < dateOfBirth.getDate())) {
    age--;
  }
  return age && age < 0 ? 0 : age;
}

// Common sorting functions for vue-good-table
export function sortByCode(codeA: number, codeB: number) {
  return Math.sign(codeA - codeB);
}

// Return an array containing only the unique elements in the input array
export function uniqueElements(arrayWithDuplicates: any[]): any[] {
  // Filter out elements whose index does not correspond to the index of the first instance of that element
  return arrayWithDuplicates.filter((element: any, index: number, array: any[]) => {
    return array.indexOf(element) === index;
  });
}

/**
 * Generate one summary save result based on multiple input results. The aggregate result is successful only if every
 * input result is successful. The aggregate error messages array contains only the unique elements in the in input
 * array. The aggregate validation errors is an object with parameters merged from every result's validation errors.
 * The aggregate response data is an object with parameters merged from every result's response data.
 */
export function aggregateSaveResults(saveResults: SaveResult[]): SaveResult {
  // Iteratively combine each result's parameters
  const aggregate: SaveResult = saveResults.reduce((previousResult: SaveResult, currentResult: SaveResult) => {
    return {
      success: previousResult.success && currentResult.success,
      errorMessages: (previousResult.errorMessages || []).concat(currentResult.errorMessages || []),
      validationErrors: Object.assign(previousResult.validationErrors || {}, currentResult.validationErrors),
      responseData: Object.assign(previousResult.responseData || {}, currentResult.responseData),
    };
  });
  // Remove duplicate array entries
  aggregate.errorMessages = uniqueElements(aggregate.errorMessages || []);
  return aggregate;
}

// Merge a static class string and a dynamic class object
export function mergeClasses(classString: string, classObject: ClassObject): ClassObject {
  const stringClasses = classString.split(' ');
  const result = Object.assign({}, classObject);
  stringClasses.forEach((className: string) => {
    result[className] = true;
  });
  return result;
}

/**
 * Converts text to title case e.g. "ASSESSMENT" and "assessment" both become "Assessment"
 * @param text mixed case text to convert to title case
 * @returns {string} title case text
 */
export function titleCase(text: string): string {
  const firstLetter = text.substring(0, 1);
  const remainingText = text.substring(1);
  return firstLetter.toUpperCase().concat(remainingText.toLowerCase());
}

/**
 * Prepends validation errors with the specified key
 *
 * @param errors object containing validation errors from API response
 * @param key identifier to be added to the beginning of each error key
 * @returns {any} identical to errors input, except keys are prefixed
 */
export function prefixErrors(errors: any, prefix: string): any {
  const errorEntries = Object.entries(errors);
  const result: { [key: string]: any } = {};
  errorEntries.forEach((entry: [string, any]) => {
    const key = entry[0];
    const value = entry[1];
    const prefixedKey = `${prefix}.${key}`;
    result[prefixedKey] = value;
  });
  return result;
}

/**
 * Generates a SaveResult response from Allocation Service errors
 *
 * @param errors any error from the allocation service
 * @returns {SaveResult} save result object
 */
export function handleAllocationErrors(errors: any): SaveResult {
  if (!errors) {
    return { success: false };
  }
  // Allocation service errors are inside errors.allocation_service
  const allocationErrors = errors.allocation_service || {};
  // Extract action errors if any exist
  const actionsWithErrors = (allocationErrors?.actions || []).filter((action: any) => {
    return action.error;
  });
  const actionErrorMessages = actionsWithErrors.map((action: any): string => {
    return `${action?.error_code || 'Error'}: ${action?.error_message || 'allocation service cannot complete action'}`;
  });
  const actionErrorSummary = actionErrorMessages.length > 0 ? actionErrorMessages.join(', ') : null;
  const allocationErrorText = allocationErrors.message || allocationErrors.error || actionErrorSummary;
  // Allocation errors will take precedence
  return buildErrorResult(allocationErrorText || errors);
}

/**
 * Generates text description of an error response from the API.
 * @param errors field-level validations by string key, or a single string message
 * @param prefix optional identifier to be added to the beginning of each field-level validation error key
 * @returns {string} unsuccessful save result with at least one error message and all field-level validations
 */
export function buildErrorResult(errors: any, prefix?: string): SaveResult {
  const errorMessages: string[] = [];
  let validationErrors: { [key: string]: string[] } = {};
  if (typeof errors === 'string' || errors instanceof String) {
    // If the errors key is set to a string, display it 'as is' as an error message
    errorMessages.push(errors as string);
  } else {
    // Otherwise, handle errors as an object with field-level validation errors
    const rawErrors = errors as { [key: string]: string[] };
    // Inject field-level validation key prefix if specified
    if (prefix !== undefined) {
      validationErrors = prefixErrors(rawErrors, prefix);
    } else {
      validationErrors = rawErrors;
    }
  }
  // Provide the top-level error message if one is found
  const rawErrorMessage = errors.message ? `${errors.message}` : null;
  if (rawErrorMessage) {
    errorMessages.push(rawErrorMessage);
  }
  // If no error messages were found, ensure there is at least one message with default text
  if (errorMessages.length === 0) {
    errorMessages.push('Cannot save: see error messages above');
  }
  // Return unsuccessful save result with any messages and validation errors foudn above
  return { success: false, errorMessages, validationErrors };
}

/**
 * Return a list filtered by keysToExclude
 *
 * Filter a given list excluding any items that have a key in keysToExclude
 *
 * @param optionItems list of items to filter
 * @param keysToExclude list of the keys to exclude when filtering
 * @returns {any[]} filtered list
 */
export function filterInternalOptions(optionItems: any[], keysToExclude: string[]) {
  if (!optionItems || optionItems.length < 0) {
    return [];
  }
  const filteredOptions = optionItems.filter((item: any) => {
    let excluded = false;
    keysToExclude.forEach((key: string) => {
      if (key in item) excluded = true;
    });
    return !excluded;
  });
  // Filter from subtables if any
  const subTableItem = filteredOptions.map((item: any) => {
    const subTableNames = item.sub_tables;
    if (subTableNames === undefined) {
      // no subtables
      return filteredOptions;
    }
    const itemWithFilteredSubtables = Object.assign({}, item);
    const subtableNames = Object.keys(subTableNames);
    subtableNames.forEach((subtableName: string) => {
      const subtableEntries = itemWithFilteredSubtables.sub_tables[subtableName];
      const filteredSubtableItems = filterInternalOptions(subtableEntries, keysToExclude);
      itemWithFilteredSubtables.sub_tables[subtableName] = filteredSubtableItems;
    });
  });
  return filteredOptions;
}

/**
 * converts a hash into a valid uri params syntax
 *
 * i.e. {a: 1, b: 2} => a=1&b=2
 *
 * @param params object to convert
 * @returns {string} string with the uri converted params
 */
export function urlParams(params: any): string {
 return Object.keys(params).map((k) => {
   return params && params[k] ? k+'='+params[k] : null;
 }).filter((p) => { return p && p.length >= 0; }).join('&');
}

// Calculate number of days between two days
export function calculateNumberOfDays(date1?: string, date2?: string): number|undefined {
 let numberOfDays: any;
 if(date1 && date2)
 {
   const firstdDate: any = date1 && Date.parse(date1);
   const seconddDate: any = date2 && Date.parse(date2);
   const diffInMs = Math.abs(seconddDate - firstdDate);
   numberOfDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
 }
  return numberOfDays;
}

export function countDecimals(val: any) {
  if (Math.floor(val.valueOf()) === val.valueOf()) return 0;
  return val.toString().split(".")[1]?.length || 0;
}

export function sanitizeArray(array: any) {
  if (!array) return [];
  // if given a string convert to an array (also remove any bracketing [] if added)
  // if given array pass on to sanitize code
  const values = (typeof array) == 'string' ? [array.replace(/[^\w\s]/gi, '')] : array;
  return values.map((item: string) => {
    return item.replace(/[^\w\s]/gi, '').trim();
  });
}

/**
 * isNull function
 * - utility method to catch "", null, undefined values
 * - why: sometimes target or value returns '' when nothing is selected
 * @param value
 */
export function isNull(value: string) {
  return value === null || value === undefined || value.length === 0 ? true : false;
}
