





































































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { SaveProvider, SaveResult } from '@/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { DialysisProcedure } from '@/store/recipients/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import SelectInput from '@/components/shared/SelectInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { KidneyDetails } from '@/store/organSpecificDetails/types';
import { RecipientPrograms } from '@/store/recipientJourney/types';
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import KidneyDialysis from '@/components/organs/kidney/KidneyDialysis.vue';
import { Recipient, RecipientDiagnostics } from '@/store/recipients/types';
import { KidneySpecificPageState } from '@/components/organs/kidney/KidneySpecificDetails.vue';
import { DialysisType, DialysisAccessMode, DialysisLocationType, DialysisLocationTypeValue } from '@/store/lookups/types';
import SaveToolbar from "@/components/shared/SaveToolbar.vue";

export interface DialysisForm {
  _id?: { $oid: string };
  dialysisType?: string;
  dialysisAccessMode?: string;
  dialysisLocation?: string;
  otherDialysisLocation?: string;
  dialysisProvider?: number|null;
  startDate?: string;
  endDate?: string;
}

interface DialysisRow {
  _id?: { $oid: string };
  dialysis_type?: { code?: string; value?: string };
  dialysis_access_mode?: { code?: string; value?: string };
  dialysis_location?: string;
  other_dialysis_location?: string;
  dialysis_provider?: { code?: number; value?: string };
  start_date?: string;
  end_date?: string;
}

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    SelectInput,
    CheckboxInput,
    SelectOtherInput,
  }
})
export default class DialysisSection extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.lookups.dialysis_type) dialysisType!: DialysisType[];
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.kidneyDetails) editState!: KidneySpecificPageState;
  @State(state => state.lookups.dialysis_access_mode) dialysisAccessMode!: DialysisAccessMode[];
  @State(state => state.lookups.dialysis_providers) dialysisProviders!: any;
  @State(state => state.lookups.dialysis_location_type) dialysisLocationLookup!: DialysisLocationType[];
  @State(state => state.organSpecificDetails.selectedDialysis) private selectedDialysis!: DialysisProcedure;
  @State(state => state.recipients.dialysis_procedures) private dialysisProcedures!: DialysisProcedure[];

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('lookupValueNumeric', { namespace: 'lookups' }) lookupValueNumeric!: (code: number, lookupId: string) => string|null;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) divider!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  /**
   * Emits a loaded event when the component has finished mounting
   *
   * @emits loaded
   */
  private mounted(): void {
    this.$emit('loaded', 'dialysisSection');
  }

  /**
   * Gets table row data for the Kidney Dialysis table
   *
   * @returns {DialysisRow[]} Kidney Dialysis table rows
   */
  get dialysisRows(): DialysisRow[] {
    if (!this.dialysisProcedures) {
      return [];
    }
    const result: DialysisRow[] = [];
    this.dialysisProcedures.forEach((dialysis: DialysisProcedure) => {
      // Determine whether to show location type value or other text
      let dialysisLocationValue: string|undefined = undefined;
      if (!!dialysis.location_type_code && dialysis.location_type_code == DialysisLocationTypeValue.Other) {
        dialysisLocationValue = dialysis.other_location_desc;
      } else {
        dialysisLocationValue = this.lookupValue(dialysis.location_type_code, 'dialysis_location_type');
      }
      const row: DialysisRow = {
        _id: dialysis._id,
        dialysis_type: {
          code: dialysis.dialysis_type_code,
          value: this.lookupValue(dialysis.dialysis_type_code, 'dialysis_type')
        },
        dialysis_access_mode: {
          code: dialysis.access_method_code,
          value: this.lookupValue(dialysis.access_method_code, 'dialysis_access_mode')
        },
        dialysis_provider: {
          code: dialysis.dialysis_provider_code ? dialysis.dialysis_provider_code : undefined,
          value: dialysis.dialysis_provider_code ? this.lookupValueNumeric(dialysis.dialysis_provider_code, 'dialysis_providers') || '-' : '-'
        },
        dialysis_location: dialysisLocationValue,
        start_date: dialysis.start_date ? this.parseDisplayDateUi(dialysis.start_date) : undefined,
        end_date: dialysis.end_date ? this.parseDisplayDateUi(dialysis.end_date) : undefined,
      };
      result.push(row);
    });
    return result;
  }

  /**
   * Gets configuration for the Kidney Dialysis table
   *
   * @returns {TableConfig} Kidney Dialysis Table configuration
   */
  get dialysisTableConfig(): TableConfig {
    return {
      data: this.dialysisRows || [],
      columns: [
        { label: this.$t('dialysis_type').toString(), field: 'dialysis_type.value' },
        { label: this.$t('dialysis_location').toString(), field: 'dialysis_location' },
        { label: this.$t('dialysis_provider').toString(), field: 'dialysis_provider.value' },
        { label: this.$t('dialysis_start_date').toString(), field: 'start_date' },
        { label: this.$t('dialysis_end_date').toString(), field: 'end_date' }
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: this.saveAllowed,
      createText: this.$t('create_dialysis').toString(),
      pagination: true,
    };
  }

  /**
   * Gets status of preEmptiveGfr
   *
   * @returns {boolean} state of preEmptiveGfr
   */
  get hasPreEmptiveGfr(): boolean {
    if (!this.editState || !this.editState.generalInfo) {
      return true;
    }
    return !!this.editState.generalInfo?.preEmptiveGfr;
  }

  /**
   * Check if we're allowed to save
   *
   * @returns {boolean} true if we can edit this Dialysis entry
   */
  get saveAllowed(): boolean {
    if (this.newJourney || this.journey.completed) return false;
    if (this.hasPreEmptiveGfr) return false;
    return true;
  }

  /**
   * Loads a form edit state based on a dialysis, or a new state if there is none
   *
   * @param dialysis Dialysis entry fetched from API, or undefined
   */
  public initializeDialysisForm(dialysis?: DialysisProcedure): void {
    // Clear table selections from subsection component
    this.$store.commit('organSpecificDetails/selectKidneyDialysis', dialysis);
    // Initialize subsection component form edit states
    this.$store.commit('pageState/set', {
      pageKey: 'kidneyDetails',
      componentKey: 'dialysis',
      value: this.buildDialysisForm(dialysis),
    });
  }

  /**
   * Return Dialysis form edit state based on supplied dialysis or empty form if none
   *
   * @param dialysis dialysis entry from recipient
   * @returns {DialysisForm} editable dialysis form state
   */
  public buildDialysisForm(dialysis?: DialysisProcedure): DialysisForm {
    if (!dialysis) {
      return {};
    }
    return {
      _id: dialysis._id,
      dialysisType: dialysis.dialysis_type_code,
      dialysisAccessMode: dialysis.access_method_code,
      dialysisLocation: dialysis.location_type_code,
      otherDialysisLocation: dialysis.other_location_desc,
      dialysisProvider: dialysis.dialysis_provider_code,
      startDate: dialysis.start_date ? this.parseDateUi(dialysis.start_date) : undefined,
      endDate: dialysis.end_date ? this.parseDateUi(dialysis.end_date) : undefined,
    };
  }

  /**
   * Builds form edit state based on selected document
   *
   * @listens ksd-new-dialysis-events#table-row-click
   * @param event selected row from the table
   */
  public selectDialysis(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (!selectedId || !this.dialysisProcedures) {
      return;
    }
    // Find the selected source document
    const found = this.dialysisProcedures.find((each: DialysisProcedure) => {
      return each._id && each._id.$oid === selectedId;
    });
    if (!found) {
      return;
    }
    // Build form state from selected dialysis
    this.initializeDialysisForm(found);
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: {[key: string]: string} = {
    // Dialysis Events (recipient Dialysis procedures)
    'access_method_code'                               : 'ksd-dialysis-access-mode',
    'dialysis_type_code'                               : 'ksd-dialysis-type',
    'location_type_code'                               : 'ksd-dialysis-location',
    'other_location_desc'                              : 'ksd-other-dialysis-location',
    'dialysis_provider_code'                           : 'ksd-dialysis-provider',
    'start_date'                                       : 'ksd-start-date',
    'end_date'                                         : 'ksd-end-date',
  };

  /**
   * PRIVATE
   */

  /**
   * Build an empty new form edit state
   */
  private createDialysis(): void {
    // Build form state
    this.initializeDialysisForm();
    this.resetValidationErrors();

  }

  /**
   * Saves current form state for Dialysis
   */
  private saveDialysis() {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveDialysis as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('saving', 'dialysis');
    // Generate payload based on current edit state
    const dialysisPayload = {
      id: !!this.selectedDialysis ? this.selectedDialysis._id : undefined,
      recipientId: this.recipientId,
      dialysis: this.extractDialysisPatch()
    };
    // Dispatch save action and register the response
    this.$store.dispatch('recipients/saveDialysis', dialysisPayload).then((success: SaveResult) => {
      /**
       * Note: saving a Dialysis Procedure can have consequences for the Recipient record itself.
       * Kidney Journeys have a waitlist factor that is automatically set based on the Procedures.
       * This can affect Wait Time calculations, because the Dialysis Start Date serves as an
       * "alternate Listing Date".
       *
       * This is why here we need to reload the recipient (including the current kidney journey),
       * and re-calculate the wait time durations.
       */
      this.$store.dispatch('recipients/get', this.recipientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
          this.$store.dispatch('journeyState/loadJourneyDurations', { recipientId: this.recipientId, journeyId: this.journeyId }).then(() => {
            this.$store.dispatch('recipients/loadDialysis', this.recipientId);
            this.initializeDialysisForm();
            saveProvider.registerSaveResult(success);
          });
        });
      });
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Returns a patch object containing changes for a Dialysis document
   *
   * For a document that does not yet exist, the object contains all data to be saved
   *
   * @returns {DialysisProcedue} changes to save
   */
  private extractDialysisPatch(): DialysisProcedure {
    if (!this.editState || !this.editState.dialysis || this.hasPreEmptiveGfr) {
      return {};
    }
    const dialysis = this.editState.dialysis;
    return {
      access_method_code: dialysis.dialysisAccessMode,
      dialysis_type_code: dialysis.dialysisType,
      location_type_code: dialysis.dialysisLocation,
      other_location_desc: dialysis.otherDialysisLocation,
      dialysis_provider_code: dialysis.dialysisProvider,
      start_date: this.sanitizeDateApi(dialysis.startDate) ? this.sanitizeDateApi(dialysis.startDate) : null,
      end_date: this.sanitizeDateApi(dialysis.endDate) ? this.sanitizeDateApi(dialysis.endDate) : null,
    };
  }

  // Tell the top-level form validation observer to reset all errors
  private resetValidationErrors() {
    const section = this.$refs.saveDialysis as SubSection;
    section.resetSaveToolbar();

    this.$emit('saving', 'dialysisSection');
  }

}
