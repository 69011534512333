import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/tasks/actions';
import { getters } from '@/store/tasks/getters';
import { mutations } from '@/store/tasks/mutations';
import { TaskState } from '@/store/tasks/types';

const state: TaskState = {
  currentTask: undefined,
};

const namespaced = true;

export const tasks: Module<TaskState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state
};
