






































































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SaveResult } from '@/types';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { RecipientDataAuditReportState, RecipientDataAuditReportQuery, recipientDataAuditReports } from '@/store/reports/types';
import { Hospital } from '@/store/hospitals/types';
import { Organ, BloodType, OrganCodeValue } from '@/store/lookups/types';
import { User } from '@/store/users/types';
import { GenericCodeValue } from '@/store/types';

@Component({
  components: {
    SaveToolbar,
    DateInput,
    SelectInput,
    SubSection
  }
})

export default class RecipientDataAuditReport extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.pageState.currentPage.recipientDataAuditReport) editState!: RecipientDataAuditReportState;
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.lookups.blood_type) bloodTypeLookup!: BloodType[];
  @State(state => state.lookups.change_types) private changeTypesLookup!: GenericCodeValue[];
  @State(state => state.users.user) user!: User;

  @Getter('getOntarioTransplantProgramsByOrgan', { namespace: 'hospitals' }) getOntarioTransplantProgramsByOrgan!: (organ_code: number) => any;

  @Prop({ default: null}) report!: string;

  private lookupsToLoad = ['change_types'];

  public mounted(): void {
    this.$store.dispatch('hospitals/load').then(() => {
      this.$store.dispatch('lookups/loadRecipientJourneyStates');
      this.$store.commit('pageState/set', {
        pageKey: 'recipientDataAuditReport',
        value: this.buildRecipientDataAuditPageState(),
      });
    });
  }

  // Default values
  public buildRecipientDataAuditPageState(): RecipientDataAuditReportState {
    return {
      fromDate: undefined,
      toDate: this.currentDateUi(),
      bloodType: [],
      changeType: [],
      hospital: [],
      organType: []
    };
  }

  /**
   * If user.hospital_organ_codes is empty then returns all given organs
   * otherwise filters the organs based on the organ codes in user.hospital_organ_codes
   *
   * @returns {Organ[]} filtered list of organs by user
   */
  get filterOrgansByUser(): { code: number; value: string; }[]{
    const filterBy: { [key: string]: any } = this.user.hospital_organ_codes || {};

    const userOrganCodes = Object.keys(filterBy).map((hospital: string) => filterBy[hospital]).flat();
    if(this.user.all_hospitals) {
      return this.organOptions;
    }

    return this.organOptions.filter((organ) => userOrganCodes.includes(organ));
  }

  /**
   * Gets list of organ codes selected (no organ codes selected is equivalent to all organ codes)
   *
   * @returns {array<number>} array of organ codes
   */
  get organCodesSelected() {
    if (this.editState.organType?.length == 0) {
      return this.defaultAllOptions(this.filterOrgansByUser);
    } else {
      return this.editState.organType;
    }
  }

   /**
   * If user.hospital_organ_codes is empty then returns all hospitals from filteredHospitals()
   * otherwise filters the hospitals based on the hospital_id keys in user.hospital_organ_codes
   *
   * @returns {Organ[]} filtered list of ontario transplant programs by user
   */
  get filteredHospitalsByUser(): Hospital[]|undefined {
    const hospitals = this.filteredHospitals || [];

    const filterBy = this.user.hospital_organ_codes || {};

    if (this.user.all_hospitals) {
      // if there are no user hospital organ codes then return all hospitals
      return hospitals;
    } else {
      // get all hospital keys from user.hospital_organ_codes and filter hospitals by the hospital_id key
      return hospitals.filter((hospital: any) => {
        return Object.keys(filterBy).includes(hospital.code);
      });
    }
  }

  /**
   * Shows all ontario transplant programs for the organ code selected in Organ Type field
   *
   * @returns {Organ[]} filtered list of ontario transplant programs
   */
  get filteredHospitals(): Hospital[]|undefined {
    return this.getOntarioTransplantProgramsByOrgan(this.organCodesSelected).map((hospital: Hospital) => {
      return {
        code: hospital._id.$oid,
        value: hospital.hospital_name_info ? hospital.hospital_name_info.name : '-'
      };
    });
  }

  /**
   * Returns an array of options for organs
   *
   * @returns {Organ[]} organ options
   */
  get organOptions(): { code: number; value: string }[] {
     return this.organLookup.filter((organ: Organ) => {
      return organ.type == 'single';
    });
  }

  // pass lookup options to get all options code
  public defaultAllOptions(options: any) {
    return options.map((option: any) => {
      return option.code;
    });
  }

  public buildRecipientDataAuditReportQuery(): RecipientDataAuditReportQuery {
    if (!this.editState) {
      return {};
    }

    let type = '';
    if (this.report == recipientDataAuditReports.Index) {
      type = 'index';
    }
    if (this.report == recipientDataAuditReports.Detail) {
      type = 'detail';
    }

    const result = {
      type: type,
      from_date: this.sanitizeDateApi(this.editState.fromDate)?.slice(0, 10) || undefined,
      to_date: this.sanitizeDateApi(this.editState.toDate)?.slice(0, 10) || undefined,
      blood_type: this.editState.bloodType?.length == 0 ? this.defaultAllOptions(this.bloodTypeLookup) : this.editState.bloodType,
      change_type: this.editState.changeType?.length == 0 ? this.defaultAllOptions(this.changeTypesLookup) : this.editState.changeType,
      organ_type: this.editState.organType?.length == 0 ? this.defaultAllOptions(this.organOptions).map(String) : this.editState.organType?.map(String),
      hospital: this.editState.hospital?.length == 0 ? this.defaultAllOptions(this.filteredHospitals) : this.editState.hospital
    };

    return result;
  }

  public generateReport(): void {
    this.clear();
    const saveToolbar = this.$refs.generateReport as SaveToolbar;
    saveToolbar.startSaving();
    // Submit query
    const recipientDataAuditQuery = this.buildRecipientDataAuditReportQuery();
    this.$store.dispatch('reports/printRecipientDataAuditReport', recipientDataAuditQuery).then((result: SaveResult) => {
      // Get the filename
      const fileName = result.responseData.report;
      // Is there actually a filename
      if (!!fileName) {
        // Create a link
        const link = document.createElement('a');
        link.href = fileName;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        // Then click it forcing a save/open dialogue
        link.click();
        // Show success notification
      }
      saveToolbar.stopSaving(result);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      saveToolbar.stopSaving(error);
    });
  }

      // API response keys on the left, id for our UI on the right
  public idLookup: {[key: string]: string} = {
    'from_date'   : 'recipientDataAuditReport-from_date',
    'to_date'     : 'recipientDataAuditReport-to_date',
    'blood_type'  : 'recipientDataAuditReport-blood_type',
    'change_type' : 'recipientDataAuditReport-change_type',
    'hospital'    : 'recipientDataAuditReport-hospital',
    'organ_type'  : 'recipientDataAuditReport-organ_code'
  }

  // Emit event to parent so it can clear validations
  private clear() {
    this.$emit('clear');
  }

}
