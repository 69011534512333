














import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import SiteHeader from './components/shared/SiteHeader.vue';
import SiteFooter from './components/shared/SiteFooter.vue';
import i18n from '@/i18n';
import { EP } from '@/api-endpoints';

@Component({
  components: {
    SiteHeader,
    SiteFooter
  }
})
export default class App extends Vue {
  @Getter('getUser', { namespace: 'users' }) private getUser!: any;
  @Getter('checkTokenExists', { namespace: 'users' }) private checkTokenExists!: boolean;

  public baseInfoLoaded = false;
  public organTypes: [] = [];

  get isLoggedIn(): boolean {
    const user = this.getUser;

    const language = localStorage.getItem('language');
    if (language) {
      i18n.locale = language;
    }

    return user && user.name ? true : false;
  }

  get isLoginPage(): boolean {
    const path = this.$route.path || '';
    return path.includes('/login') ? true : false;
  }

  /**
   * Returns query params from url as array
   *
   * @param url url string
   * @returns {string[]} returns object of key/value strings for each query paramter
   */
  getQueryParams(url: string) {
    const paramArr = url.slice(url.indexOf('?') + 1).split('&');
    const params: any = {};
    paramArr.map(param => {
        const [key, val] = param.split('=');
        params[key] = decodeURIComponent(val);
    });
    return params;
  }

  public mounted(): void {
    // get query parameters
    const queryParams = this.getQueryParams(window.location.search);
    // get access token
    const accessToken = queryParams['access_token'];
    // if token, deal with it
    if (accessToken) {
      this.$store.commit('users/setToken', accessToken);

      // after capturing the access token,
      // reload the page without any access token in the url
      if (this.checkTokenExists) {
        this.$store.dispatch('users/redirectAfterLogin');
      }
    } else {
      // load organs and feature flag on every application re-load
      if (window.location.pathname === EP.user.login) {
        this.$store.dispatch('features/load').then(() => {
          this.baseInfoLoaded = true;
        }).catch((error) => {
          this.baseInfoLoaded = true;
        });
      } else {
        this.$store.dispatch('users/loadUser').then(() => {
          Promise.all([
            this.$store.dispatch('features/load'),
            this.$store.dispatch('lookups/load', 'organ'),
            this.$store.dispatch('administration/loadEnvironment')
          ]).finally(() => {
            this.baseInfoLoaded = true;
          });
        }).catch((error) => {
          this.baseInfoLoaded = true;
        });
      }
    }
  }
}
