

























































import { Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import ModalSection from '@/components/shared/ModalSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import store from '@/store';
import i18n from '@/i18n';

interface RoleChoice {
  code?: string,
  value?: string
}

@Component({
  components: {
    ModalSection,
    SelectInput
  }
})
export default class UserMenu extends Vue {
  @Getter('getUserName', { namespace: 'users' }) private getUserName!: string;
  @Getter('checkTokenExists', { namespace: 'users' }) private checkTokenExists!: boolean;
  @Getter('getHelpUrl', { namespace: 'administration' }) private getHelpUrl!: string;

  public mounted() {
    this.$store.dispatch('users/getToken');
  }

  public preventClose(event: any): void {
    event.stopPropagation();
  }

  get isAfflo(): boolean {
    return process.env.VUE_APP_LOGO === 'AFFLO';
  }

  public setLocale(event :any): void {
    const language = event;
    if (language) {
      localStorage.setItem('language', language);
      i18n.locale = language;
    }
  }

  get langOptions(): any {
    return [
      { code: 'en' , value: this.$t('english')},
      { code: 'fr' , value: this.$t('french') }
    ];
  }

  get getCurrentLanguage(): string {
    return this.$t('language') + " " +this.langOptions.find((t: any) => t.code === this.$i18n.locale).value;
  }

  logout(): void {
    store.dispatch('users/removeToken');
    window.location.href="/login"; // redirect with page refresh to force api calls to reload
  }
}
