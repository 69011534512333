import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { CoordinatorsState, Coordinator, CoordinatorHospitalAssignments, CoordinatorOptions } from '@/store/coordinators/types';

// Map from a Coordinator document to a standardized dropdown option
function coordinatorOption(coordinator: Coordinator): CoordinatorOptions {
  const hospital_assignments = coordinator.hospital_assignments ? coordinator.hospital_assignments.map((item: CoordinatorHospitalAssignments) => { return item.hospital_id.$oid; }) : [];
  return {
    code: coordinator._id.$oid,
    value: `${coordinator.first_name} ${coordinator.last_name}`,
    hospital_assignments: hospital_assignments,
    expiry_date: coordinator.expiry_date || undefined,
  };
}

export const getters: GetterTree<CoordinatorsState, RootState> = {
  // Options for the per-hospital 'filtered' list of Coordinators used throughout a page
  coordinatorOptions(state, getters, rootState, rootGetters): CoordinatorOptions[] {
    const coordinators: Coordinator[] = state.filtered;
    if (coordinators && coordinators.length > 0) {
      let coordinatorsList = [];

      coordinatorsList = coordinators.map((coordinator: Coordinator) => {
        return coordinatorOption(coordinator);
      });
      return coordinatorsList;
    }
    return [];
  },
  // Options for Post-Transplant Follow-Up Transfer Coordinators (may be different than 'filtered')
  followUpCoordinatorOptions(state, getters, rootState, rootGetters): CoordinatorOptions[] {
    if (!state.followUpCoordinators || state.followUpCoordinators.length === 0) return [];

    const coordinators = state.followUpCoordinators.map((coordinator): CoordinatorOptions => {
      return coordinatorOption(coordinator);
    });
    return coordinators;
  },
};
