












import { Component, Vue, Model, Prop } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class ToggleButton extends Vue {
  // V-model
  @Model('change') value!: string;

  // Props
  @Prop({ required: true }) toggleLabel?: string; // aria label and hover title when not yet toggled
  @Prop({ required: true }) untoggleLabel?: string; // aria label and hover title when not yet toggled


  // Event handlers
  private clicked(event: any): void {
    this.$emit('change', !this.value);
  }
}
