import { countDecimals, sanitizeArray, isNull } from './utils';
import { extend, configure } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import i18n from '@/i18n';

/**
 * Define default message for pre-built rules
 */

configure({
  // this will be used to generate messages.
  defaultMessage: (field: string, values: any) => {
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.tc(`validation.messages.${values._rule_}`, values);
  }
});

extend('required', {
  ...rules.required,
  // the values param is the placeholders values
  message: (_, values) => i18n.tc('validation.messages.not_blank') // ported
});

extend('integer', {
  validate(value) {
    const val = parseInt(value);
    return isNaN(val) ? false : true;
  },
  // the values param is the placeholders values
  message: (_, values) => i18n.tc('validation.messages.integer') // ported
});

extend('numeric', {
  ...rules.required,
  // the values param is the placeholders values
  message: (_, values) => i18n.tc('validation.messages.integer') // ported
});

extend('regex', {
  ...rules.required,
  // the values param is the placeholders values
  message: (_, values) => i18n.tc('validation.messages.regex') // translations added
});

extend('min_value', {
  ...rules.required,
  // the values param is the placeholders values
  message: (_, values) => i18n.tc('validation.messages.min_limit') // ported
});

extend('max_value', {
  ...rules.required,
  // the values param is the placeholders values
  message: (_, values) => i18n.tc('validation.messages.max_limit') // ported
});

/**
 * must_be_checked
 * @param none
 * @returns {boolean} true if equals true
 */
extend('must_be_checked', {
  validate(value) {
    return value === true;
  },
  message: "Must be checked"
});

/**
 * IntegerArray
 * @param value
 * @returns {boolean} true if array of integers
 */
extend('IntegerArray', {
  validate(value) {
    return !value.some(isNaN);
  },
  message: "{_field_} must be an array of integers"
});

/**
 * float, must be a float
 * @param none
 * @returns {boolean} true if value boolean
 */
extend('float', {
  validate(value) {
    const val = parseFloat(value);
    return isNaN(val) ? false : true;
  },
  message: "Must be a numeric value"
});

/**
 * max_length, length must be lower or equal limit
 * @param length
 * @returns {boolean} true if value boolean
 */
extend('max_length', {
  params: ['limit'],
  validate(value, { limit }: any) {
    return value.length <= Number(limit) ? true : false;
  },
  message: "Cannot be after {limit}"
});

/**
 * value_between, must have decimal places
 * @param low, high
 * @returns {boolean} true if value boolean
 */
extend('value_between', {
  params: ['low', 'high'],
  validate(value, { low, high }: any) {
    return value >= parseFloat(low) && value <= parseFloat(high) ? true : false;
  },
  message: "Must be between {low} and {high}"
});

/**
 * decimal_places, allow upto x decimal places
 * @param places
 * @returns {boolean} true if value boolean
 */
extend('decimal_places', {
  params: ['places'],
  validate(value, { places }: any) {
    const hasPlaces = countDecimals(value);
    return hasPlaces <= parseInt(places) ? true : false;
  },
  message: "Must be a number with {places} decimal places"
});

/**
 * not_blank, value must not be blank 
 * @param none
 * @returns {boolean} true if value boolean
 */
extend('not_blank', {
  validate(value) {
    return value !== '' && value !== undefined;
  },
  message: "Must be checked"
});

/**
 * computed_required, value must not be blank 
 * @param none
 * @returns {boolean} true if value boolean
 */
extend('computed_required', {
  validate(value) {
    const valid = value !== '' && value !== undefined;
    const required = true;
    return { required, valid };
  },
  message: "This value is required",
  computesRequired: true
});

/**
 * permitted_only_when_true, validation against boolean 
 * @param target (field)
 * @returns {boolean} true unless target true
 */
extend('permitted_only_when_true', {
  params: ['target'],
  // shows message based on validate returning false
  validate(value, { target }: any) {
    const valid = !target && !value ? false : true;
    const required = true;
    return { required, valid };
  },
  message: 'permitted only when {target} is true',
  computesRequired: true
});

/**
 * filled_only_when_true, validation against boolean 
 * @param target (field)
 * @returns {boolean} true unless target true
 */
extend('filled_only_when_true', {
  params: ['target'],
  // shows message based on validate returning false
  validate(value, { target }: any) {
    const valid = (target && value) || (!target && !value) ? false : true;
    const required = true;
    return { required, valid };
  },
  message: 'only allowed when {target} is true',
  computesRequired: true
});

/**
 * blank_if_value, validation against boolean 
 * @param trigger_field, trigger_value
 * @returns {boolean} true unless target true
 */
extend('blank_if_value', {
  params: ['trigger_field', 'trigger_value'],
  // shows message based on validate returning false
  validate(value, { trigger_field, trigger_value }: any) {
    const trigger_matched = (!trigger_field && trigger_field == trigger_value);
    const valid = !value && trigger_matched ? false : true;
    const required = trigger_field ? true : false;
    return { required, valid };
  },
  message: 'blank if value {trigger_field}, {trigger_value}',
  computesRequired: true
});

/**
 * blank_unless_value, validation against boolean 
 * @param trigger_field, trigger_value
 * @returns {boolean} true unless target true
 */
extend('blank_unless_value', {
  params: ['trigger_field', 'trigger_value'],
  // shows message based on validate returning false
  validate(value, { trigger_field, trigger_value }: any) {
    const trigger_matched = !trigger_field && trigger_field == trigger_value;
    const valid = !value && !trigger_matched ? false : true;
    return valid;
  },
  message: 'blank unless value {trigger_field}, {trigger_value}'
});

/**
 * blank_unless_includes, validation against array
 * @param target, choices
 * @returns {boolean} true, valid if blank unless target included in choices list
 */
extend('blank_unless_includes', {
  params: ['target', 'choices'],
  validate(value, { target, choices }: any) {
    const sanitizedChoices = sanitizeArray(choices);
    const valid = !value && !sanitizedChoices.includes(target) || value;
    return { required: valid, valid: valid };
  },
  message: 'blank unless {target}, {choices}',
  computesRequired: true
});

/**
 * required_if_includes, validation against array
 * @param target, choices
 * @returns {boolean} true unless target & possible choices
 */
extend('required_if_includes', {
  params: ['target', 'choices'],
  // shows message based on validate returning false
  validate(value, { target, choices }: any) {
    const sanitizedChoices = sanitizeArray(choices);
    const valid = isNull(value) && sanitizedChoices.includes(target) ? false : true;
    return { required: valid, valid: valid };
  },
  message: 'This value is required',
  computesRequired: true
});

/**
 * required_if_value, validation against boolean 
 * @param trigger_field, trigger_value
 * @returns {boolean} true unless target true
 */
extend('required_if_value', {
  params: ['trigger_field', 'trigger_value'],
  // shows message based on validate returning false
  validate(value, { trigger_field, trigger_value }: any) {
    const trigger_matched = !trigger_field && trigger_field == trigger_value;
    const valid = value && trigger_matched ? false : true;
    const required = trigger_field ? true : false;
    return { required, valid };
  },
  message: 'This value is required',
  computesRequired: true
});

/**
 * required_if_has_value, value required if trigger_field has trigger_value
 * @param trigger_field
 * @param trigger_value
 * @returns {boolean}
 */
extend('required_if_has_value', {
  params: ['trigger_field', 'trigger_value'],
  // shows message based on validate returning false
  validate(value, { trigger_field, trigger_value }: any) {
    const trigger_matched = trigger_field && trigger_field == trigger_value;
    const valid = isNull(value) && trigger_matched ? false : true;
    const required = trigger_matched && isNull(value) ? true : false;
    return { required, valid };
  },
  message: 'This value is required',
  computesRequired: true
});

/**
 * boolean, validation against boolean 
 * @param none
 * @returns {boolean} true if value boolean
 */
extend('boolean', {
  validate(value, args: any) {
    return (typeof value === "boolean") || (value == 0 || value == 1) ? true : false;
  },
  message: '{_field_} must be true or false'
});

/**
 * required_if, valid is target is true and has value / target is false 
 * @param target (field)
 * @returns {boolean} true unless target true
 */
extend('required_if', {
  params: ['target'],
  // shows message based on validate returning false
  validate(value, { target }: any) {
    const valid = ((value !== null && value !== undefined && value >= 0) ? value.toString() : value) && (target == true) || target == false;
    const required = target ? true : false;
    return { required, valid };
  },
  message: 'This value is required',
  computesRequired: true
});

/**
 * required_if_filled, field is required if target has value  
 * @param target (field)
 * @returns {boolean} true unless target true
 */
extend('required_if_filled', {
  params: ['target'],
  // shows message based on validate returning false
  validate(value, { target }: any) {
    const valid = !target || target && value ? true : false;
    const required = target ? true : false;
    return { required, valid };
  },
  message: 'This value is required',
  computesRequired: true
});

/**
 * required_unless_filled, field is required if target has no value  
 * @param target (field)
 * @returns {boolean} true unless target true
 */
extend('required_unless_filled', {
  params: ['target'],
  // shows message based on validate returning false
  validate(value, { target }: any) {
    const valid = (isNull(value) && target) || isNull(value) && isNull(target) ? false : true;
    const required = target ? true : false;
    return { required, valid };
  },
  message: 'This value is required',
  computesRequired: true
});

/**
 * required_unless, valid if target is false and has value / target is true
 * @param target (field)
 * @returns {boolean} true unless target false
 */
extend('required_unless', {
  params: ['target'],
  validate(value, { target }: any) {
    const valid = value || target && !value;
    const required = target ? false : true;
    return { required, valid };
  },
  message: 'This value is required',
  computesRequired: true
});

/**
 * date_year_min, validates year against minimum value
 * @param limit (minimum year value)
 * @returns {boolean} true if above minimum year
 */
extend('date_year_min', {
  params: ['limit'],
  validate(value, { limit }: any) {
    const dateObj = new Date(value);
    const year = dateObj.getFullYear();
    return year > limit;
  },
  message: 'Year cannot be before {limit}'
});

/**
 * date_year_max, validates year against maximum value 
 * @param limit (maximum year value)
 * @returns {boolean} true if below maximum year
 */
extend('date_year_max', {
  params: ['limit'],
  validate(value, { limit }: any) {
    const dateObj = new Date(value);
    const year = dateObj.getFullYear();
    return year < limit;
  },
  message: 'Year cannot be after {limit}'
});

/**
 * datetime, validates against valid datetime, allows optional
 * @param none
 * @returns {boolean} true if valid date time 
 */
extend('datetime', {
  validate(value, args: any) {
    const timestamp = isNaN(Date.parse(value));
    const valid = value && timestamp ? false : true;
    const required = false;
    return { required, valid };
  },
  message: 'Must be a valid date and time',
  computesRequired: true
});

/**
 * in_lookup, valid if value is in lookup
 * @param lookup
 * @returns {boolean}
 */ 
extend('in_lookup', {
  params: ['lookup'],
  validate(value, { lookup }: any) {
    return (lookup.includes(value) || !value) ? true : false;
 },
  message: '{value} is no longer an acceptable value: select another option'
});

/**
 * in_lookups, valid if value is in lookup array
 * @param lookup
 * @returns {boolean}
 */ 
extend('in_lookups', {
  params: ['lookup'],
  validate(value, { lookup }: any) {
    return (lookup.includes(value) || !value) ? true : false;
 },
  message: '{value} is no longer an acceptable value: select another option'
});

/**
 * only_if_lookup_other, valid if target is other and has value
 * @param target, lookup
 * @returns {boolean}
 */ 
extend('only_if_lookup_other', {
  params: ['target', 'lookup'],
  validate(value, { target, lookup }: any) {
    const valid = value && (target == lookup);
    const required = true;
    return { required, valid };
  },
  message: 'This value is required',
  computesRequired: true
});

/**
 * unless_lookup_other, invalid if target is other and has value
 * @param target, lookup
 * @returns {boolean}
 */ 
extend('unless_lookup_other', {
  params: ['target', 'lookup'],
  validate(value, { target, lookup }: any) {
    const valid = !(value && (target == lookup));
    const required = true;
    return { required, valid };
  },
  message: '{_field_} is not valid',
  computesRequired: true
});

/**
 * min_limit, validates value against min limit
 * @param limit (minimum value)
 * @returns {boolean} true if below minimum value
 */
extend('min_limit', {
  params: ['limit'],
  validate(value, { limit }: any) {
    return value >= limit;
  },
  message: 'Cannot be before {limit}'
});

/**
 * max_limit, validates value against max limit
 * @param limit (maximum value)
 * @returns {boolean} true if above maximum value
 */
extend('max_limit', {
  params: ['limit'],
  validate(value, { limit }: any) {
    return value <= limit;
  },
  message: 'Cannot be after {limit}'
});

/**
 * object_identifier, validates value against object identifier type
 * @param object
 * @returns {boolean} true if object identifier
 */
extend('object_identifier', {
  params: ['object'],
  validate(object) {
    return object.match(/^[0-9a-fA-F]{24}$/);
  },
  message: '{_field_} is not valid'
});

/**
 * must_be_value_if, valid if property is in included values only when target field is true
 * @param target
 * @param included_values
 * @returns {boolean} true if value included, or if target false
 */
extend('must_be_value_if', {
  params: ['target', 'included_values'],
  // shows message based on validate returning false
  validate(value, { target, included_values }: any) {
    const included = included_values.includes(value);
    const valid = !target || included;
    return valid;
  },
  message: 'Must be {included_values} if {target}'
});

/**
 * cannot_be_value_if, valid if property does not match excluded values only when target field is true
 * @param target
 * @param excluded_values
 * @returns {boolean} true if value not excluded, or if target false
 */
extend('cannot_be_value_if', {
  params: ['target', 'excluded_values'],
  // shows message based on validate returning false
  validate(value, { target, excluded_values }: any) {
    const excluded = excluded_values.includes(value);
    const valid = !target || !excluded;
    return valid;
  },
  message: 'Cannot be {excluded_values} if {target}'
});

/**
 * server side validation only
 * @returns {boolean} true 
 */
extend('required_with_exemptions', {
  validate(){
    return true;
  }
});

/**
 * server side validation only
 * @returns {boolean} true 
 */
 extend('in_nested_lookup', {
  validate(){
    return true;
  }
});

/**
 * field must not contain any tags with ti-invalid class
 * 
 * note: intended to be used with vue-tags-input
 * 
 * @returns {boolean} true only if no invalid tags
 */
extend('no_invalid_tags', {
  params: ['tags'],
  validate(value, { tags }: any) {    
    const invalidTags = (tags || []).filter((tag: { text: string; tiClasses: string[] }) => {
      const tiClasses = tag.tiClasses || [];
      return tiClasses.includes('ti-invalid');
    });
    const result = invalidTags.length === 0;
    return result;
  },
  message: 'Invalid {_field_} specified',
  /**
   * Note: we must tell vee validate that this rule
   * computes required, because the variable provided
   * as the 'value' is the text input for new tags. In
   * other words, the 'value' will be blank even when
   * entries have been entered into the 'tags' variable.
   */
  computesRequired: true,
});

/**
 * required rule converted to work with vue-tags
 * 
 * note: intended to be used with vue-tags-input
 * 
 * @returns {boolean} true if tags contains no items
 */
 extend('required_tags', {
  params: ['tags'],
  validate(value, { tags }: any) {
    const result = tags.length > 0;
    return result;
  },
  message: 'This value is required',
  /**
   * Note: we must tell vee validate that this rule
   * computes required, because the variable provided
   * as the 'value' is the text input for new tags. In
   * other words, the 'value' will be blank even when
   * entries have been entered into the 'tags' variable.
   */
  computesRequired: true,
});

/**
 * field must have at least one tag
 * 
 * note: intended to be used with vue-tags-input
 * 
 * @returns {boolean} true only if there are tags
 */
extend('requires_tags', {
  params: ['tags'],
  validate(value, { tags }: any) {
    return (tags || []).length > 0;
  },
  message: 'This value is required',
  computesRequired: true, // needed because the input value will be blank
});
