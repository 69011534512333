






















































































import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { GenericCodeValue, ObjectId } from '@/store/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { CoordinatorOptions } from '@/store/coordinators/types';
import { Organ, OrganSpecification, OrganCodeValue } from '@/store/lookups/types';
import { Hospital, ACTIVE_ONTARIO_TRANSPLANT_PROGRAM } from '@/store/hospitals/types';
import { RecipientAddress, Recipient, RecipientProfileDetails } from '@/store/recipients/types';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { RecipientJourney, RecipientDonorAcceptability, ReferredWithOption } from '@/store/recipientJourney/types';

interface ReferralDetailsForm {
  urgent: boolean;
  transplant: {
    transplantProgram?: string;
    recipientCoordinator?: string;
  };
  referralPackage: {
    referredWith: number|null,
  };
}

@Component({
  components: {
    DateInput,
    SubSection,
    SaveToolbar,
    CardSection,
    SelectInput,
    CheckboxInput,
  }
})
export default class OrganDetailsSection extends Vue implements SaveableSection {
  // State
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.organDetails) editState!: ReferralDetailsForm;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('isReferredWith', { namespace: 'journeyState' }) isReferredWith!: boolean;
  @Getter('getUserHospitalIds', { namespace: 'users' }) getUserHospitalIds!: string[];
  @Getter('referredWithOptions', { namespace: 'journeyState' }) referredWithOptions!: ReferredWithOption[];
  @Getter('organName', { namespace: 'lookups' }) describeOrganName!: (organCode?: number) => string|undefined;
  @Getter('ontarioTransplantOptionsByOrgan', { namespace: 'hospitals' }) hospitalOptions!: (organCode?: number) => GenericCodeValue[];
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('coordinatorOptions', { namespace: 'coordinators' }) coordinatorOptions!: CoordinatorOptions[]; 
  @Getter('filterByHospitalIds', { namespace: 'journeyState', }) filterByHospitalIds!: (journeys: RecipientJourney[], hospitalIds: string[]) => RecipientJourney[];
  @Getter('getDefaultDonorCriteria', { namespace: 'hospitals' }) defaultDonorCriteria!: (hospitalId: string|undefined, organCode: string) => RecipientDonorAcceptability|undefined;
  @Getter('isUrgentListingLocked', { namespace: 'journeyState' }) isUrgentListingLocked!: boolean;
  @Getter('filterByCancellationStatus', { namespace: 'journeyState' }) filterByCancellationStatus!: (journeys: RecipientJourney[], cancellationStatus: boolean) => RecipientJourney[];

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  /**
   * Return true if organ details section section can be edited
   * 
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney) {
      return true;
    }
    if (this.journey.completed) {
      return false;
    }
    return true;
  }

  /**
   * Return text for the save button
   * 
   * @returns {string} text of the save button
   */
  get saveButtonText(): string {
    const organName = this.organName || undefined;
    let humanOrganName = "";
    
    if (organName !== undefined) {
      switch(organName.toLowerCase()) {
        case ("vca"): {
          humanOrganName = this.$t('vca').toString();
          break;
        }
        case ("small bowel"): {
          humanOrganName = this.$t('small_bowel').toString();
          break;
        }
        default: {
          humanOrganName = this.organName;
        }
      }
    }
   
    return this.newJourney ? `${this.$t('proceed_with')} ${humanOrganName} ${this.$t('referral')}` : this.$t('save_referral_details').toString();
  }

  /**
   * Return titlized organ name from lookup value
   * 
   * @returns {string} organ name
   */
  get organName(): string {
    if (this.newJourney) {
      const organ = this.lookupValue(this.organCode, 'organ');
      return organ ? organ : '';
    }
    if (this.journey && this.journey.organ_code) {
      const organ = this.lookupValue(this.journey.organ_code.toString(), 'organ');
      return organ ? organ : '';
    }
    return '';
  }

  /**
   * Get a string representation the organ_code
   * 
   * @returns {string} organ_code as a string
   */
  get organCode(): string {
    if (this.newJourney) {
      return this.$route.params.organ_code.toString();
    }
    return this.journey.organ_code ? this.journey.organ_code.toString() : '';
  }

  // Should the Referred With field be locked?
  get isReferredWithDisabled(): boolean {
    return this.isReferredWith;
  }

  // Text displayed in Referred With dropdown
  get referralPackageDescription(): string|null {
    if (!this.referredWithOptions || !this.editState || !this.editState.referralPackage) return null;

    const selected = this.referredWithOptions.find((option: ReferredWithOption) => {
      return option.code == this.editState.referralPackage.referredWith;
    });

    return selected?.value || null;
  }

  // Text for secondary confirmation prompt if applicable
  get confirmationText(): string|undefined {
    if (!this.editState || !this.editState.referralPackage) return undefined;

    // Prompt applies only if not yet Referred With but an option is about to be saved
    if (this.isReferredWith || !this.editState.referralPackage.referredWith) return undefined;

    // Add current organ name to popup
    const referralPackage = [this.organName, this.referralPackageDescription].join(' / ');
    return this.$t('referred_with_confirmation', { referralPackage }).toString();
  }

  // Given a hospital, load all coordinators for that hospital
  public filterCoordinators(hospitalId: string) {
    if (hospitalId) {
      this.$store.dispatch('coordinators/load', { hospitalId });
    } else {
      // reset coordinator if no transplant program is selected
      this.editState.transplant.recipientCoordinator = undefined;
    }
  }

  /**
   * Populates the Referral Details form state with data from the selected journey
   */
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'organDetails',
      value: this.buildReferralDetailsForm()
    });
  }

  /**
   * Gets changes from the edit state as a patch for the journey
   * 
   * If the edit state doesn't exist return an empty object
   * 
   * @returns {any} object containing field changes
   */
  public extractPatch(): RecipientJourney {
    if (this.editState && this.editState.transplant) {
      return this.extractReferralDetailsPatch(this.editState);
    } else {
      return {};
    }
  }

  // PRIVATE

  /**
   * Emits a loaded event after all lookup tables are loaded
   * 
   * @listens rd#loaded
   * @emits loaded
   */
  private loaded(): void {
    this.$store.dispatch('hospitals/load', ACTIVE_ONTARIO_TRANSPLANT_PROGRAM).then(() => {
      this.initializeForm();
    });
    this.$emit('loaded', 'organDetailsSection');
  }

  /**
   * Generates Referral Details form state based on the selected journey
   *
   * @returns {ReferralDetailsForm} Referral Details form state
   */
  private buildReferralDetailsForm(): ReferralDetailsForm {
    if (!this.journey) {
      return {
        urgent: false, // All new journeys have urgent false by default
        transplant: {},
        referralPackage: {
          referredWith: null,
        },
      };
    }
    const transplantProgram = this.journey.transplant_program || {};
    const hospitalId = transplantProgram.transplant_hospital_id ? transplantProgram.transplant_hospital_id.$oid : undefined;
    const coordinatorId = transplantProgram.transplant_coordinator_id ? transplantProgram.transplant_coordinator_id.$oid : undefined;
    const organSpecificDetails = this.journey.organ_specific_details ? this.journey.organ_specific_details : {};

    // Load recipient coordinators
    this.$store.dispatch('coordinators/load', { hospitalId });
    
    return {
      urgent: !!this.journey.urgent,
      transplant: {
        transplantProgram: hospitalId,
        recipientCoordinator: coordinatorId,
      },
      referralPackage: {
        referredWith: this.isReferredWith ? this.journey.stage_attributes?.referral?.referral_number || null : null,
      },
    };
  }

  /**
   * Returns a journey patch object containing changes from a Referral Details form
   * 
   * @returns {RecipientJourney}
   */
  private extractReferralDetailsPatch(organDetails: ReferralDetailsForm): RecipientJourney {
    const transplant = organDetails.transplant || {};
    if (!this.journey) {
      const result: RecipientJourney = {
        urgent: organDetails.urgent,
        organ_code: this.organCode ? Number(this.organCode) : undefined,
        transplant_program: {
          transplant_hospital_id: (transplant.transplantProgram ? { $oid: transplant.transplantProgram } : undefined),
          transplant_coordinator_id: (transplant.recipientCoordinator ? { $oid: transplant.recipientCoordinator } : undefined),
        },
        referred_with_referral_number: organDetails?.referralPackage?.referredWith || null,
      };
      // DIAG: Some hospitals have specific donor acceptability criteria defaults needed on journey create only
      const donorAcceptability = this.defaultDonorCriteria(this.editState.transplant.transplantProgram, this.organCode);
      // Add default donor_acceptability criteria if any
      if (donorAcceptability) {
        result.donor_acceptability = donorAcceptability;
      }
      return result;
    }
    return {
      urgent: organDetails.urgent,
      transplant_program: {
        transplant_hospital_id: (transplant.transplantProgram ? { $oid: transplant.transplantProgram } : null ),
        transplant_coordinator_id: (transplant.recipientCoordinator ? { $oid: transplant.recipientCoordinator } : null ),
      },
      referred_with_referral_number: organDetails?.referralPackage?.referredWith || null,
    };
  }

  /**
   * Saves the form edit state.
   * 
   * Prepares a payload for transplant details, recipient coordinator and urgent.
   * Dispatches a save action, and registers the save result.
   *
   * @emits save
   */
  public savePatch(): void {
    // Report to parent that saving has began
    this.$emit('save', 'organDetails');
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.organDetails as unknown as SaveProvider;
    // Generate payload from the current editState
    const payload = {
      recipientId: this.recipientId,
      journeyId: this.journeyId,
      journey: this.extractPatch(),
    };
    // Attempt to save
    this.$store.dispatch('journeyState/saveJourney', payload).then((success: SaveResult) => {
      // For new organs, we must fetch the new ID from the successful response data
      const journeyId = this.newJourney ? success.responseData.journey._id.$oid : this.journeyId;
      // If successful then reload recipient to get updated journey details
      this.$store.dispatch('recipients/get', this.recipientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', journeyId).then(() => {
          // For new organs, navigate to the edit organ page
          if (this.newJourney) {
            // navigate to edit organ page
            this.$router.push({name: 'edit-organ', params: { organ_id: journeyId }});
          } else {
            // Re-initialize if viewing an existing journey
            this.initializeForm();
            // If organ is liver then reload exception diseases (adds liver/kidney exception disease)
            if (this.organCode == OrganCodeValue.Liver.toString()) {
              this.$emit('reloadDiseasesSection');
            }
            // Register save result
            saveProvider.registerSaveResult(success);
            // Clear any errors
            this.$emit('clear');
            // Referred With can affect Referral ID, so we need to reload the Referral section here
            this.$emit('reloadReferralSection');
          }
        });
      });
    }).catch((error: SaveResult) => {
      this.$emit('handleErrors', error);
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Resets the save toolbar
   */
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = this.$refs.organDetails as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: {[key: string]: string} = {
    'transplant_program.transplant_hospital_id'    : 'rd-transplant-program',
    'transplant_program.transplant_coordinator_id' : 'rd-transplant-coordinator',
    'referred_with_referral_number'                : 'referral-package-referred-with',
  };
}
