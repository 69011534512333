




































import { State, Getter } from 'vuex-class';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import UserLinks from '@/components/administration/side-nav/UserLinks.vue';

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    UserLinks
  }
})
export default class SideNavSubscriptions extends Vue {
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('donorCanAccessCtrLog', { namespace: 'users' }) private donorCanAccessCtrLog!: boolean;

  public sideNavClosed = false;

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }
}
