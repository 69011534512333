import Vue from 'vue';
import { MutationTree } from 'vuex';
import { ObjectId } from '@/store/types';
import { errorType, TaskState, TaskType } from '@/store/tasks/types';

export const mutations: MutationTree<TaskState> = {
  setCurrentTask(state: TaskState, taskState: TaskType) {
    Vue.set(state, 'currentTask', taskState);
  },
  setPercentage(state: TaskState, percentage: number) {
    Vue.set(state, 'percentage', percentage);
  },
  setStatus(state: TaskState, status: string|undefined) {
    Vue.set(state, 'status', status);
  },
  resetCurrentTask(state: TaskState, taskState: TaskType) {
    Vue.set(state, 'currentTask', undefined);
  },
  setErrorState(state: TaskState, error: errorType) {
    Vue.set(state, 'errorState', error);
  },
  resetTask(state: TaskState) {
    [
      'currentTask',
      'percentage',
      'status',
      'errorState'
    ].forEach((key: string) => Vue.set(state, key, undefined));
  }
};
