

































































import { Getter, State } from 'vuex-class';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import ModalSection from '@/components/shared/ModalSection.vue';
import { discontinueAllocationReason, discontinueAllocationLookupType } from '@/store/lookups/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import { Allocation, Allocations, AllocationRecipient } from '@/store/allocations/types';

@Component({
  components: {
    ModalSection,
    SelectInput
  }
})
export default class DiscontinueOrgansModal extends Vue {
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;
  @State(state => state.allocations.isLoadingAllocation) private isLoadingAllocation!: boolean;
  @State(state => state.allocations.isDiscontinuingAllAllocations) private isDiscontinuingAllAllocations!: boolean;
  @State(state => state.allocations.isDiscontinuingOneAllocation) private isDiscontinuingOneAllocation!: boolean;
  @State(state => state.pageState.currentPage.discontinueAllocationState) private editState!: any;
  @State(state => state.allocations.selected) private allocation!: Allocation;

  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: string;
  @Getter('getDiscontinueAllocationReasons', { namespace: 'lookups' }) private getDiscontinueAllocationReasons!: (type: string) => discontinueAllocationReason[];

  @Prop({ required: true }) discontinueType!: string; // MANDATORY discontinue lookup type

  public modalErrorMessage = '';

  /**
   * Return the organ code
   *
   * Get the organ_code param from the url.
   *
   * @returns {string} organ code
   */
  get organCode(): string {
    return this.$route.params?.organ_code?.toString() || '';
  }

  // Toggle modal
  public openModal(): void {
    const targetModal = this.$refs.discontinueOrgansModal as ModalSection;
    targetModal.toggleStaticModal();
  }

  // Close modal
  private closeModal(): void {
    const targetModal = this.$refs.discontinueOrgansModal as ModalSection;
    targetModal.hideModal();
  }

  /**
   * Populates state with reason state
   */
  public initialize(): void {
    // clear any error message
    this.modalErrorMessage = '';
    // build state from valid ids
    this.$store.commit('pageState/set', {
      pageKey: 'discontinueAllocationState',
      value: { 
        reason: null,
        discontinueReasons: this.getDiscontinueAllocationReasons(this.discontinueType),
        modalType: this.discontinueType
      }
    });
    // reset the form
    (this.$refs.discontinueValidations as any).reset();
    // open modal
    this.openModal();
  }

  // PRIVATE

  private performDiscontinue(): void {
    switch (this.editState.modalType) {
      case discontinueAllocationLookupType.DonorRelated:
        this.discontinueOrgans();
        break;
      case discontinueAllocationLookupType.OfferRelated:
        this.discontinueAllocation();
        break;
      default:
        this.discontinueAllocation();
        break;
    }
  }

  /**
   * Attempt to discontinue all organs
   */
  private discontinueOrgans(): void {
    // Create a payload for discontinuing
    const payload = {
      clientId: this.clientId,
      reasonId: this.editState.reason
    };
    // Attempt to discontinue the all Organs
    this.$store.dispatch('allocations/discontinueOrgans', payload).then((success: any) => {
      this.closeModal();
    }).catch((error: any) => {
      this.modalErrorMessage = error;
    });
  }

  // Discontinue allocation and cancel/withdraw all existing offers
  private discontinueAllocation(): void {
    // Create a payload for discontinuing
    const payload = {
      clientId: this.clientId,
      organCode: this.organCode,
      allocationId: this.allocation._id,
      reasonId: this.editState.reason
    };
    // Attempt to discontinue the Allocation
    this.$store.dispatch('allocations/discontinueAllocation', payload).then((success: any) => {
      this.closeModal();
    }).catch((error: any) => {
      this.modalErrorMessage = error;
    });
  }



}
