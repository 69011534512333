import { MutationTree } from 'vuex';
import { LookupsState } from '@/store/lookups/types';
import Vue from 'vue';

export const mutations: MutationTree<LookupsState> = {
  set(state, opts) {
    Vue.set(state, opts.id, opts.value);
  },
  loadSubTables(state, opts) {
    Vue.set(state, opts.id, opts.value);
  },
};