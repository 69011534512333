import axios from 'axios';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { AdministrationState, CtrLogItem } from '@/store/administration/types';

export const actions: ActionTree<AdministrationState, RootState> = {
  loadEnvironment({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Prepare request
      const url = APIRoute(EP.environment);
      // Dispatch request
      axios.get(url).then((response: any) => {
        // Process successful response
        const responseData = response.data;
        commit('setEnvironment', responseData);
        resolve();
      }).catch((error: any) => {
        // Process error
        console.warn(error);
        reject();
      });
    });
  },
  loadCtrIntegrationLog({ commit, getters }, { pageNumber, pageSize }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Clear previous response
      commit('setCtrIntegrationLog', undefined);
      // Prepare request
      const url = APIRoute(EP.administration.ctr.integration_log.index, [
        [':page_number', pageNumber],
        [':page_size', pageSize],
      ]);
      // Dispatch request
      axios.get(url).then((response: any) => {
        // Process successful response
        const ctrLogItems: CtrLogItem[] = response.data.ctr_interface_log;
        commit('setCtrIntegrationLog', ctrLogItems);
        resolve();
      }).catch((error: any) => {
        // Process error
        console.warn(error);
        reject();
      });
    });
  },
};
