











import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class HiddenInput extends Vue {
  // V-model
  @Prop() value!: any;

  // Standard properties
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
}
