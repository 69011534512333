

























































import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SelectedLivingDonorProfileLinks extends Vue {

  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string;
  @Getter('showRecoveryInformation', { namespace: 'livingDonors' }) private showRecoveryInformation!: boolean;

  @Prop({ default: false }) active!: boolean;
  @Prop({default: false }) newDonor!: boolean;

  public show = true;

  public toggleNav() {
    this.show = !this.show;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) {
       this.$emit("toggle");
    }
  }

  /**
   * Create a return to profile link
   *
   * @returns {string} client_id as a string
   */
  get profileReturnLink(): string {
    return this.clientId === '' ? `/livingDonors` : `/livingDonors/${this.clientId}`;
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.active) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new living donors we only ever show this in active mode
      const location = this.$router.resolve({ name: 'edit-living-donor', params: { id: this.clientId }, hash: hash });
      return location.href;
    }
  }
}
