

























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LoadingTableView extends Vue {
  @Prop({ default: null }) rows!: number;
  @Prop({ default: null }) columns!: number;
}
