import Vue from 'vue';
import { MutationTree } from 'vuex';
import { OrganDetails, VadProcedure, HistoryLiverSmc } from '@/store/organSpecificDetails/types';

export const mutations: MutationTree<OrganDetails> = {
  setLiverMelds(state, liverMelds) {
    Vue.set(state, 'liverMelds', liverMelds);
  },
  selectLiverMeld(state, liverMeld) {
    Vue.set(state, 'selectedLiverMeld', liverMeld);
  },
  setLiverPelds(state, liverPelds) {
    Vue.set(state, 'liverPelds', liverPelds);
  },
  selectLiverPeld(state, liverPeld) {
    Vue.set(state, 'selectedLiverPeld', liverPeld);
  },
  setLiverHccs(state, liverHccs) {
    Vue.set(state, 'liverHccs', liverHccs);
  },
  selectLiverHcc(state, liverHcc) {
    Vue.set(state, 'selectedLiverHcc', liverHcc);
  },
  setHeartMeasurements(state, heartMeasurements) {
    Vue.set(state, 'heartMeasurements', heartMeasurements);
  },
  setHemodynamicInfo(state, hemodynamicInfo) {
    Vue.set(state, 'hemodynamicInfo', hemodynamicInfo);
  },
  selectKidneyDialysis(state, dialysis) {
    Vue.set(state, 'selectedDialysis', dialysis);
  },
  setAlcoholicLiverDiseasePrograms(state, aldPrograms) {
    Vue.set(state, 'aldPrograms', aldPrograms);
  },
  setPancreasInfusions(state, pancreasInfusion) {
    Vue.set(state, 'pancreasInfusion', pancreasInfusion);
  },
  selectPancreasInfusions(state, pancreasInfusion) {
    Vue.set(state, 'selectedPancreasInfusion', pancreasInfusion);
  },
  clearPancreasInfusions(state, pancreasInfusion) {
    Vue.set(state, 'selectedPancreasInfusion', undefined);
  },
  setVadProcedures(state, vadInfo: VadProcedure) {
    Vue.set(state, 'vadProcedures', vadInfo);
  },
  setLiverBridgings(state, liverBridgings) {
    Vue.set(state, 'liverBridgings', liverBridgings);
  },
  selectLiverBridging(state, liverBridging) {
    Vue.set(state, 'selectedLiverBridging', liverBridging);
  },
  setLiverDownstagings(state, liverDownstagings) {
    Vue.set(state, 'liverDownstagings', liverDownstagings);
  },
  selectLiverDownstaging(state, liverDownstaging) {
    Vue.set(state, 'selectedLiverDownstaging', liverDownstaging);
  },
  setHistoryLiverSmc(state, historyLiverSmc) {
    Vue.set(state, 'historyLiverSmc', historyLiverSmc);
  },
};
