








































import { EP } from '@/api-endpoints';
import { Getter, State } from 'vuex-class';
import { NumericCodeValue } from '@/store/types';
import TextInput from "@/components/shared/TextInput.vue";
import SubSection from '@/components/shared/SubSection.vue';
import { SaveResult, SaveableSection, SaveProvider } from '@/types';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";
import { RecipientJourney, RecipientWaitlistAttributes } from '@/store/recipientJourney/types';

interface RemoveFromWaitlistState {
  reasonForWaitlistRemoval?: number;
  reasonOther?: string;
}

@Component({
  components: {
    TextInput,
    SubSection,
    SelectOtherInput,
  },
})
export default class OverrideWaitTime extends Vue {
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.removeFromWaitlist) editState!: RemoveFromWaitlistState;

  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('isClustered', { namespace: 'journeyState' }) isClustered!: boolean;
  @Getter('isWaitlisted', { namespace: 'journeyState' }) isWaitlisted!: boolean;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('reasonForRemovalOptions', { namespace: 'lookups' }) reasonForRemovalOptions!: NumericCodeValue[];

  @Prop({default: false }) newJourney!: boolean;
  @Prop({ default: false }) divider!: boolean;

  // True only if user is permitted to sve
  get isRemoveFromWaitlistAuthorized(): boolean {
    return this.checkAllowed(EP.recipients.journeys.waitlist.removeFromWaitlist, 'PUT');
  }

  // True only journey has already been waitlisted and the user is permitted to save
  get canSave(): boolean {
    return !this.newJourney
        && this.isWaitlisted
        && this.isRemoveFromWaitlistAuthorized
        && !this.journey.completed;
  }

  // If Reason for Removal changes, clear Other
  private onReasonForRemovalChange(): void {
    Vue.set(this.editState, 'reasonOther', undefined);
  }

  // Text for confirmation prompt
  get confirmationText(): string {
    return this.isClustered ? 'Warning: This action will remove all clustered journeys from their respective organ waitlists. Click OK to proceed with the removal of all clustered journeys. Click Cancel if you wish to first uncluster a journey then remove it from its organ waitlist.'
        : 'This action will remove the recipient from this organ waitlist. Do you wish to proceed?';
  }

  // Validation mapping
  public idLookup(): {[key: string]: string} {
    return {
      'removeFromWaitlist.waitlist_removal_reason_code' : 'remove-from-waitlist-reason',
      'removeFromWaitlist.waitlist_removal_reason'      : 'remove-from-waitlist-reason-other',
    };
  }

  // Setup form state based on journey data
  private buildRemoveFromWaitlistState(journey?: RecipientJourney): RemoveFromWaitlistState {
    const result: RemoveFromWaitlistState = {};
    if (!journey) {
      return result;
    }
    const attributes = journey.stage_attributes?.waitlist || {};
    Object.assign(result, {
      reasonForWaitlistRemoval: attributes.waitlist_removal_reason_code,
      reasonOther:              attributes.waitlist_removal_reason_other,
    });
    return result;
  }

  // Setup request payload based on form state
  private extractWaitlistAttributesPatch(): RecipientWaitlistAttributes {
    const form = this.editState || {};
    const result: RecipientWaitlistAttributes = {
      waitlist_removal_reason_code:  form.reasonForWaitlistRemoval != null ? form.reasonForWaitlistRemoval : null,
      waitlist_removal_reason_other: form.reasonOther || null,
    };
    return result;
  }

  // Loading
  private mounted(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'removeFromWaitlist',
      value: this.buildRemoveFromWaitlistState(this.journey),
    });
  }

  // Saving
  private savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveRemoveFromWaitlist as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('saving', 'removeFromWaitlist');
    // Generate payload based on current edit state
    const waitlistAttributesPatch = this.extractWaitlistAttributesPatch();
    // Setup saving payload
    const payload = {
      journeyId: this.journeyId,
      recipientId: this.recipientId,
      waitlistAttributes: waitlistAttributesPatch,
      prefix: 'removeFromWaitlist',
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveRemoveFromWaitlist', payload).then((success: SaveResult) => {
      // Reload recipient, journey, and journey durations
      this.$store.dispatch('recipients/get', this.recipientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
          this.$store.dispatch('journeyState/loadJourneyDurations', { recipientId: this.recipientId, journeyId: this.journeyId }).then(() => {
            // Re-initialize form state based on response from API
            this.initializeForm();
            // Report to parent that saving has completed so it can clear validations and reload waitlist decisions
            this.$emit('saved', 'removeFromWaitlist');
          });
        });
      });
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }
}
