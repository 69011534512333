import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/administration/actions';
import { getters } from '@/store/administration/getters';
import { mutations } from '@/store/administration/mutations';
import { AdministrationState } from '@/store/administration/types';

const state: AdministrationState = {};

const namespaced = true;

export const administration: Module<AdministrationState, RootState> = {
  state,
  actions,
  getters,
  mutations,
  namespaced,
};
