































import store from '@/store';
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import SelectInput from '@/components/shared/SelectInput.vue';
import { APIRoute, APIBaseRoute, EP } from '@/api-endpoints';

interface LoginState {
  user: string|null;
}

@Component({
  components: {
    SelectInput
  }
})
export default class LoginByRole extends Vue {
  @State(state => state.userAccounts.userList) private userList!: any;
  @State(state => state.pageState.currentPage.LoginState) private editState!: LoginState;

  @Getter('getUserEntries', { namespace: 'userAccounts' }) private getUserEntries!: any[];
  @Getter('generateRedirectLocation', { namespace: 'users' }) private generateRedirectLocation!: string;
  @Getter('showUserSelect', { namespace: 'features' }) private showUserSelect!: boolean;

  mounted(): void {
    if (this.showUserSelect) {
      this.$store.dispatch('userAccounts/getAll').then(() => {
        this.initializeForm();
      });
    } else {
      this.initializeForm();
    }
  }

  get getEHealthURL(): string {
    return APIBaseRoute(EP.auth.ehealth);
  }

  get getFormattedList(): any[] {
    // build list of users with value to send back to api as id/openid
    const users = this.getUserEntries.map((item: any) => {
      const fullName: any = `${item.first_name} ${item.last_name}`;
      const id: any = item._id ? item._id.$oid : null;
      return { title: fullName, value: fullName, code: id };
    });
    return users;
  }

  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'LoginState',
      value: this.buildLoginState()
    });
  }

  private buildLoginState(): LoginState {
    return {
      user: null
    };
  }

  get getOAuthID(): string {
    const id = `${this.editState.user || null}`;
    const users = this.getUserEntries;
    const user = users.find((item: any) => {
      return item._id.$oid === id;
    });
    return user ? user.oauth2_user_identifier : null;
  }

  loginUser(): void {
    const token = `${this.editState.user || null}`;
    if (token) {
      window.location.href = `/login?access_token=${token}`;
    }
  }
}

