
































































































































































import { Getter, State } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { WidgetSettings } from '@/store/dashboard/types';
import WidgetNotifications from '@/components/dashboard/widgets/WidgetNotifications.vue';
import WidgetActiveAllocations from '@/components/dashboard/widgets/WidgetActiveAllocations.vue';
import WidgetPrimaryOffers from '@/components/dashboard/widgets/WidgetPrimaryOffers.vue';
import WidgetBookmarks from '@/components/dashboard/widgets/WidgetBookmarks.vue';
import WidgetBackupOffers from '@/components/dashboard/widgets/WidgetBackupOffers.vue';
import WidgetActiveDonors from '@/components/dashboard/widgets/WidgetActiveDonors.vue';
import WidgetLabResults from '@/components/dashboard/widgets/WidgetLabResults.vue';
import WidgetRecentRecipients from '@/components/dashboard/widgets/WidgetRecentRecipients.vue';
import WidgetRecentLivingDonors from '@/components/dashboard/widgets/WidgetRecentLivingDonors.vue';
import WidgetUrgentListings from '@/components/dashboard/widgets/WidgetUrgentListings.vue';
import WidgetExdDonors from '@/components/dashboard/widgets/WidgetExdDonors.vue';
import WidgetTransplantInProgress from '@/components/dashboard/widgets/WidgetTransplantInProgress.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { UserPreferenceData } from '@/store/users/types';
import { WidgetPermission } from '@/store/dashboard/types';

@Component({
  components: {
    WidgetNotifications,
    WidgetActiveAllocations,
    WidgetPrimaryOffers,
    WidgetBookmarks,
    WidgetBackupOffers,
    // WidgetActiveDonors, TP-12599 Hidding until CR "Allocation states and Allocation Complete button" is implemented
    WidgetLabResults,
    WidgetRecentRecipients,
    WidgetRecentLivingDonors,
    WidgetUrgentListings,
    WidgetExdDonors,
    WidgetTransplantInProgress
  }
})

export default class Dashboard extends Vue {
  @State(state => state.users.user.preferences) private preferences!: any;
  @State(state => state.pageState.currentPage.dashHome) private editState!: any;

  // Getters
  @Getter('getPreferences', { namespace: 'users'}) getPreferences!: any;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('getUserWidgetPermissions', { namespace: 'users'}) getUserWidgetPermissions!: WidgetPermission[];
  @Getter('isWidgetRequired', { namespace: 'users'}) isWidgetRequired!: (widgetName: string) => boolean
  @Getter('isWidgetAllowed', { namespace: 'users'}) isWidgetAllowed!: (widgetName: string) => boolean
  @Getter('getWidgetPermission', { namespace: 'users'}) getWidgetPermission!: WidgetPermission;

  public errorMessage = null;

  private dashboardShowArray: { [key: string]: any } = {
      showNotifications: 'notifications',
      // showAllocations: 'active_allocations',
      showPrimaryOffers: 'active_primary_offers',
      showBackupOffers: 'active_backup_offers',
      showActiveDonors: 'active_donors',
      showTransplantInProgress: 'transplant_in_progress',
      showBookmarks: 'bookmarks',
      showRecentRecipientsRegistrations: 'recent_recipient_registrations',
      showRecentLivingDonors: 'recent_living_donor_registrations',
      showUrgentListings: 'urgent_listings',
      showExdDonors: 'exd_donors',
      showLabResults: 'donor_lab_results'
    };


  /**
   * Get a string representing the current Node environment e.g. production, development
   *
   * @returns {string} current Node environment
   */
  get nodeEnvironment(): string {
    return process.env.VUE_APP_DEPLOY_VERSION || process.env.NODE_ENV;
  }

  /**
   * Returns a boolean indicating if the widget is available for the user based on a given key
   *
   * @returns {boolean} true if widget is allowed to be shown.
   */
  public widgetAllowedByKey(stateKey: string): boolean{
    return this.checkAllowed(`/dashboard/${this.dashboardShowArray[stateKey]}`, 'GET');
  }

  /**
   * Returns a boolean indicating if the widget is a required widget to stop editing for that widget
   *
   * @returns {boolean} true if widget is required to be shown.
   */
  public widgetLockedByKey(stateKey: string): boolean{
    return this.isWidgetRequired(this.dashboardShowArray[stateKey]);
  }

  /**
   * Gets the preferences payload to save.
   *
   * @returns {Oject} current Node environment
   */
  get preferencesPayload(): UserPreferenceData {
    const preferences = this.getPreferences;

    return {
      notifications:        preferences.notifications ? preferences.notifications : {},
      allocations:          preferences.allocations ? preferences.allocations : {},
      primary:              preferences.primary ? preferences.primary  : {},
      transplantInProgress: preferences.transplantInProgress ? preferences.transplantInProgress  : {},
      bookmarks:            preferences.bookmarks ? preferences.bookmarks : {},
      backup:               preferences.backup ? preferences.backup : {},
      activeDonors:         preferences.activeDonors ? preferences.activeDonors : {},
      recentRecipients:     preferences.recentRecipients ? preferences.recentRecipients : {},
      recentLivingDonors:   preferences.recentLivingDonors ? preferences.recentLivingDonors : {},
      urgentListings:       preferences.urgentListings ? preferences.urgentListings : {},
      exdDonors:            preferences.exdDonors ? preferences.exdDonors : {},
      labResults:           preferences.labResults ? preferences.labResults : {}
    };
  }

  /**
   * Converts key value into titleized string, e.g. showActiveDonors -> Active Donors
   *
   * @returns {string} titleized string
   */
  convertKeyToTitleString(key: string): string {
    const str = key ? key : '';
    return str.replace('show', '').replace(/([A-Z])/g, ' $1').trim();
  }

  mounted(): void {
    Promise.all([
      this.$store.commit('setPageTitle', `Dashboard`),
      this.$store.dispatch('dashboard/loadWidgetRolePermissions')
    ]).finally(() => {
      this.initializeWidget();
      const result = this.getUserWidgetPermissions;
    });
  }

  @Watch('preferences', { immediate: true, deep: true })
  initializeWidget(): void {
    this.errorMessage = null; // clear error message
    const preferences = this.getPreferences || {}; // clear settings

    // rebuild preferences
    this.$store.commit('pageState/set', {
      pageKey: 'dashHome',
      value: {
        showNotifications: this.isWidgetRequired('notifications') || preferences.notifications?.visible,
        // showAllocations: this.isWidgetRequired('active_allocations') || preferences.allocations?.visible,
        showPrimaryOffers: this.isWidgetRequired('active_primary_offers') || preferences.primary?.visible,
        showBackupOffers: this.isWidgetRequired('active_backup_offers') || preferences.backup?.visible,
        // showActiveDonors: preferences.activeDonors?.visible, //TP-12599 Hidding until CR "Allocation states and Allocation Complete button" is implemented
        showTransplantInProgress: this.isWidgetRequired('transplant_in_progress') || preferences.transplantInProgress?.visible,
        showBookmarks: this.isWidgetRequired('bookmarks') || preferences.bookmarks?.visible,
        showRecentRecipientsRegistrations: this.isWidgetRequired('recent_recipient_registrations') || preferences.recentRecipients?.visible,
        showRecentLivingDonors: this.isWidgetRequired('recent_living_donor_registrations') || preferences.recentLivingDonors?.visible,
        showUrgentListings: this.isWidgetRequired('urgent_listings') || preferences.urgentListings?.visible,
        showExdDonors: this.isWidgetRequired('exd_donors') || preferences.exdDonors?.visible,
        showLabResults: this.isWidgetRequired('donor_lab_results') || preferences.labResults?.visible
      }
    });
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Donor, dispatches a save action, handle errors and registers the save result.
   */
  public savePatch(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'dashHome',
      value: this.editState
    });

    const preferencesPatch : UserPreferenceData = this.preferencesPayload;

    preferencesPatch.notifications.visible =  this.isWidgetRequired('notifications') || this.editState.showNotifications;
    // preferencesPatch.allocations.visible = this.isWidgetRequired('active_allocations') || this.editState.showAllocations;    
    preferencesPatch.primary.visible = this.isWidgetRequired('active_primary_offers') || this.editState.showPrimaryOffers;
    preferencesPatch.backup.visible = this.isWidgetRequired('active_backup_offers') || this.editState.showBackupOffers;
    // preferencesPatch.activeDonors.visible = this.editState.showActiveDonors;
    preferencesPatch.transplantInProgress.visible = this.isWidgetRequired('transplant_in_progress') || this.editState.showTransplantInProgress;
    preferencesPatch.bookmarks.visible = this.isWidgetRequired('bookmarks') || this.editState.showBookmarks;
    preferencesPatch.recentRecipients.visible = this.isWidgetRequired('recent_recipient_registrations') || this.editState.showRecentRecipientsRegistrations;
    preferencesPatch.recentLivingDonors.visible = this.isWidgetRequired('recent_living_donor_registrations') || this.editState.showRecentLivingDonors;
    preferencesPatch.urgentListings.visible = this.isWidgetRequired('urgent_listings') || this.editState.showUrgentListings;
    preferencesPatch.exdDonors.visible = this.isWidgetRequired('exd_donors') || this.editState.showExdDonors;
    preferencesPatch.labResults.visible = this.isWidgetRequired('donor_lab_results') || this.editState.showLabResults;

    this.$store.dispatch('users/savePreferences', { preferences: preferencesPatch }).then((success: SaveResult) => {
      // If successful dismiss dialog
      this.dismiss();
    }).catch((error: any) => {
      // Show error notification
      this.errorMessage = error.message;
    });

  }

  public dismiss(): void {
    $("#customizeDash").dropdown('toggle');
  }
}
