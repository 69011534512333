import Vue from 'vue';
import { MutationTree } from 'vuex';
import { ObjectId } from '@/store/types';
import { AllocationState, Allocations, Allocation, ExclusionRules, AllocationChecklistResponse, AllocationChecklistForm, AllocationChecklist, RecipientDetails } from '@/store/allocations/types';

export const mutations: MutationTree<AllocationState> = {
  setExclusionRules(state: AllocationState, exclusionRules: ExclusionRules) {
    Vue.set(state, 'exclusionRules', exclusionRules);
  },
  setAllocation(state: AllocationState, allocation: Allocation) {
    Vue.set(state, 'selected', allocation);
  },
  setConfirmedChecklistStatus(state: AllocationState, checklist: AllocationChecklist): void {
    Vue.set(state, 'checklist_status', checklist);
  },
  /**
   * Mutation to clear out a selected allocation so that the we can reset it.
   * @param state
   */
  clearAllocation(state: AllocationState) {
    [
      'selected',
      'exclusionRules',
      'checklist',
      'lastLoadedAllocationDonorId'
    ].forEach((key: string) => Vue.set(state, key, undefined));
  },
  setAllocations(state: AllocationState, allocations: Allocations) {
    Vue.set(state, 'allAllocations', allocations);
  },
  setActiveAllocations(state: AllocationState, allocations: Allocations) {
    Vue.set(state, 'activeAllocations', allocations);
  },
  setInactiveAllocations(state: AllocationState, allocations: Allocations) {
    Vue.set(state, 'inactiveAllocations', allocations);
  },
  startCreatingAllocation(state: AllocationState) {
    Vue.set(state, 'isCreatingAllocation', true);
  },
  stopCreatingAllocation(state: AllocationState) {
    Vue.set(state, 'isCreatingAllocation', false);
  },
  setLastLoadedAllocationDonorId(state: AllocationState, donorId: ObjectId) {
    Vue.set(state, 'lastLoadedAllocationDonorId', donorId);
  },
  startLoading(state: AllocationState) {
    Vue.set(state, 'isLoadingAllocation', true);
  },
  startLoadingAllocations(state: AllocationState) {
    Vue.set(state, 'isLoadingAllocations', true);
  },
  stopLoading(state: AllocationState) {
    Vue.set(state, 'isLoadingAllocation', false);
  },
  stopLoadingAllocations(state: AllocationState) {
    Vue.set(state, 'isLoadingAllocations', false);
  },
  startGeneratingReport(state: AllocationState) {
    Vue.set(state, 'isGeneratingAllocationReport', true);
  },
  stopGeneratingReport(state: AllocationState) {
    Vue.set(state, 'isGeneratingAllocationReport', false);
  },
  startAddingRecipient(state: AllocationState) {
    Vue.set(state, 'isAddingRecipient', true);
  },
  stopAddingRecipient(state: AllocationState) {
    Vue.set(state, 'isAddingRecipient', false);
  },
  startDiscontinuingOneAllocation(state: AllocationState) {
    Vue.set(state, 'isDiscontinuingOneAllocation', true);
  },
  stopDiscontinuingOneAllocation(state: AllocationState) {
    Vue.set(state, 'isDiscontinuingOneAllocation', false);
  },
  startDiscontinuingAllAllocations(state: AllocationState) {
    Vue.set(state, 'isDiscontinuingAllAllocations', true);
  },
  stopDiscontinuingAllAllocations(state: AllocationState) {
    Vue.set(state, 'isDiscontinuingAllAllocations', false);
  },
  startDiscontinuingDonor(state: AllocationState) {
    Vue.set(state, 'discontinuingDonor', false);
  },
  startDiscontinuingOneOffer(state: AllocationState) {
    Vue.set(state, 'isDiscontinuingOneOffer', true);
  },
  stopDiscontinuingOneOffer(state: AllocationState) {
    Vue.set(state, 'isDiscontinuingOneOffer', false);
  },
  startMakingOffer(state: AllocationState) {
    Vue.set(state, 'isMakingOffer', true);
  },
  stopMakingOffer(state: AllocationState) {
    Vue.set(state, 'isMakingOffer', false);
  },
  startRespondingOffer(state: AllocationState) {
    Vue.set(state, 'isRespondingOffer', true);
  },
  stopRespondingOffer(state: AllocationState) {
    Vue.set(state, 'isRespondingOffer', false);
  },
  startDecliningMultiple(state: AllocationState) {
    Vue.set(state, 'isDecliningMultiple', true);
  },
  stopDecliningMultiple(state: AllocationState) {
    Vue.set(state, 'isDecliningMultiple', false);
  },
  setDonorDetails(state: AllocationState, allocation: Allocation) {
    Vue.set(state, 'donorDetails', allocation);
  },
  clearDonorDetails(state: AllocationState) {
    Vue.set(state, 'donorDetails', undefined);
  },
  setRecipientDetails(state: AllocationState, recipientDetails: RecipientDetails) {
    Vue.set(state, 'recipientDetails', recipientDetails);
  },
  clearRecipientDetails(state: AllocationState) {
    Vue.set(state, 'recipientDetails', undefined);
  },
  setChecklist(state: AllocationState, checklist: AllocationChecklistForm) {
    Vue.set(state, 'checklist', checklist);
  },
  setChecklistDetails(state: AllocationState, checklist: AllocationChecklistResponse) {
    Vue.set(state, 'checklistDetails', checklist);
  },
  setChecklistHistory(state: AllocationState, checklist: AllocationChecklistResponse) {
    Vue.set(state, 'checklistHistory', checklist);
  }
};
