import axios from 'axios';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { ValidationsState } from '@/store/validations/types';

export const actions: ActionTree<ValidationsState, RootState> = {
  loadNew({ commit }, {view, action}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Get validations based on view and action
      const url = APIRoute(EP.validations.new_validations, [[':view', view], [':action', action]]);
      axios.get(url).then((response: any) => {
        const responseData: any[] = response.data;
        // Pass value to mutation to place in store
        commit('set', responseData);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  loadEdit({ commit }, {view, action, clientId}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Get validations based on view and action
      const url = APIRoute(EP.validations.edit_validations, [[':view', view], [':action', action], [':id', clientId]]);
      axios.get(url).then((response: any) => {
        const responseData: any[] = response.data;
        // Pass value to mutation to place in store
        commit('set', responseData);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  loadEditDeath({ commit }, {clientId}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Get validations based on view and action
      const url = APIRoute(EP.validations.death_edit_validations, [[':recipient_id', clientId]]);
      axios.get(url).then((response: any) => {
        const responseData: any[] = response.data;
        // Pass value to mutation to place in store
        commit('set', responseData);
        resolve();
      }).catch((error: any) => {
        reject(error);
      });
    });
  },
  loadRecoveryInfoEditValidations({ commit }, {livingDonorId, livingDonorJourneyId}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Get validations based on view and action
      const url = APIRoute(EP.living_donors.recoveryInfo.edit_validations, [[':living_donor_id', livingDonorId], [':living_donor_journey_id', livingDonorJourneyId]]);
      axios.get(url).then((response: any) => {
        const responseData: any[] = response.data;
        // Pass value to mutation to place in store
        commit('setAppendRecoveryInfoRules', responseData);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  reset({ commit }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('reset');
    });
  }
};
