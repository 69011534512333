
































































































































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { ObjectId } from '@/store/types';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { titleCase } from '@/utils';
import { Recipient, RecipientSignificantEventType } from '@/store/recipients/types';
import { WaitlistSectionPageState } from '@/components/organs/shared/_WaitlistSection.vue';
import { RecipientJourney, RecipientWaitlistAttributes, RecipientWaitlistFactors, WaitlistDecision, WaitlistDecisionChange, WaitlistDecisionDetails, WaitlistFactorEvent, WaitlistClusterEvent, JourneyDurations, JourneyEvent, JourneyEventTo, JourneyStage, SignificantEventWaitlistRemoval } from '@/store/recipientJourney/types';
import { Organ, OrganWaitlistMedicalStatus, OrganWaitlistMedicalStatusValue, HeartMedicalStatusValue, ReasonForMedicalHold, HEART_STATUSES_EXCLUDED_FROM_SECONDARY, RecipientSignificantEventFactorCode, RecipientSignificantEventFactorCodeStage, WaitlistFactorCode, WaitlistFactorCodeValue } from '@/store/lookups/types';
import PaginationToolbar from '@/components/shared/PaginationToolbar.vue';

interface HistoryRow {
  id: any;
  startDate: string;
  startTime?: string;
  endDate: string;
  endTime?: string;
  days: string;
  type: string;
  details: string;
  detailsLinks?: ClusteredJourneyLink[];
  userId: string;
  isSelected: boolean;
  hasNestedRows: boolean;
  nestedRows?: NestedHistoryRow[];
}

interface ClusteredJourneyLink {
  journeyId: string;
  organName: string;
}

interface NestedHistoryRow {
  id: any;
  date: string;
  time?: string;
  days?: string;
  decision: string;
  reasonForHold: string;
  holdComments: string;
  userId: string;
}

@Component({
  components: {
    SubSection,
    PaginationToolbar,
  },
})
export default class WaitlistHistoryTable extends mixins(DateUtilsMixin) {
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.waitlistSection) editState!: WaitlistSectionPageState;
  @State(state => state.lookups.medical_hold_reasons) reasonForMedicalHoldOptions!: ReasonForMedicalHold[];
  @State(state => state.journeyState.waitlistDecisions) waitlistDecisions!: WaitlistDecision[];
  @State(state => state.journeyState.selectedWaitlistDecision) selectedWaitlistDecision!: WaitlistDecision;
  @State(state => state.lookups.recipient_significant_event_factor_codes) recipientSignificantEventFactorCodes!: RecipientSignificantEventFactorCode[];

  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('waitTimeDescription', { namespace: 'journeyState' }) waitTimeDescription!: boolean;
  @Getter('lookupValueNumeric', { namespace: 'lookups' }) lookupValueNumeric!: (code: number, lookupId: string) => string|null;
  @Getter('medicalStatusLookup', { namespace: 'lookups' }) medicalStatusLookup!: (excludeHold: boolean, organLookup?: Organ[], organCode?: number) => any;
  @Getter('secondaryMedicalStatusLookup', { namespace: 'lookups' }) secondaryMedicalStatusLookup!: (organLookup?: Organ[], organCode?: number) => any;
  @Getter('describeMedicalStatus', { namespace: 'lookups' }) describeMedicalStatus!: (organLookup?: Organ[], organCode?: number, medicalStatusCode?: string|null, secondaryMedicalStatusCode?: string|null) => string|undefined;

  // Properties
  @Prop({ default: false }) newJourney!: boolean;

  /**
   * Emits a loaded event when the component has finished mounting
   * 
   * @emits loaded
   */
  private mounted(): void {
    // Check what data we need to load for this component
    if (!this.newJourney) {
      // Edit Journey - need to load both wait time and waitlist decisions
      const payload = { recipientId: this.recipientId, journeyId: this.journeyId };
      Promise.all([
        this.$store.dispatch('journeyState/loadJourneyDurations', payload),
        this.$store.dispatch('journeyState/loadWaitlistDecisions', payload)
      ]).finally(() => {
        this.$emit('loaded', 'waitlistHistoryTable');
      });
    } else {
      // New Journey - simply reset wait time and decisions
      this.$store.commit('journeyState/clearJourneyDurations');
      this.$store.commit('journeyState/clearWaitlistDecisions');
      this.$emit('loaded', 'waitlistHistoryTable');
    }
  }

  /**
   * Get a numeric representation of which Organ is associated with the selected Journey
   * 
   * @returns {number} code that can be used when fetching entries from the Organ lookup table
   */
  get organCode(): number {
    if (!this.journey || !this.journey.organ_code) {
      return 0;
    }
    return this.journey.organ_code;
  }

  /**
   * Returns a string representation of a medical hold reason, including custom other reason
   * 
   * @param factors source of medical hold information
   * @returns {string|undefined} description if there is a medical hold, undefined otherwise
   */
  private describeReasonForMedicalHold(details?: WaitlistDecisionDetails): string|undefined {
    if (!details || !details.reason_code || !this.reasonForMedicalHoldOptions) {
      return undefined;
    }
    // Fetch entry in the lookup table describing the Medical Hold reason
    const reasonDocument = this.reasonForMedicalHoldOptions.find((reason: ReasonForMedicalHold) => {
      return reason.code == details.reason_code;
    });
    if (!reasonDocument) {
      return undefined;
    }
    // Check if the entry is 'Other'
    const isOther = reasonDocument === undefined ? false : reasonDocument.other_selected || false;
    // Show custom other reason if necessary, otherwise show text value from lookup entry
    const description = isOther ? details.other_reason || 'Unknown Other' : reasonDocument.value;
    return description;
  }

  /**
   * Gets table row data representing Waitlist Status History for the selected recipient
   * 
   * @returns {HistoryRow[]} Status History rows
   */
  get statusHistoryRows(): HistoryRow[] {
    // Fetch selected decisions from vue-x store
    const selectedDecision = this.selectedWaitlistDecision;

    // Note: no need to re-sort the waitlist decisions here
    const decisions = this.waitlistDecisions || [];

    /**
     * Note: the API WaitlistDecisions activity is responsible for filtering, mapping, and otherwise determining
     * what is and isn't considered a 'waitlist decision row'. The information in the API response is based on
     * data in the Recipient Significant Events collection, but API re-structures medical hold changes, derives
     * end dates, calculates 'days', etc.
     */
    const rows = decisions.map((decision: WaitlistDecision) => {
      const id = decision._id.$oid;
      const isSelected = selectedDecision && selectedDecision._id.$oid == id;
      const startDate = this.parseDisplayDateUiFromDateTime(decision.event_date) || '-';
      const startTime = this.parseTimeUiFromDateTime(decision.event_date) || undefined;
      // Fetch end date and number of days the decision was effective, calculated by the API
      const endDate = this.parseDisplayDateUiFromDateTime(decision.end_date || undefined) || '-';
      const endTime = this.parseTimeUiFromDateTime(decision.end_date || undefined) || undefined;
      const days = decision.days != null ? decision.days.toString() : '-';
      // Describe event type and details
      const type = this.buildTypeText(decision);
      const details = this.buildDetailsText(decision);
      // Setup clustered journey links for cluster holds and suspensions
      const detailsLinks = this.buildDetailsLinks(decision as WaitlistClusterEvent);
      const userId = decision.created_by || '-';
      const hasNestedRows = !!decision.changes || false;
      // Sort nested rows by event_date
      const changes = decision.changes || [];
      const nestedRows = changes.map((change: WaitlistDecisionChange) => {
        const nestedRowId = change._id.$oid || change._id;
        const date = this.parseDisplayDateUiFromDateTime(change.event_date) || '-';
        const time = this.parseTimeUiFromDateTime(change.event_date) || undefined;
        // Display appropriate decision [Added, Updated, Extended, Removed]
        const rawDecisionValue = change.change_type;
        const decision =  !!rawDecisionValue ? titleCase(rawDecisionValue) : '-';
        const description = this.describeReasonForMedicalHold(change.details);
        const reasonForHold = description || '-'; 
        const holdComments = change.details.reason_comment || '-';
        const nestedChangeUserId = change.created_by || '-';
        const nestedRow: NestedHistoryRow = {
          id: nestedRowId,
          date,
          time,
          decision,
          reasonForHold,
          holdComments,
          userId: nestedChangeUserId,
        };
        return nestedRow;
      });
      
      const row: HistoryRow = {
        id,
        startDate,
        startTime,
        endDate,
        endTime,
        days,
        type,
        details,
        detailsLinks,
        userId,
        isSelected,
        hasNestedRows,
        nestedRows,
      };
      return row;
    });
    return rows;
  }

  private currentPage = 1;
  private perPage = 10;

  private setPerPage(perPage: number): void {
    Vue.set(this, 'perPage', perPage);
  }

  private setCurrentPage(currentPage: number): void {
    Vue.set(this, 'currentPage', currentPage);
  }

  /**
   * Return only the table rows currently visible based on current pagination configuration.
   * 
   * Pagination here is reduced to two values: current page (starts at page 1) and the maximum
   * size of any page ("perPage"). From these two numbers we can derive a starting index and
   * an ending index. The ending index is cut off at the end of the overall rows, and so the
   * last page may be smaller than the perPage maximum.
   * 
   * Note: assumes that the "currentPage" value is 1 or more.
   * 
   * @returns {HistoryRow[]} subset of statusHistoryRows defined by currentPage and perPage
   */
  get paginatedRows(): HistoryRow[] {
    const allRows = this.statusHistoryRows || [];
    const startIndex = (this.currentPage - 1) * this.perPage;
    const endIndex = Math.min(startIndex + this.perPage, allRows.length);
    const sliced = this.perPage > 0 ? allRows.slice(startIndex, endIndex) : allRows;
    return sliced;
  }

  private buildDetailsLinks(clusterEvent: WaitlistClusterEvent): ClusteredJourneyLink[] {
    const result: ClusteredJourneyLink[] = [];
    const stageFactorCode = clusterEvent.cluster_code || 0;
    switch (stageFactorCode) {
      case WaitlistFactorCodeValue.HoldCluster:
      case WaitlistFactorCodeValue.SuspendedCluster:
        // Get clustered journey IDs from cluster event 'extra' string array
        const journeyIds = clusterEvent.extra || [];
        let clusteredJourney: RecipientJourney|undefined = undefined;
        let organEntry: Organ|undefined = undefined;
        journeyIds.forEach((clusteredJourneyId: string|ObjectId) => {
          // DIAG: TECH_DEBT Migrated recipients could have their related_journeys as string[]
          // this was fixed on API but we are checking here for either string[] or ObjectId[]
          const journeyId = (typeof clusteredJourneyId === 'string') ? clusteredJourneyId : clusteredJourneyId.$oid;
          clusteredJourney = (this.recipient.journeys || []).find((journey: RecipientJourney) => {
            return journey?._id?.$oid === journeyId;
          });
          organEntry = this.organLookup.find((organ: Organ) => { return organ.code === clusteredJourney?.organ_code; });
          result.push({
            journeyId,
            organName: organEntry?.value || 'Unknown'
          });
        });
        break;
    }
    return result;
  }


  /**
   * Build display text value for Type column
   *
   * The logic here depends on the type of event:
   * - factor (f, f-hm, f-hm-dc) - stage_factor_code (e.g. serum hold, medical hold)
   * - cluster (c) - cluster_code (e.g. cluster hold)
   * - waitlist removal (wr) is remove from waitlist
   */
  private buildTypeText(decision: WaitlistDecision|JourneyEvent): string {
    // Check if the event is a remove from waitlist decision
    if (decision._type === RecipientSignificantEventType.WaitlistRemoval) return 'Removed from Waitlist';

    // Otherwise most waitlist decision types are based on the 'stage_factor_code' lookup entry
    // Note: here we are using the 'abbreviated_value' string parameter from the lookup
    const stageFactorCode = (decision as WaitlistFactorEvent)?.stage_factor_code || (decision as WaitlistClusterEvent)?.cluster_code;
    const stageFactorLookup = this.waitlistStageFactor(stageFactorCode);
    const stageFactorValue = stageFactorLookup ? stageFactorLookup.abbreviated_value || stageFactorLookup.value || null : null;
    return stageFactorValue || '-';
  }

  // Build display text value for Details column
  private buildDetailsText(decision: WaitlistDecision|JourneyEvent|SignificantEventWaitlistRemoval): string {
    // Check if the event is removed from waitlist, which has 'reason_code' and possibly 'reason_other'
    if (decision._type === RecipientSignificantEventType.WaitlistRemoval) {
      const removalDecision = decision as SignificantEventWaitlistRemoval;
      // Removed from waitlist details based on 'waitlist_removal_reason_codes' lookup
      const otherReason = removalDecision.reason_other;
      if (otherReason) return otherReason;
      const reasonCode = removalDecision.reason_code;
      const reasonText = this.lookupValueNumeric(reasonCode, 'waitlist_removal_reason_codes');
      return reasonText || '-';
    }
    // Otherwise most waitlist decision details are based on 'stage_factor_code' and 'details' / 'extra' information
    const stageFactorCode = (decision as unknown as WaitlistFactorEvent)?.stage_factor_code || 0;
    const toValue: any = (decision as unknown as JourneyEvent)?.to;
    const details: WaitlistDecisionDetails = (decision as unknown as WaitlistDecision).details;
    let result: string|undefined = undefined;
    switch (stageFactorCode) {
      case WaitlistFactorCodeValue.MedicalStatus:
        // Describe value as string value code for organ-specific Medical Status
        const medicalStatusCode: string = toValue as string;
        const secondaryMedicalStatusCode = (decision as WaitlistFactorEvent).extra || null;
        result = this.describeMedicalStatus(this.organLookup, this.organCode, medicalStatusCode, secondaryMedicalStatusCode);
        break;
      case WaitlistFactorCodeValue.HoldMedical:
        // Describe reason from details
        result = this.describeReasonForMedicalHold(details);
        break;
      case WaitlistFactorCodeValue.HoldSerumHla:
        // Describe reason from extra
        const holdReason = (decision as WaitlistFactorEvent).extra || null;
        result = holdReason ? `HLA ${titleCase(holdReason)}` : '-';
        break;
      case WaitlistFactorCodeValue.SuspendedMedical:
        // Constant text specified by requirements
        result = 'Medical Hold Elapsed';
        break;
      case WaitlistFactorCodeValue.SuspendedLiverNaMeld:
        // Describe reason from extra
        const suspensionReason = (decision as WaitlistFactorEvent).extra || null;
        result = suspensionReason ? `NaMELD ${titleCase(suspensionReason)}` : '-';
        break;
      case WaitlistFactorCodeValue.SuspendedLiverHcc:
        // Constant text specified by requirements
        result = 'HCC Expiry';
        break;
      case WaitlistFactorCodeValue.SuspendedHeart:
        // Constant text specified by requirements
        result = 'Heart on Hold';
        break;
    }
    return result || '-';
  }

  /**
   * Returns full lookup document for a Stage Factor
   * 
   * @param reasonCode code number
   * @returns {RecipientSignificantEventFactorCode|undefined} lookup document if it exists, undefined otherwise
   */
  private waitlistStageFactor(stageFactorCode?: number): WaitlistFactorCode|undefined { 
    if (!this.recipientSignificantEventFactorCodes || !stageFactorCode) {
      return undefined;
    }
    // Stage factors are all in sub tables so first fetch the top-level lookup entry for Waitlist
    const factorLookups = this.recipientSignificantEventFactorCodes || [];
    const waitlistLookup = factorLookups.find((factorLookup: RecipientSignificantEventFactorCode) => {
      return factorLookup.code == RecipientSignificantEventFactorCodeStage.Waitlist;
    });
    const waitlistFactors = waitlistLookup ? waitlistLookup.sub_tables.waitlist_factor_codes || [] : [];
    const stageFactor = waitlistFactors.find((waitlistFactor: WaitlistFactorCode) => {
      return waitlistFactor.code == stageFactorCode;
    });
    return stageFactor;
  }

  /**
   * Selects the source API document corresponding to the clicked row
   * 
   * @param row table row data associated with the click event
   */
  private handleRowClick(row: HistoryRow): void {
    // Check if we have the necessary data
    const decisions = this.waitlistDecisions || [];
    if (decisions.length === 0) return;

    // Find the corresponding document
    let selected = decisions.find((event: WaitlistDecision) => {
      return event._id.$oid == row.id;
    });
    if (!selected) return;

    // If this event was already selected, clear the selection
    if (this.selectedWaitlistDecision && this.selectedWaitlistDecision._id.$oid == selected._id.$oid) {
      selected = undefined;
    }

    // Store a reference to the source document
    this.$store.commit('journeyState/selectWaitlistDecision', selected);

    // Report to parent that page-level form validations may need to be cleared
    this.$emit('clear');
  }
}
