

































































































































































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Organ, OrganCodeValue } from '@/store/lookups/types';
import { Recipient } from '@/store/recipients/types';
import { AssessmentState, JourneyStage, JourneyStatus, RecipientJourney, RecipientWaitlistAttributes, ReferralDecisionState, WaitlistDecision} from '@/store/recipientJourney/types';
import { calculateAge, titleCase } from '@/utils';
import OrganIcon from '@/components/shared/OrganIcon.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CtrLogModal from '@/components/shared/CtrLogModal.vue';
import NewOrganLink from '@/components/shared/NewOrganLink.vue';
import PancreasWholeSpecificDetails from '../organs/pancreas/PancreasWholeSpecificDetails.vue';
import { PancreasWholeDetails } from '@/store/organSpecificDetails/types';

@Component({
  components: {
    OrganIcon,
    SubSection,
    CtrLogModal,
    NewOrganLink,
  }
})
export default class RecipientSummary extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.journeyState.selectedWaitlistDecision) selectedWaitlistDecision!: WaitlistDecision;
  @State(state => state.journeyState.waitlistDecisions) waitlistDecisions!: WaitlistDecision[];
  @State(state => state.journeyState.selectedWaitlistDecision) waitlistDecision!: WaitlistDecision;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) private clientId!: string;
  @Getter('recipientDisplayName', { namespace: 'recipients' }) recipientDisplayName!: string;
  @Getter('getJourneysByStatus', { namespace: 'recipients' }) filterJourneys!: (status: string) => RecipientJourney[];
  @Getter('getJourneyStatusDisplayValue', { namespace: 'recipients' }) getJourneyStatusDisplayValue!: (journey: RecipientJourney) => string|undefined;
  @Getter('organName', { namespace: 'lookups'}) organName!: (organCode?: number) => string;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter("journeyName", { namespace: "recipients" }) journeyName!: (organCode?: number, journeyId?: string) => string;
  @Getter('getJourneyStageDisplayValue', { namespace: 'recipients' }) journeyStageDisplayValue!: (journey: RecipientJourney) => string|undefined;
  @Getter('waitlistStatusDescription', { namespace: 'recipients' }) waitlistStatusDescription!: (attributes: RecipientWaitlistAttributes) => string;
  @Getter('recipientCanAccessCtrLog', { namespace: 'users' }) private recipientCanAccessCtrLog!: boolean;

  @Prop({ default: () => { return []; } }) decisionDate!: {
    id: string | undefined;
    event_date: string;
  }[]; 

  /**
   * Return all active journeys
   *
   * @returns {RecipientJourney[]} all active journeys
   */
  get activeJourneys(): any {
    return this.filterJourneys('active');
  }

  /**
   * Return deceased if recipient death is marked
   *
   * @returns {string}
   */
  get recipientState(): string {
    return this.recipient.state == 'deceased' ? '(Deceased)' : '';
  }

  get oop_recipient(): boolean {
    return this.recipient.oop_recipient ? true : false;
  }

  /**
   * Return the CTR ID of the current recipient.
   *
   * @returns {string}
   */
  get ctrIdDisplayValue(){
    return this.recipient?.patient_profile?.ctr?.national_recipient_id || '-';
  }

  /**
   * Return all postTransplant journeys
   *
   * @returns {RecipientJourney[]} all postTransplant journeys
   */
  get postTransplantJourneys(): any {
    return this.filterJourneys('post-transplant');
  }

  /**
   * Return all complete journeys
   *
   * @returns {RecipientJourney[]} all complete journeys
   */
  get completedJourneys(): any {
    return this.filterJourneys('complete');
  }

  /**
   * Return all oop journeys
   *
   * @returns {RecipientJourney[]} all oop journeys
   */
  get oopJourneys(): any {
    return this.filterJourneys('oop');
  }

  /**
   * Returns a list of Journey IDs found in ANY of the above categories:
   * - Active
   * - Post-Transplant
   * - Completed ("Cancelled")
   * - Out-of-province
   * @returns {string[]} array containing IDs of the recipient's journeys that have been categorized
   */
   get categorizedJourneyIds(): string[] {
    const result: string[] = [];
    if (!this.recipient) return result;

    const activeJourneyIds = (this.activeJourneys || []).map((journey: RecipientJourney): string => { return journey?._id?.$oid || ''; });
    const postTransplantJourneyIds = (this.postTransplantJourneys || []).map((journey: RecipientJourney): string => { return journey?._id?.$oid || ''; });
    const completedJourneyIds = (this.completedJourneys || []).map((journey: RecipientJourney): string => { return journey?._id?.$oid || ''; });
    const oopJourneyIds = (this.oopJourneys || []).map((journey: RecipientJourney): string => { return journey?._id?.$oid || ''; });

    result.push(...activeJourneyIds);
    result.push(...postTransplantJourneyIds);
    result.push(...completedJourneyIds);
    result.push(...oopJourneyIds);
    return result;
   }

  /**
   * Return all journeys that cannot be categorized into any of the expected groups
   *
   * NOTE: in practice all journeys should be categorized into a group in the Recipient Summary, so
   * in theory this list should be empty. However, this is included as a robustness feature so when
   * we encounter any unexpected data we can ensure the journey is listed SOMEWHERE in the Summary.
   *
   * @returns {RecipientJourney[]} all journeys that are not active, post-transplant, complete, or oop
   */
  get uncategorizedJourneys(): RecipientJourney[] {
    if (!this.recipient) return [];

    const allJourneys = this.recipient.journeys || [];
    const uncategorized = allJourneys.filter((journey: RecipientJourney) => {
      const journeyId: string = journey?._id?.$oid || '';
      return !this.categorizedJourneyIds.includes(journeyId);
    });

    return uncategorized;
  }

  /**
   * Get a number for the age of the Recipient
   *
   * Calculates the age of the Recipient using the value of Date of Birth and Date of Death (if exists)
   *
   * @returns {number|null} Recipient's age or null
   */

  get calculatedAge() {
    const recipientDoB = this.recipient.patient_profile?.birth?.date || null;
    const recipientDoD =
      this.recipient.patient_profile && this.recipient.death
        ? this.recipient.death.death_date
        : null;
    // I have a birth and death date
    if (recipientDoB && recipientDoD) {
      return calculateAge(recipientDoB, this.parseDateUi(recipientDoD));
    }
    // I have a birth date only
    if (recipientDoB && !recipientDoD) {
      return calculateAge(recipientDoB);
    }
    // I have a birth date only
    if (recipientDoB && !recipientDoD) {
      return calculateAge(recipientDoB);
    }
    return null;
  }

  get getCumulativeCPRA() {
    const cumulative_cpra = this.recipient.diagnostics?.hla?.cumulative_cpra;
    return cumulative_cpra != null ? cumulative_cpra : '-' ;
  }

   /**
   * Return journey stage display value
   */
  get stageDisplayValue() {
    return (journey: RecipientJourney): string|undefined => {
      // NOTE: delegate all logic for deriving Phase to vue-x getter to ensure consistency
      return this.journeyStageDisplayValue(journey);
    };
  }

  /**
   * Return journey status display value
   */
  get statusDisplayValue() {
    return (journey: RecipientJourney): string|string[]|undefined =>{
      const status = this.getJourneyStatusDisplayValue(journey);
      return status?  status.split(',') : '--';
    };
  }

  get isHspEligible() {
    return (journey: RecipientJourney): boolean =>{
      return journey?.recipient_programs?.hsx_eligible || false;
    };
  }

  get pancreas_type() {
    return (journeyId: string):any =>{
      const journey = this.recipient.journeys?.filter((journey: RecipientJourney) => {
        return journey._id?.$oid == journeyId;
      });
      if (!journey) return false;
      let pancreas_type;
      journey.forEach((journey) => {
        if (journey.organ_code == OrganCodeValue.PancreasWhole){
          const organSpecificDetails: PancreasWholeDetails|undefined = journey?.organ_specific_details;
          pancreas_type = organSpecificDetails?.pancreas_type;
        }
      });
      return pancreas_type;
    };
  }

  /**
   * Return journey's phase date
   *
   */
  get phaseDate() {
    return (journey: RecipientJourney): string|undefined => {
      if(!journey) return '';
      let phaseDate;
      const journeyStage = journey.stage;
      const journeyState =journey.state;
      switch(journeyStage) {
        case JourneyStage.Referral:
          const received_date = journey.stage_attributes?.referral?.received_date;
          phaseDate = received_date ? received_date : journey.start_date;
        break;
        case JourneyStage.Assessment:
          if(journey.stage_attributes?.referral?.state == ReferralDecisionState.Referral_Accepted)  phaseDate = journey.stage_attributes?.referral?.factors?.decision_date;
          if(journey.stage_attributes?.assessment?.consultation_state == AssessmentState.Completed) phaseDate = journey.stage_attributes?.assessment?.factors?.consultation_decision_date;
          if(journey.stage_attributes?.assessment?.state == AssessmentState.LivingDonor) phaseDate = journey.stage_attributes?.assessment?.factors?.assessment_decision_date;
        break;
        case JourneyStage.Waitlist:
          phaseDate = journey.stage_attributes?.waitlist?.factors?.listing_date;
        break;
        default:
        break;
      }
      return phaseDate ? this.parseDate(phaseDate) : '';
    };
  }

  /**
   * Return journey's status date
   *
   */
  get statusDate() {
    return (journey: RecipientJourney, status: string): string|undefined => {

      if(!status) return '';
      let statusDate;
      switch(status) {
        case JourneyStatus.Received:
          statusDate = '';
          break;
        case JourneyStatus.Incomplete:
        case JourneyStatus.Deferred:
          statusDate = journey.stage_attributes?.referral?.factors?.decision_date;
          break;
        case JourneyStatus.Delayed:
          let is_delayed;
          const today = this.parseDateTimeUi(new Date().toISOString());
          const consultation_decision_delay_date = journey.stage_attributes?.assessment?.factors?.consultation_decision_delay_end_date ? this.parseDateTimeUi(journey.stage_attributes?.assessment?.factors?.consultation_decision_delay_end_date) : '';

          if(consultation_decision_delay_date && today) is_delayed = today <= consultation_decision_delay_date;
          if(is_delayed) statusDate = journey.stage_attributes?.assessment?.factors?.consultation_decision_delay_start_date;
          break;
        case JourneyStatus.TreatmentRequired:
        case JourneyStatus.ToBeListed:
          statusDate = journey.stage_attributes?.assessment?.factors?.assessment_decision_date;
          break;
        case JourneyStatus.TransplantInProgress:
          statusDate = journey.stage_attributes?.waitlist?.factors?.transplant_in_progress_date || journey.stage_attributes?.waitlist?.factors?.living_donor_transplant_in_progress_date;
          break;
        case JourneyStatus.MedicalHold:          
          statusDate = journey.stage_attributes?.waitlist?.factors?.on_hold_medical_date;
          break;
        case JourneyStatus.SerumHold:
          statusDate = journey.stage_attributes?.waitlist?.factors?.on_hold_serum_hla_antibody_date;
          break;
        case JourneyStatus.MedicalSuspension:
          statusDate = journey.stage_attributes?.waitlist?.factors?.suspended_medical_date;
          break;
        case JourneyStatus.NaMELDSuspension:
          statusDate = journey.stage_attributes?.waitlist?.factors?.suspended_liver_sodium_meld_date;
          break;
        case JourneyStatus.HCCSuspension:
          statusDate = journey.stage_attributes?.waitlist?.factors?.suspended_liver_hcc_date;
          break;
        case JourneyStatus.HeartSuspension:
          statusDate = journey.stage_attributes?.waitlist?.factors?.suspended_heart_date;
          break;
        case JourneyStatus.ClusterHold:
          statusDate = journey.stage_attributes?.waitlist?.factors?.on_hold_cluster_date;
          break;
        case JourneyStatus.ClusterSuspension:
          statusDate = journey.stage_attributes?.waitlist?.factors?.suspended_cluster_date;
          break;
        case JourneyStatus.IncompleteClusterHold:
          statusDate = journey.stage_attributes?.waitlist?.factors?.on_hold_incomplete_cluster_date;
          break;
        case JourneyStatus.PartialClusterTransplantHold:
          statusDate = journey.stage_attributes?.waitlist?.factors?.on_hold_partial_cluster_transplant_date;
          break;
        default:
          statusDate = '';
          break;
      }
      return statusDate ? this.parseDate(statusDate) : '';  
    };
  }

  public medicalStatusCode(journey: RecipientJourney) :string {
    return journey?.stage_attributes?.waitlist?.factors?.medical_status_code || '-';
  }

  // Styling for cells
  public getJourneyStyle(journey: RecipientJourney): string {
    let style = [];
    const organId = this.$route.params.organ_id;
    if(organId == journey._id?.$oid) style.push("active");
    if(journey.stage == JourneyStage.Waitlist && journey.state == "active") style.push("allocation-eligible");
    if(journey.stage == JourneyStage.Waitlist && journey.state == "inactive") style.push("allocation-not-eligible");
    if(journey.state == AssessmentState.LivingDonor) style.push('waiting-for-living-donor-only');
    return style.join(" ");
  }

  /**
   * Return the hospital MRN for the given journey, if no journey exists then returns -
   *
   * @returns {string}
   */
  public hospitalMrn(journey: RecipientJourney)  :string {
    return journey?.transplant_program?.transplant_hospital_mrn || '-';
  }

  public parseDate(datetime: string) {
    return this.parseDisplayDateUi(datetime);
  }

  public createJourney(organCode: string) {
    this.$router.push({
      name: 'new-organ',
      params: {
        id: String(this.recipient.client_id),
        organ_code: organCode
      }
    });
  }
  
  // PRIVATE
  
  // Initialize and open the CTR Log Modal
  private openCtrModal(): void {
    const ctrLogModal = this.$refs.ctrLogModal as CtrLogModal;
    ctrLogModal.initialize('recipients', this.clientId);
  }
}
