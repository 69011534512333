











import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class UserLinks extends Vue {
  @Getter('getUserName', { namespace: 'users' }) private getUserName!: string;
  @Getter('canAccessManageUsers', { namespace: 'users' }) private canAccessManageUsers!: boolean;

  @Prop({ default: false }) active!: boolean; // HTML ID

  get canAccessUsers(): boolean {
    return this.canAccessManageUsers;
  }
}
