import Vue from 'vue';
import Component from 'vue-class-component';
import { TranslationContext } from '@/types';

@Component
export class TranslationUtilsMixin extends Vue {
  /**
   * Translate context key as well as individually-translated values
   *
   * @param context object containing i18n translation key and optional template values
   * @returns {string} translation result
   */
  public translateContext(context: TranslationContext): string {
    const translatedValues: { [key: string]: string } = {};
    const contextValues = context.values || {};
    Object.keys(contextValues).forEach((contextValueKey: string) => {
      const contextValue = contextValues[contextValueKey];
      translatedValues[contextValueKey] = this.$te(contextValue) ? this.$t(contextValue).toString() : contextValue;
    });
    return this.$t(context.key, translatedValues).toString();
  }

  /**
   * Convenience method for array of contexts
   *
   * @param contexts array of objects containing i18n translation key and optional template values
   * @returns {string[]} translation results
   */
  public translateContexts(contexts: TranslationContext[]): string[] {
    const result = contexts.map((context: TranslationContext): string => {
      return this.translateContext(context);
    });
    return result;
  }
}
