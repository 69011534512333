import axios from 'axios';
import { ActionTree } from 'vuex';
import { SaveResult } from '@/types';
import { prefixErrors } from '@/utils';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { ProceduresState, ProcedureStemCellTherapy } from '@/store/procedures/types';

export const actions: ActionTree<ProceduresState, RootState> = {
  loadStemCellTherapy({ commit }, recipientId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.recipients.stemCellTherapy.show, [[':recipientId', recipientId]]);
      axios.get(url).then((response: any) => {
        const procedure: ProcedureStemCellTherapy = response.data.procedure_stem_cell_therapy;
        commit('setStemCellTherapy', procedure);
        resolve();
      }).catch((error: any) => {
        // 404 error expected if patient does not yet have an HLA Typing lab result
        commit('clearStemCellTherapy');
        reject();
      });
    });
  },
  saveStemCellTherapy({ state }, { recipientId, procedure, prefix }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      /**
       * Recipient has no more than one HLA Stem Cell Therapy procedure, so here we determine whether to send
       * a PATCH or a POST based on whether that one procedure has been loaded into the vue-x state or not.
       */
      const hasSingletonProcedure = !!state.stemCellTherapy;
      let method: any;
      let ep: string;
      if (hasSingletonProcedure) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.stemCellTherapy.update, [[':recipientId', recipientId]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.stemCellTherapy.create, [[':recipientId', recipientId]]);
      }
      // Send asynchronously
      const payload = { procedure_stem_cell_therapy: procedure };
      method(ep, payload).then((response: any) => {
        // Check if the update was successful
        const isSuccessful = response.data && !response.data.errors;
        if (isSuccessful) {
          // Handle successful response
          resolve({ success: true, responseData: response.data });
        } else if (response.data && response.data.errors) {
          // Handle server-side validation errors
          const validationErrors = prefixErrors(response.data.errors, prefix);
          const result: SaveResult = { success: false , errorMessages: ['Cannot save: see error messages above'], validationErrors };
          reject(result);
        }
      }).catch((error: any) => {
        // Handle generic errors
        reject({ success: false, errorMessages: [error.message] });
      });
    });
  },
};
