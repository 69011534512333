import Vue from 'vue';
import { MutationTree } from 'vuex';
import { Coordinator, CoordinatorsState } from '@/store/coordinators/types';

export const mutations: MutationTree<CoordinatorsState> = {
  set(state, { coordinators, key }: { coordinators: Coordinator[], key: string }) {
    Vue.set(state, key, coordinators);
  },
  clear(state, key = 'filtered') {
    Vue.set(state, key, []);
  },
};
