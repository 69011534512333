




























































import { State, Getter } from 'vuex-class';
import PageTop from '@/components/shared/PageTop.vue';
import { LivingAllocation } from '@/store/livingAllocations/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { LivingDonor } from '@/store/livingDonors/types';
import LivingDonorSummary from '@/components/livingDonors/LivingDonorSummary.vue';
import LivingDonorOffers from '@/components/livingAllocations/LivingDonorOffers.vue';
import LivingDonorStickySummary from '@/components/livingDonors/LivingDonorStickySummary.vue';
import LoadingSideNav from '@/components/shared/side-nav/LoadingSideNav.vue';
import LoadingDonorPage from '@/components/shared/LoadingDonorPage.vue';
import SideNavLivingDonorOrgan from '@/components/livingDonors/side-nav/SideNavLivingDonorOrgan.vue';

@Component({
  components: {
    PageTop,
    SideNavLivingDonorOrgan,
    LoadingSideNav,
    LoadingDonorPage,
    LivingDonorStickySummary,
    LivingDonorSummary,
    LivingDonorOffers
  },
})
export default class EditDeceasedDonorAllocations extends Vue {
  @State(state => state.livingAllocations.selected) private allocation!: LivingAllocation;
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;

  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string|undefined;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('livingDonorDisplayName', { namespace: 'livingDonors' }) private livingDonorDisplayName!: string;

  // Set page title based on organ and allocation region
  private setPageTitle(): void {
    this.$store.commit('setPageTitle', `Living Donors / ${this.livingDonorDisplayName} / ${this.livingDonorOrganName}`);
  }

  private sectionsLoaded = new Set();
  private allSectionsLoaded = false;

  // Return true if all sections and their associated data has been loaded
  get isLoaded(): boolean {
    return this.allSectionsLoaded;
  }

  // Return true is the section reference supplied has been loaded
  public isSectionLoaded(ref: string): boolean {
    if (!ref) return false;
    return this.sectionsLoaded.has(ref);
  }

  public loaded(ref: string): void {
    if (!ref) return;
    // Create a set of all the sections to load filtering out validations and the save button
    const sectionsToLoad = new Set(Object.keys(this.$refs).filter((ref: string) => !ref.match(/validations|saveDonor/)));
    // Add the ref we just loaded
    this.sectionsLoaded.add(ref);
    if (this.sectionsLoaded.size === sectionsToLoad.size) {
      this.$store.dispatch('utilities/scrollBehavior');
      this.allSectionsLoaded = true;
    }
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
  public mounted(): void {
    this.setPageTitle();
    this.getAllocations();
  }

  // Update title if organ or allocation region changes
  @Watch('$route.params.organ_code')
  onOrganCodeChange() {
    if(!this.$route.hash){
      this.setPageTitle();
      this.getAllocations();
    }
  }

  @Watch('$route.params.option')
  onAllocationRegionChange() {
    if(!this.$route.hash){
      this.setPageTitle();
      this.getAllocations();
    }
  }

  /**
   * Return the organ name as a string
   *
   * Using the organ_code param, find the matching consented organ for the selected donor.
   *
   * @returns {string} organ name
   */
  get livingDonorOrganName(): string {
    // Prepare organ name for display
    const rawOrganCode = this.$route.params.organ_code || '';
    const numericOrganCode = rawOrganCode != null ? parseFloat(rawOrganCode) : undefined;
    const rawOrganName = this.organNameLookup(numericOrganCode);
    const prettyOrganName = numericOrganCode ? rawOrganName : 'Unknown';
    const donorIndicators = this.livingDonor.indicators || {};
    const organCount = '';
    return `${organCount}${prettyOrganName}`;
  }

  public reloadSummary(): void {
    const livingDonorSummary = this.$refs.livingDonorSummary as LivingDonorSummary;
    livingDonorSummary.initializeForm();
  }

  private getAllocations() {
    this.$store.dispatch('livingAllocations/getAllAllocations', { clientId: this.clientId, clearSelectedAllocation: true });
  }
}
