





























































































import '@/vee-validate-rules.ts';
import { Getter } from 'vuex-class';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Rules } from '@/store/validations/types';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';
import { format as dateFns } from 'date-fns';
import { isMasked } from '@/utils';
import { Format } from '@/store/utilities/types';
import i18n from '@/i18n';

@Component({
  components: {
    ValidationAsterisk
  }
})
export default class DateInput extends Vue {
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('isReadOnly', { namespace: 'validations' }) private isReadOnly!: (readonly?: any) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any) => string;

  // V-model
  @Prop() value!: string;

  // Standard properties
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'
  @Prop({ required: true }) name!: string; // Field name, also used as the label

  // Optional properties
  @Prop({ default: "en" }) dateFormat!: string // date format to use
  // iso  = yyyy-mm-dd
  // en   = dd-mm-yyyy

  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) label!: string; // Alternate Label property
  @Prop({ default: false }) disabled!: boolean; // Turn input data entry off
  @Prop({ default: false }) append!: boolean; // Input label addon
  @Prop({ default: '' }) appendText!: string; // Input label addon
  @Prop({ default: false }) calculated!: boolean|string // Show Calculated indicator
  @Prop({ default: 'Calculated' }) calculatedText!: string; // Customize label for Calculated indicator
  @Prop({ default: false }) readonly!: boolean; // Render input as if it were plain text and turn input data entry off
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually, while still being readable for screen readers

  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: null }) ruleKey!: string // OPTIONAL parameter path to load client-side validation e.g. new_validations, edit_validations
                                            // used by input components to set 'rules' properties in their validation providers based on the client-side validations loaded from the back-end
  @Prop({ default: false }) legacy!: boolean; // flag to use legacy date control, set to true to use duet-date-picker
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix
  @Prop({ default: null }) minDateLimit!: any; // Minimum date limit
  @Prop({ default: null }) maxDateLimit!: any; // Maximum date limit
  @Prop({ default: null }) direction!: any; // direction
  

  // Since we are delegating between the duet-date-control and our custom input we need
  // to have keep track of the state. DuetDate Picker does not like having invalid values sent to it
  // it just clears it out. So we use this **HACK** to keep track of a last known good state just so that
  // the date picker is happy, while we can send the invalid value up to the
  // component as needed.
  private invalidValue: string|null = null;
  private lastGoodKnownValue: any = null;

  // masked input
  get isMasked(): boolean {
    if (!this.value) return false;
    return isMasked(this.value.toString());
  }

  // Format input value as some forms return bad data
  // We use the lastGoodKnownValue as a check to keep the date picker happy
  get getValue(): any {
    const val = this.invalidValue ? (this.lastGoodKnownValue ? this.lastGoodKnownValue : null) : this.value;
    return this.formatValue(val);
  }

  get getDefaultMinDate(): string {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 40); // default current - 40 years
    return (date.toISOString()).substring(0, 10); // return iso date-only string
  }

  get getDefaultMaxDate(): string {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1); // default current + 1 year
    return (date.toISOString()).substring(0, 10); // return iso date-only string
  }

  get getMinDateLimit(): string {
    // do this as we can't access methods inside the default prop
    return this.minDateLimit ? this.minDateLimit : this.getDefaultMinDate;
  }

  get getMaxDateLimit(): string {
    // do this as we can't access methods inside the default prop
    return this.maxDateLimit ? this.maxDateLimit : this.getDefaultMaxDate;
  }

  get getSettings(): any {
    switch (this.dateFormat) {
      case("en"):
        return {
          buttonLabel: "Choose date",
          placeholder: "DD-MM-YYYY",
          selectedDateMessage: "Selected date is",
          prevMonthLabel: "Previous month",
          nextMonthLabel: "Next month",
          monthSelectLabel: "Month",
          yearSelectLabel: "Year",
          closeLabel: "Close window",
          keyboardInstruction: "You can use arrow keys to navigate dates",
          calendarHeading: "Choose a date",
          dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
          monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          locale: 'en-GB',
          format: "dd-MM-yyyy", // format for date-fns
          ariaLabel: "Entry pattern is numeric with two digits for the day, two digits for the month and four digits for the year", // aria label
          vMask: "##-##-####", // v-mask
          regex: /^(\d{2})\-?(\d{2})\-?(\d{4})$/,
          createDate: function(matches: any) { return new Date(matches[3], matches[2] -1, matches[1]); }
        };

        break;
      case("iso"):
        return {
          buttonLabel: "Choose date",
          placeholder: "YYYY-MM-DD",
          selectedDateMessage: "Selected date is",
          prevMonthLabel: "Previous month",
          nextMonthLabel: "Next month",
          monthSelectLabel: "Month",
          yearSelectLabel: "Year",
          closeLabel: "Close window",
          keyboardInstruction: "You can use arrow keys to navigate dates",
          calendarHeading: "Choose a date",
          dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
          monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          locale: 'en-ISO',
          format: "yyyy-MM-dd", // format for date-fns
          ariaLabel: "Entry pattern is numeric with four digits for the year, two digits for the month, and two digits for the day", // aria label
          vMask: "####-##-##", // v-mask
          regex: /^(\d{4})\-?(\d{2})\-?(\d{2})$/,
          createDate: function(matches: any) { return new Date(matches[1], matches[2] -1, matches[3]); }
        };
        break;
    }
  }

  get getLocalizationSettingsForDuet(): any {
    const object = Object.fromEntries(
      Object.entries(this.getSettings)
        .filter(([key]) => [
          'buttonLabel',
          'placeholder',
          'selectedDateMessage',
          'prevMonthLabel',
          'nextMonthLabel',
          'monthSelectLabel',
          'yearSelectLabel',
          'closeLabel',
          'keyboardInstruction',
          'calendarHeading',
          'dayNames',
          'monthNames',
          'monthNamesShort',
          'locale',
          ].includes(key))
    );
    return object;
  }

  private isDateArrayValid(matches: any[]|null): boolean {
    if (!matches) return false;
    if (matches.length < 4) return false;

    let day, month, year = null;

    switch (this.dateFormat) {
      case("en"):
        day = matches[1];
        month = matches[2];
        year = matches[3];
        break;
      case("iso"):
        day = matches[3];
        month = matches[2];
        year = matches[1];
        break;
      default:
        return false;
        break;
    }

    if (year < 1600 || year > 9999 || month == 0 || month > 12 || day == 0 || day > 31) {
      return false; // invalid date
    } else {
      return true; // valid date
    }
  }

  public dateAdapter(): any {
    const _vm = this as DateInput;
    return Object.assign({},
      // parent listeners
      this.$listeners,
      {
        parse(value = "") {
          const provider = _vm.$refs.provider as any;
          const matches = value.match(_vm.getSettings.regex);

          const validMatch = _vm.isDateArrayValid(matches);
            provider.reset();

          if (validMatch) {
            // If we have the matches then we have a good value and we set it up.
            _vm.invalidValue = null;
            _vm.lastGoodKnownValue = value;
            return _vm.getSettings.createDate(matches);
          } else {
            // Invalid value to be passed to the parent.
            _vm.invalidValue = value;

            // only set error message if user entered value
            if (matches) provider.setErrors(`${i18n.tc('validation.messages.date')} ${_vm.getSettings.placeholder}`);
            return null;
          }
        },
        format(date: any) {
          // generate value for UI using chosen format
          return dateFns(date, _vm.getSettings.format);
        }
      }
    );
  }

  // Apply validation rules
  get formRules(): any {
    return this.getRules(this.ruleSet, this.ruleKey, this.rules);
  }

  mounted() {
    // If a value was set up on creation we can assume that it's a valid one and set it
    // up as the last good known value.
    if(this.value) {
      this.lastGoodKnownValue = this.value;
    }
  }
  // Strip time portion off ISO datetime string
  formatValue(value: string): string {
    return value ? value.substring(0, 10) : value;
  }

  // Forward events to the parent component
  public maskedInputEvents(): any {
    const _vm = this as DateInput;
    if (this.legacy) {
      return Object.assign({},
        // parent listeners
        this.$listeners,
        {
          // custom listeners
          input(event: any) {
            // Emit updated value for v-model
            _vm.$emit('input', event.target.value);
          }
        }
      );
    } else {
      return Object.assign({},
        // parent listeners
        this.$listeners,
        {
          // custom listeners
          input(event: any) {
            // We only want to bubble up the invalidValue as bubbling all values will mess up the
            // duet date picker as it already has the value it needs.
            if(_vm.invalidValue) {
              _vm.$emit('input', _vm.invalidValue);
            }
          },
          duetChange(event: any) {
            // In case of date selection from the calendar, we also have to set up the values
            _vm.lastGoodKnownValue = event.detail.value;
            _vm.invalidValue = null;
            // Emit updated value on change
            _vm.$emit('input', event.detail.value.length > 0 ? event.detail.value : null);
          }
        }
      );
    }
  }
}
