























































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { VueGoodTable } from 'vue-good-table';
import { Recipient } from '@/store/recipients/types';
import PageTop from '@/components/shared/PageTop.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { JourneyStage, JourneyStatus, PostTransplantState, RecipientJourney } from '@/store/recipientJourney/types';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Organ, InsurancePlanCode } from '@/store/lookups/types';
import { NumericCodeValue, GenericCodeValue } from '@/store/types';
import { OrganWaitlistMedicalStatus } from '@/store/lookups/types';
import { Format } from '@/store/utilities/types';
import { urlParams, isMasked } from '@/utils';
import LoadingListView from '@/components/shared/LoadingListView.vue';
import FullPageListLayout from "@/components/layouts/FullPageListLayout.vue";

@Component({
  components: {
    PageTop,
    SubSection,
    VueGoodTable,
    LoadingListView,
    FullPageListLayout
  }
})
export default class ListRecipients extends mixins(DateUtilsMixin) {
  @State(state => state.pageState.currentPage.recipientListSettings) private recipientListSettings!: any;
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.lookups.insurance_plan_codes) insurancePlanCodeLookup!: InsurancePlanCode[];

  //Getters
  @Getter('showList', { namespace: 'recipients' }) public recipients!: Recipient[];
  @Getter('organName', { namespace: 'lookups' }) organName!: (organCode?: number) => string;
  @Getter('describeMedicalStatus', { namespace: 'lookups' }) describeMedicalStatus!: (organLookup?: Organ[], organCode?: number, medicalStatusCode?: string|null, secondaryMedicalStatusCode?: string|null) => string|undefined;
  @Getter('medicalStatusLookup', { namespace: 'lookups' }) medicalStatusLookup!: (excludeHold: boolean, organLookup?: Organ[], organCode?: number) => any;
  @Getter('organOptions', { namespace: 'lookups' }) organOptions!: (type?: string) => NumericCodeValue[];
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('getJourneyStatusDisplayValue', { namespace: 'recipients' }) journeyStatusDisplayValue!: (journey: RecipientJourney) => string|undefined;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('canAccessOopRecipients', { namespace: 'users' }) private canAccessOopRecipients!: boolean;

  public isLoading = false;

  get getListOop(): boolean {
    return this.recipientListSettings && this.recipientListSettings.listOOP || false;
  }

  setListOOP(value = false): void {
    this.$store.commit('pageState/set', { pageKey: 'recipientListSettings', value: { listOOP: value }});
  }

  get getOOPToggleButtonText(): any {
    return this.getListOop ? this.$t('view_ontario_recipients') : this.$t('view_out_of_province_recipients');
  }

  private toggleTableByOOP(): void {
    this.setListOOP(!this.getListOop);
    this.loadData();
  }

  get canCreate(): boolean {
    return this.checkAllowed('/recipients', 'POST');
  }

  private lookupsToLoad = [
    'insurance_plan_codes'
  ];

  public currentPage = 1;
  public perPage = 25;
  public isOverflowed = false;

  public mounted(): void {
    this.$store.commit('setPageTitle', `Recipients`);
    // allow to use a permalink to view out of province recipients
    const listOOP = location.hash == '#oop-recipients' ? true : false;
    Promise.all([
      this.$store.commit('pageState/set', { pageKey: 'recipientListSettings', value: { listOOP: listOOP }})
    ]).finally(() => {
      this.loadData();
    });
    this.checkwidth();
    window.addEventListener('resize', this.checkwidth);
  }

  public checkwidth() {
    this.isOverflowed = window.innerWidth < 1200;
  }



  /**
   * Loads recipients list with the custom search parameters and sort options
   *
   */
  public loadData(searchParams='', sortParams='') {
    this.isLoading = true;
    const search_params = [searchParams, sortParams].filter((p) => { return p && p.length >=0; });
    const dispatchMethod = this.getListOop ? 'recipients/getListOop' : 'recipients/getList';
    this.$store.dispatch(
      dispatchMethod,
      {
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        search_params: `${search_params.length > 0 ? '&' : ''}${search_params.join('&')}`,
        oopResults: this.getListOop
      }
    ).finally(() => {
      this.isLoading = false;
    });
  }

  public selectRecipient(event: any) {
    if (event.row.client_id !== undefined) {
      const clientId: string = event.row.client_id.toString();
      const routerUrl = this.getListOop ? 'edit-recipient-oop' : 'edit-recipient';
      this.$router.push({
        name: routerUrl,
        params: {
          id: clientId,
        }
      });
    } else {
      console.warn('Missing recipient clientId');
      this.$router.push({name: 'list-recipients'});
    }
  }

  get insurancePlansOptions() {
    if ((this.insurancePlanCodeLookup || []).length <= 0) return [];
    return this.insurancePlanCodeLookup.map((plan) =>{
      return {
        value: plan.value,
        code: plan.value
      };
    });
  }

  public updatePagination(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);

    this.currentPage = event.currentPage;
    this.perPage = event.currentPerPage;
    this.loadData(search_params, sort_params);
  }

  /**
   * Sets the search terms and sorting options
   *
  */
  public filterList(event: any) {
    let search_params = urlParams(event?.searchParams);
    let sort_params = urlParams(event?.sortParams);
    this.currentPage = event.currentPage;
    this.loadData(search_params, sort_params);
  }

  private buildMedicalStatusFilter(): any {
    // Match options from query filters
    const filter: any[] = [];
    this.organOptions().forEach((organ) => {
      const options: OrganWaitlistMedicalStatus[] = this.medicalStatusLookup(true, this.organLookup, organ.code);
      // Map lookup format (code/value) to filter option format (value/text)
      options.forEach((option: OrganWaitlistMedicalStatus) => {
        const values: any[] = filter.flat().map((f) => { return f.value; });

        if(values.indexOf(option.value) < 0 ){
          filter.push({
            code: option.code,
            value: option.value
          });
        }
      });
    });

    return filter;
  }

  /**
   * Getter for the columns.
   *
   */
  get recipientsColumns(): any[] {
    return [
      {
          label: `${this.$t('ctr_id')}`,
          field: 'national_recipient_id',
          sortable: true,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('tgln_id')}`,
          field: 'client_id',
          sortable: true,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('first_name')}`,
          field: 'first_name',
          sortable: true,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('last_name')}`,
          field: 'last_name',
          sortable: true,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        },
        {
          label: `${this.$t('dob')}`,
          field: 'dob',
          type: 'date',
          sortable: false,
          width: '130px',
          thClass: 'vgt-left-align',
          tdClass: 'vgt-left-align',
          formatFn: this.formatDate
        },
        {
          label: `${this.$t('organ_referral')}`,
          field: 'organ',
          html: true,
          sortable: true,
          filterOptions: {
            enabled: true,
            type: 'text',
            custom: true,
          }
        },
        {
          label: `${this.$t('journey_status')}`,
          field: 'journey_status',
          sortable: true,
          html: true,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          },
          hidden: this.getListOop //Hide Journey Status for OOP Recipients
        },
        {
          label: `${this.$t('medical_status')}`,
          field: 'medical_status',
          sortable: false,
          html: true,
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'dropdown',
            placeholder: 'All',
            filterDropdownItems: this.buildMedicalStatusFilter()
          }
        },
        {
          label: `${this.$t('insurance_plan')}`,
          field: 'insurance_plan',
          sortable: true,
          filterOptions: {
            enabled: true,
            custom: true,
            placeholder: 'All',
            type: 'dropdown',
            filterDropdownItems: this.insurancePlansOptions
          }
        },
        {
          label: `${this.$t('insurance_number')}`,
          field: 'insurance_number',
          sortable: true,
          thClass: 'has-style',
          tdClass: 'has-style',
          width: '160px',
          filterOptions: {
            enabled: true,
            custom: true,
            type: 'text'
          }
        }
      ];
  }

  /**
   * Parse date-only field to be shown in table
   *
   * @param value the date property
   * @returns {string} string representation of date
   */
  formatDate(value: string|null): string {
    if (!value) return '-';
    if (isMasked(value)) return `${value}`;

    return this.parseDisplayDateUi(value) || '-';
  }

  /**
   * Translates any of the columns that are just codes and unreadable to a more readable format and adds it into the object
   *
   * Affects the following columns: Organ Referrals, Journey Status, Medical Status, Insurance Type
   */
  get recipientList(): any[] {
    if (!this.recipients || this.isLoading) {
      return [];
    } else {
      const recipients: any = this.recipients.entries;
      const result = recipients.map((recipient: Recipient) => {
        let metaData: any = {}; // data to inject

        // Find the Insurance Name from the lookup
        const insuranceName = this.lookupValue(`${recipient.insurance_plan_code}`, 'insurance_plan_codes') || '-';

        if (!recipient.journeys || recipient.journeys.length === 0 ) {
          metaData = {
            organ: 'None',
            insurance_plan: insuranceName
          };
        } else {
          const journeyOrgans = recipient.journeys.map((journey: RecipientJourney) => {
            const organCode = journey.organ_code;
            if (organCode == null) {
              return 'N/A';
            }
            const organName: string|undefined = this.organName(organCode) || undefined;
            return organName ? this.$t(organName) : 'Unknown';
          });
          const journeyStatus = recipient.journeys.map((journey: RecipientJourney) => {
            if (journey.stage == null) {
              return 'N/A';
            }
            //Display '--' for completed journey's
            // Completed superceded journey's will appear as Follow-up
            const is_superceded_post_transplant = journey.state == JourneyStatus.Superceded && journey.stage == JourneyStage.PostTransplant;

            if(journey.completed && !is_superceded_post_transplant) return '--';

            return this.journeyStatusDisplayValue(journey);
          });
          const journeyMedicalStatus = recipient.journeys.map((journey: RecipientJourney) => {
            if (journey.medical_status === undefined || journey.medical_status === null) {
              return 'N/A';
            }
            const medicalStatus = this.describeMedicalStatus(this.organLookup, journey.organ_code, journey.medical_status);
            return medicalStatus ? medicalStatus : 'Unknown';
          });
          metaData = {
            organ: journeyOrgans.join('<br /> '),
            journey_status: journeyStatus.filter(Boolean).join('<br /> '),
            medical_status: journeyMedicalStatus.filter(Boolean).join('<br /> '),
            insurance_plan: insuranceName
          };
        }
        return Object.assign(recipient, metaData);
      });
      return result;
    }
    return this.recipients;
  }

  private recipientTableConfig(): TableConfig {
    return {
      data: this.recipientList,
      columns: this.recipientsColumns,
      empty: 'Cannot find any Recipients that match the selected filters',
      createButton: false,
      pagination: true,
      sortOptions: {
        enabled: true
      },
      paginationOptions: {
        enabled: true,
        perPage: this.perPage,
        mode: 'pages',
        perPageDropdown: [10, 25, 100],
        setCurrentPage: this.currentPage,
        dropdownAllowAll: false,
        nextLabel: 'Older',
        prevLabel: 'Newer',
        rowsPerPageLabel: 'Results per page',
        position: 'bottom'
      }
    };
  }
}
