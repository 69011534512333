var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{ref:"provider",attrs:{"rules":_vm.formRules,"name":_vm.name,"vid":_vm.validationId ? _vm.validationId : _vm.inputId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{ 'sr-only': _vm.hideLabel },attrs:{"for":_vm.inputId}},[_vm._v(" "+_vm._s(_vm.label || _vm.name)+" "),_c('validation-asterisk',{attrs:{"rules":_vm.formRules,"crossValues":_vm.crossValues,"ruleKey":_vm.ruleKey}}),_vm._t("link")],2),(_vm.calculated)?_c('small',{staticClass:"form-text text-muted",attrs:{"title":"Calculated"}},[_c('font-awesome-icon',{staticClass:"text-grey",attrs:{"icon":['far', 'exclamation-circle'],"fixed-width":""}}),_vm._v(" "+_vm._s(_vm.calculatedText)+" ")],1):_vm._e(),(_vm.append)?[_c('div',{staticClass:"input-group mb-3"},[_c('input',_vm._g({directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],class:Object.assign({}, {'is-invalid': !_vm.disabled && errors[0], 
          'form-control': !_vm.isReadOnly(_vm.readonly), 
          'form-control-plaintext': _vm.isReadOnly(_vm.readonly)}, _vm.cssClasses,
          {'is-warning': _vm.showWarning}),attrs:{"id":_vm.inputId,"type":"text","readonly":_vm.isReadOnly(_vm.readonly||_vm.disabled),"tabindex":_vm.readonly ? -1 : 0,"title":_vm.showTitle ? _vm.getValue : ''},domProps:{"value":_vm.getValue}},_vm.inputEvents())),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.appendText))])]),(errors[0])?_c('div',{staticClass:"invalid-feedback",attrs:{"id":(_vm.inputId + "-error")}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'exclamation-circle'],"fixed-width":""}}),_vm._v(" "+_vm._s(_vm.translateError(errors))+" ")],1):_vm._e(),(!errors[0] && _vm.showWarning)?_c('div',{staticClass:"warning-feedback",attrs:{"id":(_vm.inputId + "-error")}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'exclamation-circle'],"fixed-width":""}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.getWarningMessage)}})],1):_vm._e()])]:[_c('input',_vm._g({directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],class:Object.assign({}, {'is-invalid': !_vm.disabled && errors[0], 
        'form-control': !_vm.isReadOnly(_vm.readonly), 
        'form-control-plaintext': _vm.isReadOnly(_vm.readonly)}, _vm.cssClasses,
        {'is-warning': _vm.showWarning}),attrs:{"id":_vm.inputId,"type":"text","readonly":_vm.isReadOnly(_vm.readonly||_vm.disabled),"tabindex":_vm.readonly ? -1 : 0,"title":_vm.showTitle ? _vm.getValue : ''},domProps:{"value":_vm.getValue}},_vm.inputEvents())),(errors[0])?_c('div',{staticClass:"invalid-feedback",attrs:{"id":(_vm.inputId + "-error")}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'exclamation-circle'],"fixed-width":""}}),_vm._v(" "+_vm._s(_vm.translateError(errors))+" ")],1):_vm._e(),(!errors[0] && _vm.showWarning)?_c('div',{staticClass:"warning-feedback",attrs:{"id":(_vm.inputId + "-error")}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'exclamation-circle'],"fixed-width":""}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.getWarningMessage)}})],1):_vm._e()]]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }