import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/procedures/actions';
import { getters } from '@/store/procedures/getters';
import { mutations } from '@/store/procedures/mutations';
import { ProceduresState } from '@/store/procedures/types';

const state: ProceduresState = {};

const namespaced = true;

export const procedures: Module<ProceduresState, RootState> = {
  state,
  actions,
  getters,
  mutations,
  namespaced,
};
