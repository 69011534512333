





















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CountDownClock extends Vue {
  @Prop({ required: true, default: 1 }) duration!: number;
  @Prop({ default: new Date() }) start!: string;

  public hours = 0;
  public minutes = 0;
  public seconds = 0;
  public expired = false;
  public running = false;

  public mounted() {
    const startTime = new Date(this.start).getTime();
    const duration = this.duration; 
    const endTime = new Date(startTime + duration).getTime();
    const now = new Date().getTime();

    // start the counter
    setInterval(() => {
      this.running = true;
      this.minutes = 60;
      const currentTime = new Date().getTime();

      // if passed start time, begin the clock
      if (currentTime >= startTime) {
        this.timerCount(startTime, endTime);
      }
    }, 1000);
  }

  timerCount(startTime: number, endTime: number) {
    const now = new Date().getTime();

    // is this a future countdown?
    const distanceTo = (startTime > now) ? (endTime - now) : (startTime - now);
    const distancefrom = endTime - now;

    if (distanceTo < 0 && distancefrom < 0) {
      // expired
      this.expired = true;
      this.calculateTime(distancefrom);
    } else if (distanceTo < 0 && distancefrom > 0) {
      // counting from
      this.calculateTime(distancefrom);
    } else if (distanceTo > 0 && distancefrom > 0) {
      // counting to
      // TODO: show 20 minute grace period when notification feature is added
      this.calculateTime(distanceTo);
    }
  }

  calculateTime(distance: number) {
    if (distance > 0) {
      // show negative numbers
      this.seconds = ~~((distance/1000) % 60 );
      this.minutes = ~~((distance/1000/60) % 60 );
      this.hours = ~~((distance/(1000*60*60)) % 24 );
    } else {
      // show positive numbers
      this.seconds = Math.abs(~~((distance % (1000 * 60)) / 1000));
      this.minutes = Math.abs(~~(((distance % (1000 * 60 * 60)) / (1000 * 60))));
      this.hours = Math.abs(~~((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    }
  }
}
