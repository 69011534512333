import Vue from 'vue';
import { MutationTree } from 'vuex';
import { HistoryState, HistoryVxm } from '@/store/history/types';

export const mutations: MutationTree<HistoryState> = {
  clearVxm(state) {
    Vue.set(state, 'vxm', null);
  },
  setVxm(state, historyVxm: HistoryVxm[]) {
    Vue.set(state, 'vxm', historyVxm);
  },
  clearSelectedVxm(state) {
    Vue.set(state, 'selectedVxm', null);
  },
  selectVxm(state, historyVxm: HistoryVxm) {
    Vue.set(state, 'selectedVxm', historyVxm);
  },
};
