import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/surgicalPersons/actions';
import { getters } from '@/store/surgicalPersons/getters';
import { mutations } from '@/store/surgicalPersons/mutations';
import { SurgicalPerson, SurgicalPersonsState } from '@/store/surgicalPersons/types';

const state: SurgicalPersonsState = {
  surgicalPeopleFiltered: []
};

const namespaced = true;

export const surgicalPersons: Module<SurgicalPersonsState, RootState> = {
  namespaced,
  mutations,
  actions,
  state,
  getters,
};
