
































import { State } from 'vuex-class';
import { ModalContent, ClassObject } from '@/types';
import { Component, Vue } from 'vue-property-decorator';
import ModalSection from '@/components/shared/ModalSection.vue';

interface WaitlistOutcomeModalState {
  style: string;
  body: string;
}

@Component({
  components: {
    ModalSection,
  }
})
export default class WaitlistOutcomeModal extends Vue {
  @State(state => state.pageState.currentPage.wailistOutcomeModalState) editState!: WaitlistOutcomeModalState;

  // Reference modal component to provide access to its functions
  get modalSection(): ModalSection|null {
    const modalSection = this.$refs.waitlistOutcomeModal as ModalSection;
    return modalSection ? modalSection : null;
  }

  // Combine specified modal style without default classes
  get waitlistOutcomeModalClass(): string {
    if (!this.editState) {
      return 'modal-content';
    } else {
      return `modal-content ${this.editState.style}`;
    }
  }

  // Build and show the modal section
  public initialize(modalContent: ModalContent) {
    if (!this.modalSection) {
      return;
    }
    this.$store.commit('pageState/set', {
      pageKey: 'wailistOutcomeModalState',
      value: {
        style: modalContent.style,
        body: modalContent.body,
      }
    });
    this.modalSection.showModal();
  }

  // Hide the modal section
  public dismiss() {
    if (!this.modalSection) {
      return;
    }
    this.$store.commit('pageState/set', {
      pageKey: 'wailistOutcomeModalState',
      value: undefined,
    });
    this.modalSection.hideModal();
  }
}
