











































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { OrganCodeValue } from '@/store/lookups/types';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { WaitlistSectionPageState } from '@/components/organs/shared/_WaitlistSection.vue';
import { RecipientJourney, RecipientWaitlistAttributes, RecipientWaitlistFactors } from '@/store/recipientJourney/types';

export interface WaitlistSummaryState {
  listDate: string|null;
  dialysisDate: string|null;
}

@Component({
  components: {
    DateInput,
    SubSection,
  },
})
export default class WaitlistSummary extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.waitlistSection) editState!: WaitlistSectionPageState;

  // Props
  @Prop({ default: false }) newJourney!: boolean;

  // Organ code from top-level journey organ_code or from the route params
  get organCode(): number {
    if (this.newJourney) return Number(this.$route.params.organ_code);
    return this.journey?.organ_code ? this.journey.organ_code : 0;
  }

  // Whether or not to show fields associated with Kidney organ
  get showKidneyFields(): boolean {
    return this.organCode === OrganCodeValue.Kidney;
  }

  // Initialize state based on Recipient Journey data
  private mounted(): void {
    this.initializeForm();
    this.$emit('loaded', 'waitlistSummary');
  }

  /**
   * Initialize form state based on latest Recipient Journey data
   *
   * Note: assumes that the 'waitlistSection' page key has been already been set in the
   * _WaitlistSection card-section component before this component sets its nested state
   */
  private initializeForm(): void {
    // Commit our state
    this.$store.commit('pageState/set', {
      pageKey: 'waitlistSection',
      componentKey: 'summary',
      value: this.buildWaitlistSummaryState()
    });
  }

  /**
   * Build state for the Waitlist Summary area based on selected journey
   * 
   * @returns {WaitlistSummaryState} form state for Waitlist Summary
   */
  private buildWaitlistSummaryState(): WaitlistSummaryState {
    const waitlistAttributes = this.journey?.stage_attributes?.waitlist || {};
    const waitlistFactors = waitlistAttributes?.factors || {};
    const result = {
      listDate: this.parseDateUi(waitlistFactors.listing_date) || null,
      dialysisDate: this.parseDateUi(waitlistFactors.dialysis_start_date) || null,
    };
    return result;
  }

  // Reload component when any the journey's waitlist attributes and/or factors change
  @Watch('journey.stage_attributes.waitlist')
  private onWaitlistAttributesChanged(): void {
    this.initializeForm();
  }
}
