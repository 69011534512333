import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/utilities/actions';
import { getters } from '@/store/utilities/getters';
import { UtilitiesState } from '@/store/utilities/types';

const state: UtilitiesState = {};

const namespaced = true;

export const utilities: Module<UtilitiesState, RootState> = {
  namespaced,
  actions,
  getters,
  state,
};
