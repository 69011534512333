






















import { Getter }  from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import CardSection from '@/components/shared/CardSection.vue';
import HlaViewVirtualCrossmatchHistory from '@/components/hla/HlaViewVirtualCrossmatchHistory.vue';
import HlaPerformSpecifiedVirtualCrossmatch from '@/components/hla/HlaPerformSpecifiedVirtualCrossmatch.vue';

@Component({
  components: {
    CardSection,
    HlaViewVirtualCrossmatchHistory,
    HlaPerformSpecifiedVirtualCrossmatch,
  }
})
export default class HlaVirtualCrossmatch extends Vue {
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;

  // List lookup data to be loaded by the card section component
  private lookupsToLoad = [
    'hla_testing_method',
  ];

  // Load recipient VXM history
  private loaded(): void {
    this.$emit('loaded', 'hlaVirtualCrossmatch');
    this.$store.dispatch('history/loadRecipientVxm', this.recipientId);
  }
}
