import Vue from 'vue';
import { MutationTree } from 'vuex';
import { ProceduresState } from '@/store/procedures/types';

export const mutations: MutationTree<ProceduresState> = {
  setStemCellTherapy(state, procedure) {
    Vue.set(state, 'stemCellTherapy', procedure);
  },
  clearStemCellTherapy(state) {
    Vue.set(state, 'stemCellTherapy', undefined);
  },
};
