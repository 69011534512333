

































































































































































































































































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { TableConfig } from '@/types';
import { VueGoodTable } from 'vue-good-table';
import { DeceasedDonor, DeceasedDonorAttachment } from '@/store/deceasedDonors/types';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import SelectInput from '@/components/shared/SelectInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import { SaveProvider, SaveResult } from '@/types';
import { AttachmentCategory } from '@/store/lookups/types';
import SaveToolbar from "@/components/shared/SaveToolbar.vue";

// Declare all v-model fields used in the form
interface DonorDocumentsForm {
  id?: string;
  fileName?: string;
  ctrStatus?: string;
  clinicalAttachments?: FileList;
  description?: string;
  uploadedFiles?: UploadedFile[];
  categoryCode?: number;
}

interface UploadedFile {
  category?: string;
  dateUploaded?: string;
  fileName?: string;
  fileType?: string;
  description?: string;
}

interface TableRow {
  oid: string;
  category?: string;
  category_code?: number;
  dateUploaded?: string;
  fileName?: string;
  ctrFileName?: string;
  fileType?: string;
  description?: string;
  uploadedBy?: string;
  ctrStatusCode?: string;
  ctrStatusText?: string;
  editRemove?: string;
  checked?: boolean;
}

@Component({
  components: {
    CardSection,
    VueGoodTable,
    SubSection,
    SelectInput,
    TextInput,
    CheckboxInput,
    TextAreaInput,
    ModalSection,
    SaveToolbar
  }
})
export default class DonorDocuments extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.deceasedDonors.selected) private deceasedDonor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.donorDocuments) editState!: DonorDocumentsForm;
  @State(state => state.deceasedDonors.attachments) attachments!: DeceasedDonorAttachment[];

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = [ 'attachment_category' ];

  // Getters
  @Getter('documentCategoryCode', { namespace: 'deceasedDonors' }) private documentCategoryCodeLookup: any;
  @Getter('AttachmentCategoryOptions', { namespace: 'lookups' }) attachmentCategoryOptions!: (enableDonor: boolean) => AttachmentCategory[];
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('getUserName', { namespace: 'users' }) private getUserName!: string;

  public currentPageTable = 1;
  public perPage = 10;
  public selectedDocuments: string[] = [];
  private isTableUpdating = false;
  private taskInProgress = false;

  get hasWriteAccess(): boolean {
    return this.isGroupWriteable("donor_attachments");
  }

  get isOopDonor(): boolean {
    return this.deceasedDonor.indicators?.out_of_province || false;
  }

  // Prepare donor attachments for presentation in the historical tables.
  get tableRows(): TableRow[] {
    const attachments = this.attachments || [];
    return attachments.map((item: DeceasedDonorAttachment) => {
      return {
        oid: item._id!['$oid'],
        uuid: item.uuid,
        deleted: item.deleted,
        category: this.attachmentCategoryValue(item.category_code) || '-',
        category_code: item.category_code,
        dateUploaded: this.parseDisplayDateUiFromDateTime(item.created_at),
        fileName: !item.from_ctr ? item.original_filename : '-',
        ctrFileName: item.from_ctr && item.original_filename ? item.original_filename : '-',
        fileType: item.mime_type,
        description: item.description || '-',
        uploadedBy: item.updated_by ? item.updated_by : item.created_by,
        fromCtr: item.from_ctr,
        ctrStatusCode: item.ctr_status,
        ctrStatusText: this.$t(item.ctr_status || '').toString(),
        checked: false
      };
    });
  }

  /**
  *
  */
  get tableConfig(): TableConfig {
    const tableConfig = [
      { label: `${this.$t('attachment_date')}`,
        field: 'dateUploaded',
        width: '10%',
        sortable: true
      },
      { label: `${this.$t('fileName')}`,
        field: 'fileName',
        sortable: true
      },
      { label: `${this.$t('ctr_file_name')}`,
        field: 'ctrFileName',
        sortable: true
      },
      { label: `${this.$t('category')}`,
        field: 'category',
        width: '10%',
        sortable: true
      },
      { label: `${this.$t('fileType')}`,
        field: 'fileType',
        width: '10%',
        sortable: true
      },
      { label: `${this.$t('description')}`,
        field: 'description',
        sortable: true
      },
      { label: `${this.$t('uploadedBy')}`,
        field: 'uploadedBy',
        width: '10%',
        sortable: true
      },
      { label: `${this.$t('ctr_status')}`,
        field: 'ctrStatusText',
        width: '10%',
        sortable: true
      }
    ];

    if (this.hasWriteAccess) { tableConfig.push({label: '', field: 'editRemove', width: '5%',sortable: false}); }
    if (this.hasWriteAccess) { tableConfig.unshift({label: '', field: 'checkbox', width: '5%',sortable: false}); }

    return {
      data: this.tableRows,
      columns: tableConfig,
      createButton: true,
      createText: `${this.$t('attach_new_document')}`,
      sortOptions: {
        enabled: true,
        initialSortBy: {field: 'dateUploaded', type: 'desc'}
      },
      paginationOptions: {
      enabled: true,
      mode: 'records',
      perPage: this.perPage,
      perPageDropdown: [10, 25, 50],
      setCurrentPage: this.currentPageTable,
      dropdownAllowAll: true,
      nextLabel: this.$t('table.older'),
      prevLabel: this.$t('table.newer'),
      rowsPerPageLabel: this.$t('table.results'),
      },
    };
  }

   // Show loading state or empty message
  get emptyMessage(): string {
    if (!this.attachments) {
      return this.$t('loading').toString();
    } else {
      return this.$t('use_form_below').toString();
    }
  }

  // Event handlers

  /**
    * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
    *
    * @listens #mounted
    */
  private mounted(): void {
    Vue.nextTick(() => {
      this.$store.commit('pageState/set', {
        pageKey: 'donorDocuments',
        value: {}
      });
    });
    this.$store.dispatch('deceasedDonors/loadDonorAttachments', this.deceasedDonor.client_id);
  }

  // Initialize the form
  private initialize(): void {
      this.$store.commit('pageState/set', {
        pageKey: 'donorDocuments',
        value: {}
      });
  }

  /**
    * Emits a loaded event after all subcomponents have finished loading.
    *
    * The Donor Documents card section emits a loaded event when it finishes loading lookup tables. This is the only
    * loading process that this form component is responsible for, so it immediately emits its own loaded event.
    *
    * @listens donorDocuments#loaded
    * @emits loaded
    */
  public loaded(): void {
    this.$emit('loaded', 'donorDocuments');
  }

  /**
   * Clears out the form when clicking the clear button, so that new files can be uploaded instead.
   */
  private onFormClear(): void {
    this.clearForm();
  }

  // toggles selection of document
  private toggleDocumentSelection(row: TableRow): void {
    if (!row.checked) {
      this.selectedDocuments.push(row.oid);
    } else {
      this.selectedDocuments.splice(this.selectedDocuments.indexOf(row.oid), 1);
    }
  }

  // Sets the passed files data to the editState and toggles the delete Modal
  private onDeleteFileClick(row?: TableRow): void {
    if (row) {
      Vue.set(this.editState, 'fileName', row.fileName);
      Vue.set(this.editState, 'id', row.oid);
      Vue.set(this.editState, 'ctrStatus', row.ctrStatusCode);
    }

    this.toggleModal('deleteModal');
  }

  // Handle deleting a file. Called after user accepts delete warning modal dialog popup
  private deleteFile(): void {
    const saveProvider = this.$refs.uploadDocument as unknown as SaveProvider;

    this.$store.dispatch('deceasedDonors/deleteFile', {
      id: this.editState.id,
      donorId: this.deceasedDonor.client_id,
    }).then((result: SaveResult) => {
      this.$store.dispatch('deceasedDonors/loadDonorAttachments', this.deceasedDonor.client_id).then((response) => { 
        this.clearForm();
        if(response.success) saveProvider.registerSaveResult(result);
        this.initialize();
      }).catch((result: SaveResult) => {
        this.$emit('handleErrors', result);
        saveProvider.registerSaveResult(result);
    });
    }).catch((result: SaveResult) => {
      this.$emit('handleErrors', result);
      saveProvider.registerSaveResult(result);
    });

    this.toggleModal('deleteModal');
  }


  // Handles uploading of selected documents to CTR
  private uploadDocumentsToCtr(): void {
    this.taskInProgress = true;
    const payload = {
      donorId: this.deceasedDonor.client_id,
      attachmentIds: this.selectedDocuments
    };

    const saveToolbar = this.$refs.uploadToCtr as unknown as SaveToolbar;

    // Show upload is in progress
    saveToolbar.startSaving();

    this.$store.dispatch('deceasedDonors/uploadDocumentsToCtr', payload).then((success: SaveResult) => {
      // Show upload success
      saveToolbar.stopSaving({success: true});
      this.$store.dispatch('deceasedDonors/loadDonorAttachments', this.deceasedDonor.client_id);
      this.clearForm();
      this.$store.commit('deceasedDonors/clearAttachments');
      this.$emit('save', success);
      this.initialize();
      this.taskInProgress = false;
    }).catch((error: any) => {
      // Show upload error
      saveToolbar.stopSaving(error);
      this.taskInProgress = false;
    });

    this.toggleModal('uploadModal');
  }

  // Downloads documents from CTR
  private downloadDocumentsFromCtr(): void {
    this.taskInProgress = true;
    const payload = {
      donorId: this.deceasedDonor.client_id,
    };

    const saveToolbar = this.$refs.downloadFromCtr as unknown as SaveToolbar;

    // Show downlad is in progress
    saveToolbar.startSaving();

    this.$store.dispatch('deceasedDonors/downloadDocumentsFromCtr', payload).then((success: SaveResult) => {
      // Show download is successful
      saveToolbar.stopSaving({success: true});
      this.$store.dispatch('deceasedDonors/loadDonorAttachments', this.deceasedDonor.client_id);
      this.clearForm();
      this.$emit('save', success);
      this.initialize();
      this.taskInProgress = false;
    }).catch((error: SaveResult) => {
      // Show download error
      saveToolbar.stopSaving(error);
      this.taskInProgress = false;
    });

    this.toggleModal('downloadModal');
  }

  // Toggle a modal based on a ref
  private toggleModal(ref: string): void {
    const targetModal = this.$refs[ref] as ModalSection;
    targetModal.toggleModal();
  }

  /**
   * Event handle run when clicking on the edit button on a row in the uploaded files table.
   */
  private editFile(row: TableRow) {
    if (row) {
      Vue.set(this.editState, 'fileName', row.fileName);
      Vue.set(this.editState, 'id', row.oid);
      Vue.set(this.editState, 'ctrStatus', row.ctrStatusCode);
    }

    this.initializeUploadFormFromTableRow(row);
  }

  private onCreateButtonClicked(): void {
    this.clearForm();
  }

  public initializeUploadFormFromTableRow(row: TableRow): void {

    const attachments = this.attachments || [];
    //coz we need original value of description in TableRow we overwrite "" by "-"
    const deceasedDonorAttachment = attachments.filter((item: DeceasedDonorAttachment) => { return item._id?.$oid === row.oid; })[0];

    this.$store.commit('pageState/set', {
      pageKey: 'donorDocuments',
      value: {
        id: deceasedDonorAttachment._id?.$oid,
        fileName: deceasedDonorAttachment.original_filename,
        categoryCode: deceasedDonorAttachment.category_code,
        description: deceasedDonorAttachment.description,
      }
    });
    (this.$refs.fileUploader as any).value = null;
  }

  /**
    * Updates form state when Clinical Attachments files are uploaded
    *
    * @listens clinicalAttachments#changed
    */
  private onClinicalAttachmentsChanged(event: any) {
    if (!!this.editState && !!event.target) {{
      this.editState.clinicalAttachments = event.target.files;

      if (this.editState.clinicalAttachments && this.editState.clinicalAttachments[0]) {
        Vue.set(this.editState, 'fileName', this.editState.clinicalAttachments[0].name);
      } else {
        Vue.set(this.editState, 'fileName', '');
      }
    }}
  }

  // Private methods

  /**
   * Populates the Donor Documents form state with data from the selected Deceased Donor.
   */
  public clearForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'donorDocuments',
      value: {
      }
    });

    this.$emit('clear');
    this.resetSaveToolbar();

    (this.$refs.fileUploader as any).value = null;
  }

  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = (this.$refs.uploadDocument as SubSection).$refs['save-deceasedDonor-upload-document'] as SaveToolbar;
    saveToolbar.reset();
  }

  /**
   * Event handler triggered when uploading a new document, or editing an existing one.
   */
  public uploadDocument(): void {
    const saveProvider = this.$refs.uploadDocument as unknown as SaveProvider;
    const payload: any = {
      id: this.editState.id,
      fileList: this.editState.clinicalAttachments,
      fileName: this.editState.fileName,
      donorId: this.deceasedDonor.client_id,
      description: this.editState.description || '',
      categoryCode: this.editState.categoryCode,
    };
    this.isTableUpdating = true;

    this.$store.dispatch('deceasedDonors/uploadFile', payload).then((result: SaveResult) => {
      this.$store.dispatch('deceasedDonors/loadDonorAttachments', this.deceasedDonor.client_id).then(() => {
        this.isTableUpdating = false;
      }).catch(() => {
        this.isTableUpdating = false;
      });
      this.clearForm();
      saveProvider.registerSaveResult(result);
      this.$emit('save', result);
      this.initialize();
    }).catch((result: SaveResult) => {
      this.$emit('handleErrors', result);
      saveProvider.registerSaveResult(result);
      this.isTableUpdating = false;
    });
  }

  public generateDownloadLink(oid: string): void {
    const payload = {
      donorId: this.deceasedDonor.client_id,
      id: oid,
    };

    this.$store.dispatch('deceasedDonors/downloadFile', payload).then((result: any) => {
      const link = document.createElement('a');
      link.href = result.url;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
    }).catch((result: any) => {
      // Show download error
      alert(this.$t('error_generating_download_link').toString());
    });
  }

  // API responses on the left, UI IDs on the right
  public idLookup: {[key: string]: string} = {
    'file': 'donorAttachment-clinicalAttachment',
    'original_filename': 'donorAttachment-fileName',
    'category_code': 'donorAttachment-categoryCode'
  }

  // PRIVATE

  /**
   * Use the document_attachment_category lookup table to convert from the code to the value (the visual label to present on the tables).
   *
   * @param categoryCode numeric code from a given attachment representing the Attachment Category
   * @returns {string|undefined} attachment's category as text
   */
  private attachmentCategoryValue(categoryCode?: number): string|undefined {
    const lookupTable = this.attachmentCategoryOptions(true);

    if (!!lookupTable) {
      const entry = lookupTable.find((category: AttachmentCategory) => category.code == categoryCode);

      if (!!entry) {
        return entry.value;
      }
    }

    return undefined;
  }
}
