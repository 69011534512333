import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/history/actions';
import { getters } from '@/store/history/getters';
import { mutations } from '@/store/history/mutations';
import { HistoryState } from '@/store/history/types';

const state: HistoryState = {
  vxm: null,
  selectedVxm: null,
};

const namespaced = true;

export const history: Module<HistoryState, RootState> = {
  namespaced,
  mutations,
  actions,
  state,
  getters,
};
