





































import { Getter, State } from 'vuex-class';
import { Recipient } from '@/store/recipients/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SelectedRecipientJourneyLinks from "@/components/recipients/side-nav/SelectedRecipientJourneyLinks.vue";
import SelectedRecipientHlaLinks from "@/components/recipients/side-nav/SelectedRecipientHlaLinks.vue";
import SelectedRecipientProfileLinks from "@/components/recipients/side-nav/SelectedRecipientProfileLinks.vue";
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedRecipientJourneyLinks,
    SelectedRecipientProfileLinks,
    SelectedRecipientHlaLinks
  }
})
export default class SideNavHla extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  @Getter('selectedRecipientJourneysList', { namespace: 'recipients' }) private selectedRecipientJourneysList!: { id: string; name: string, organCode: number }[];

  public sideNavClosed = false;

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }

  public historyBack(): void {
    const navigationType = window.performance ? window.performance.navigation.type : 0;
    if(navigationType < 2){
      //if is from refer link then using just
      this.$router.back();
    }
    else {
      //if is from refresh after refer link
      document.location.href = document.referrer;
    }
  }

  get noHistory(): boolean {
    const navigationType = window.performance ? window.performance.navigation.type : 0;
    const historyLength = window.history.length || 0;
   //implemented  after new tab/window (0,1) +refresh(1,1-2)
    return navigationType < 2 && historyLength <= 2;

  }

}
