





















import { State, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { organCodeLookup } from '@/types';
import { Recipient } from "@/store/recipients/types";

@Component
export default class SelectedRecipientProfileLinksOop extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  @Prop({ default: false }) active!: boolean;

  public show = true;

  public toggleNav() {
    this.show = !this.show;
  }

  /**
   * Gets the recipient client_id.
   *
   * Converts the recipient client_id to a string
   *
   * @returns {string} client_id as a string
   */
  get clientId(): string {
    return this.recipient.client_id == null ? '' : this.recipient.client_id.toString();
  }
  
  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    return hash;
  }
}
