import { render, staticRenderFns } from "./DownstagingTherapy.vue?vue&type=template&id=4815b28a&"
import script from "./DownstagingTherapy.vue?vue&type=script&lang=ts&"
export * from "./DownstagingTherapy.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/organs/liver/_locales/DownstagingTherapy.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fliver%2FDownstagingTherapy.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports