import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { UsersState } from '@/store/users/types';
import { actions } from '@/store/users/actions';
import { getters } from '@/store/users/getters';
import { mutations } from '@/store/users/mutations';

const state: UsersState = {};

const namespaced = true;

export const users: Module<UsersState, RootState> = {
  namespaced,
  actions,
  getters,
  mutations,
  state
};
