



































































import { Getter } from 'vuex-class';
import { TableConfig } from '@/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import SubSection from '@/components/shared/SubSection.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';

@Component({
  components: {
    SubSection,
  }
})
export default class TherapiesSection extends Vue {
  @Getter('selectedJourney', { namespace: 'journeyState'}) private journey!: RecipientJourney;
  
  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  get therapies(): RecipientJourney {
    return this.journey;
  }

  // TODO: remove all old table config and saving
  public selectedTherapy: RecipientJourney|null = null; // value of actual existing entry, or null when creating a new one
  public therapyTableConfig(): TableConfig {
    return {
      data: [],
      columns: [
        { label: this.$t('bridging_therapy_date').toString(), field: 'date_analysis', width: '25%' },
        { label: this.$t('bridging_therapy_type').toString(), field: 'bridging_therapy_type', width: '25%' },
        { label: this.$t('downstaging_date').toString(), field: 'downstaging_date', width: '25%' },
        { label: this.$t('downstaging_type').toString(), field: 'downstaging_type', width: '25%' }
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: true,
      createText: this.$t('create_therapy').toString()
    };
  }
  public isValidTherapy(): boolean {
    return true;
  }
  public saveTherapy() {
    return true;
  }
  public selectTherapy(event: any) {
    return true;
  }
  public createTherapy() {
    return true;
  }
}
