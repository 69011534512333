










import { Component, Vue, Prop } from 'vue-property-decorator';
import { organCodeLookup } from '@/types';

@Component
export default class OrganIcon extends Vue {
  // Props
  @Prop({ default: null }) organCode!: string; // organ code
  @Prop({ default: false }) summaryBarIcon!: string; // summary bar
  @Prop({ default: false }) compareModalIcon!: string; // compare modal
  @Prop({ default: null }) title!: string|null; // title attribute

  /**
   * Return organ name from the organ code using organCodeLookup
   * 
   * @returns {string} organ name
   */
  get organName(): string {
    return this.organCode ? organCodeLookup[this.organCode] : '';
  }

  /**
   * Return organ icon code for font-awesome
   * 
   * @returns {string[]} font-awesome code
   */
  get organIcon(): string[] {
    // TODO: Add islets / vca / small bowel when available
    switch (this.organName) {
      case 'liver':
        return ['fac', 'liver'];
        break;
      case 'lung':
        return ['fas', 'lungs'];
        break;
      case 'heart':
        return ['fas', 'heart'];
        break;
      case 'kidney':
        return ['fas', 'kidneys'];
        break;
      case 'pancreas-whole':
        return ['fac', 'pancreas-whole'];
        break;
      case 'pancreas-for-islets':
        return ['fac', 'pancreas-whole'];
        break;
      default:
        return ['fas', 'circle'];
    }
  }

  /**
   * Return organ icon custom class (some organs require special classes)
   * 
   * @returns {string} font-awesome class
   */
  get organIconClass(): string {
    if (this.compareModalIcon || this.summaryBarIcon) {
      switch (this.organName) {
        case 'pancreas-whole':
        case 'pancreas-for-islets':
          return 'fa-flip-vertical';
          break;
        default:
          return '';
      }
    }
    switch (this.organName) {
      case 'pancreas-whole':
      case 'pancreas-for-islets':
        return 'fa-stack-1x inverse fa-flip-vertical';
        break;
      default:
        return 'fa-stack-1x inverse';
    }
  }
}
