import { render, staticRenderFns } from "./_BaseWidget.vue?vue&type=template&id=5af94d74&"
import script from "./_BaseWidget.vue?vue&type=script&lang=ts&"
export * from "./_BaseWidget.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports