





























import { State, Getter } from 'vuex-class';
import { Component, Vue, } from 'vue-property-decorator';
import LoginByUser from '@/components/login/LoginByUser.vue';

@Component({
  components: {
    LoginByUser
  },
})
export default class Login extends Vue {
  @State(state => state.pageState.currentPage.login) private editState!: any;

  get isAfflo(): boolean {
    return process.env.VUE_APP_LOGO === 'AFFLO';
  }

  private mounted(): void {
    this.$store.commit('setPageTitle', 'Log in');
  }
}

