import axios from 'axios';
import { ActionTree } from 'vuex';
import { SaveResult } from '@/types';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { buildErrorResult, handleAllocationErrors } from '@/utils';
import { WaitlistState, WaitlistQuery, WaitlistQueryResult } from '@/store/waitlist/types';

export const actions: ActionTree<WaitlistState, RootState> = {
  submitWaitlistQuery({ commit }, query: WaitlistQuery): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Clear any previous response
      commit('setWaitlistQueryResult', null);
      const organCode: string = query.organ_code;
      // Waitlist Grid endpoint is POST so we can send filter parameters as a payload
      const ep: string = APIRoute(EP.waitlist.grid, [[':organCode', organCode]]);
      axios.post(ep, query).then((response: any) => {
        // Check if query was successful
        const isSuccessful = !response.data.errors;
        if (isSuccessful) {
          // Store successful response
          const sanitizedResult: WaitlistQueryResult = response.data.waitlist;
          commit('setWaitlistQueryResult', sanitizedResult);
          // Resolve promise with successful SaveResult
          const result: SaveResult = {
            success: true,
            responseData: response.data,
          };
          resolve(result);
        } else {
          // Reject promise with a SaveResult containing meaningful errors
          const result: SaveResult = handleAllocationErrors(response.data.errors);
          reject(result);
        }
      }).catch((error: any) => {
        // Reject promise with a SaveResult containing meaningful errors
        const result: SaveResult = handleAllocationErrors(error);
        reject(result);
      });
    });
  },
  printWaitlistReport({ commit }, query: any): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // Waitlist Grid endpoint is POST so we can send filter parameters as a payload
      const organCode: string = query.organ_code;
      const ep: string = APIRoute(EP.waitlist.print, [[':organCode', organCode]]);
      axios.post(ep, query).then((response: any) => {
        // Check if query was successful
        const isSuccessful = !response.data.errors;
        if (isSuccessful) {
          // Resolve promise with successful SaveResult
          const result: SaveResult = {
            success: true,
            responseData: response.data,
          };
          resolve(result);
        } else {
          // Reject promise with a result containing meaningful errors
          const result: any = response.data.errors;
          reject(result);
        }
      }).catch((error: any) => {
        // Reject promise with a result containing meaningful errors
        const result: any = error;
        reject(error);
      });
    });
  }
};
