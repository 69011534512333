





















import { Getter } from 'vuex-class';
import { Recipient } from '@/store/recipients/types';
import { Component, Vue } from 'vue-property-decorator';
import PageTop from '@/components/shared/PageTop.vue';
import LoadingSideNav from '@/components/shared/side-nav/LoadingSideNav.vue';
import LoadingRecipientsPage from '@/components/shared/LoadingRecipientsPage.vue';


@Component({
  components: {
    PageTop,
    LoadingSideNav, 
    LoadingRecipientsPage
  },
})
export default class BaseRecipient extends Vue {
  @Getter('show', { namespace: 'recipients' }) private recipient!: Recipient;
}
