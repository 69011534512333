import Vue from 'vue';
import VueI18n from 'vue-i18n';
import customValidationMessages from '@/views/_locales/errorMessages.json';
import common from '@/components/_locales/common.json';

Vue.use(VueI18n);

// combine default & custom messages
const messagesEN = {
  code: 'en',
  messages: { 
    ...customValidationMessages.en
  }
};

const messagesFR = {
  code: 'fr',
  messages: { 
    ...customValidationMessages.fr
  }
};

// export i18n with validation messages
export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'xx', //for keys tests
  messages: {
    en: {
      ...common.en, 
      validation: messagesEN
    },
    fr: {
      ...common.fr, 
      validation: messagesFR
    }
  }
});
