















































































import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from "vue-class-component";
import { SaveResult } from '@/types';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { HospitalOption, ACTIVE_ONTARIO_TRANSPLANT_PROGRAM } from '@/store/hospitals/types';
import { TransplantActivityReportState, TransplantActivityReportQuery } from '@/store/reports/types';
import { Organ, OrganCodeValue } from '@/store/lookups/types';
import { User } from '@/store/users/types';

@Component({
  components: {
    SaveToolbar,
    SelectInput,
    DateInput,
    SubSection
  }
})

export default class TransplantActivityReport extends mixins(DateUtilsMixin)  {
  // State
  @State(state => state.pageState.currentPage.transplantActivityReportState) editState!: TransplantActivityReportState;

  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.users.user) user!: User;

  @Getter('hospitalOptionsOntarioTransplant', { namespace: 'hospitals' }) hospitalOptionsOntarioTransplant!: HospitalOption[];

  @Getter('allOptionsPlaceholderValue', { namespace: 'reports' }) allOptionsPlaceholderValue!: (state: any, disabled?: boolean) => string;

  public mounted(): void {
    this.$store.dispatch('hospitals/load', ACTIVE_ONTARIO_TRANSPLANT_PROGRAM).then(() => {
      this.$store.commit('pageState/set', {
        pageKey: 'transplantActivityReportState',
        value: this.buildTransplantActivityPageState(),
      });
    });
  }

  public buildTransplantActivityPageState(): TransplantActivityReportState {
    return {
      organ: undefined,
      hospital: undefined,
      startDate: undefined,
      endDate: this.defaultDate,
    };
  }

  get filteredOrganLookup() {
    // remove vessels for banking
    return this.organLookup.filter((organ: Organ) => {
      return organ.code != OrganCodeValue.VesselsForBanking;
    });
  }

  /**
   * If user.hospital_organ_codes is empty then returns all given organs
   * otherwise filters the organs based on the organ codes in user.hospital_organ_codes
   *
   * @returns {Organ[]} filtered list of organs by user
   */
  get filterOrgansByUser(): Organ[] {
    const filterBy: { [key: string]: any } = this.user.hospital_organ_codes || {};

    const userOrganCodes = Object.keys(filterBy).map((hospital: string) => filterBy[hospital]).flat();
    if(userOrganCodes.length === 0) {
      return this.filteredOrganLookup;
    }

    return this.filteredOrganLookup.filter((organ) => userOrganCodes.includes(organ.code));
  }

  /**
   * If user.hospital_organ_codes is empty then returns all hospitals from filteredHospitals()
   * otherwise filters the hospitals based on the hospital_id keys in user.hospital_organ_codes
   *
   * @returns {Organ[]} filtered list of ontario transplant programs by user
   */
  get filteredHospitalsByUser(): HospitalOption[]|undefined {
    const hospitals = this.hospitalOptionsOntarioTransplant || [];

    const filterBy = this.user.hospital_organ_codes || {};

    if (Object.keys(filterBy).length === 0) {
      // if there are no user hospital organ codes then return all hospitals
      return hospitals;
    } else {
      // get all hospital keys from user.hospital_organ_codes and filter hospitals by the hospital_id key
      return hospitals.filter((hospital: any) => {
        return Object.keys(filterBy).includes(hospital.code);
      });
    }
  }

  get defaultDate(): string|undefined {
    return this.currentDateUi();
  }

  // pass lookup options to get all options code
  public defaultAllOptions(options: any) {
    return options.map((option: any) => {
      return option.code.toString();
    });
  }

  public buildTransplantActivityReportQuery(): TransplantActivityReportQuery {
    if (!this.editState) {
      return {};
    }
    const organCodes = (this.editState.organ || []);

    const result = {
      organ: organCodes ? organCodes.map((organ: any) => organ.toString()) : null,
      hospital: (this.editState.hospital || []).length == 0 ? this.defaultAllOptions(this.filteredHospitalsByUser) : this.editState.hospital,
      start_date: this.editState.startDate,
      end_date: this.editState.endDate
    };
    return result;
  }

  public generateReport(): void {
    this.$emit('clear');
    const saveToolbar = this.$refs.generateReport as SaveToolbar;
    saveToolbar.startSaving();

    const transplantActivityQuery = this.buildTransplantActivityReportQuery();
    // Submit query
    this.$store.dispatch('reports/printTransplantActivityReport', transplantActivityQuery).then((result: SaveResult) => {

      // Get the filename
      const fileName = result.responseData.report;

      // Is there actually a filename
      if (!!fileName) {
        // Create a link
        const link = document.createElement('a');
        link.href = fileName;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        // Then click it forcing a save/open dialogue
        link.click();
        // Show success notification
      }
      saveToolbar.stopSaving(result);

    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      saveToolbar.stopSaving(error);
    });
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: {[key: string]: string} = {
    'organ'      : 'transplant_activity-organ',
    'hospital'   : 'transplant_activity-transplant-program',
    'start_date' : 'transplant_activity-start_date',
    'end_date'   : 'transplant_activity-end_date'
  }
}
