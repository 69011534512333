import { MutationTree } from 'vuex';
import { UserAccountsState } from '@/store/userAccounts/types';
import Vue from 'vue';

export const mutations: MutationTree<UserAccountsState> = {
  set(state, user) {
    state.selected = user;
  },
  setUserList(state, users) {
    state.userList = users;
  }
};
