



























































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State }  from 'vuex-class';
import { RootState, ObjectId } from '@/store/types';
import { Recipient } from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import CardSection from '@/components/shared/CardSection.vue';
import { LiverHccLab } from '@/store/organSpecificDetails/types';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { LiverDetails } from '@/store/organSpecificDetails/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import { LiverSpecificForm } from '@/components/organs/liver/LiverSpecificDetails.vue';

export interface HccResultForm {
  _id?: { $oid: string };
  dateHccData?: string|null;
  alphaFetoProtein?: number|null;
  totalTumorVolume?: number|null;
  numberNodules?: number|null;
  largestNodule?: number|null;
  diameterAllNodules?: number|null;
  hccScore?: number|null;
}

interface LiverHccRow {
  _id?: { $oid: string };
  dateHccData?: string;
  alphaFetoProtein?: string;
  totalTumorVolume?: string;
  numberNodules?: string;
  largestNodule?: string;
  diameterAllNodules?: string;
  hccScore?: string;
}

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    CardSection,
    NumberInput,
    CheckboxInput,
    BooleanRadioInput,
  }
})
export default class HccResult extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.liverDetails) editState!: LiverSpecificForm;
  @State(state => state.organSpecificDetails.liverHccs) private liverHccs!: LiverHccLab[];
  @State(state => state.organSpecificDetails.selectedLiverHcc) private selectedHcc!: LiverHccLab;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  /**
   * Return true if we can edit the selected measurement
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }

    if (!!this.selectedHcc && !!this.liverHccs) {
      if (this.selectedHcc._id) {
        return this.isLastEntry(this.selectedHcc._id, this.liverHccs);
      }
    }
    return true;
  }

  // load validation rules
  public mounted() {
    this.initializeHccForm();
    if (!this.editState.hccResult!._id) {
      this.$store.dispatch('validations/loadNew', { view: `recipients/${this.recipientId}/liver_hcc_labs/`, action: 'new_validations' });
    } else {
        this.$store.dispatch('validations/loadEdit', { view: `recipients/${this.recipientId}/liver_hcc_labs/`, action: 'edit_validations', clientId: this.journeyId });
    }
  }


  /**
   * Reload list of Liver HCC Labs, the Recipient and Liver Journey, as well as any new Waitlist Decisions.
   * 
   * This is needed because saving a Liver HCC Labs can affect the Journey's Waitlist Factors.
   */
  private loadHccs(): void {
    this.$store.dispatch('organSpecificDetails/loadLiverHccs', this.recipientId).then(() => {
      this.$emit('loaded', 'liverHccs');
    }).catch(() => {
      console.warn('Could not load recipient liver HCC labs.');
    });
    // Reload recipient and journey for latest Waitlist Factors
    this.$store.dispatch('recipients/get', this.recipientId).then(() => {
      this.$store.dispatch('journeyState/getJourney', this.journeyId).then(() => {
        // Reload all waitlist decisions for the recipient's journey
        const waitlistDecisionsConfig = {
          journeyId: this.journeyId,
          recipientId: this.recipientId,
        };
        this.$store.dispatch('journeyState/loadWaitlistDecisions', waitlistDecisionsConfig);
      });
    });
  }

  // Gets table data for recipient liver hcc labs
  get hccRows(): LiverHccRow[] {
       
    if (!this.liverHccs || this.liverHccs.length < 0) {
      return [];
    }
    const result: LiverHccRow[] = [];
    this.liverHccs.forEach((hccLab: LiverHccLab) => {
      const row: LiverHccRow = {
        _id: hccLab._id,
        dateHccData: this.parseDisplayDateUi(hccLab.test_date) || '-',
        alphaFetoProtein: hccLab.afp == undefined ? '-' : hccLab.afp.toString(),
        totalTumorVolume: hccLab.ttv == undefined ? '-' : hccLab.ttv.toString(),
        numberNodules: hccLab.number_of_nodules == undefined ? '-' : hccLab.number_of_nodules.toString(),
        largestNodule: hccLab.largest_nodule_size == undefined ? '-' : hccLab.largest_nodule_size.toString(),
        diameterAllNodules: hccLab.total_nodules_size == undefined ? '-' : hccLab.total_nodules_size.toString(),
        hccScore: hccLab.hcc_score == undefined ? '-' : hccLab.hcc_score.toString()
      };
  
      result.push(row);
    });
    return result;
  }

  // Loads a form edit state based on the lab, or a new state if there is none
  private initializeHccForm(lab?: LiverHccLab): void {
    // Clear table selections from subsection component
    this.$store.commit('organSpecificDetails/selectLiverHcc', lab);
    // Initialize subsection component form edit states
    this.$store.commit('pageState/set', {
      pageKey: 'liverDetails',
      componentKey: 'hccResult',
      value: this.buildHccForm(lab),
    });
    this.$emit('clear');
  }

  /**
   * Returns Sodium Hcc form edit state based on recipient Sodium Hcc lab document
   *
   * @param lab Hcc lab document fetched from API
   * @returns {HccResultForm} editable Hcc form state
   */
  public buildHccForm(lab?: LiverHccLab): HccResultForm {
    if (!lab) {
      return {
        dateHccData: this.currentDateUi(),
      };
    }
    return {
      _id: lab._id,
      dateHccData: this.parseDateUi(lab.test_date),
      alphaFetoProtein: lab.afp,
      totalTumorVolume: lab.ttv,
      numberNodules: lab.number_of_nodules,
      largestNodule: lab.largest_nodule_size,
      diameterAllNodules: lab.total_nodules_size,
      hccScore: lab.hcc_score,
    };
  }

  get hccTableConfig(): TableConfig {
    return {
      data: this.hccRows,
      columns: [
        { label: 'Date of HCC Data ', field: 'dateHccData'},
        { label: 'Alpha-Feto Protein (AFP)', field: 'alphaFetoProtein'},
        { label: 'Total Tumour Volume (TTV)', field: 'totalTumorVolume'},
        { label: 'Number of Nodules', field: 'numberNodules'},
        { label: 'Diameter of Largest Nodule', field: 'largestNodule'},
        { label: 'Total Diameter of all Nodules', field: 'diameterAllNodules'},
        { label: 'HCC Score', field: 'hccScore'},
      ],
      empty: 'Use the form below to add a new HCC',
      createButton: this.canEdit,
      createText: 'Create HCC',
      pagination: true,
    };
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: {[key: string]: string} = {
    'liver_hcc_lab.test_date'          : 'lsd-hcc-date',
    'liver_hcc_lab.afp'                : 'lsd-hcc-afp',
    'liver_hcc_lab.ttv'                : 'lsd-hcc-ttv',
    'liver_hcc_lab.number_of_nodules'  : 'lsd-hcc-nodule-number',
    'liver_hcc_lab.largest_nodule_size': 'lsd-hcc-largest-nodule',
    'liver_hcc_lab.total_nodules_size' : 'lsd-hcc-nodule-diameter',
  };

  // PRIVATE

  //  Builds form edit state based on selected document
  private selectHcc(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (!selectedId || !this.liverHccs) {
      return;
    }
    // Find the selected source document
    const found = this.liverHccs.find((each: LiverHccLab) => {
      return each._id && each._id.$oid === selectedId;
    });
    if (!found) {
      return;
    }
    // Build form state based on selected document
    this.initializeHccForm(found);
  }

  // Build an empty new form edit state
  private createHcc(): void {
    // Build form state
    this.initializeHccForm();
  }

  // Saves current form state for HCC
  private saveHcc(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveHccResult as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'Hcc');
    // Generate payload based on current edit state
    // NOTE: Liver Score Journey ID is needed to handle edge cases where recipient has multiple liver journeys (see APS-198)
    const hccPayload = {
      id: !!this.selectedHcc ? this.selectedHcc._id : undefined,
      recipientId: this.recipient.client_id,
      hcc: this.extractHccPatch(),
      liverScoreJourneyId: this.journeyId,
    };
    // Dispatch save action and register the response
    this.$store.dispatch('organSpecificDetails/saveHcc', hccPayload).then((success: SaveResult) => {
      // If successful reload table data, clear form, and show success notification
      this.initializeHccForm();
      this.loadHccs();
      saveProvider.registerSaveResult(success);
      // Clear any errors
      this.$emit('clear');
      // Load new SMC History
      this.$store.dispatch('organSpecificDetails/loadHistoryLiverSmc', {
        recipientId: this.recipientId, 
        journeyId: this.journeyId
      });
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Returns a patch object containing changes for a Liver Hcc Lab document
  private extractHccPatch(): LiverHccLab {
    if (!this.editState || !this.editState.hccResult) {
      return {};
    }
    const hcc = this.editState.hccResult;

    return {
      test_date: this.sanitizeDateApi(hcc.dateHccData || undefined),
      number_of_nodules: hcc.numberNodules,
      largest_nodule_size: hcc.largestNodule,
      total_nodules_size: hcc.diameterAllNodules,
      ttv: hcc.totalTumorVolume,
      afp: hcc.alphaFetoProtein,
    };
  }
}
