


























































































































































import { TableConfig } from '@/types';
import { VueGoodTable } from 'vue-good-table';
import TextInput from "@/components/shared/TextInput.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import 'vue-good-table/dist/vue-good-table.css';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import CollapsibleHeading from '@/components/shared/CollapsibleHeading.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { SaveProvider, SaveResult, DismissableNotification } from '@/types';

export interface Options {
  [key: string]: string | undefined;
}

@Component({
  components: {
    VueGoodTable,
    SaveToolbar,
    TextInput,
    SelectInput,
    CollapsibleHeading,
  }
})
export default class SubSection extends Vue implements SaveProvider {
  @Prop({ required: true }) subSectionId!: string;
  @Prop({ required: false }) subtitle!: string;
  @Prop({ default: null }) tableConfig!: TableConfig|null;
  @Prop({ default: '' }) title!: string;
  @Prop({ default: false }) saveButton!: boolean;
  @Prop({ default: 'Save' }) saveButtonText!: string;
  @Prop({ default: false }) subSubSection!: boolean;
  @Prop({ required: false }) confirmation?: string;
  @Prop({ required: false }) totalRecords?: number|null;
  @Prop({ required: false }) tabbableValue?: string|null;
  @Prop({ required: false }) mode?: string;
  @Prop({ default: false }) divider!: boolean; // When true, sub-section divider is rendered even without a save button
  @Prop({ default: false }) highlightSelection!: boolean; // when true, highlight selected rows
  @Prop({ default: 'row' }) tableWrapperClass?: string;
  @Prop({ default: false }) disabled?: boolean;
  @Prop({ required: false }) preconfirmation?: string|null; // optional warning text to show before 'confirmation' warning
  @Prop({ required: false }) rowStyleClass?: string|((row: any) => string)
  @Prop({ default: 'card-footer action-row temp-saving row' }) saveToolbarClass?: string;
  @Prop({ default: false }) collapsible?: boolean; // button to collapse or reveal subsection (table and contents)
  @Prop({ default: false }) defaultCollapsed?: boolean; // when true, subsection will initially be rendered as already collapsed
  @Prop({ default: false }) isParentLoading?: boolean; // optional flag to override loading state

  // Lookup properties
  @Prop({ default: () => { return []; } }) lookupsToLoad!: string[];
  @Prop({ default: () => { return []; } }) laboratoriesToLoad!: string[];
  @Prop({ default: () => { return []; } }) hospitalsToLoad!: string[];

  // Public attributes;
  public isLoading = false;
  public collapsed = false;

  //sorting and search
  public sortParams: Options = {};
  public searchParams: Options = {};

  // pagination
  public currentPage = 1;
  public currentPerPage = 25;

  // Private attributes
  private selectedIds: any[] = [];

  public mounted(): void {
    // If sub-section is collapsible, and should default to collapsed, set initial state 
    if (this.collapsible && this.defaultCollapsed) this.collapsed = true;

    // Load any lookups if we have them
    if (this.lookupsToLoad.length > 0) {
      this.getLookups(this.lookupsToLoad, this.laboratoriesToLoad, this.hospitalsToLoad);
    } else {
      this.$emit('loaded');
    }
  }

  labelMatchesTabbableValue(label: string, tabbable: string): boolean {
    if (!tabbable) { return false; }
    if (!label) { return false; }
    return label.replace(/\s/g, '') === tabbable.replace(/\s/g, '');
  }

  getLookups(lookupsToLoad: string[], laboratoriesToLoad: string[], hospitalsToLoad: string[]) {
    const itemsLoaded: string[] = [];
    const numberOfItemsToLoad = lookupsToLoad.length + laboratoriesToLoad.length + hospitalsToLoad.length;
    this.isLoading = true;

    lookupsToLoad.forEach(item => {
      // load them
      this.$store.dispatch('lookups/load', item).then(() => {
        itemsLoaded.push(item);
        if(itemsLoaded.length === numberOfItemsToLoad) {
          this.isLoading = false;
          this.$emit('loaded');
        }
      });
    });

    laboratoriesToLoad.forEach(item => {
      // load them
      this.$store.dispatch('laboratories/load', item).then(() => {
        itemsLoaded.push(item);
        if(itemsLoaded.length === numberOfItemsToLoad) {
          this.isLoading = false;
          this.$emit('loaded');
        }
      });
    });

    if (hospitalsToLoad.length > 0) {
      this.$store.dispatch('hospitals/load').then(() => {
        itemsLoaded.push('hospitals');
        if(itemsLoaded.length === numberOfItemsToLoad) {
          this.isLoading = false;
          this.$emit('loaded');
        }
      });
    }

  }

  get styleClass() {
    // By default use constant table style
    if (!this.highlightSelection) {
      return 'vgt-table table table-bordered table-hover bordered';
    }
    // For selection highlighting, use detailed table design
    return 'vgt-table table table-bordered table-hover bordered table-with-detail';
  }

  get rowStyle(): any {
    // Permit customization of row style class using props
    if (this.rowStyleClass) return this.rowStyleClass;

    // By default use constant row style class
    if (!this.highlightSelection) {
      return 'tr-link';
    }

    // For selection highlighting, use function driven by _id field (styling purposes only)
    return (row: any): string => {
      const id: any = row._id?.$oid || 'UNKNOWN';
      const selected = this.selectedIds.includes(id);
      const result = selected ? 'tr-active' : 'tr-link' ;
      return result;
    };
  }

  // Helper methods
  get paginationOptions(): any {
    if (!this.tableConfig || !this.tableConfig.pagination) {
      // No pagination
      return {};
    } else if (!this.tableConfig.paginationOptions) {
      // Default options if pagination: true
      return {
        enabled: true,
        perPage: 3,
        mode: 'records',
        perPageDropdown: [3, 10],
        setCurrentPage: this.currentPage,
        dropdownAllowAll: true,
        nextLabel: this.$t('table.older').toString(),
        prevLabel: this.$t('table.newer').toString(),
        rowsPerPageLabel: this.$t('table.results').toString(),
      };
    } else {
      return this.tableConfig.paginationOptions;
    }
  }

  /**
   * @returns {object} sorting properties for vue-good-table based on sub-section properties
   */
  get sortOptions(): any {
    if (!this.tableConfig || !this.tableConfig.sortOptions){
      // No sorting
      return { enabled: false };
    } else {
      // Custom options specified in table config
      return this.tableConfig.sortOptions;
    }
  }

  /**
   * @returns {object} searching properties for vue-good-table based on sub-section properties
   */
  get searchOptions(): any {
    if (!this.tableConfig || !this.tableConfig.searchOptions){
      // No searching
      return { enabled: false };
    } else {
      // Custom options specified in table config
      return this.tableConfig.searchOptions;
    }
  }

   /**
   * @returns {object} paging, filtering and sorting options whithin each event.
   */
  get tableParams() {
    return {
      currentPage: this.currentPage,
      currentPerPage: this.currentPerPage,
      searchParams: this.searchParams,
      sortParams: this.sortParams
    };
  }

  // Event handlers
  public onRowClick(event: any) {
    // Update internal sense of which rows are selected (only used for styling purposes)
    const row = event.row || {};
    const id = row._id ? row._id.$oid : 'UNKNOWN';
    Vue.set(this, 'selectedIds', [id]);
    // Emit event to parent, which will handle functional logic separate from styling
    this.$emit('table-row-click', event);
  }

  private searchDebounce: any;

  /**
   * Triggers the filtering search query per column
   * @param {any} event, the column to filter
   * @param {string} field, the text to query
  */
  public updateFilters(event: any, field: string){
    this.currentPage = 1;
    this.searchParams[field] = event;
    if(this.searchDebounce) {
      clearTimeout(this.searchDebounce);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _vm = this;
      setTimeout(() => {
        _vm.$emit('on-column-filter', this.tableParams);
      }, 200);
    }
    this.$forceUpdate();
  }

  /**
   * Triggers the change of page
   * @param {any} event the event for paging
  */
  public onPageChange(event: any) {
    this.currentPage = event.currentPage;
    this.currentPerPage = event.currentPerPage;
    this.$emit('on-page-change', this.tableParams);
  }

  /**
   * Triggers the per page change
   * @param {any} event the event for per page change
  */
  public onPerPageChange(event: any) {
    this.currentPage = event.currentPage;
    this.currentPerPage = event.currentPerPage;
    this.$emit('on-per-page-change', this.tableParams);
  }

  /**
   * Triggers the sort change
   * @param {any} event the event for sorting
  */
  public onSortChange(event: any) {
    if (event.length === 0) {
      return;
    }

    let column = this.tableConfig?.columns.find((c) => {
      return c.field === event[0].field;
    });

    if (column.sortable){
      this.currentPage = 1;
      this.sortParams = { sort_by: event[0].field, sort_type: event[0].type };
      this.$emit('on-sort-change', this.tableParams);
    }
  }

  public onCreateRow(event: any) {
    this.selectedIds = [];
    this.$emit('table-create-row');
  }

  // Generate a unique reference for the save toolbar using the card section ID
  private saveToolbarRef(): string {
    return `save-${this.subSectionId}`;
  }

  // Generate a unique reference for the table component
  get tableRef(): string {
    return `${this.subSectionId}-table`;
  }

  // Use dynamic reference to refer to the save toolbar associated with this specific card section
  private saveToolbar(): SaveToolbar {
    return this.$refs[this.saveToolbarRef()] as SaveToolbar;
  }

  // Handle saving triggered by local save button
  public performSave(): void {
    // Show pre-confirmation prompt if necessary
    if (!!this.preconfirmation) {
      const preconfirmed = confirm(this.preconfirmation);
      if (!preconfirmed) {
        // Cancel save if not pre-confirmed
        return;
      }
    }
    // Show confirmation prompt if necessary
    if (!!this.confirmation) {
      const confirmed = confirm(this.confirmation);
      if (!confirmed) {
        // Cancel save if not confirmed
        return;
      }
    }
    // Show saving notification
    this.saveToolbar().startSaving();
    // Report save event to parent
    this.$emit('save');
  }

  // Handle result of save
  public registerSaveResult(result: SaveResult): void {
    // Show appropriate saving notification if the saveToolbar is still visible
    if (this.saveToolbar()) this.saveToolbar().stopSaving(result);
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Reset save toolbar if is visible
    if (this.saveToolbar()) {
      this.saveToolbar().reset();
    }
  }
}
