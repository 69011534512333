import Vue from 'vue';
import { MutationTree } from 'vuex';
import { AlcoholicLiverDiseaseProgram } from '@/store/organSpecificDetails/types';
import { JourneyState, ReferralDecision, AssessmentDecision, ConsultationDecision, JourneyDurations, WaitlistDecision, PostTransplantFollowUp, WaitDaysAdjustmentEvent } from '@/store/recipientJourney/types';

export const mutations: MutationTree<JourneyState> = {
  setJourney(state, journey) {
    state.selectedJourney = journey;
  },
  clearJourney(state) {
    state.selectedJourney = undefined;
  },
  setOrganSpecificDetails(state, organSpecificDetails) {
    state.selectedJourney!.organ_specific_details = organSpecificDetails;
  },
  selectReferralDecision(state: JourneyState, referralDecision: ReferralDecision): void {
    Vue.set(state, 'selectedReferralDecision', referralDecision);
  },
  clearReferralDecision(state: JourneyState): void {
    Vue.set(state, 'selectedReferralDecision', undefined);
  },
  selectAssessmentDecision(state: JourneyState, assessmentDecision: AssessmentDecision): void {
    Vue.set(state, 'selectedAssessmentDecision', assessmentDecision);
  },
  clearAssessmentDecision(state: JourneyState): void {
    Vue.set(state, 'selectedAssessmentDecision', undefined);
  },
  selectConsultationDecision(state: JourneyState, consultationDecision: ConsultationDecision): void {
    Vue.set(state, 'selectedConsultationDecision', consultationDecision);
  },
  clearConsultationDecision(state: JourneyState): void {
    Vue.set(state, 'selectedConsultationDecision', undefined);
  },
  setJourneyDurations(state: JourneyState, journeyDurations: JourneyDurations): void {
    Vue.set(state, 'journeyDurations', journeyDurations);
  },
  clearJourneyDurations(state: JourneyState): void {
    Vue.set(state, 'journeyDurations', undefined);
  },
  clearWaitlistDecisions(state: JourneyState): void {
    Vue.set(state, 'waitlistDecisions', undefined);
    Vue.set(state, 'selectedWaitlistDecision', undefined);
  },
  setWaitlistDecisions(state: JourneyState, decisions: WaitlistDecision[]): void {
    Vue.set(state, 'waitlistDecisions', decisions);
  },
  clearWaitlistDecision(state: JourneyState): void {
    Vue.set(state, 'selectedWaitlistDecision', undefined);
  },
  selectWaitlistDecision(state: JourneyState, decision: WaitlistDecision): void {
    Vue.set(state, 'selectedWaitlistDecision', decision);
  },
  setTransplantDetails(state, transplantDetails): void {
    Vue.set(state, 'transplantDetails', transplantDetails);
  },
  setPostTransplantFollowUps(state, postTransplantFollowUps) {
    Vue.set(state, 'postTransplantFollowUps', postTransplantFollowUps);
  },
  selectPostTransplantFollowUp(state: JourneyState, postTransplantFollowUp: PostTransplantFollowUp): void {
    Vue.set(state, 'selectedPostTransplantFollowUp', postTransplantFollowUp);
  },
  clearPostTransplantFollowUp(state: JourneyState): void {
    Vue.set(state, 'selectedPostTransplantFollowUp', undefined);
  },
  clearAldProgram(state: JourneyState): void {
    Vue.set(state, 'selectedAldProgram', undefined);
  },
  selectAldProgram(state: JourneyState, aldProgram: AlcoholicLiverDiseaseProgram): void {
    Vue.set(state, 'selectedAldProgram', aldProgram);
  },
  clearWaitTimeOverrideEvents(state: JourneyState): void {
    Vue.set(state, 'waitTimeOverrideEvents', undefined);
  },
  setWaitTimeOverrideEvents(state: JourneyState, events: WaitDaysAdjustmentEvent[]): void {
    Vue.set(state, 'waitTimeOverrideEvents', events);
  },
  selectWaitTimeOverrideEvent(state: JourneyState, event: WaitDaysAdjustmentEvent): void {
    Vue.set(state, 'selectedWaitTimeOverrideEvent', event);
  },
  setValidationErrors(state, errors) {
    Vue.set(state, 'validationErrors', errors);
  }
};