














































































































































































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";
import { OrganCodeValue, OrganCodeChecklistValue } from '@/store/lookups/types';
import { Getter, State } from 'vuex-class';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { GenericCodeValue } from '@/store/types';
import ChecklistHistoryModal from '@/components/livingAllocations/offers/ChecklistHistoryModal.vue';

import SubSection from '@/components/shared/SubSection.vue';
import { LivingDonor } from '@/store/livingDonors/types';
import CardSection from '@/components/shared/CardSection.vue';
import { AllocationControlState } from '@/components/livingAllocations/_AllocationControls.vue';
import { TableConfig, SaveableSection, SaveProvider, SaveResult, TranslationContext } from '@/types';
import { LivingAllocation, LivingAllocationRecipient, LivingAllocationChecklist, LivingDonorDetails, LivingAllocationChecklistForm, LivingAllocationChecklistResponse, LivingChecklistUsers, LivingChecklistFields, LivingAllocationStateValues } from '@/store/livingAllocations/types';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { BloodType, RhIndicator } from '@/store/lookups/types';
import { Hospital } from '@/store/hospitals/types';

interface DonorBlood {
  type: string,
  sub_type: string,
  rh: string
}

@Component({
  components: {
    SubSection,
    CardSection,
    TextInput,
    DateInput,
    CheckboxInput,
    TextAreaInput,
    ChecklistHistoryModal
  }
})

export default class DonorChecklist extends mixins(TranslationUtilsMixin, DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.pageState.currentPage.allocations) allocationControlState!: AllocationControlState;
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;
  @State(state => state.livingAllocations.selected) private allocation!: LivingAllocation;
  @State(state => state.pageState.currentPage.checklistForm) editState!: LivingAllocationChecklistForm;
  @State(state => state.currentUser) private currentUser!: any;
  @State(state => state.livingAllocations.checklistDetails) private checklistDetails!: LivingAllocationChecklistResponse;
  @State(state => state.livingAllocations.isLoadingAllocation) private isLoadingAllocation!: boolean;

  @State(state => state.pageState.currentPage.checklistFields) fields!: LivingChecklistUsers;
  @State(state => state.lookups.rh_indicator) private rhIndicatorLookup!: RhIndicator[];
  @State(state => state.livingAllocations.donorDetails) private donorDetails!: LivingDonorDetails;
  @State(state => state.hospitals.all) hospitals!: Hospital[];

  // Getters
  @Getter('clientId', { namespace: 'livingDonors' }) private clientId!: string|undefined;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('getUser', { namespace: 'users' }) private getUser!: any;
  @Getter('getChecklistHistory', { namespace: 'livingAllocations' }) private getChecklistHistory!: any;
  @Getter('stripHTML', { namespace: 'utilities' }) stripHTML!: (value: string) => string;
  @Getter('primaryOffers', { namespace: 'livingAllocations' }) private primaryOffers!: LivingAllocationRecipient[];
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('specialConsiderations', { namespace: 'livingAllocations' }) private specialConsiderations!: TranslationContext[];

  @Prop({ default: false }) canSave!: boolean;

  private lookupsToLoad = [
    'rh_indicator',
    'tgln_original_region'
  ];

  public openChecklistHistory(): void {
    const checklistHistoryModal = this.$refs.checklistHistoryModal as ChecklistHistoryModal;
    checklistHistoryModal.initializeModal();
  }

  /**
   * Return the organ code
   *
   * Get the organ_code param from the url.
   *
   * @returns {string} organ code
   */
  get organCode(): string {
    return this.$route.params.organ_code?.toString() || '';
  }

  /**
   * Return the organ code with local / provincial if exists
   *
   * Get the organ_code & option param from the url.
   *
   * @returns {string} organ code
   */
  get organCodeWithOption(): string {
    // get option (local/provincial)
    const option = this.$route.params.option || null;
    const organ_code = this.$route.params.organ_code?.toString() || '';
    // if option, append option to organ code
    return option ? `${organ_code}_${option}` : organ_code;
  }

  /**
   * Return the Allocation checklist type
   *
   *
   * @returns {string} type
   */
  get allocationChecklistType(): string {
    if(!this.allocation || !this.editState) {
      return this.$t('pre').toString();
    }
    return this.editState.checklistType;
  }

  /**
   * return true if the pre allocation was already created
   * in order to show it in the table.
   *
   * @returns {boolean} true if pre allocation was created
   */
  get preAllocationCreated(): boolean {
    const checklistDetails = this.checklistDetails || {};
    return checklistDetails && (checklistDetails.pre?.details?.csc1_confirmed || checklistDetails?.pre?.confirmed);
  }

  /**
   * return true if the post allocation was already created
   * in order to show it in the table.
   * @returns {boolean} true if post allocation was created
   */
  get postAllocationCreated(): boolean {
    const checklistDetails = this.checklistDetails || {};
    return this.showPostAllocationChecklist && (checklistDetails?.post?.details?.csc1_confirmed || checklistDetails?.post?.confirmed);
  }

  /**
   * current CSC User

   * Gets the current user. for now is csc 1
   *
   * @returns {string} user
   */
  get cscUser(): string {
    return this.confirmed('csc1') ? 'csc2' : 'csc1';
  }

  /**
   * Gets the title for the current checklist
   *
   * @returns {string} user
   */
  get allocationChecklistTitle(): string {
    const postTitle = this.isReAllocationChecklist ? this.$t('re').toString() : this.$t('post').toString();
    const state = this.isPreAllocationChecklistSelected ? this.$t('pre').toString() : postTitle;
    return `${state}-${this.$t('allocation').toString()}`;
  }

  /**
   * Gets table row data for the checklists
   *
   * @returns {AllocationChecklist[]} Checklists row
   */
  get checklistRows(): any[] {
    const result: LivingAllocationChecklist[] = [];
    if(!this.editState || !this.allocation) {
      return [];
    }
    let checklistData = [];
    const checklistStatus = this.checklistDetails || {};
    // Pre checklist details
    if (this.preAllocationCreated) {
      const row = {
        allocation_id : this.allocation.client_id,
        checklist_date: this.parseDisplayDateUiFromDateTime(checklistStatus.pre?.details?.csc1_confirmed_date) || '-',
        checklist_type: this.$t('pre_allocation').toString(),
        checklist_status: checklistStatus.pre?.confirmed ? this.$t('complete').toString() : (checklistStatus.pre?.details?.csc1_confirmed ? this.$t('pending').toString() : '-'),
        checklist_first_confirmer: checklistStatus.pre?.details?.csc1_confirmed_userid || '-',
        checklist_second_confirmer: checklistStatus.pre?.details?.csc2_confirmed_userid || '-',
        checklist_first_confirmer_date: this.parseDisplayDateUiFromDateTime(checklistStatus.pre?.details?.csc1_confirmed_date) || '-',
        checklist_second_confirmer_date: this.parseDisplayDateUiFromDateTime(checklistStatus.pre?.details?.csc2_confirmed_date) || '-'
      };
      checklistData.push(row);
    }
    // Post checklist details
    if (this.postAllocationCreated) {
      const row = {
        allocation_id : this.allocation.client_id,
        checklist_date: this.parseDisplayDateUiFromDateTime(checklistStatus.post?.details?.csc1_confirmed_date) || '-',
        checklist_type: this.isReAllocationChecklist ? this.$t('re_allocation').toString() : this.$t('post_allocation').toString(),
        checklist_status: checklistStatus.post?.confirmed ? this.$t('complete').toString() : (checklistStatus.post?.details?.csc1_confirmed ? this.$t('pending').toString() : '-'),
        checklist_first_confirmer: checklistStatus.post?.details?.csc1_confirmed_userid || '-',
        checklist_second_confirmer: checklistStatus.post?.details?.csc2_confirmed_userid || '-',
        checklist_first_confirmer_date: this.parseDisplayDateUiFromDateTime(checklistStatus.post?.details?.csc1_confirmed_date) || '-',
        checklist_second_confirmer_date: this.parseDisplayDateUiFromDateTime(checklistStatus.post?.details?.csc2_confirmed_date) || '-'
      };
      checklistData.push(row);
    }
    return checklistData;
  }

  /**
  * Default confirmations fields data
  *
  *
  * @returns {any[]} data
  */
  get checklistConfirmations(): any[] {
    let confirmationsData = this.getConfirmationData();
    return [
      {
        // checked
        fields: [
          { label: this.$t('right_tgln#').toString(), name: 'input_donor_id', value: confirmationsData.donor_id }
        ],
        checkField: 'donor_id',
      },
      {
        // checked
        fields: [
          { label: this.$t('input_abo_height_weight').toString(),
            name: 'input_abo_height_weight',
            value: confirmationsData.abo_height_weight
          },
        ],
        label: this.$t('input_abo_height_weight').toString(),
        checkField: 'blood',
      },
      {
        // checked
        fields: [
          { label: this.$t('right_donor_type').toString(), name: 'input_donor_type', value: confirmationsData.donor_category }
        ],
        checkField: 'donor_type',
        restrictedOrgans: [
          OrganCodeChecklistValue.Heart,
          OrganCodeChecklistValue.Liver,
          OrganCodeChecklistValue.KidneyLocal,
          OrganCodeChecklistValue.KidneyProvincial,
          OrganCodeChecklistValue.PancreasWhole
        ],
      },
      {
        // checked - pre
        fields: [
          { label: this.$t('right_region').toString(), name: 'input_region', value: confirmationsData.region }
        ],
        checkField: 'region',
        restrictedOrgans: [
          OrganCodeChecklistValue.KidneyLocal,
          OrganCodeChecklistValue.KidneyProvincial,
          OrganCodeChecklistValue.Liver
        ],
        checklistRestriction: ['pre']
      },
      {
        // checked - pre
        fields: [
          { label: this.$t('right_region').toString(), name: 'input_region', value: confirmationsData.region }
        ],
        checkField: 'region',
        restrictedOrgans: [
          OrganCodeChecklistValue.KidneyLocal,
          OrganCodeChecklistValue.KidneyProvincial,
          OrganCodeChecklistValue.Liver,
          OrganCodeChecklistValue.PancreasWhole
        ],
        checklistRestriction: ['post', 're']
      },
      {
        // checked
        fields: [{
          label: this.$t('review_offering_plan').toString(),
          name: 'input_offering_plan',
          value: confirmationsData.offering_plan,
          labelOnly: true
        }],
        checkField: 'offering_plan',
        checklistRestriction: ['pre'],
      },
      {
        // checked
        fields: [{
          label: this.$t('right_program').toString(),
          name: 'input_offering_program',
          value: this.allocationProgram,
        }],
        checkField: 'offering_program',
        checklistRestriction: ['post', 're'],
      },
      {
        // checked
        fields: [{
          label: this.$t('right_recipient').toString(),
          name: 'input_offering_recipient',
          value: confirmationsData.offering_recipient,
          labelOnly: true
        }],
        checkField: 'offering_recipient',
        checklistRestriction: ['post', 're'],
      },
      {
        // checked
        fields: [{
          label: this.$t('right_allocation_date_time').toString(),
          name: 'input_allocation_date',
          value: confirmationsData.allocation_date,
        }],
        checkField: 'allocation_date',
      },
      {
        // checked
        fields: [{
          label: this.$t('no_new_high_status_recipients').toString(),
          name: 'input_no_new_high_status_check',
          labelOnly: true,
          value: confirmationsData.no_new_high_status_check
        }],
        checkField: 'no_new_high_status_check',
        restrictedOrgans: [
          OrganCodeChecklistValue.Heart,
          OrganCodeChecklistValue.Liver
        ],
      },
      {
        // checked
        fields: [{
          label: this.$t('tips_confirmed').toString(),
          name: 'input_tips_confirmed',
          value: confirmationsData.tips_confirmed
        }],
        checkField: 'tips_confirmed',
      },
      {
        // checked
        fields: [{
          label: this.$t('ontario_hsp_kidney_export').toString(),
          name: 'input_ontario_hsp_kidney',
          value: confirmationsData.ontario_hsp_kidney
        }],
        checkField: 'ontario_hsp_kidney',
        restrictedOrgans: [
          OrganCodeChecklistValue.KidneyProvincial
        ],
      },
      { // checked
        fields: [{
          label: this.$t('verify_special_considerations').toString(),
          name: 'special_considerations',
          value: confirmationsData.special_considerations,
          textArea: true
        }],
        checkField: 'special_considerations'
      }
    ];
  }

  /**
   * sets the Default confirmation values per checklist into the page state
   *
   *
   * @returns {any[]} data
   */
  public checklistConfirmationsFiltered(confirmations: any[]): any[] {
    return confirmations.filter((item: any) => {

      // start with true for checklist type and organ
      let prePostReAllowed = true;
      let organAllowed = true;

      // if checklist type restricted, is it in the list
      if (item.checklistRestriction) {
        prePostReAllowed = item.checklistRestriction.includes(this.allocationChecklistType.toLowerCase());
      }

      // if organ restricted, is it in the list
      if (item.restrictedOrgans) {
        organAllowed = item.restrictedOrgans.map((organ: any) => organ.toString()).includes(this.organCodeWithOption);
      }

      // if both are still true then don't filter out confirmation, otherwise hide confirmation
      const filteredConfirmation = prePostReAllowed && organAllowed ? true : false;

      // return value
      return filteredConfirmation;
    });
  }

  /**
   * Filters the confirmations based on the type of user given.
   *
   * @returns {any} list of confirmations
   */
  get allowedConfirmations(): any {
    const checklistItems = this.checklistConfirmationsFiltered(this.checklistConfirmations).filter((item: any) => {
      return item?.exceptionList != this.editState?.checklistType;
    });
    return checklistItems;
  }

  /**
   * default check values for the confirmations
   *
   * @param confirmed if the list was previously confirmed
   * @returns {ChecklistFields} patch object containing field changes
   */
  public defaultCheckFields(confirmed?: boolean): LivingChecklistFields{
    // build default checklist fields from what is allowed
    const checkFields: any = {};
    this.allowedConfirmations.map((item: any) => {
      checkFields[item.checkField] = confirmed || false;
    });
    return checkFields;
  }

  /**
  * checks if the current allocation list is a pre allocation checklist
  *
  *
  * @returns {boolean} true if the checklist is a pre allocation checklist
  */
  get isPreAllocationChecklistSelected(): boolean {
    if(this.isPreAllocationChecklist) {
      return true;
    }

    if(!this.editState) {
      return false;
    }

    return this.editState.checklistType.toLowerCase() === 'pre';
  }

  /**
  * checks if the current allocation list is a pre allocation checklist
  *
  *
  * @returns {boolean} true if the checklist is a pre allocation checklist
  */
  get isPreAllocationChecklist(): boolean {
    if(!this.allocation) {
      return false;
    }

    return this.checklistDetails?.pre?.details?.csc1_confirmed &&
           !this.checklistDetails?.pre?.confirmed &&
           !this.checklistDetails?.post?.confirmed;
  }

  /**
  * checks if the post allocation checklist should be shown in the table
  *
  * NOTE: usually checklist is shown in table based on allocation service indicating it has been
  * confirmed once i.e. 'csc1_confirmed: true'. The only special case we need to handle here is the
  * 'Re-Allocation' scenario, where we should hide the prior 'Post-Allocation' as soon as the
  * the allocation state reverts back to 'offering'.
  *
  * @returns {boolean} true if the post allocation checklist can be shown
  */
  get showPostAllocationChecklist(): boolean {
    if(!this.allocation) {
      return false;
    }
    return this.allocation.state !== LivingAllocationStateValues.Offering;
  }

  /**
  * checks if the current allocation list is a post allocation checklist
  *
  *
  * @returns {boolean} true if the checklist is a post allocation checklist
  */
  get isPostAllocationChecklistSelected(): boolean {
    if(!this.editState) {
      return false;
    }

    return this.editState.checklistType.toLowerCase() === 'post';
  }

   /**
  * checks if the current pending allocation checklist is a re-allocation checklist
  * A reallocation checklist is just after the first post allocation was created.
  *
  * @returns {boolean} true if the checklist is a re-allocation checklist
  */
  get isReAllocationChecklist(): boolean {
    if(!this.editState || !this.getChecklistHistory) {
      return false;
    }
    const postChecklists = this.getChecklistHistory?.filter((checklist: any) => checklist.checklist_type === 'post');
    return (postChecklists.length >= 1 && !this.checklistDetails?.post?.confirmed) ||
            postChecklists.length > 1;
  }

  /**
  * checks if the pre allocation checklist can be created
  *
  *
  * @returns {boolean} true if the pre allocation checklist can be created
  */
  get allowPreAllocationChecklist(): boolean {
    if(!this.allocation){
      return false;
    }
    const checklistDetails = this.checklistDetails || {};
    return !checklistDetails?.pre?.details?.csc1_confirmed && !checklistDetails?.post?.details?.csc1_confirmed;
  }

    /**
  * checks if the post allocation checklist can be created
  *
  *
  * @returns {boolean} true if the post allocation checklist can be created
  */
  get allowPostAllocationChecklist(): boolean {
    if(!this.allocation){
      return false;
    }
    const checklistDetails = this.checklistDetails || {};
    return this.allocation.state === 'offer-accepted' &&
           !checklistDetails?.post?.details?.csc1_confirmed &&
           checklistDetails?.pre?.confirmed;
  }


  /**
  * Is the post allocation checklist complete
  *
  * @returns {boolean} true if post allocation complete
  */
  get postChecklistComplete(): boolean {
    return this.checklistDetails?.post?.confirmed;
  }

  /**
  * checks if the re allocation checklist can be created
  *
  *
  * @returns {boolean} true if the re allocation checklist can be created
  */
  get allowReAllocationChecklist(): boolean {
    return false;
  }

  get disableCsc1Confirmation(): boolean {
    return this.editState?.checklistUser === 'csc2' ||
           (this.isPreAllocationChecklistSelected ? this.checklistDetails?.pre?.details?.csc1_confirmed : this.checklistDetails?.post?.details?.csc1_confirmed);
  }

  get disableCsc2Confirmation(): boolean {
    return this.editState?.checklistUser === 'csc1' ||
           (this.isPreAllocationChecklistSelected ? this.checklistDetails?.pre?.details?.csc2_confirmed : this.checklistDetails?.post?.details?.csc2_confirmed) ||
           !this.isCsc2ValidUser;
  }

  get isCsc2ValidUser(): boolean {
    const checklistDetails = this.isPreAllocationChecklistSelected ? this.checklistDetails?.pre?.details : this.checklistDetails?.post?.details;

    if(!this.confirmed('csc1')) { return true; }

    if(checklistDetails?.csc1_confirmed) {
      return this.confirmed('csc1') && checklistDetails?.csc1_confirmed_userid !== this.getUser.user_id;
    }

    return true;
  }

  get allocationProgram(): string {
    const primaryAccepted = this.primaryOffers?.filter((allocationOffer) => allocationOffer.offer?.response_code === 'A');

    return primaryAccepted && primaryAccepted.length > 0 ? primaryAccepted[0].program : '--';
  }

  /**
  *  Checks if an user is confirmed for the current checklist
  *
  * @param user string for the current user
  * @returns {boolean} true if the checklist is a post allocation checklist
  */
  public confirmed( user: string ): boolean {
    if(!this.allocation) {
      return false;
    }

    if(this.isPreAllocationChecklistSelected) {
      return this.allocation?.checklists_status[`pre_${user}_confirmed`];
    }
    else if (this.isPostAllocationChecklistSelected) {
      return this.allocation?.checklists_status[`post_${user}_confirmed`];
    }
    else {
      return false;
    }
  }

  /**
   * Returns the save button text
   *
   *
   * @returns {string} text for the save button
   */
  public saveChecklistButtonText(): string {
    if(!this.editState?.checklistType) {
      return "";
    }
    const postTitle = this.isReAllocationChecklist ? this.$t('re').toString() : this.$t('post').toString();
    const type = this.isPreAllocationChecklistSelected ? this.$t('pre').toString() : postTitle;

    return `${this.$t('save')} ${type}-${this.$t('allocation')}`;
  }


  /**
   * Displays the save button if there are allocation and state data
   *
   *
   * @returns {string} text for the save button
   */
  public saveButtonDisplay(): boolean {
    return !!this.allocation && !!this.editState;
  }

  /**
   * Commits the checklist state that contains the form data depending on the selected checklist
   *
   *
   * @returns {void}
   */
  public showAllocation(type: string): void {
    this.$store.commit('pageState/set', {
      pageKey: 'checklistForm',
      componentKey: 'checklistType',
      value: type
    });
    this.initializeChecklistForm(this.allocation);
    this.defaultChecklistFields();
  }

  /**
   * Emits a loaded event when the component has finished mounting
   *
   * @emits loaded
   */
  private mounted(): void {
    this.$store.dispatch('livingAllocations/getDonorDetails', { livingDonorId: this.clientId, organCode: this.allocation.organ_code, allocationId: this.allocation._id }).finally(() => {
      if (this.allocation) {
        this.loadChecklist();
        this.$emit('loaded', 'donorChecklist');
      }
    });

  }

  /**
   * Reloads the entire form based on current allocation changes
   *
   */
  public loadChecklist(): void {
    Promise.all([
      this.$store.commit('pageState/set',{
        pageKey: 'checklistFields',
        value: null
      }),
      this.$store.dispatch('livingAllocations/getChecklistDetails', { livingDonorId: this.clientId, organCode: this.allocation.organ_code, allocationId: this.allocation._id }),
      this.$store.dispatch('livingAllocations/getChecklistHistory', { livingDonorId: this.clientId, organCode: this.allocation.organ_code, allocationId: this.allocation._id })
    ]).finally(() => {
      this.loadChecklistDetails();
    });
  }

  /**
   * Reloads the entire form based on checklistDetails changes
   *
   */
  @Watch('checklistDetails')
  public loadChecklistDetails(): void {
    this.initializeChecklistForm(this.allocation);
    if(this.confirmed('csc1')){
      this.defaultChecklistFields();
    }
  }

  /**
   * Checks if the given user has confirmed all items
   *
   *
   * @returns {boolean} true if user has confirmed
   */
  public confirmationFor(user: string): boolean {
    let confirmation = true;
    let checklist = this.fields[user];
    Object.keys(checklist).forEach((key: any) => {
      confirmation = confirmation && checklist[key];
      return;
    });
    return confirmation;
  }


  /**
   * Gets configuration for the Checklists table
   *
   * @returns {TableConfig} Configuration for the checklist table
   */
  public selectChecklist(event: any){
    const selected = event.row;
    if (selected.checklist_type === 'Pre-Allocation') {
      this.showAllocation('Pre');
    }
    else if ([this.$t('re_allocation').toString(), this.$t('post_allocation').toString()].includes(selected.checklist_type) &&
             ['offer-accepted', 'offer-confirmed'].includes(this.allocation.state)){
      this.showAllocation('Post');
    }

    this.initializeChecklistForm(this.allocation);
  }

  /**
   * Gets configuration for the Checklists table
   *
   * @returns {TableConfig} Configuration for the checklist table
   */
  get checklistTableConfig(): TableConfig {
    return {
      data: this.checklistRows || [],
      columns: [
        { label: this.$t('checklist_date').toString(), field: 'checklist_date' },
        { label: this.$t('allocation_id').toString(), field: 'allocation_id' },
        { label: this.$t('checklist_type').toString(), field: 'checklist_type' },
        { label: this.$t('checklist_status').toString(), field: 'checklist_status' },
        { label: this.$t('csc_1').toString(), field: 'checklist_first_confirmer' },
        { label: this.$t('csc_signoff_1').toString(), field: 'checklist_first_confirmer_date' },
        { label: this.$t('csc_2').toString(), field: 'checklist_second_confirmer' },
        { label: this.$t('csc_signoff_2').toString(), field: 'checklist_second_confirmer_date' }
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: false,
      pagination: false
    };
  }

  /**
   * Return form edit state based on supplied allocation or empty form if none
   *
   * @param allocation Allocation object
   * @returns {AllocationChecklistForm} ChecklistForm
   */
  public buildChecklistForm(allocation?: LivingAllocation): LivingAllocationChecklistForm {
    const dateAndTime = allocation && allocation.created_at ? this.parseDisplayDateTimeUiFromDateTime(allocation.created_at) : undefined;

    return {
      // Tp 14462 use TGLN ID (living_donor_id) for Living Donor ID
      livingDonorId: this.livingDonor?.living_donor_id,
      allocationId: allocation?._id,
      checklistType: this.allocationChecklistType,
      checklistUser: this.cscUser,
      allocationRunDate: dateAndTime,
      _id: this.livingDonor?._id,
      consentedOrgan: this.allocation ? this.lookupValue(this.organCode, 'organ') : '',
      donorFirstName: allocation?.donor.first_name,
      donorLastName: allocation?.donor.last_name
    };
  }

  /**
   * sets the Default confirmation values per checklist into the page state
   *
   *
   * @returns {void}
   */
  public defaultChecklistFields(): void {
    this.$store.commit('pageState/set',{
      pageKey: 'checklistFields',
      value: {
        csc1: this.defaultCheckFields(this.confirmed('csc1')),
        csc2: this.defaultCheckFields(this.confirmed('csc2'))
      }
    });
  }

  /**
   * Initialize the form and confirmations
   *
   * @param allocation Allocation entry fetched from API, or undefined
   */
  public initializeChecklistForm(allocation?: LivingAllocation): void {
    // Initialize subsection component form edit states
    this.$store.commit('pageState/set', {
      pageKey: 'checklistForm',
      value:  this.buildChecklistForm(allocation)
    });
  }

  /**
   * Gets the form data that is going into the confirmations
   *
   * @return any confirmations fields data
   */
  public getConfirmationData(): any {
    if(!this.donorDetails){
      return {};
    }

    const rh_indicator = this.rhIndicatorLookup?.find((rh) => rh.code === this.donorDetails.donor?.blood_rh );

    const abo_type = this.allocation.donor.blood_type || '--';
    const abo_sub_type = this.allocation.donor.blood_sub_type || '--';
    const rh = rh_indicator?.value || '--';
    const height = this.donorDetails.donor.height || '--';
    const weight = this.donorDetails.donor.weight || '--';

    // Translate, sanitize, and concatenate Special Considerations for this Allocation
    // NOTE: must match exactly what is shown in Allocation Details (see B#14478)
    const translatedConsiderations = this.translateContexts(this.specialConsiderations);
    const special_considerations = this.stripHTML(translatedConsiderations.join(', ')) || '--';

    // Tp 14462 use TGLN ID (living_donor_id) for Living Donor ID
    const tgln_id = this.livingDonor ? this.livingDonor.living_donor_id : '--';

    return {
      donor_id: tgln_id,
      abo_height_weight: `${abo_type}, ${abo_sub_type}, ${rh}, ${height}, ${weight}`,
      region: this.allocation.donor.region_code ? this.lookupValue(this.allocation.donor.region_code, "tgln_original_region") : "--",
      donor_category: this.allocation.donor.donor_category || '--',
      allocation_date: this.allocation.created_at ? this.parseDisplayDateTimeUiFromDateTime(this.allocation.created_at) : '--',
      tips_confirmed: this.numberOfTIPs || '--',
      ontario_hsp_kidney: this.allocation.donor.donor_at_threshold ? this.$t('yes').toString() : this.$t('no').toString() || '--',
      special_considerations: special_considerations
    };
  }

  /**
   * Saves current form state for Checklist
   */
  public savePatch(): void{
    const saveProvider = this.$refs.saveChecklist as unknown as SaveProvider;
    const payload = {
      checklist_type: this.editState.checklistType.toLowerCase(),
      confirmation_type: this.editState.checklistUser === "csc1" ? "first" : "second",
      csc_confirmed: this.confirmationFor(this.editState.checklistUser)
    };
    this.$emit('save', 'saveChecklist');
    // Dispatch save action and register the response
    this.$store.dispatch('livingAllocations/saveChecklist',
      { clientId: this.clientId,
        organCode: this.organCode,
        allocationId: this.allocation._id,
        checklist: payload })
    .then((success: SaveResult) => {
      saveProvider.registerSaveResult(success);
      this.$store.dispatch('livingAllocations/getAllocation', {
        clientId: this.clientId,
        organCode: this.organCode,
        allocationId: this.editState.allocationId });
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  /**
   * Gets a patch object representing form edit state changes for this form
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {any} patch object containing field changes
   */
  public extractPatch(): LivingAllocationChecklistForm {
    if (!this.editState) {
      return {
        checklistType: '',
        checklistUser: ''
      };
    }
    return this.editState;
  }

   /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = this.$refs.saveChecklist as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }

  // return the number of times recipients have a status of 'TRANSPLANT IN PROGRESS' (or TIPS)
  get numberOfTIPs(): string {
    const recipients = this.allocation.recipients || [];
    if (!recipients) { return '--'; }
    const tips = recipients.filter((recipient) => recipient.status.toLowerCase() == 'transplant in progress').length;
    return tips.toString();
  }
}
