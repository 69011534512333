export const journeyStatusMockData: any = {
  referrals: [{
    decision_date: '2019-01-11',
    recipient_coordinator: 'Betty Schmidt',
    decision: 4,
    decision_reason: 'N/A',
    absolute_contraindications: '',
    relative_contraindications: '',
    days: 4
  },{
    decision_date: '2019-01-06',
    recipient_coordinator: 'Betty Schmidt',
    decision: 1,
    decision_reason: 'N/A',
    absolute_contraindications: '',
    relative_contraindications: '',
    days: 5
  },{
    decision_date: '2018-12-28',
    recipient_coordinator: 'Betty Schmidt',
    decision: 2,
    decision_reason: 'N/A',
    absolute_contraindications: '',
    relative_contraindications: '',
    days: 9
  },{
    decision_date: '2018-12-21',
    recipient_coordinator: 'Betty Schmidt',
    decision: 1,
    decision_reason: 'N/A',
    absolute_contraindications: '',
    relative_contraindications: '',
    days: 7
  }],
  consultations: [{
    date: '2019-01-22',
    most_responsible_physician: 'Betty Schmidt',
    consulting_physician: 'Raveesh Nagpal',
    comments: 'comments',
    days: 4
  },{
    date: '2019-01-15',
    most_responsible_physician: 'Betty Schmidt',
    consulting_physician: 'Raveesh Nagpal',
    comments: 'comments',
    days: 7
  }],
  assessments: [{
    outcome_date: '2019-02-03',
    outcome: 'Further treatment required',
    medical_status: 'on hold',
    comments: 'comments',
    days: 1
  },{
    outcome_date: '2019-01-29',
    outcome: 'Further treatment required',
    medical_status: 'on hold',
    comments: 'comments',
    days: 5
  },{
    outcome_date: '2019-01-26',
    outcome: 'Further treatment required',
    medical_status: 'on hold',
    comments: 'comments',
    days: 3
  }]
};