import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, State } from "vuex-class";
import TransplantDetails from '@/components/organs/shared/TransplantDetails.vue';

export interface TransplantDetailsPageState {
  donorId?: string;
  donorClientId?: number;
  transplantDate?: string;
  recipientStatus?: string;
  organTransplantDetails?: any;
  copyToCluster?: boolean;
}

export interface TransplantSectionPageState {
  transplantDetails?: TransplantDetailsPageState;
  isClearingTransplantData?: boolean;
  modalErrorMessages?: string[]|null;
}

@Component
export class TransplantMixin extends Vue {

  @State(state => state.pageState.currentPage.transplantSection) editState!: TransplantSectionPageState;

  @Getter('isClustered', { namespace: 'journeyState', }) isClustered!: boolean;
  @Getter('wasTransplanted', { namespace: 'journeyState', }) wasTransplanted!: boolean;

  /**
 * Secondary confirmation prompt only needed for clustered journey if 'copy' checkbox is false
 *
 * for details see https://shore.tpondemand.com/entity/7541-722-v42-transplant-details
 */
  isConfirmationRequired(): boolean {
    if (!this.editState) return false;

    if (!this.isClustered) return false;

    // do not show popup if transplanted
    if (this.wasTransplanted) return false;

    const transplant = this.editState.transplantDetails || {};
    const copyToCluster = transplant.copyToCluster || false;
    return !copyToCluster;
  }
}
