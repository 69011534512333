import axios from 'axios';
import Vue from 'vue';
import { ActionTree } from 'vuex';
import { SaveResult } from '@/types';
import { APIRoute, EP } from '@/api-endpoints';
import { RootState } from '@/store/types';
import { UserDetails, UserAccountsState } from '@/store/userAccounts/types';

export const actions: ActionTree<UserAccountsState, RootState> = {
  new({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('set', getters.newUser);
      resolve();
    });
  },
  getAll({ commit, getters } ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.users.all);
      axios.get(url).then((response: any) => {
        const sanitizedUsers: UserDetails[] = response.data.users;
        commit('setUserList', sanitizedUsers);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  getUsers({ commit, getters }, { pageNumber, pageSize, search_params }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.users.index, [
        [':page_number', pageNumber],
        [':page_size', pageSize],
        [':search_params', search_params]
      ]);
      axios.get(url).then((response: any) => {
        const sanitizedUsers: UserDetails[] = response.data.users;
        commit('setUserList', sanitizedUsers);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  get({ commit, getters }, { clientId }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.users.show, [[':id', clientId]]);
      axios.get(url).then((response: any) => {
        const sanitizedUser: UserDetails = response.data.user;
        commit('set', sanitizedUser);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  getProfile({ commit, getters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.users.profile.show);
      axios.get(url).then((response: any) => {
        const sanitizedUser: UserDetails = response.data.profile;
        commit('set', sanitizedUser);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
  save({ commit, getters }, { clientId, userDetails }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      if (userDetails) {
        let method: any;
        let ep: string;
        const payload = { user: userDetails };
        if (clientId) {
          method = axios.patch;
          ep = APIRoute(EP.users.update, [[':id', clientId]]);
        } else {
          method = axios.post;
          ep = APIRoute(EP.users.create);
        }
        method(ep, payload).then((response: any) => {
          const isSuccessful = response.data && !response.data.errors;
          if (isSuccessful) {
            // Handle successful response
            resolve({ success: true, responseData: response.data });
          } else if (response.data && response.data.errors) {
            // Handle server-side validation errors
            reject({ success: false, errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
          }
        }).catch((error: any) => {
          // Handle generic errors
          reject({ success: false, errorMessages: [error.message] });
        });
      } else {
        reject();
      }
    });
  },
  saveProfile({ commit, getters }, { userDetails }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      if (userDetails) {
        const payload = { user: userDetails };
        const method = axios.patch;
        const ep = APIRoute(EP.users.profile.update);
        method(ep, payload).then((response: any) => {
          const isSuccessful = response.data && !response.data.errors;
          if (isSuccessful) {
            // Handle successful response
            resolve({ success: true, responseData: response.data });
          } else if (response.data && response.data.errors) {
            // Handle server-side validation errors
            reject({ success: false, errorMessages: ['Cannot save: see error messages above'], validationErrors: response.data.errors });
          }
        }).catch((error: any) => {
          // Handle generic errors
          reject({ success: false, errorMessages: [error.message] });
        });
      } else {
        reject();
      }
    });
  }
};
