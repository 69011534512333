





























import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import { AllocationRecipient } from '@/store/allocations/types';
import CountDownClock from '@/components/shared/CountDownClock.vue';

@Component({
  components: {
    CountDownClock
  }
})
export default class OfferTimers extends mixins(DateUtilsMixin) {
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;
  @State(state => state.currentUser) private currentUser!: any;
  @State(state => state.pageState.currentPage.offerTimers) private editState!: any;

  @Getter('recipients', { namespace: 'allocations' }) private recipients!: AllocationRecipient[];
  @Getter('openPrimaryOffers', { namespace: 'allocations' }) private openPrimaryOffers!: AllocationRecipient[];

  private mounted(): void {
    this.initializeForm();
  }

  /**
   * Watch for changes to the recipients
   *
   * @listens recipients#changed
   */
  @Watch('recipients', { immediate: true, deep: true })
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'offerTimers',
      value: this.buildOfferTimers()
    });
  }

  private buildOfferTimers(): any {
    return {
      primary: this.openPrimaryOffers
    };
  }

  // get duration in seconds
  getSecondsBetween(datetime_effective: string, datetime_expires: string): number {
    const effective = new Date(datetime_effective);
    const expires = new Date(datetime_expires);

    const secondsBetween = expires.getTime() - effective.getTime();
    return secondsBetween;
  }

  // get date and time when offered
  getDateTimeOffered(row: AllocationRecipient): string|unknown {
    if (!row.offer) return undefined;

    return row.offer.datetime_offered ? this.parseTimeUiFromDateTime(row.offer.datetime_offered) : undefined;
  }

  // generate timer attributes for CountDownClock component
  getTimerAttributes(row: AllocationRecipient): Record<string, unknown> {
    const { datetime_effective, datetime_expires } = row.offer || {};

    const effective = datetime_effective || undefined;
    const expires = datetime_expires || undefined;

    return {
      start: effective,
      duration: effective && expires ? this.getSecondsBetween(effective, expires) : 0
    };
  }

}
