































































































import { Component, Vue } from 'vue-property-decorator';
import LoadingSection from '@/components/shared/LoadingSection.vue';

@Component({
  components: {
    LoadingSection
  }
})
export default class LoadingRecipientsPage extends Vue {}
