


































import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { TableConfig } from '@/types';
import { Recipient } from '@/store/recipients/types';
import { CtrLogItem } from '@/store/administration/types';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import SubSection from '@/components/shared/SubSection.vue';
import ModalSection from '@/components/shared/ModalSection.vue';

interface CtrLogRow {
  createdAt: string; // used for sorting
  date: string;
  time: string;
  type: string;
  status: string;
}

@Component({
  components: {
    SubSection,
    ModalSection,
  }
})
export default class CtrLogModal extends mixins(DateUtilsMixin) {
  @State(state => state.recipients.ctrLogs) ctrLogsRecipents!: CtrLogItem[];
  @State(state => state.deceasedDonors.ctrLogs) ctrLogsDonors!: CtrLogItem[];
  @State(state => state.pageState.currentPage) private currentPage!: any;
  @State(state => state.pageState.currentPage.ctrLogs) private editState!: any;
  
  @Getter('sortByDate', { namespace: 'utilities' }) sortByDate!: (collection: any[], fieldName: string) => any[];

  /**
   * Get table configuration details for the subsection
   *
   * @returns {TableConfig} CTR Logs table configuration
   */
  get ctrLogsTableConfig(): TableConfig {
    return {
      data: this.ctrLogRows,
      columns: [
        { label: 'Date', field: 'date' },
        { label: 'Time (EST)', field: 'time' },
        { label: 'Sync Type', field: 'type' },
        { label: 'Status', field: 'status' },
      ],
      empty: this.$t('empty_ctr_logs').toString(),
      pagination: true,
      paginationOptions: {
        enabled: true,
        mode: 'records',
        perPage: 3,
        perPageDropdown: [10, 25, 50],
        dropdownAllowAll: true,
        nextLabel: this.$t('table.older'),
        prevLabel: this.$t('table.newer'),
        rowsPerPageLabel: this.$t('table.results'),
      },
    };
  }
  
  /**
   * Get all rows for table data
   *
   * @returns {CtrLogRow[]} Ctr Log data
   */
  get ctrLogRows(): CtrLogRow[] {
    // Our state isn't set yet
    if (!this.editState || !this.editState.logs) return [];
    // We have no data in the logs
    if (this.editState.logs.length <= 0) return [];
    const results: CtrLogRow[] = [];
    this.editState.logs.forEach((record: CtrLogItem) => {
      const row: CtrLogRow = {
        createdAt: record.created_at,
        date: this.parseDateUiFromDateTime(record.created_at) || '-',
        time: this.parseTimeUiFromDateTime(record.created_at) || '-',
        // TODO: This seems unnecessary, might be safe to remove later
        type: this.editState.type === 'recipients' ? 'Recipient' : 'Donor',
        status: record.status || '-',
      };
      results.push(row);
    });
    // Sort rows by createdAt
    return this.sortByDate(results, 'createdAt');
  }
  
  // Initialize by type (recipients || deceasedDonors)
  public initialize(type: string, clientId: string) {
    // Skip initializing if we have no type or clientId
    if (!type || !clientId) return;
    // Set our initial state
    Vue.set(this.currentPage, 'ctrLogs', { type, logs: [] });
    // Get CTR logs by type and clientId
    this.$store.dispatch(`${type}/getCtrLogs`, { clientId: clientId }).then(() => {
      if (type === 'recipients') {
        // Set state for recipient logs
        Vue.set(this.editState, 'logs', this.ctrLogsRecipents);
      } else if (type === 'deceasedDonors') {
        // Set state for donor logs
        Vue.set(this.editState, 'logs', this.ctrLogsDonors);
      }
      // Toggle the modal
      (this.$refs.ctrLogModal as ModalSection).toggleModal();
    });
  }
}
