import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/lookups/actions';
import { getters } from '@/store/lookups/getters';
import { LookupsState } from '@/store/lookups/types';
import { mutations } from '@/store/lookups/mutations';

// Initialize empty lookups state (all lookups are initially undefined)
const state: LookupsState = {};

const namespaced = true;

export const lookups: Module<LookupsState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state
};
