import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/laboratories/actions';
import { LaboratoriesState } from '@/store/laboratories/types';
import { getters } from '@/store/laboratories/getters';

const state: LaboratoriesState = {};

const namespaced = true;

export const laboratories: Module<LaboratoriesState, RootState> = {
  namespaced,
  actions,
  state,
  getters,
};
