import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { APIRoute, EP } from "@/api-endpoints";
import { CoordinatorsState, Coordinator } from "@/store/coordinators/types";

export const actions: ActionTree<CoordinatorsState, RootState> = {
  load({ commit }, { hospitalId, key = 'filtered' }: { hospitalId: string, key: string }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.coordinators.index, [[':hospital_id', hospitalId]]);
      axios.get(url).then((response: any) => {
        const coordinators: Coordinator[] = response.data.coordinators;
        commit('set', { coordinators, key });
        resolve();
      }).catch((error: any) => {
        reject();
      });
    });
  },
  loadLivingDonorCoordinators({ commit }, { hospitalId, key = 'filtered' }: { hospitalId: string, key: string }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.coordinators.index_living_donors, [[':hospital_id', hospitalId]]);
      axios.get(url).then((response: any) => {
        const coordinators: Coordinator[] = response.data.coordinator;
        commit('set', { coordinators, key });
        resolve();
      }).catch((error: any) => {
        reject();
      });
    });
  }
};
