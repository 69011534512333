import Vue from 'vue';
import axios from 'axios';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { APIRoute, EP } from '@/api-endpoints';
import { HospitalsState, Hospital } from '@/store/hospitals/types';
import { isMasked } from '@/utils';

export const actions: ActionTree<HospitalsState, RootState> = {
  load({ state, commit }, hospitalType): Promise<void> {
    const ht = hospitalType ? hospitalType : 'all';
    return new Promise<void>((resolve, reject) => {
      if(state.loadingTracker[ht] &&
        (state.loadingTracker[ht].currentState === 'loading'
          || state.loadingTracker[ht].currentState === 'loaded')) {
        resolve();
        return;
      }

      // Begin fetching index of hospitals with parameters limiting the response to the specified hospital type
      const url = APIRoute(EP.hospitals.index, [[':hospital_type', ht || '']]);

      commit('startLoadingTracking', ht);
      axios.get(url).then((response: any) => {
        // Set the specified hospitals state to the list of hospitals in the response data
        const hospitals: Hospital[] = response.data.hospitals;
        Vue.set(state, ht, hospitals);
        commit('finishLoadingTracking', ht);
        resolve();
      }).catch((error: any) => {
        commit('resetLoadingTracking', ht);
        console.warn(error);
        reject();
      });
    });
  },
  get({ commit }, hospitalId): Promise<void> {
    const hospitals: Hospital[] = [];
    return new Promise<void>((resolve, reject) => {
      hospitalId.forEach((id: string) => {
        if (isMasked(id)) { resolve(); } // resolve without error
        else {
          const url = APIRoute(EP.hospitals.show, [[":id", id]]);
          axios.get(url).then((response: any) => {
            const hospital: Hospital = response.data.hospital;
            hospitals.push(hospital);
            commit("setTransplantProgram", hospitals);
            resolve();
          }).catch((error: any) => {
            reject();
          });  
        }
      });
    });
  },
};
