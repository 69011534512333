import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { FeaturesState } from '@/store/features/types';
import { actions } from '@/store/features/actions';
import { getters } from '@/store/features/getters';
import { mutations } from '@/store/features/mutations';

const state: FeaturesState = {
  features: undefined
};

const namespaced = true;

export const features: Module<FeaturesState, RootState> = {
  namespaced,
  actions,
  getters,
  mutations,
  state
};
