import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { actions } from '@/store/recipientJourney/actions';
import { getters } from '@/store/recipientJourney/getters';
import { JourneyState } from '@/store/recipientJourney/types';
import { mutations } from '@/store/recipientJourney/mutations';

const state: JourneyState = {
  selectedJourney: undefined,
  validationErrors: {},
};

const namespaced = true;

export const journeyState: Module<JourneyState, RootState> = {
  namespaced,
  mutations,
  actions,
  getters,
  state
};
