import Vue from 'vue';
import { MutationTree } from 'vuex';
import { HospitalsState } from './types';
import { ACTIVE_OOP_TRANSPLANT_PROGRAM, ACTIVE_ONTARIO_TRANSPLANT_PROGRAM } from '@/store/hospitals/types';

export const mutations: MutationTree<HospitalsState> = {
  setTransplantProgram(state, hospitals) {
    Vue.set(state, 'transplantProgram', hospitals);
  },
  startLoadingTracking(state, type: string) {
    Vue.set(state.loadingTracker, type, { currentState: 'loading' });
  },
  finishLoadingTracking(state, type: string) {
    Vue.set(state.loadingTracker, type, { currentState: 'loaded' });
  },
  resetLoadingTracking(state, type: string) {
    Vue.delete(state.loadingTracker, type);
  }
};
