import axios from 'axios';
import Vue from 'vue';
import { ActionTree } from 'vuex';
import { SaveResult } from '@/types';
import { APIRoute, EP } from '@/api-endpoints';
import { RootState } from '@/store/types';
import { FeaturesState, FeatureDetails } from '@/store/features/types';

export const actions: ActionTree<FeaturesState, RootState> = {
  load({ commit, getters }, id): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = APIRoute(EP.features.index);
      axios.get(url).then((response: any) => {
        const features: FeatureDetails = response.data.features;
        commit('set', features);
        resolve();
      }).catch((error: any) => {
        console.log(error);
        reject(error);
      });
    });
  },
};
