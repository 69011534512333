import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { TranslationContext } from '@/types';
import { Hospital } from '@/store/hospitals/types';
import { LivingAllocationResponse, LivingAllocationState, LivingAllocationChecklistResponse, 
  LivingAllocationChecklistDetails, LivingAllocationChecklistForm, LivingAllocation, LivingAllocations, 
  LivingAllocationOffer, LivingAllocationRecipient, LivingAllocationOfferTypeValues, LivingDonorDetails, 
  LivingRecipientDetails, LivingAllocationOfferResponseCodeValues, LivingAllocationOfferRecipient, 
  RANKING_CATEGORY_HSP, SYSTEM_ONLY_ALLOCATION_STATES, RESPONSE_CODES_CONSIDERED_TO_BE_OPEN } from '@/store/livingAllocations/types';
import { GenericCodeValue } from '@/store/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { LivingDonor, LivingDonorOrgan } from '@/store/livingDonors/types';
import { allocations } from '../allocations';

/**
 * Is the specified allocation recipient offer an open / proposed offer?
 *
 * @param offer offer data from allocation recipient entry
 * @returns {boolean} true only if offer is considered open, false otherwise
 */
 function isOpenOffer(offer: LivingAllocationOffer): boolean {
  if (!offer) return false;

  const responseCode = offer.response_code || null;
  return RESPONSE_CODES_CONSIDERED_TO_BE_OPEN.includes(responseCode);
}

export const getters: GetterTree<LivingAllocationState, RootState> = {
  show(state): any {
    return state.exclusionRules;
  },
  selectedAllocation(state): LivingAllocation|undefined {
    return state.selected;
  },
  recipients(state): LivingAllocationRecipient[] {
    if (state.selected && state.selected.recipients) {
      return state.selected.recipients;
    } else {
      return [];
    }
  },

  donorDetails(state): LivingDonorDetails[] | undefined {
    return state.donorDetails;
  },

  checklist(state): LivingAllocationChecklistForm | undefined {
    return state.checklist;
  },

  checklistDetails(state): LivingAllocationChecklistResponse | undefined {
    return state.checklistDetails;
  },

  getChecklistHistory(state): LivingAllocationChecklistDetails[] | undefined {
    return state.checklistHistory || [];
  },

  selectedRecipientDetails(state): LivingRecipientDetails | undefined {
    return state.recipientDetails;
  },
  allAllocations(state): LivingAllocations[] {
    if (state.allAllocations && state.allAllocations.length > 0) {
      return state.allAllocations;
    }
    return [];
  },
  activeAllocations(state): LivingAllocations[] {
    if (state.activeAllocations && state.activeAllocations.length > 0) {
      return state.activeAllocations;
    }
    return [];
  },
  findAllocations(_state, getters, _rootState, rootGetters): any {
    // return Allocation object from an organCode
    return (allocations: LivingAllocations[], organCode: string): LivingAllocation[]|null => {
      // No organCode
      if (organCode == null) return null;

      // No allocations
      if (!allocations || allocations.length === 0) return null;

      // Fetch allocations for organ specified in the Organ Consent data
      const allocationsForOrgan = allocations?.find((allocationList: LivingAllocations) => {
        const allocationOrganCode = allocationList.organ_code ? allocationList.organ_code.toString() : '';
        return allocationOrganCode == organCode;
      });

      // Return if we have no Allocations
      if (!allocationsForOrgan || allocationsForOrgan.allocations.length === 0) return null;

      // Filter allocations by option and return the allocation
      return allocationsForOrgan.allocations;
    };
  },
  findAllocationForPage(_state, getters, _rootState, rootGetters): any {
    // return Allocation object from an organCode
    return (allocations: LivingAllocations[], organCode: string): LivingAllocations|null => {
      // No organCode
      if (organCode == null) return null;

      // No allocations
      if (!allocations || allocations.length === 0) return null;

      // Filter out allocations with system-only states e.g. discontinued, error
      const filteredAllocations = allocations.filter((allocation: any) => {
        return !SYSTEM_ONLY_ALLOCATION_STATES.includes(allocation.state);
      });

      // Filter allocations by option and return the allocation
      return getters.filterAllocationsByOption(filteredAllocations, undefined, undefined);
    };
  },
  filterAllocationsByOption(_state, _getters, _rootState, rootGetters): any {
    return (allocations: LivingAllocation[]): LivingAllocation|null => {

      const sortByDate: any = (rootGetters as any)['utilities/sortByDate'];

      // Return the most recent allocation by created_at
      const result: LivingAllocation|null = sortByDate(allocations, 'created_at')[0];

      // Result to return
      return result;
    };
  },
  getAllocationInfoByConsentedOrgan(state, getters, rootState, rootGeters): any {
    return (organConsent: LivingDonorOrgan): LivingAllocation|null => {
      // Fetch active allocations for organ specified in the Organ Consent data
      const allAllocationsForOrgan = getters.allAllocations?.find((allocationList: LivingAllocations) =>{
        return allocationList.organ_code == organConsent.organ_code;
      });
      // Return if we don't have any allocations
      if (!allAllocationsForOrgan || allAllocationsForOrgan.allocations.length === 0) return null;

      // Filter allocations by option and return the active allocation
      return getters.filterAllocationsByOption(allAllocationsForOrgan.allocations);
    };
  },
  recipientOffer(state, clientId?: any): any {
    // return recipients offer with client_id as input
    return (clientId?: any): LivingAllocationOffer|undefined => {
      // if there is no selected allocation or no clientId
      if (!state.selected || !clientId) {
        return undefined;
      }
      // if there are recipients
      if (state.selected.recipients.length > 0) {
        const recipient = state.selected.recipients.find((item: LivingAllocationRecipient) => {
          return item._id === clientId;
        });
        // is there a recipient and an offer?
        if (recipient && recipient.offer) {
          return recipient.offer;
        }
      }
      return undefined;
    };
  },

  allPrimaryBackupOffers(state): LivingAllocationRecipient[] {
    // return all open primary offers for a selected allocation
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    const allOffers = state.selected.recipients.filter((recipient: LivingAllocationRecipient) => {
      if (!recipient.offer) return false;

      const offerType = recipient.offer.offer_type_code;
      if (offerType == LivingAllocationOfferTypeValues.Primary || offerType == LivingAllocationOfferTypeValues.Backup) {
        if (recipient.offer.response_code != LivingAllocationOfferResponseCodeValues.Withdraw && recipient.offer.response_code != LivingAllocationOfferResponseCodeValues.Cancel) {
          return recipient;
        }
      }
    });
    return allOffers;
  },
  openPrimaryOffers(state): LivingAllocationRecipient[] {
    // return all open primary offers for a selected allocation
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    const openPrimaryOffers = state.selected.recipients.filter((recipient: LivingAllocationRecipient) => {
      if (!recipient.offer) return false;

      if (recipient.offer.offer_type_code === LivingAllocationOfferTypeValues.Primary) {
        if (isOpenOffer(recipient.offer)) {
          return recipient;
        }
      }
    });
    return openPrimaryOffers;
  },
  closedPrimaryOffers(state): LivingAllocationRecipient[] {
    // return all closed primary offers for a selected allocation
    // (a closed offer is considered to have a response_code of not null)
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    const closedPrimaryOffers = state.selected.recipients.filter((recipient: LivingAllocationRecipient) => {
      if (!recipient.offer) return false;

      if (recipient.offer.offer_type_code === LivingAllocationOfferTypeValues.Primary) {
        if (!isOpenOffer(recipient.offer)) {
          return recipient;
        }
      }
    });
    return closedPrimaryOffers;
  },
  primaryOffers(state): LivingAllocationRecipient[] {
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    return state.selected.recipients.filter((recipient: LivingAllocationRecipient) => {
      if (!recipient.offer) return false;

      return recipient.offer.offer_type_code === LivingAllocationOfferTypeValues.Primary;
    });
  },
  openBackupOffers(state): LivingAllocationRecipient[] {
    // return all open backup offers for a selected allocation
    // (an open offer is considered to have a response_code of null)
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    const openBackupOffers = state.selected.recipients.filter((recipient: LivingAllocationRecipient) => {
      if (!recipient.offer) return false;

      if (recipient.offer.offer_type_code === LivingAllocationOfferTypeValues.Backup) {
        if (isOpenOffer(recipient.offer)) {
          return recipient;
        }
      }
    });
    return openBackupOffers;
  },
  closedBackupOffers(state): LivingAllocationRecipient[] {
    // return all closed backup offers for a selected allocation
    // (a closed offer is considered to have a response_code of not null)
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    const closedBackupOffers = state.selected.recipients.filter((recipient: LivingAllocationRecipient) => {
      if (!recipient.offer) return false;

      if (recipient.offer.offer_type_code === LivingAllocationOfferTypeValues.Backup) {
        if (!isOpenOffer(recipient.offer)) {
          return recipient;
        }
      }
    });
    return closedBackupOffers;
  },
  backupOffers(state): LivingAllocationRecipient[] {
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    return state.selected.recipients.filter((recipient: LivingAllocationRecipient) => {
      if (!recipient.offer) return false;

      return recipient.offer.offer_type_code === LivingAllocationOfferTypeValues.Backup;
    });
  },
  openNoOffers(state): LivingAllocationRecipient[] {
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    const openNoOffers = state.selected.recipients.filter((recipient: LivingAllocationRecipient) => {
      if (!recipient.offer) return false;

      if (recipient.offer.offer_type_code == LivingAllocationOfferTypeValues.NoOffer && isOpenOffer(recipient.offer)) {
        return recipient;
      }
    });

    return openNoOffers;
  },
  /**
   * Return filtered offer responses options
   *
   * @returns {GenericCodeValue[]} Response options
   */
  noOffers(state): LivingAllocationRecipient[] {
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    return state.selected.recipients.filter((recipient: LivingAllocationRecipient) => {
      if (!recipient.offer) return false;

      return recipient.offer.offer_type_code === LivingAllocationOfferTypeValues.NoOffer;
    });
  },
  responseOptions(state, getters) {
    return (offer: LivingAllocationResponse, offerResponses: GenericCodeValue[]): GenericCodeValue[] => {
      if (offerResponses && offerResponses.length <= 0) return [];
      const offerType: any = offer.offerType;
      const responseCode: any = offer.responseCode;
      const filteredOfferResponses = offerResponses.filter((item: any) => {
        switch(item.code) {
          case LivingAllocationOfferResponseCodeValues.RequestExtension:
            return offerType == LivingAllocationOfferTypeValues.Primary;
            break;
          case LivingAllocationOfferResponseCodeValues.Accept:
            return [LivingAllocationOfferTypeValues.Primary, LivingAllocationOfferTypeValues.Backup].includes(offerType);
            break;
          case LivingAllocationOfferResponseCodeValues.Cancel:
          case LivingAllocationOfferResponseCodeValues.Withdraw:
          case LivingAllocationOfferResponseCodeValues.TimeOut:
            return false;
            break;
          case LivingAllocationOfferResponseCodeValues.Decline:
            if (responseCode !== LivingAllocationOfferResponseCodeValues.AcceptWithCondition) {
              return true;
            }
            break;
          default:
            return true;
            break;
        }
      });
      return filteredOfferResponses;
    };
  },

  /**
   * Return Reason Category options based on selected type
   *
   * Conditionals for Decline require a further sub set of options
   * based on the organ_code.
   *
   * @returns {GenericCodeValue[]} Reason Category options
   */
  reasonCategoryOptions(state, getters) {
    return (offer: LivingAllocationResponse, offerResponses: GenericCodeValue[], organCode: string): GenericCodeValue[] => {
      const responseCode = offer.responseCode;
      switch (responseCode) {
        case LivingAllocationOfferResponseCodeValues.AcceptWithCondition:
        case LivingAllocationOfferResponseCodeValues.Withdraw:
        case LivingAllocationOfferResponseCodeValues.RequestExtension:
          const reasonCategoryOptions = offerResponses.find((item: GenericCodeValue) => {
            return item.code == responseCode;
          });
          return reasonCategoryOptions?.sub_tables?.offer_reason_categories || [];
          break;
        case LivingAllocationOfferResponseCodeValues.Cancel:
        case LivingAllocationOfferResponseCodeValues.Decline:
          // Get response type lookup value
          const offerResponseType = offerResponses.find((item: any) => {
            return item.code == responseCode;
          });
          // Get organ specific sub table
          const organReasonCategories = offerResponseType?.sub_tables?.organ_specific_categories_reasons;
          if (!organReasonCategories || organReasonCategories.length <= 0) {
            return [];
          }
          // Get organ specific reason categories
          const organReasonCategoryOptions = offerResponseType?.sub_tables?.organ_specific_categories_reasons.find((item: any) => {
            return item.code == organCode;
          });
          return organReasonCategoryOptions.sub_tables.offer_reason_categories;
          break;
        default:
          return [];
          break;
      }
    };
  },

  /**
   * Return filtered reason options
   *
   * @returns {GenericCodeValue[]} Reason options
   */
  reasonOptions(state, getters) {
    return (offer: LivingAllocationResponse, offerResponses: GenericCodeValue[], organCode: string): GenericCodeValue[] => {
      const responseCategoryCode = offer.responseCategoryCode;
      if (responseCategoryCode == null) {
        return [];
      }
      const reasonOptions = getters.reasonCategoryOptions(offer, offerResponses, organCode).find((item: GenericCodeValue) => {
        return item.code == responseCategoryCode.toString();
      });
      return reasonOptions ? reasonOptions.sub_tables.offer_reasons : [];
    };
  },

  // Disable response options
  disableResponseOptions(state, getters) {
    return (offer: LivingAllocationResponse): boolean => {
      const offerType = offer.offerType;
      if (offerType == LivingAllocationOfferResponseCodeValues.Accept || offerType == LivingAllocationOfferResponseCodeValues.Decline || offerType == LivingAllocationOfferResponseCodeValues.RequestExtension) {
        return true;
      }
      return !offerType ? true : false;
    };
  },

  // Disable response category options
  disableResponseCategoryOptions(state, getters) {
    return (offer: LivingAllocationResponse): boolean => {
      const responseCode = offer.responseCode;
      if (responseCode == LivingAllocationOfferResponseCodeValues.AcceptWithCondition || responseCode == LivingAllocationOfferResponseCodeValues.Decline || responseCode == LivingAllocationOfferResponseCodeValues.RequestExtension) {
        return false;
      }
      return true;
    };
  },
  /**
   * Return offer Response Category value
   *
   * @param responseCode response code
   * @param responseCategoryCode response category code
   * @param offerResponses list of offer responses
   * @param organCode organ code
   * @returns {string} response category value
   */
  offerResponseCategory(state, getters) {
    return (responseCode: string|null, responseCategoryCode: number|null, offerResponses: any[], organCode: number): string|undefined => {
      const offer = { responseCode };
      const responseCategory = getters.reasonCategoryOptions(offer, offerResponses, organCode).find((item: GenericCodeValue) => {
        return Number(item.code) == responseCategoryCode;
      });
      if (responseCategory) {
        return responseCategory.value;
      } else {
        return undefined;
      }
    };
  },
  /**
   * Return offer Response Reason value
   *
   * @param responseCode response code
   * @param responseCategoryCode response category code
   * @param reasonCode reason code
   * @param offerResponses list of offer responses
   * @param organCode organ code
   * @returns {string} reason code value
   */
  offerResponseReason(state, getters) {
    return (responseCode: string|null, responseCategoryCode: number|null, reasonCode: number|null, offerResponses: any[], organCode: string): string|undefined => {
      if (responseCode == null || responseCategoryCode == null || reasonCode == null) {
        return undefined;
      }
      const offer = { responseCode };
      const responseCategory = getters.reasonCategoryOptions(offer, offerResponses, organCode).find((item: GenericCodeValue) => {
        return Number(item.code) == responseCategoryCode;
      });
      if (responseCategory && responseCategory.sub_tables) {
        const reasons = responseCategory.sub_tables.offer_reasons;
        const reason = reasons.find((item: GenericCodeValue) => Number(item.code) == reasonCode);
        return reason ? reason.value : undefined;
      } else {
        return undefined;
      }
    };
  },

  /**
   * Check if offer has been confirmed
   *
   * @param offer allocation offer
   * @returns {boolean} offer confirmed
   */
  isOfferConfirmed(state) {
    return (offer: LivingAllocation): boolean => {
      if (!offer) return false;
      return offer.state === 'offer-confirmed';
    };
  },

  /**
   * Check if offer has been accepted
   *
   * @param offer allocation offer
   * @returns {boolean} offer accepted
   */
  isOfferAccepted(state) {
    return (offer: LivingAllocation): boolean => {
      if (!offer) return false;
      return offer.state === 'offer-accepted';
    };
  },

  /**
   * Check if offer is backup
   *
   * @param offer allocation offer
   * @returns {boolean} offer backup
   */
   isOfferingBackup(state) {
    return (offer: any): boolean => {
      if (!offer) return false;
      if (offer.offered_recipients && offer.offered_recipients.length == 0) return false;
      return offer.offered_recipients[0].offer_type_code == LivingAllocationOfferTypeValues.Backup;
    };
  },

  /**
   * Check if the selected Allocation is offerable
   *
   * Allocation state is offering, offer-accepted or offer-confirmed and there are selected rows
   *
   * @returns {boolean} Allocation is offerable
   */
  isAllocationOfferable(state) {
    // There is no selected Allocation
    if (!state.selected) return false;
    // What states are offerable
    const allocationOfferableStates = ['offering', 'offer-accepted', 'offer-confirmed'];
    return allocationOfferableStates.includes(state.selected.state);
  },

  /**
   * Special Considerations for the selected Allocation
   *
   * This determines what is shown in both Allocation Details and Checklists, which must always
   * match to ensure user recipients are not unintentionally missed (see B#14478)
   *
   * NOTE: relies on 'deceasedDonors' and 'hospitals' modules to handle some considerations
   *
   * @returns {TranslationContext[]} array of objects containing i18n translation keys and template values
   */
   specialConsiderations(state, getters, rootState, rootGetters): TranslationContext[] {
    if (!state.selected) return [];

    // Derive special considerations from Allocation
    const considerations: TranslationContext[] = [];
    const allocation: LivingAllocation = state.selected;

    // Kidney-specific considerations
    if (allocation.organ_code === OrganCodeValue.Kidney) {
      // Allocation Type e.g. Local or Provincial
      const allocationType = (allocation.allocation_type || 'unknown').toLowerCase();

      // Add Kidney Special Consideration message
      considerations.push({ key: 'special_consideration.allocation_type', values: { allocationType } });

      // Check for ECD flag and add consideration message
      if (allocation.donor.ecd) considerations.push({ key: 'special_consideration.ecd' });
    }

    // Manually added Recipients and Out-of-province Programs
    // NOTE: for Out-of-province Programs, we need to load program name from hospitals module
    const recipients: LivingAllocationRecipient[] = allocation.recipients || [];
    const manualRecipients: LivingAllocationRecipient[] = recipients.filter((recipient: LivingAllocationRecipient) => {
      // NOTE: here we use an explicit equality check, to prevent "*******" from being treated as truthy
      return recipient.added_manually === true;
    });
    if (manualRecipients.length > 0) {
      const allHospitals = rootState.hospitals?.all || [];
      manualRecipients.forEach((recipient: LivingAllocationRecipient) => {
        if (!recipient.out_of_province) {
          const recipientId = recipient.client_id.toString();
          considerations.push({ key: 'special_consideration.manually_added.recipient', values: { recipientId } });
        } else {
          const hospitalId = recipient.hospital_id;
          const recipientHospital = allHospitals.find((hospital: Hospital) => {
            return hospital._id.$oid === hospitalId;
          });
          const hospitalName = recipientHospital?.hospital_name_info?.name || 'unknown';
          if (hospitalName) considerations.push({ key: 'special_consideration.manually_added.out_of_province_program', values: { hospitalName } });
        }
      });
    }

    // Expedited Allocation
    if (allocation.expedited) {

      // Expedited Reason: Missing Donor Virology
      if (allocation.expedited_reasons.donor_virology_missing) {
        considerations.push({ key: 'special_consideration.expedited_allocation.missing_virology' });
      }

      // Expedited Allocation: Missing donor HLA Test Results
      if (allocation.expedited_reasons.donor_hla_typing_missing) {
        considerations.push({ key: 'special_consideration.expedited_allocation.missing_hla' });
      }

      // If we're an Expedited Allocation but we're not showing Virology
      // or HLA missing show an Unknown message
      if (!allocation.expedited_reasons.donor_virology_missing
          && !allocation.expedited_reasons.donor_hla_typing_missing) {
        // Expedited Allocation: Unknown
        considerations.push({ key: 'special_consideration.expedited_allocation.unknown' });
      }
    }

    return considerations;
  },

  /**
  * Return if the selected Allocation is provincial
  *
  * @returns {boolean} true if allocation_type is provincial
  */
   isProvincialAllocation(state): boolean {
    return state.selected?.allocation_type?.toLowerCase() === 'provincial';
  },

  /**
  * Return if the selected Allocation is local
  *
  * @returns {boolean} true if allocation_type is local
  */
  isLocalAllocation(state): boolean {
    if (!state.selected || !state.selected.allocation_type) return false;

    return state.selected.allocation_type.toLowerCase() === 'local';
  },

  /**
   * Return a list of recipients from the selected allocation
   *
   * From the selected allocation, take the effective_rank and
   * find the corresponding recipient in the allocation.
   *
   * @param recipientRanks array of effective_rank
   * @returns {AllocationOfferRecipient[]} array of recipients id and offer_organ_code
   */
   getRecipientsByEffectiveRank(state, getters) {
     return (recipientRanks: number[]): LivingAllocationOfferRecipient[] => {
      const validRecipients = getters.selectedAllocation.recipients.filter((recipient: LivingAllocationRecipient) => {
        return recipientRanks.includes(recipient.effective_rank);
      });
      const recipientPayload: LivingAllocationOfferRecipient[] = validRecipients.map((recipient: LivingAllocationRecipient) => {
        const entry: LivingAllocationOfferRecipient = {
          id: recipient._id,
          effective_rank: recipient.effective_rank,
          offer_organ_code: recipient.organ_code,
          hsp: getters.determineHspValue(recipient),
          re_offer_scenario: recipient.re_offer_scenario,
          cluster_organ_code: recipient.cluster_organ_code,
          offer_type_code: recipient?.offer?.offer_type_code || null,
          response_code: recipient?.offer?.response_code || null,
          offered_to_2nd_category: getters.offeringToSecondCategory(recipient)
        };
        return entry;
      });
      return recipientPayload;
    };
  },

  determineHspValue(state, getters) {
    return (recipient: LivingAllocationRecipient): string => {
      // if HSP Patient Identified by CTR, show 'HSP' indicator
      return recipient.ranking_category && recipient.ranking_category == RANKING_CATEGORY_HSP ? 'HSP' : '-';
    };
  },

  /**
   * Derive 'offered_to_2nd_category' flag as needed: true if an offer
   * being made to this recipient's entry would be an offer to their
   * 'second category' in the ranking rules.
   *
   * E.g. an HSP Kidney recipient with 'Medically Urgent' medical
   * status (H) can appear twice in a Kidney (Provincial) listing. In
   * this case the 'HSP Step' is the first category, and 'Medically
   * Urgent' is the second category.
   *
   * Essentially, all we need to do here is check if the recipient has
   * the 'is_2nd_ranking_entry' or 'has_2nd_ranking_entry' flags set.
   * If so, we send a boolean value for 'offered_to_2nd_category'.
   *
   * Note: this determination is only needed for recipients with two
   * entries in the allocation for the same organ. Most recipients will
   * have only one entry and for them this function will return 'false'.
   *
   * @param recipientEntry recipient row from Allocation Recommendation
   *
   * @returns {boolean} true only if offered to 'is_2nd_ranking_entry'
   */
   offeringToSecondCategory(state, getters) {
    return (recipientEntry: LivingAllocationRecipient): boolean|undefined => {
      return !!recipientEntry.is_2nd_ranking_entry;
    };
  }
};
