






















import { Getter, State } from 'vuex-class';
import PageTop from '@/components/shared/PageTop.vue';
import { Component, Vue } from 'vue-property-decorator';
import SelectInput from '@/components/shared/SelectInput.vue';
import SideNavAdmin from '@/components/administration/side-nav/SideNavAdmin.vue';

@Component({
  components: {
    PageTop,
    SideNavAdmin
  }
})
export default class Administration extends Vue {
}
