import Vue from 'vue';
import { MutationTree } from 'vuex';
import { AdministrationState, CtrLogItem } from '@/store/administration/types';

export const mutations: MutationTree<AdministrationState> = {
  setCtrIntegrationLog(state: AdministrationState, ctrLogItems?: CtrLogItem[]) {
    Vue.set(state, 'ctrIntegrationLog', ctrLogItems);
  },
  setEnvironment(state: AdministrationState, url: string) {
    Vue.set(state, 'environment', url);
  }
};
