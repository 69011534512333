
























































































import { Getter, State } from 'vuex-class';
import { GenericCodeValue } from '@/store/types';
import ModalSection from '@/components/shared/ModalSection.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { DonorDetails, RecipientDetails } from '@/store/allocations/types';
import { LivingDonor } from '@/store/livingDonors/types';
import { LivingAllocationResponse, LivingAllocationRecipient, LivingAllocationResponseAction, LivingAllocationOfferResponseCodeValues, LivingAllocationOfferTypeValues, LivingAllocation } from '@/store/livingAllocations/types';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import { AllocationErrorsMixin } from "@/mixins/allocation-errors-mixin";
import { mixins } from 'vue-class-component';
import { SYSTEM_ONLY_EXCEPTIONAL_DISTRIBUTION_REASONS } from '@/store/lookups/types';

interface LivingAllocationResponseForm {
  changed?: boolean;
  _id?: string;
  offerType?: string;
  rank?: number;
  tgln_id?: number;
  lastName?: string;
  transplantProgram?: string;
  organ?: string;
  offerDateTime?: string;
  offeredBy?: string;
  hsp?: string;
  type?: string;
  responseCode?: string;
  responseCategoryCode?: number|null;
  responseReasonCode?: number|null;
  responseDateTime?: string;
  responsiblePhysician?: string;
  responseBy?: string;
  recipientStatus?: string;
  exceptional_distribution?: {
    accepted?: boolean,
    accepted_reason_code?: number|null,
    accepted_reason_other?: string|null
  }
}

@Component({
  components: {
    ModalSection,
    CheckboxInput,
    SelectOtherInput,
    TextInput
  }
})
export default class OfferResponseAccept extends mixins(AllocationErrorsMixin) {
  @State(state => state.livingDonors.selectedLivingDonor) private livingDonor!: LivingDonor;
  @State(state => state.pageState.currentPage.offerResponseAccept) private editState!: any;
  @State(state => state.livingAllocations.donorDetails) private donorDetails!: DonorDetails;
  @State(state => state.livingAllocations.isLoadingAllocation) private isLoadingAllocation!: boolean;
  @State(state => state.livingAllocations.isRespondingOffer) private isRespondingOffer!: boolean;

  @Getter('clientId', { namespace: 'livingDonors' }) private donorId!: string;
  @Getter('selectedAllocation', { namespace: 'livingAllocations' }) private allocation!: LivingAllocation;
  @Getter('exdAcceptanceReasons', { namespace: 'lookups' }) private exdAcceptanceReasons!: any;
  @Getter('lookupValueNumeric', { namespace: 'lookups' }) lookupValueNumeric!: (code: number, lookupId: string) => string|null;
  
  offerResponseErrorMessage = ''; // Error message to show

  /**
   * Clears EXD acceptance on change
   *
   */
  public clearExdAcceptanceOther(): void {
    Vue.set(this.editState, 'exd_reason_other', undefined);
  }

  /**
   * Initializes modal
   *
   * @param responsePatch original response details
   * @param organCode organ code
   */
  public initializeModal(responsePatch: any[], organCode: string, ): void {
    // build state from valid ids
    this.buildState(responsePatch, organCode);
    (this.$refs.offerResponseAccept as ModalSection).toggleStaticModal();
    this.clearOfferResponseState();
  }

  /**
   * Builds a list of EXD reasons based on exd_reason_codes 
   * using exceptionalDistributionLookup for display
   *
   * @returns {string[]} array of exd reasons
   */
  get getExdReasonList(): string[] {
    // get list of exd reason codes
    const exd_reason_codes = this.donorDetails?.donor?.exd_reason_codes || [];

    // filter out system-only reasons (see B#15171)
    const filtered = exd_reason_codes.filter((code: number) => {
      return !SYSTEM_ONLY_EXCEPTIONAL_DISTRIBUTION_REASONS.includes(code);
    });

    // Sort codes by number and then map to display text
    const list = filtered.sort((x: any, y: any) => x > y ? 1 : -1).map((code: number): string => {
      return this.lookupValueNumeric(code, 'donor_exceptional_distribution') || 'Unknown';
    });

    // add other exd reasons to list
    if (this.donorDetails?.donor?.exd_reason_details_travel) { list.push(`Exceptional Distribution Travel Reason: ${this.donorDetails?.donor?.exd_reason_details_travel}`); }
    if (this.donorDetails?.donor?.exd_reason_details_other) { list.push(`Exceptional Distribution Other Reason: ${this.donorDetails?.donor?.exd_reason_details_other}`); }
    if (this.donorDetails?.donor?.exd_reason_details_transmission) { list.push(`Increased Risk of Transmission Reason: ${this.donorDetails?.donor?.exd_reason_details_transmission}`); }

    return list;
  }

  /**
   * Clears the dialog state
   *
   */
  private clearOfferResponseState(): void {
    Vue.set(this.editState, 'offerResponseAccept', {
      exceptional_distribution: false,
      exd_reason_codes: undefined,
      exd_reason_other: undefined
    });
    // clear error messages on initializing model
    (this.$refs.offerResponseAcceptValidations as any).reset();
    this.offerResponseErrorMessage = '';
    this.closeModal();
  }

  /**
   * Builds the Offer Response state from recipientIds
   *
   * @param responsePatch original response details
   * @param organCode organ code
   */
  private buildState(responsePatch: any[], organCode: string): void {
    this.$store.commit('pageState/set', {
      pageKey: 'offerResponseAccept',
      value: {
        organCode: organCode,
        responsePatch: responsePatch,
        exceptional_distribution: false,
        exd_reason_codes: undefined,
        exd_reason_details_other: undefined
      }
    });
  }

  /**
   * Cancels the modal modal
   *
   */
  private closeModal(): void {
    (this.$refs.offerResponseAccept as ModalSection).hideModal();
    if(this.offerResponseErrorMessage) this.$emit('reloadTable');
  }

  /**
   * Confirm and saves response
   *
   */
  private confirmChanges(): void {
    this.performPatch();
  }

  /**
   * Get a string representation the organ_code
   *
   * @returns {string} organ_code param as a string
   */
  get organCode(): string {
    return this.$route.params.organ_code ? this.$route.params.organ_code.toString() : '';
  }

  /**
   * Appends data to patch
   *
   * @returns {LivingAllocationResponseAction} allocation response
   */
  private appendToResponsePatch(): LivingAllocationResponseAction[] {
    const responsePatch = this.editState.responsePatch;
    const newResponsePatch: any[] = [];
    responsePatch.forEach((response: LivingAllocationResponseForm) => {
      const newResponse = {
        ...response
      };
      if (newResponse.type === LivingAllocationOfferResponseCodeValues.Accept ||
          newResponse.type === LivingAllocationOfferResponseCodeValues.AcceptWithCondition) {
        // send acceptance details (from story 7.2.1)
        newResponse.exceptional_distribution = {
          accepted: this.editState.exceptional_distribution,
          accepted_reason_code: Number(this.editState.exd_reason_code),
          // always send a value back, even if it's null
          accepted_reason_other: this.editState.exd_reason_other ? this.editState.exd_reason_other : ""
        };
      }
      newResponsePatch.push(newResponse);
    });
    return newResponsePatch;
  }

  /**
   * Performs the patch operation
   *
   */
  private performPatch(): void {
    // build patch from edited rows
    const newPatch = this.appendToResponsePatch();
    const payload = {
      clientId: this.donorId,
      organCode: this.organCode,
      allocationId: this.allocation._id,
      responseDetails: newPatch
    };

    // clear error message
    this.offerResponseErrorMessage = "";

    this.$store.commit('livingAllocations/startRespondingOffer');
    this.$store.dispatch('livingAllocations/respondOffer', payload).then((success: any) => {
      this.$store.commit('livingAllocations/stopRespondingOffer');
      this.clearOfferResponseState();
      this.$emit('reloadTable');
    }).catch((error: any) => {
      this.$store.commit('livingAllocations/stopRespondingOffer');
      const error_message = this.getErrorMessage(error);
      this.offerResponseErrorMessage = error_message;
    });
  }
}
